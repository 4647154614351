import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class NotificationModule extends VuexModule {
  listNotifikasi = [];
  isEmpty = false;
  recordUnread = 0;


  @Action
  [Actions.GET_NOTIFIKASI](payload:any) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`/api/v1/inbox`, {
        params: {
          ...payload
        }
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_NOTIFIKASI, data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Mutation
  [Mutations.SET_NOTIFIKASI](data:any) {
    this.listNotifikasi = data?.data || [];
    if (data?.data.length === 0) {
      this.isEmpty = true
    }
    this.recordUnread = data?.meta?.totalUnread || 0;
  }

  get getListNotifikasi() {
    return this.listNotifikasi;
  }

  get getIsEmpty() {
    return this.isEmpty;
  }

  get getIsLoading() {
    return true;
  }

  get getUnread() {
    return this.recordUnread;
  }

}
