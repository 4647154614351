import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
export interface ITheme {
  id: string;
  code: string;
  name: string;
  description: string;
  color: string;
  deleted_at: Date | null;
  created_at: Date;
  updated_at: Date;
}

@Module
export default class ThemeModule extends VuexModule {
  serviceName =
    process.env.VUE_APP_NODE_ENV === "local" ? "auth/api/v1" : "auth/api/v1";
  loading = false;
  page = 1;
  itemsPerPage = 100;
  trash = 0;
  name = "";
  currentTheme = null;
  themes = Array<ITheme>();

  @Mutation
  [Mutations.SET_THEME_LOADING](loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  [Mutations.SET_CURRENT_THEME](theme) {
    this.currentTheme = theme;
  }

  @Mutation
  [Mutations.SET_THEMES](themes) {
    this.themes = themes;
  }

  get isLoading(): boolean {
    return this.loading;
  }

  get getCurrentTheme() {
    return this.currentTheme;
  }

  get themeQueryParams() {
    return {
      page: this.page,
      itemsPerPage: this.itemsPerPage,
      trash: this.trash,
    };
  }

  get themeList(): Array<ITheme> {
    return this.themes;
  }

  @Action
  [Actions.UPDATE_CURRENT_THEME](theme) {
    this.context.commit(Mutations.SET_CURRENT_THEME, theme);
  }

  @Action({ rawError: true })
  [Actions.GET_THEME_LIST]() {
    this.context.commit(Mutations.SET_THEME_LOADING, true);
    const url = new URLSearchParams(this.context.getters.themeQueryParams);
    return ApiService.get(`${this.serviceName}/theme`, `?${url.toString()}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_THEMES, data.data.data);
        return Promise.resolve(data);
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR, error);
        return Promise.reject(error);
      })
      .finally(() =>
        this.context.commit(Mutations.SET_LANGUAGE_LOADING, false)
      );
  }
}
