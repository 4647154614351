import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";
export interface ITheme {
  id: string;
  code: string;
  name: string;
  description: string;
  color: string;
  deleted_at: Date | null;
  created_at: Date;
  updated_at: Date;
}

@Module
export default class TenantModule extends VuexModule {
  serviceName = "auth/api/v1";

  @Action
  [Actions.GET_DASHBOARD_SETTING](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${this.serviceName}/tenant/setting`, {
        params: payload,
      })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_TENANTS]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`${this.serviceName}/tenant`)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.SAVE_DASHBOARD_SETTING](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${this.serviceName}/tenant/update-setting`, payload)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }
}
