import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

// Kanban role, usually its todo, active and closed but feel free to choose what on what.
//  for now, it just act as an appointer to just some specific kanban,
type BoardRoleName = "draft" | "candidate" | "active" | "closed" | "canceled";

export interface BoardRole {
  name: BoardRoleName;
  tier: number; // e.g. there are maybe 2 closed board, tech closed and closed.
  // this what makes it differentiates each other. The more higher it is, the more it acts as an inheritance
}

export interface StakeholderData {
  code: number;
  name: string;
  role: string;
  email: string;
  phone_number: string;
}

export interface Stakeholders {
  stackholder_code: string | null;
  charter_code: string | null;
  stackholder: StakeholderData | null;
}

export interface KanbanItem {
  id: string;
  code: string;
  name: string;
  charter_segment_code: string | null;
  value: string | null;
  charter_delivery_status_code: string | null;
  charter_running_status_code: string | null;
  progress: string | null;
  closed_date: string | null;
  references: any | null;
  is_private: boolean;
  segment: any | null;
  delivery: any | null;
  running: {
    code: string;
    name: string;
  } | null;
  stackholders: Stakeholders[] | null;
  customers: any | null;
}

export interface KanbanBoard {
  id?: string;
  title: string;
  color: string;
  dragTo: string[];
  role: BoardRole;
  item: KanbanItem[];
}

export interface KanbanData {
  active: boolean;
  boards: KanbanBoard[];
}

@Module
export default class Kanban extends VuexModule {
  kanban = null;
  kanbanState: KanbanData = {
    active: false,
    boards: [
      {
        id: "_candidate",
        title: "Candidate",
        color: "secondary",
        dragTo: ["_active"],
        role: {
          name: "candidate",
          tier: 1,
        },
        item: [],
      },
      {
        id: "_active",
        title: "Active",
        color: "info",
        dragTo: ["_tech-closed", "_closed", "_draft", "_canceled"],
        role: {
          name: "active",
          tier: 1,
        },
        item: [],
      },
      {
        id: "_tech-closed",
        title: "Tech Closed",
        color: "warning",
        dragTo: ["_active", "_closed", "_draft", "_canceled"],
        role: {
          name: "closed",
          tier: 2,
        },
        item: [],
      },
      {
        id: "_closed",
        title: "Closed",
        color: "success",
        dragTo: ["_draft", "_active"],
        role: {
          name: "closed",
          tier: 1,
        },
        item: [],
      },
      {
        id: "_draft",
        title: "Draft",
        color: "secondary",
        dragTo: ["_candidate", "_active"],
        role: {
          name: "draft",
          tier: 1,
        },
        item: [],
      },
      {
        id: "_canceled",
        title: "Canceled",
        color: "danger",
        dragTo: ["_draft", "_candidate", "_active"],
        role: {
          name: "canceled",
          tier: 1,
        },
        item: [],
      },
    ],
  };
  choosenKanban: any = null;

  get getKanban() {
    return this.kanban;
  }

  get getChoosenKanban() {
    return this.choosenKanban;
  }

  get getKanbanState() {
    return this.kanbanState.active;
  }

  get getKanbanBoards() {
    return this.kanbanState.boards;
  }

  @Mutation
  [Mutations.SET_KANBAN](payload) {
    this.kanban = payload;
  }

  @Mutation
  [Mutations.SET_KANBAN_CHOOSEN](payload) {
    this.choosenKanban = payload;
  }

  @Mutation
  [Mutations.SET_KANBAN_STATE](payload) {
    this.kanbanState = payload;
  }

  @Mutation
  [Mutations.SET_KANBAN_BOARDS](payload) {
    this.kanbanState.boards = payload;
  }

  @Mutation
  [Mutations.SET_KANBAN_BOARD_ITEM](payload) {
    const board = this.kanbanState.boards.find(
      (item) =>
        item.id === `_${payload.board.replaceAll(" ", "-").toLowerCase()}`
    );
    if (board) board.item = payload.item;
  }

  @Mutation
  [Mutations.SET_KANBAN_IS_ACTIVE](payload) {
    this.kanbanState.active = payload;
  }

  @Action
  [Actions.GET_KANBAN_ITEMS](payload) {
    if (payload === "candidate")
      return [
        {
          id: 1,
          title: "Pengadaan Material Pariwisata Bali dan perbaikan fasilitas",
          due_date: "12-12-12",
          value: 5000000000,
          type: "Mega Project",
          stakeholders: [
            {
              id: 1,
              name: "Jane Doe",
              email: "janedoe@email.com",
              phone_number: "+62812345678",
              role: "Project Manager",
            },
          ],
        },
      ];

    return [];
  }
}
