import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class IntegratorModule extends VuexModule {
  isIntegratorVerifying = false;
  serviceName = "integrator";

  @Mutation
  [Mutations.SET_INTEGRATOR_LOADING](loading) {
    this.isIntegratorVerifying = loading;
  }

  get isIntegratorLoading(): boolean {
    return this.isIntegratorVerifying;
  }

  @Action({ rawError: true })
  [Actions.VALIDATE_MY_TENS_CRED](payload) {
    this.context.commit(Mutations.SET_INTEGRATOR_LOADING, true);
    return new Promise<boolean>((resolve, reject) => {
      ApiService.post(`${this.serviceName}/api/v1/cryptos/decrypt`, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOKEN, data.data.token);
          resolve(data);
        })
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error);
          reject(false);
        })
        .finally(() => {
          this.context.commit(Mutations.SET_INTEGRATOR_LOADING, false);
        });
    });
  }
}
