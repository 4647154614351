import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

const service = "auth/api/v1";

@Module
export default class JobGroupModule extends VuexModule {
  @Action
  [Actions.JOB_GROUP_QUERY](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/jobgroup`, { params: query })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.JOB_GROUP_POST](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/jobgroup`, payload)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.JOB_GROUP_PUT](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`${service}/jobgroup`, payload.id, payload.data)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.JOB_GROUP_DELETE](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`${service}/jobgroup/${id}`)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }
}
