// import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from '@/core/services/ApiService';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '../enums/StoreEnums';
import { MutationsAdminValidasi } from "@/store/enums/StoreAdminValidasiEnums";
import { toastSuccess, toastError } from '@/core/services/Alert';
import router from '@/router';

const service = process.env.VUE_APP_NODE_ENV == 'local' ? '/api/v1' : '/api/v1';
const serviceApproval = "approval/api/v1";

export interface IMasterAssetFilter {
  page: number;
  limit: number;
  totalPage: number;
  totalRecord:number;
}

@Module
export default class AssetsModule extends VuexModule {
  masterAssetFilter: IMasterAssetFilter = {
    page: 1,
    limit: 10,
    totalPage: 0,
    totalRecord: 0,
  };

  listAssets = [];
  detailAssets = [];
  listOfAssets: any = [];
  searchAssets = {};
  metaOfListAssets = {};
  booleanComp = false;
  statusUploadMasal = false;
  updateAssetFlag = false
  paramsFilter = {}
  channel_ids = []
  dialog_modal_import = false
  import_type = 'new'
  monitoring_coordinate = []
  modalDelete = false
  nameAsset = ''
  asset_initial = ''
  reset_filter = false
  updated_asset = false
  assets_all = false
  showToolbar = true

  get getshowToolbar() {
    return this.showToolbar
  }
  
  get assetsAll() {
    return this.assets_all
  }

  get getUpdatedAsset() {
    return this.updated_asset
  }
  
  get getResetFilter() {
    return this.reset_filter
  }

  get getAssetInitial() {
    return this.asset_initial
  }

  get getNameAsset() {
    return this.nameAsset
  }

  get getDeletePopup() {
    return this.modalDelete
  }

  get monitoringCoordinate() {
    return this.monitoring_coordinate
  }

  resetfilterPoinnt = ''

  get getImportType() {
    return this.import_type
  }

  get getDialogModalImport() {
    return this.dialog_modal_import
  }

  get getIdChannel() {
    return this.channel_ids
  }

  get getResetPoint() {
    return this.resetfilterPoinnt
  }

  get assetFilter(): IMasterAssetFilter {
    return this.masterAssetFilter;
  }

  get getUpdateAssetFlag() {
    return this.updateAssetFlag;
  }

  get statusComponent() {
    return this.booleanComp;
  }

  get getAssets() {
    return this.listAssets;
  }

  get getDetailAsset() {
    return this.detailAssets;
  }

  get getListAsset() {
    return this.listOfAssets;
  }

  get getMetaOfListAssets() {
    return this.metaOfListAssets;
  }

  get searchTextSearchAssets() {
    return this.searchAssets;
  }

  get getStatusUploadMasal() {
    return this.statusUploadMasal;
  }

  /**
   * global action dan mutation untuk component boolean
   * @param data mode = tipe object dari component boolean ex : dialog, modal, drawer
   * name untuk nama dari modal, membedakan antar modal jika ada dalam satu pages
   * status untuk component
   * title untuk judul dari modal
   * text untuk sub judul dari modal
   */
  
  @Mutation
  [Mutations.SET_SHOW_TOOLBAR](payload) {
    this.showToolbar = payload;
  }
  
  @Mutation
  [Mutations.SET_ALL_ASSET](payload) {
    this.assets_all = payload;
  }

  @Mutation
  [Mutations.SET_UPDATED_ASSET](payload) {
    this.updated_asset = payload;
  }

  @Mutation
  [Mutations.SET_RESET_FILTER](payload) {
    this.reset_filter = payload;
  }

  @Mutation
  [Mutations.SET_ASSET_INITIAL](payload) {
    this.asset_initial = payload;
  }

  @Mutation
  [Mutations.SET_DELETE_MODAL](payload) {
    this.modalDelete = payload;
  }

  @Mutation
  [Mutations.SET_LAST_NAME_ASSET](payload) {
    this.nameAsset = payload.aset_name;
  }

  @Mutation
  [Mutations.SET_MONITORING_COORDINATE](payload) {
    this.monitoring_coordinate = payload;
  }

  @Mutation
  [Mutations.SET_IMPORT_TYPE](payload) {
    this.import_type = payload
  }

  @Mutation
  [Mutations.SET_DIALOG_MODAL_IMPORT](payload) {
    this.dialog_modal_import = payload
  }

  @Mutation
  [Mutations.SET_RESET_FILTER_POINT](payload) {
    this.resetfilterPoinnt = payload;
  }

  @Mutation
  [Mutations.SET_ID_CHANNELS](payload) {
    this.channel_ids = payload;
  }
  
  @Mutation
  [Mutations.SET_GLOBAL_MODAL_STATE](status) {
    this.booleanComp = status;
  }

  @Mutation
  [Mutations.SET_PARAMS_FILTER](data) {
    this.paramsFilter = data;
  }

  @Mutation
  [Mutations.SET_UPDATE_ASSETS_FLAG](data) {
    this.updateAssetFlag = data
  }

  @Action
  [Actions.UPDATE_GLOBAL_MODAL_STATE](status) {
    this.context.commit(Mutations.SET_GLOBAL_MODAL_STATE, status);
  }

  @Mutation
  [Mutations.SET_STATUS_UPLOAD_MASAL](status) {
    this.statusUploadMasal = status;
  }

  @Action
  [Actions.UPDATE_STATUS_UPLOAD_MASAL](status) {
    this.context.commit(Mutations.SET_STATUS_UPLOAD_MASAL, status);
  }

  @Mutation
  [Mutations.SET_ASSETS](res) {
    if (res.data) {
      this.listAssets = res.data.sort(function (a, b) {
        return a.updated_at > b.updated_at
          ? -1
          : a.updated_at < b.updated_at
          ? 1
          : 0;
      });
    }
  }

  @Mutation
  [Mutations.SET_DETAIL_ASSETS](data) {
    this.detailAssets = data;
  }

  @Mutation
  [Mutations.SET_LIST_ASSETS](data) {
    this.listOfAssets = data;
  }

  @Mutation
  [Mutations.SET_SEARCH_ASSETS](payload) {
    this.searchAssets = payload;
  }

  @Mutation
  [Mutations.SET_FILTER_ASSET](payload: IMasterAssetFilter) {
    this.masterAssetFilter = payload;
  }

  @Action
  [Actions.GET_LIST_MONITORING_COORDINATE](payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/asset/coordinate-monitoring`, {
        params: Object.assign(
          {
            // id_channels: '6311aed38c130d3fa0415efc',
            page: payload.page,
            limit: payload.limit,
          }
        ),
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  }

  @Action
  [Actions.FILE_UPLOAD_ASSET](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/asset/upload`, payload)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_ASSETS](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/asset`, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ASSETS, payload);
          /**
           * log save on kafka start
           */
          this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
                action: 'ASSET.CREATE',
                asset_id: data?.data?._id,
                asset_name: data?.data?.name,
				        data : {
                  ...data?.data
                }
          })

          toastSuccess(data.msg);
          resolve(data);
        })
        .catch((err) => {
          toastError(err);
        });
    });
  }

  @Action
  [Actions.BULK_DELETE_ASSETS](payload) {
    ApiService.setUrl();
    return new Promise((resolve, reject) => {
      ApiService.post(`${service}/asset/bulk-delete`, {
        asset_id: payload
      })
        .then(({ data }) => {
          toastSuccess(data.data);
          const entityData = {
            user_id : '',
            entity_id : '',
            action : '',
            entity : 'ASSET_KBUMN',
            init_role : '',
            feedback : ''
          }

          this.context.commit(MutationsAdminValidasi.SET_ACTION_STATUS_ASSET, {
            ...entityData,
            entity_id : ''
          })
          resolve(data);
        })
        .catch((err) => {
          toastError(err);
          reject(err);
        })
    });
  }

  // approval
  @Action
  [Actions.APPROVAL_ASSETS](payload) {
    ApiService.setHeader();
    ApiService.setApprovalurl();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${serviceApproval}/action`, payload ,{
        headers: { Authorization: `Bearer 632c5acff60465b5f6162fc3` }
      })
        .then(({ data }) => {
          this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
            action : 'ASSET.APPROVAL',
            data : {
              approval_detail : payload
            }
          })

          resolve(data);
        })
        .catch((err) => {
          toastError(err);
        });
    });
  }
  // end approval

  @Action
  [Actions.UPDATE_ASSETS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`/api/v1`, 'asset', payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ASSETS, payload);
          toastSuccess(data.msg);
          /**
           * log save on kafka start
           */
          this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
                action: 'ASSET.UPDATE',
                asset_id: data?.data?._id,
                asset_name: data?.data?.name,
                data : {
                  ...data?.data
                }
          })

          resolve(data);
          router.push({ path: '/assets/list' });
        })
        .catch((err) => {
          toastError(err);
        });
    });
  }

  @Action
  [Actions.CREATE_SEARCH_ASSETS](search) {
    this.context.commit(Mutations.SET_SEARCH_ASSETS, search);
  }

  @Action
  [Actions.GET_DETAIL_ASSETS](payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`${service}/asset`, `${payload}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DETAIL_ASSETS, data);
          return resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_LIST_ASSETS](params) {
    ApiService.setUrl();
    this.context.commit(Mutations.SET_LOADING, true);
    this.context.commit(Mutations.SET_PARAMS_FILTER, params)
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/asset`, {
        params: Object.assign(
          {
            page: this.masterAssetFilter.page,
            limit: this.masterAssetFilter.limit,
            ...params
          }
        ),
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LIST_ASSETS, data.data);
          this.context.commit(Mutations.SET_FILTER_ASSET, data.meta);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        }).finally(() => {
          this.context.commit(Mutations.SET_LOADING, false);
        })
    });
  }

  @Action
  [Actions.GET_TOTAL_APPROVAL](id) {
    ApiService.setUrl();

    const payload: {
      page: number;
      limit: number;
      approval_status: number;
      request_edit: number | undefined; // Make request_edit optional
    } = {
      page: 1,
      limit: 1,
      approval_status: id,
      request_edit: 1
    };
    
    if (id !== '631ed2f9f97fb56ec2fcc0e8,631ed318f97fb56ec2fcc0ea,631ed357f97fb56ec2fcc0f0') {
      delete (payload as { request_edit?: number }).request_edit;
    }
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/asset`, {
        params: Object.assign(
          {
            ...payload
          }
        ),
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  }

  @Action
  [Actions.DOWNLOAD_OUTSIDE_INSIDE_ASSET](payload) {
    ApiService.setUrl();
    return new Promise((resolve, reject) => {
      ApiService.post(`${service}/asset/download-excel`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  }

  @Action
  [Actions.DOWNLOAD_OUTSIDE_INSIDE_ASSET_COUNT](payload) {
    ApiService.setUrl();
    return new Promise((resolve, reject) => {
      ApiService.post(`${service}/asset/download-excel/count`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  }

  @Action
  [Actions.DOWNLOAD_LIST_ASSETS](total) {
    ApiService.setHeader();
    ApiService.setApprovalurl();
    return new Promise((resolve, reject) => {
      ApiService.query(`${serviceApproval}/download-list-asset`, {
        responseType: 'blob',
        params: Object.assign(
          {
            page: this.masterAssetFilter.page,
            limit: total,
            ...this.paramsFilter
          }
        ),
      })
        .then(({ data }) => {
          ApiService.setUrl();
          resolve(data);
        })
        .catch((err) => {
          ApiService.setUrl();
          reject(err);
        })
    });
  }

  @Action
  [Actions.DELETE_ASSET]({ id, payload }) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`${service}/asset/${id}`)
        .then(({ data }) => {
          /**
           * log save on kafka start
           */
          this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
                action: 'ASSET.DELETE',
                asset_id: id,
                asset_name: payload?.aset_name,
                data : {
                  ...payload
                }
          })

          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }
}
