import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-405a0948"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page d-flex flex-row flex-column-fluid" }
const _hoisted_2 = {
  id: "kt_wrapper",
  class: "d-flex flex-column flex-row-fluid wrapper"
}
const _hoisted_3 = {
  id: "kt_content",
  class: "content d-flex flex-column flex-column-fluid"
}
const _hoisted_4 = { class: "post d-flex flex-column-fluid" }
const _hoisted_5 = {
  key: 1,
  class: "container-fluid p-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_AsideMenuArcgis = _resolveComponent("AsideMenuArcgis")!
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_KTFooter = _resolveComponent("KTFooter")!
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      (_ctx.loaderEnabled)
        ? (_openBlock(), _createBlock(_component_KTLoader, {
            key: 0,
            logo: _ctx.loaderLogo
          }, null, 8, ["logo"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_AsideMenuArcgis, {
          lightLogo: _ctx.themeLightLogo,
          darkLogo: _ctx.themeDarkLogo
        }, null, 8, ["lightLogo", "darkLogo"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_KTHeader, { title: _ctx.pageTitle }, null, 8, ["title"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.pageOpen != 'dashboard-map')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    id: "kt_content_container",
                    class: _normalizeClass({
                'container-fluid': _ctx.contentWidthFluid,
              })
                  }, [
                    _createVNode(_component_router_view)
                  ], 2))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_router_view)
                  ]))
            ])
          ]),
          (_ctx.pageOpen != 'dashboard-map')
            ? (_openBlock(), _createBlock(_component_KTFooter, { key: 0 }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(_component_KTScrollTop)
  ], 64))
}