import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";

const service =
  process.env.VUE_APP_NODE_ENV == "local" ? "auth/api/v1" : "auth/api/v1";

interface IDashboardLinkFilter {
  page: number;
  itemsPerPage: number;
  trash: number;
  sortBy: Array<string>;
  sortDesc: Array<number>;
}

export interface IDashboardLink {
  id: string;
  page: string;
  url: string;
  code: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export enum DashLinkDrawerMode {
  ADD = "add",
  EDIT = "edit",
  DEFAULT = "",
}

@Module
export default class DashboardLinkModule extends VuexModule {
  drawerState = false;
  drawerMode = DashLinkDrawerMode.DEFAULT;
  dashLinkFilter: IDashboardLinkFilter = {
    page: 1,
    itemsPerPage: 10,
    trash: 0,
    sortBy: ["created_at"],
    sortDesc: [1],
  };
  dashLinkFetching = true;
  dashLinkIsSubmitting = false;
  dashLinkData = null;
  dashLinkDetail = null;

  get dashboardLinkFilter() {
    return this.dashLinkFilter;
  }

  get dashboardLinkisFetching() {
    return this.dashLinkFetching;
  }

  get dashboardLinkDrawerState() {
    return this.drawerState;
  }

  get dashboardLinkDrawerMode() {
    return this.drawerMode;
  }

  get dashboardLinkIsSubmitting() {
    return this.dashLinkIsSubmitting;
  }

  get dashboardLinkData() {
    return this.dashLinkData;
  }

  @Mutation
  [Mutations.SET_DASHBOARD_LINK_DRAWER_STATE](state: boolean) {
    this.drawerState = state;
  }

  @Action
  [Actions.UPDATE_DASHBOARD_LINK_DRAWER_STATE](state: boolean) {
    this.context.commit(Mutations.SET_DASHBOARD_LINK_DRAWER_STATE, state);
  }

  @Mutation
  [Mutations.SET_DASHBOARD_LINK_DRAWER_MODE](mode: DashLinkDrawerMode) {
    this.drawerMode = mode;
  }

  @Action
  [Actions.UPDATE_DASHBOARD_LINK_DRAWER_MODE](mode: DashLinkDrawerMode) {
    this.context.commit(Mutations.SET_DASHBOARD_LINK_DRAWER_MODE, mode);
  }

  @Mutation
  [Mutations.SET_DASHBOARD_LINK_FILTER](filter: IDashboardLinkFilter) {
    this.dashLinkFilter = filter;
  }

  @Action
  [Actions.UPDATE_DASHBOARD_LINK_FILTER](filter: IDashboardLinkFilter) {
    this.context.commit(Mutations.SET_DASHBOARD_LINK_FILTER, filter);
  }

  @Mutation
  [Mutations.SET_DASHBOARD_LINK_IS_FETCHING](isFetching: boolean) {
    this.dashLinkFetching = isFetching;
  }

  @Mutation
  [Mutations.SET_DASHBOARD_LINK_IS_SUBMITTING](isSubmitting: boolean) {
    this.dashLinkIsSubmitting = isSubmitting;
  }

  @Mutation
  [Mutations.SET_DASHBOARD_LINK_DATA](data) {
    this.dashLinkData = data;
  }

  @Mutation
  [Mutations.SET_DASHBOARD_LINK_DETAIL](data) {
    this.dashLinkDetail = data;
  }

  @Action({ rawError: true })
  async [Actions.GET_DASHBOARD_LINK_LIST]() {
    this.context.commit(Mutations.SET_DASHBOARD_LINK_IS_FETCHING, true);
    const slug = `page=${this.dashLinkFilter.page}&itemsPerPage=${this.dashLinkFilter.itemsPerPage}&trash=${this.dashLinkFilter.trash}&sortBy[]=${this.dashLinkFilter.sortBy}&sortDesc[]=${this.dashLinkFilter.sortDesc}`;
    return ApiService.get(`${service}/dashboardlink`, `?${slug}`)
      .then(async ({ data }) => {
        this.context.commit(Mutations.SET_DASHBOARD_LINK_DATA, data.data);
        return Promise.resolve(data);
      })
      .catch((err) => {
        this.context.commit(Mutations.SET_ERROR, err);
        return Promise.reject(err);
      })
      .finally(() =>
        this.context.commit(Mutations.SET_DASHBOARD_LINK_IS_FETCHING, false)
      );
  }

  @Action({ rawError: true })
  async [Actions.GET_DASHBOARD_LINK_DETAIL](id: string) {
    this.context.commit(Mutations.SET_DASHBOARD_LINK_IS_FETCHING, true);
    return ApiService.get(`${service}/dashboardlink`, id)
      .then(async ({ data }) => {
        this.context.commit(Mutations.SET_DASHBOARD_LINK_DETAIL, data.data);
        return Promise.resolve(data);
      })
      .catch((err) => {
        this.context.commit(Mutations.SET_ERROR, err);
        return Promise.reject(err);
      })
      .finally(() =>
        this.context.commit(Mutations.SET_DASHBOARD_LINK_IS_FETCHING, false)
      );
  }

  @Action({ rawError: true })
  async [Actions.STORE_DASHBOARD_LINK](payload) {
    this.context.commit(Mutations.SET_DASHBOARD_LINK_IS_SUBMITTING, true);
    return ApiService.post(`${service}/dashboardlink`, payload)
      .then(({ data }) => {
        return Promise.resolve(data);
      })
      .catch((err) => {
        this.context.commit(Mutations.SET_ERROR, err);
        return Promise.reject(err);
      })
      .finally(() =>
        this.context.commit(Mutations.SET_DASHBOARD_LINK_IS_SUBMITTING, false)
      );
  }

  @Action({ rawError: true })
  async [Actions.SOFT_DELETE_DASHBOARD_LINK](id: string) {
    this.context.commit(Mutations.SET_DASHBOARD_LINK_IS_SUBMITTING, true);
    return ApiService.delete(`${service}/dashboardlink/${id}`)
      .then(({ data }) => {
        return Promise.resolve(data);
      })
      .catch((err) => {
        this.context.commit(Mutations.SET_ERROR, err);
        return Promise.reject(err);
      })
      .finally(() =>
        this.context.commit(Mutations.SET_DASHBOARD_LINK_IS_SUBMITTING, false)
      );
  }

  @Action({ rawError: true })
  async [Actions.UPDATE_DASHBOARD_LINK](data) {
    this.context.commit(Mutations.SET_DASHBOARD_LINK_IS_SUBMITTING, true);
    return ApiService.put(`${service}/dashboardlink`, data.id, data)
      .then(({ data }) => Promise.resolve(data))
      .catch((err) => {
        this.context.commit(Mutations.SET_ERROR, err);
        return Promise.reject(err);
      })
      .finally(() =>
        this.context.commit(Mutations.SET_DASHBOARD_LINK_IS_SUBMITTING, false)
      );
  }
}
