import { createStore } from "vuex";
import { config } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import AuthModule from "@/store/modules/AuthModule";
import ScheduleModule from "@/store/modules/ScheduleModule";
import RiskModule from "@/store/modules/RiskModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import HashModule from "@/store/modules/HashModule";
import ProjectModule from "@/store/modules/ProjectModule";
import ProjectAddModlules from "@/store/modules/ProjectAddModules";
import ProjectCloseModules from "@/store/modules/ProjectCloseModules";
import TopModule from "@/store/modules/TopModule";
import IntegratorModule from "@/store/modules/IntegratorModule";
import MytensModule from "@/store/modules/MytensModule";
import MasterRoleModule from "@/store/modules/MasterRoleModule";
import ApprovalModule from "@/store/modules/ApprovalModule";
import LanguageModule from "@/store/modules/LanguageModule";
import ThemeModule from "@/store/modules/ThemeModule";
import MasterMenuModule from "@/store/modules/MasterMenuModule";
import GeneralSettingModule from "@/store/modules/GeneralSettingModule";
import RoleModule from "@/store/modules/RoleModule";
import NotificationModule from "@/store/modules/NotificationModule";
import TelkomsatModule from "@/store/modules/TelkomsatModule";
import KanbanModule from "@/store/modules/KanbanModule";
import MemberModule from "@/store/modules/MemberModule";
import ReportModule from "@/store/modules/ReportModule";
import FileModule from "@/store/modules/fileModule";
import DashboardLinkModule from "@/store/modules/DashboardLinkModule";
import GoogleMapModule from "@/store/modules/GoogleMapModule";
import ProjectTemplate from "@/store/modules/ProjectTemplateModule";
import ProjectUpdateModule from "@/store/modules/ProjectUpdateModule";
import FileCategoryModule from "@/store/modules/FileCategoryModule";
import DrawerModule from "@/store/modules/DrawerModule";
import SymptomModule from "@/store/modules/SymptomModule";
import AdminTompsModule from "@/store/modules/AdminTompsModule";
import DashboardModule from "@/store/modules/DashboardModule";
import DashboardGeneralModule from "@/store/modules/DashboardGeneralModule";
import TenantModule from "@/store/modules/TenantModule";
import TutorialModules from "@/store/modules/TutorialModules";
import JobGroupModule from "@/store/modules/JobGroupModule";
import ProvinceModule from "@/store/modules/ProvinceModule";
import ChannelModule from "@/store/modules/ChannelModule";
import EarningStatusModules from "@/store/modules/EarningStatusModules"
import AssetsDataMasterModule from "@/store/modules/AssetsDataMasterModule"
import i18n from "@/core/plugins/i18n";
import ArcGISModule from "@/store/modules/ArcGISModule";
import AllMasterModule from "@/store/modules/AllMasterModule";
import AssetsModule from "@/store/modules/AssetsModule";
import RoleAssetsModule from "@/store/modules/RoleAssetModule";
import AdminValidasiModule from "@/store/modules/AdminValidasiModule";
import TokenPowerBIModule from "@/store/modules/TokenPowerBIModule";
import KafkaModule from "./modules/KafkaModule";

config.rawError = true;

const store = createStore({
  modules: {
    SymptomModule,
    DrawerModule,
    AdminTompsModule,
    ProjectTemplate,
    ProjectUpdateModule,
    FileModule,
    ReportModule,
    NotificationModule,
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ScheduleModule,
    RiskModule,
    HashModule,
    ProjectModule,
    ProjectAddModlules,
    TopModule,
    IntegratorModule,
    MytensModule,
    MasterRoleModule,
    TenantModule,
    ProjectCloseModules,
    ApprovalModule,
    LanguageModule,
    ThemeModule,
    MasterMenuModule,
    GeneralSettingModule,
    RoleModule,
    TelkomsatModule,
    KanbanModule,
    MemberModule,
    DashboardLinkModule,
    GoogleMapModule,
    FileCategoryModule,
    DashboardModule,
    TutorialModules,
    DashboardGeneralModule,
    JobGroupModule,
    ProvinceModule,
    ChannelModule,
    EarningStatusModules,
    ArcGISModule,
    AssetsDataMasterModule,
    AllMasterModule,
    AssetsModule,
    RoleAssetsModule,
    AdminValidasiModule,
    TokenPowerBIModule,
    KafkaModule
  },
  state() {
    return {
      webpSupport: false,
      locale: window.localStorage.getItem("locale") || "id",
    };
  },
  mutations: {
    [Mutations.SET_WEBP_SUPPORT](state, value) {
      state.webpSupport = value;
    },
    [Mutations.SET_LOCALE](state, value) {
      state.locale = value;
      i18n.global.locale.value = value;
      window.localStorage.setItem("locale", value);
    },
  },
  actions: {
    [Actions.UPDATE_LOCALE]({ commit }, locale) {
      commit(Mutations.SET_LOCALE, locale);
    },
  },
});

export default store;
