
import {
  ref,
  getCurrentInstance,
  computed,
  watch,
  reactive,
  onMounted,
} from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Getters } from "@/store/enums/StoreTutorialEnums";

import IntroJs from "intro.js";
import * as storeTutorial from "@/store/enums/StoreTutorialEnums";
import { Tutorial } from "@/store/enums/interface_option/tutorial";

export default {
  setup() {
    // code start introjs-7

    const $instance = getCurrentInstance();
    const nextData = ref(0);
    const nextData2 = reactive({
      url: null,
      status: null,
    });
    const nextStepTutorial = ref(0);
    const show = ref(false);
    const route = useRoute();
    // const router = useRouter();

    let fullPath = ref("");
    const store = useStore();
    const comFilterStep = computed(() => store.getters[Getters.FILTER_STEP]);
    const localStorageFun = (name: string) => {
      try {
        if (name) {
          const a = localStorage.getItem(name) as any;
          return a ? JSON.parse(a) : undefined;
        } else {
          return name;
        }
      } catch (e) {
        console.log("localStorageFun", e);
      }
    };
    const localStorageFunSave = (
      name?: string | null,
      status?: boolean,
      nameStorage?: {
        name: string;
        data: string;
      }
    ) => {
      try {
        switch (status) {
          case true:
            if (nameStorage?.name) {
              let array = [];
              if (localStorageFun(nameStorage?.name)) {
                array = localStorageFun(nameStorage?.name);
              }
              array.push(nameStorage?.data as never);
              localStorage.setItem(nameStorage?.name, JSON.stringify(array));
            }
            break;
          default: {
            if (name) {
              let array = [];
              array = localStorageFun("introjs-tutorial");
              array.push(name as never);
              localStorage.setItem("introjs-tutorial", JSON.stringify(array));
            } else {
              return name;
            }
          }
        }
      } catch (e) {
        console.log("localStorageFun", e);
      }
    };

    const funDocument = (name: string, status = "1") => {
      return new Promise((resolve) => {
        switch (status) {
          case "1":
            {
              const doc = document.querySelectorAll(name);
              doc[0]
                ?.getElementsByClassName("next")[0]
                ?.addEventListener("click", () => {
                  nextData.value = 0;
                  resolve("step-1");
                });
              doc[0]
                ?.getElementsByClassName("lewati")[0]
                ?.addEventListener("click", () => {
                  nextData.value = 0;
                  resolve("step-2");
                });
            }
            break;
          case "2":
            {
              const time = setInterval(() => {
                const doc = document.querySelectorAll(name);
                if (doc.length > 0) {
                  doc[0]
                    ?.getElementsByClassName("next")[0]
                    ?.addEventListener("click", () => {
                      nextData.value = 0;
                      resolve("step-1");
                    });

                  doc[0]
                    ?.getElementsByClassName("lewati")[0]
                    ?.addEventListener("click", () => {
                      nextData.value = 0;
                      resolve("step-2");
                    });
                  clearInterval(time);
                }
              });
            }
            break;
        }
      });
    };

    // project detail closed
    const listStep = () => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-project-closed"
              )[0] as HTMLElement,
              intro: `<div class="introjs-3" id="step-closed">
 <div>Klik disini untuk mengembalikan proyek yang tertutup ke proyek aktif</div>

  <div class="grid-tutorial">
    <div  class="lewati"></div>
    <button class="next">Selanjutnya</button>
  </div>
</div>`,
              position: "left",
            },
          ],
        });
        intro2.start();

        funDocument(".introjs-3#step-closed").then((status) => {
          switch (status) {
            case "step-1":
              nextStepTutorial.value = 1;
              intro2.nextStep();
              localStorageFunSave(null, true, {
                name: "project-closed-child",
                data: "project-detail",
              });
              break;
            case "step-2":
              intro2.exit();
              localStorageFunSave(null, true, {
                name: "project-closed-child",
                data: "project-detail",
              });
              break;
          }
        });
      });
    };
    // project table
    const listStep2 = () => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-project-closed-table"
              )[0] as HTMLElement,
              intro: `<div class="introjs-3" id="step-closed-table">
 <div>Klik proyek untuk melihat detail proyek yang sudah ditutup</div>

  <div class="grid-tutorial">
    <div  class="lewati"></div>
    <button class="next">Selanjutnya</button>
  </div>
</div>`,
              position: "left",
            },
          ],
        });
        intro2.start();

        funDocument(".introjs-3#step-closed-table").then((status) => {
          switch (status) {
            case "step-1":
              nextStepTutorial.value = 1;
              intro2.nextStep();
              localStorageFunSave(null, true, {
                name: "project-closed-child",
                data: "project-table",
              });
              break;
            case "step-2":
              intro2.exit();
              localStorageFunSave(null, true, {
                name: "project-closed-child",
                data: "project-table",
              });
              break;
          }
        });
      });
    };

    const funStepIntro = (varnew) => {
      const funDocumentDetail = (name?: string) => {
        switch (name) {
          case "project-closed-detail":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun("project-closed-child");
                if (!save?.includes("project-closed")) {
                  if (!save2?.includes("project-detail")) {
                    setTimeout(() => {
                      listStep();
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "project-closed-table":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun("project-closed-child");
                if (!save?.includes("project-closed")) {
                  if (!save2?.includes("project-table")) {
                    setTimeout(() => {
                      listStep2();
                    }, 1000);
                  }
                }
              });
            }
            break;
        }
      };
      const boolLinkChecker = (name = "", status?: string) => {
        switch (status) {
          case "regex": {
            const regext = name || "";
            let detect = regext.match(/\/project\/show\/?([^.]*)/g);
            detect = detect?.length ? detect[0].split("#") : [];
            return detect;
          }
          case "all":
            return varnew?.data?.link === name
              ? true
              : fullPath.value === `/project/show/${varnew?.data}#${name}`;
          default:
            return nextData.value === 0 && varnew?.data?.link === name
              ? true
              : fullPath.value === `/project/show/${varnew?.data}#${name}`;
        }
      };

      if (
        varnew?.name === "project-closed/load-list" &&
        varnew?.bool === true
      ) {
        if (
          nextData.value === 0 &&
          localStorageFun("save-id-project")?.includes("HE04F")
        ) {
          nextData.value = 1;
          console.log("project-closed-detail");
          funDocumentDetail("project-closed-detail");
        }
      } else if (
        varnew?.name === "project-closed/load-list-table" &&
        varnew?.bool === true
      ) {
        if (
          nextData.value === 0 &&
          localStorageFun("save-id-project")?.includes("HE04F")
        ) {
          nextData.value = 1;
          funDocumentDetail("project-closed-table");
        }
      }
    };

    watch(
      comFilterStep,
      (varnew) => {
        if (varnew?.name === "project-all/aside") {
          nextData2.url = varnew?.data;
          fullPath.value = varnew?.data;
          if (varnew?.data === "/project/HE04F") nextData.value = 0;
        } else if (
          varnew?.name === "project-closed/load-list" &&
          varnew?.bool === true
        ) {
          fullPath.value = route?.fullPath;

          if (fullPath.value !== "") {
            funStepIntro({ ...varnew });
          }
        } else if (
          varnew?.name === "project-closed/load-list-table" &&
          varnew?.bool === true
        ) {
          fullPath.value = route?.fullPath;

          if (fullPath.value !== "") {
            funStepIntro({ ...varnew });
          }
        }
      },
      { deep: true }
    );

    return {
      show,
    };
  },
};
