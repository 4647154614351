import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import { boolean } from "yup/lib/locale";

const service =
  process.env.VUE_APP_NODE_ENV == "local"
    ? "sysreport/api/v1"
    : "sysreport/api/v1";

let servicePbi = "https://apigateway-shared.tomps.id/powerbi/api/v1/powerbi"

if(process.env.VUE_APP_NODE_ENV == "Production") servicePbi = "https://apigateway-shared.tomps.id/powerbi/api/v1/powerbi"
else if (process.env.VUE_APP_NODE_ENV == "Staging") servicePbi = "https://stg-api-shared.tomps.id/powerbi/api/v1/powerbi"
else servicePbi = "https://dev-api-shared.tomps.id/powerbi/api/v1/powerbi"

@Module
export default class DashboardModule extends VuexModule {
  scaleProjects = [];
  filterDate = "";
  topProjects = [];
  categoryProjects = [];
  segmentProjects = [];
  progressProjects = [];
  statusProjects = [];
  stakeholderProjects = [];
  issueProjects = [];
  progressGrafikPojects = [];
  adminValue = boolean;
  maxIssueProjects = 0;
  maxStatusProjects = 0;
  maxProgressProjects = 0;
  maxProgGrafikProjects = 0;
  locationProjects = [];
  errors = [];
  budgetLeft = [];
  budgetRight = [];
  chartEarnValue = [];

  get getscaleProjects() {
    return this.scaleProjects;
  }

  get getbudgetLeftProjects() {
    return this.budgetLeft;
  }
  get getbudgetRightProjects() {
    return this.budgetRight;
  }

  get getfilterDate() {
    return this.filterDate;
  }

  get gettopProjects() {
    return this.topProjects;
  }

  get getcategoryProjects() {
    return this.categoryProjects;
  }

  get getsegmentProjects() {
    return this.segmentProjects;
  }

  get getprogressProjects() {
    return this.progressProjects;
  }

  get getstatusProjects() {
    return this.statusProjects;
  }

  get getstakeholderProjects() {
    return this.stakeholderProjects;
  }

  get getissueProjects() {
    return this.issueProjects;
  }

  get getadminVal() {
    return this.adminValue;
  }

  get proggrafikPojects() {
    return this.progressGrafikPojects;
  }

  get getmaxissueProjects() {
    return this.maxIssueProjects;
  }

  get maxstatusTotalProjects() {
    return this.maxStatusProjects;
  }

  get maxprogressProjects() {
    return this.maxProgressProjects;
  }
  get maxproggrafikProjects() {
    return this.maxProgGrafikProjects;
  }
  get locationgraphProject() {
    return this.locationProjects;
  }

  get getchartEarnValue() {
    return this.chartEarnValue;
  }

  @Mutation
  [Mutations.SET_SCALE_PROJECTS](data) {
    this.scaleProjects = data;
  }

  @Mutation
  [Mutations.SET_BUDGET_PROJECTS](data) {
    this.budgetLeft = data.filter((item) => {
      return (
        item.type === "totalNilaiProyek" || item.type === "penyerapanAnggaran"
      );
    });
    this.budgetRight = data.filter((item) => {
      return (
        item.type !== "totalNilaiProyek" && item.type !== "penyerapanAnggaran"
      );
    });
  }

  @Mutation
  [Mutations.SET_FILTER_DASHBOARD_DATE](data) {
    this.filterDate = data;
  }

  @Mutation
  [Mutations.SET_TOP_PROJECTS](data) {
    this.topProjects = data;
  }

  @Mutation
  [Mutations.SET_CATEGORY_PROJECTS](data) {
    if (data.length > 5) {
      this.categoryProjects = data
        .slice(0, 5)
        .filter((item) => item.Total_Proyek > 0);
    } else {
      this.categoryProjects = data.filter((item) => item.Total_Proyek > 0);
    }
  }

  @Mutation
  [Mutations.SET_SEGMENT_PROJECTS](data) {
    if (data.length > 5) {
      this.segmentProjects = data
        .slice(0, 5)
        .filter((item) => item.Total_Proyek > 0);
    } else {
      this.segmentProjects = data.filter((item) => item.Total_Proyek > 0);
    }
  }

  @Mutation
  [Mutations.SET_PROGRESS_PROJECTS](data) {
    this.progressProjects = data;
  }

  @Mutation
  [Mutations.SET_STATUS_PROJECTS](data) {
    this.statusProjects = data;
  }

  @Mutation
  [Mutations.SET_STAKEHOLDER_PROJECTS](data) {
    if (data.length > 5) {
      this.stakeholderProjects = data
        .slice(0, 5)
        .filter((item) => item.proyek > 0);
    } else {
      this.stakeholderProjects = data.filter((item) => item.proyek > 0);
    }
  }

  @Mutation
  [Mutations.SET_ISSUE_PROJECTS](data) {
    if (data.length > 5) {
      this.issueProjects = data
        .slice(0, 5)
        .filter((item) => item.totalProyek > 0);
    } else {
      this.issueProjects = data.filter((item) => item.totalProyek > 0);
    }
  }

  @Mutation
  [Mutations.SET_CHANGE_ADMIN](data) {
    this.adminValue = data;
  }

  @Mutation
  [Mutations.SET_MAX_ISSUE_PROJECTS](data) {
    this.maxIssueProjects = data;
  }

  @Mutation
  [Mutations.SET_MAX_STATUS](data) {
    this.maxStatusProjects = data;
  }

  @Mutation
  [Mutations.SET_MAX_PROGRESS](data) {
    this.maxProgressProjects = data.reduce((a, b) => {
      return a + b;
    });
  }

  @Mutation
  [Mutations.SET_PROGRESS_GRAFIK](data) {
    data.map((item) => {
      item.data.map((val) => {
        val.valueMoney = val.x;
        val.x = val.x / 1000000;
      });
    });

    this.progressGrafikPojects = data;
  }

  @Mutation
  [Mutations.SET_MAX_PROGRESS_GRAFIK](data) {
    this.maxProgGrafikProjects = data;
  }

  @Mutation
  [Mutations.SET_LOCATION_PROJECT](data) {
    this.locationProjects = data;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_CHART_EARN_VALUE](data) {
    this.chartEarnValue = data;
  }

  @Action
  [Actions.GET_SCALE_PROJECTS](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/skalaProyek/getCountAllSkalaProyek`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SCALE_PROJECTS, data.data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_FILTER_DASHBOARD_DATE](data) {
    this.context.commit(
      Mutations.SET_FILTER_DASHBOARD_DATE,
      data.value.toString()
    );
  }

  @Action
  [Actions.GET_BUDGET_PROJECTS](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/anggaranProyek/getCountAllAnggaranProyek`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BUDGET_PROJECTS, data.data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_TOP_PROJECTS](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/termOfPayment/getAllTermOfPaymentProyek`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOP_PROJECTS, data.data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_CATEGORY_PROJECTS](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/kategoriProyek/getAllKatergoriProyek`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CATEGORY_PROJECTS, data.data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_SEGMENT_PROJECTS](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/segmenProyek/getAllSegmenProyek`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SEGMENT_PROJECTS, data.data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PROGRESS_PROJECTS](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/progressProyek/getCountAllProgressProyek`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PROGRESS_PROJECTS, data.data);
          this.context.commit(Mutations.SET_MAX_PROGRESS, data.data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_STATUS_PROJECTS](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/dashboard/getStatusProyek`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_STATUS_PROJECTS, data.data);
          this.context.commit(Mutations.SET_MAX_STATUS, data.totalProyek);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_STAKEHOLDER_PROJECTS](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/stakeHolder/getAllStakeHolder`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_STAKEHOLDER_PROJECTS, data.data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_ISSUE_PROJECTS](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/issueProyek/getAllIssueProyek`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ISSUE_PROJECTS, data.data);
          this.context.commit(Mutations.SET_MAX_ISSUE_PROJECTS, data.maxIssue);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_CHANGE_ADMIN](value) {
    this.context.commit(Mutations.SET_CHANGE_ADMIN, value);
  }

  @Action
  [Actions.GET_PROGRESS_GRAFIK](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/progressProyek/getGrafikAllProgressProyek`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PROGRESS_GRAFIK, data.data);
          this.context.commit(
            Mutations.SET_MAX_PROGRESS_GRAFIK,
            data.totalData
          );
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_LOCATION_PROJECT](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/lokasiProyek/getAllLokasiProyek`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOCATION_PROJECT, data.data);
          resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_DETAIL_SUMMARY](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`${service}/dashboard-detail/summary`, id)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_DETAIL_SCURVE](charterId) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/dashboard-detail/scurves-chart`, charterId)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_CARD_EVA](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`${service}/dashboard-detail/earnValueAnalysis/get`, id)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_CHART_EVA](projectId) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(
        `${service}/dashboard-detail/earnValueAnalysis/getChart`,
        projectId
      )
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PBI_TOKEN](payload) {
    let params = {
      groupId: "",
      reportId:"",
      datasetId: ""
    };
    
    if(process.env.VUE_APP_NODE_ENV == "Production"){
      params = {
        groupId: "69f9d51a-877b-4d45-85c8-21c15db99b53",
        reportId:"997f9249-97f1-4598-9859-90bbdfe2aeea",
        datasetId:"429afb05-4ed7-40f8-add9-e5c8fee2dd8d",
      }
    } else {
      params = {
        groupId: "69f9d51a-877b-4d45-85c8-21c15db99b53",
        reportId:"27837d3f-5f97-4adf-ba4c-558bea3ab121",
        datasetId:"8b5c52ac-8576-4a4c-9ded-5ee08c00753f",
      }
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${servicePbi}/get_bi_token`, params)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }
  @Action
  [Actions.GET_PBI_TOKEN_REPORT](payload) {
    let params = {
      groupId: "",
      reportId:"",
      datasetId: ""
    };
    
    if(process.env.VUE_APP_NODE_ENV == "Production"){
      params = {
        groupId: "69f9d51a-877b-4d45-85c8-21c15db99b53",
        reportId:"a4f8bc25-c470-4f96-8c28-0127df0c1e25",
        datasetId:"dabf0bd2-225b-4f67-acac-4d6560c71496",
      }
    } else {
      params = {
        groupId: "69f9d51a-877b-4d45-85c8-21c15db99b53",
        reportId:"90a90b23-87fc-4c3b-9e6e-dbd4d46703ea",
        datasetId:"8b5c52ac-8576-4a4c-9ded-5ee08c00753f",
      }
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${servicePbi}/get_bi_token`, params)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }
}
