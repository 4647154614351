
import { definceIconNotif, dateFormatSeconds, capitalize } from "@/core/helpers/helps";
import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { computed, defineComponent, onMounted, ref } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { CircleCloseFilled } from '@element-plus/icons-vue'
import { watch } from "vue";
import images from "@/assets/ts/images.js";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    drawerStatus: Boolean,
  },
  components: {
  },
  setup(prop, context) {
    const { t } = useI18n();
    const drawer = ref(prop.drawerStatus);
    const emptyImage = images.empty
    const router = useRouter();
    const activeName = ref("first");
    const limit = ref(5)
    const isHideLoadMore = ref(false)
    const isLoading = ref(true)
    const store = useStore();
    const showUnreadOnly = ref(false)
    const visible = ref(false)
    const typeNotif = ref([])
    const typeNotifList = ['approve', 'request', 'reject']
    const getListNotifikasi = computed(() => {
      return store.getters.getListNotifikasi;
    });
    const isEmpty = computed(() => {
      return store.getters.getIsEmpty;
    });

    const closeDeliverable = () => {
      drawer.value = false;
      context.emit("onClose");
    };

    const loadMore = () => {
      limit.value = 0
      isHideLoadMore.value = true
    }

    const getNotification = (params:any) => {
      store.dispatch(Actions.GET_NOTIFIKASI, { 
          limit: limit.value,
          is_read : showUnreadOnly.value ? 0 : undefined,
          ...params
      }).then(() => {
        isLoading.value = false
      })
    } 

    watch(limit, (cr, pv) => {
      if (cr !== 5) {
        getNotification({
          limit : limit.value
        })
      }
    })

    watch(typeNotif, (cr, pv) => {
      isHideLoadMore.value = true
      getNotification({
          is_read : showUnreadOnly.value ? 0 : undefined,
          status : typeNotif.value.length > 0 ? typeNotif.value.toString() : undefined,
          limit : 0,
      })
    })

    watch(showUnreadOnly, (cr, pv) => {
      isHideLoadMore.value = true
      getNotification({
          is_read : cr ? 0 : undefined,
          status : typeNotif.value.length > 0 ? typeNotif.value.toString() : undefined,
          limit : 0
      })
    })

    onMounted(() => {
      getNotification({})
    });

    const changeIsread = (data:any) => {
      closeDeliverable()
      if (data.is_read) {
        router.push(`/assets/views/${data.asset_id}/informasi-assets#FSD`);
        setTimeout(() => {
          window.location.reload()
        }, 500)
        return;
      }

      ApiService.post("/api/v1/inbox/read", {
        id : data?._id
      }, {}).then(
        (res) => {
          if (res?.status === 200) {
            store.dispatch(Actions.GET_NOTIFIKASI, { 
              limit: limit.value
            });

            router.push(`/assets/views/${data.asset_id}/informasi-assets#FSD`);
            setTimeout(() => {
          window.location.reload()
            }, 500)
          }
        }
      );
    };


    return {
      definceIconNotif,
      isEmpty,
      isLoading,
      changeIsread,
      getListNotifikasi,
      drawer,
      closeDeliverable,
      activeName,
      dateFormatSeconds,
      loadMore,
      isHideLoadMore,
      emptyImage,
      showUnreadOnly,
      typeNotifList,
      typeNotif,
      t,
      visible,
      capitalize
    };
  },
});
