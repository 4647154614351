import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

let service = "https://apigateway-shared.tomps.id/powerbi/api/v1/powerbi"

if(process.env.VUE_APP_NODE_ENV == "Production") service = "https://apigateway-shared.tomps.id/powerbi/api/v1/powerbi"
else if (process.env.VUE_APP_NODE_ENV == "Staging") service = "https://stg-api-shared.tomps.id/powerbi/api/v1/powerbi"
else service = "https://dev-api-shared.tomps.id/powerbi/api/v1/powerbi"

@Module
export default class ApprovalModule extends VuexModule {
  token_informations = {
    usuage: {},
    data: []
  }
  accessTokenDrawer = false

  get getTokenPBI() {
    return this.token_informations
  }

  get getAccessTokenDrawer() {
    return this.accessTokenDrawer
  }

  @Action
  [Actions.GET_POWERBI_TOKEN]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`${service}/index`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_POWERBI_TOKEN, data.data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Mutation
  [Mutations.SET_POWERBI_TOKEN](payload) {
    this.token_informations = payload
  }
  
  @Mutation
  [Mutations.SET_POWERBI_TOKEN_DRAWER](payload) {
    this.accessTokenDrawer = payload
  }

  @Action
  [Actions.EDIT_POWERBI_TOKEN](payload) {
    return new Promise<void>((resolve, reject) => {
        ApiService.put(`${service}/update`, payload.id, payload)
          .then(({ data }) => {
            this.context.commit(Mutations.SET_POWERBI_TOKEN_DRAWER, false);
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
  }
}
