import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ICharter } from "./ProjectModule";

export enum PrjUpdateTabEnum {
  PARTNER = "PARTNER",
  DELIVERABLES = "DELIVERABLES",
  TERM_OF_PAYMENT = "TERM_OF_PAYMENT",
  PROJECT_COST = "PROJECT_COST",
  ISSUE_AND_FOLLOW_UP = "ISSUE_AND_FOLLOW_UP",
  DOCUMENT = "DOCUMENT",
}

export enum PrjUpdateDrawerModeEnum {
  ADD = "ADD",
  EDIT = "EDIT",
  UPDATE = "UPDATE",
}

@Module
export default class ProjectUpdateModule extends VuexModule {
  prjUpdateActiveTab = PrjUpdateTabEnum.PARTNER;
  prjUpdateNavFrom = null;
  prjUpdateDrawerState = false;
  prjUpdateIsSubmitting = false;
  prjUpdateIsFetching = false;
  prjUpdateSelectedData = null;
  prjUpdateDrawerMode = PrjUpdateDrawerModeEnum.EDIT;
  prjUpdateCharter: ICharter | null = null;

  get getPrjUpdateCharter() {
    return this.prjUpdateCharter;
  }

  @Mutation
  [Mutations.SET_PROJECT_UPDATE_CHARTER](charter: ICharter) {
    this.prjUpdateCharter = charter;
  }

  @Action
  [Actions.UPDATE_PROJECT_UPDATE_CHARTER](charter: ICharter) {
    this.context.commit(Mutations.SET_PROJECT_UPDATE_CHARTER, charter);
  }

  get getPrjUpdateDrawerMode() {
    return this.prjUpdateDrawerMode;
  }

  @Mutation
  [Mutations.SET_PROJECT_UPDATE_DRAWER_MODE](mode: PrjUpdateDrawerModeEnum) {
    this.prjUpdateDrawerMode = mode;
  }

  @Action
  [Actions.UPDATE_PROJECT_UPDATE_DRAWER_MODE](mode: PrjUpdateDrawerModeEnum) {
    this.context.commit(Mutations.SET_PROJECT_UPDATE_DRAWER_MODE, mode);
  }

  get getPrjUpdateSelectedData() {
    return this.prjUpdateSelectedData;
  }

  @Mutation
  [Mutations.SET_PROJECT_UPDATE_SELECTED_DATA](data: any) {
    this.prjUpdateSelectedData = data;
  }

  @Action
  [Actions.UPDATE_PROJECT_UPDATE_SELECTED_DATA](data: any) {
    this.context.commit(Mutations.SET_PROJECT_UPDATE_SELECTED_DATA, data);
  }

  get getPrjUpdateIsFetching() {
    return this.prjUpdateIsFetching;
  }

  @Mutation
  [Mutations.SET_PROJECT_UPDATE_IS_FETCHING](isFetching: boolean) {
    this.prjUpdateIsFetching = isFetching;
  }

  @Action
  [Actions.UPDATE_PROJECT_UPDATE_IS_FETCHING](isFetching: boolean) {
    this.context.commit(Mutations.SET_PROJECT_UPDATE_IS_FETCHING, isFetching);
  }

  get getPrjUpdateIsSubmitting() {
    return this.prjUpdateIsSubmitting;
  }

  @Mutation
  [Mutations.SET_PROJECT_UPDATE_IS_SUBMITTING](isSubmitting: boolean) {
    this.prjUpdateIsSubmitting = isSubmitting;
  }

  @Action
  [Actions.UPDATE_PROJECT_UPDATE_IS_SUBMITTING](isSubmitting: boolean) {
    this.context.commit(
      Mutations.SET_PROJECT_UPDATE_IS_SUBMITTING,
      isSubmitting
    );
  }

  get getPrjUpdateDrawerState() {
    return this.prjUpdateDrawerState;
  }

  @Mutation
  [Mutations.SET_PROJECT_UPDATE_DRAWER_STATE](newState: boolean) {
    this.prjUpdateDrawerState = newState;
  }

  @Action
  [Actions.UPDATE_PROJECT_UPDATE_DRAWER_STATE](newState: boolean) {
    this.context.commit(Mutations.SET_PROJECT_UPDATE_DRAWER_STATE, newState);
  }

  get getPrjUpdateActiveTab() {
    return this.prjUpdateActiveTab;
  }

  get getPrjUpdateNavFrom() {
    return this.prjUpdateNavFrom;
  }

  @Mutation
  [Mutations.SET_PROJECT_UPDATE_NAVIGATE_FROM](from) {
    return (this.prjUpdateNavFrom = from);
  }

  @Action
  [Actions.UPDATE_PROJECT_UPDATE_NAVIGATE_FROM](from) {
    this.context.commit(Mutations.SET_PROJECT_UPDATE_NAVIGATE_FROM, from);
  }

  @Mutation
  [Mutations.SET_PROJECT_UPDATE_ACTIVE_TAB](tab: PrjUpdateTabEnum) {
    this.prjUpdateActiveTab = tab;
  }

  @Action
  [Actions.UPDATE_PROJECT_UPDATE_ACTIVE_TAB](tab: PrjUpdateTabEnum) {
    this.context.commit(Mutations.SET_PROJECT_UPDATE_ACTIVE_TAB, tab);
  }
}
