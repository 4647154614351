// import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { setAllertError, setAllertSuccess } from "@/core/services/Alert";
import ApiService from "@/core/services/ApiService";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "../enums/StoreEnums";

const service =
  process.env.VUE_APP_NODE_ENV == "local"
    ? "/api/v1"
    : "/api/v1";

@Module
export default class ProvinceModule extends VuexModule {
  listProvince = [];
  listRegency = [];
  listVillage = [];
  listDistrict = [];

  @Mutation
  [Mutations.SET_PROVINCE_SIT](data) {
    this.listProvince = data;
  }

  @Mutation
  [Mutations.SET_REGENCY_SIT](data) {
    this.listRegency = data;
  }

  @Mutation
  [Mutations.SET_VILLAGE_SIT](data) {
    this.listVillage = data;
  }

  @Mutation
  [Mutations.SET_DISTRICT_SIT](data) {
    this.listDistrict = data;
  }

  get getListProvince() {
    return this.listProvince;
  }

  get getListRegency() {
    return this.listRegency;
  }

  get getListVillage() {
    return this.listVillage;
  }
  
  get getListDistrict() {
    return this.listDistrict;
  }

  @Action
  [Actions.GET_PROVINCE_LIST]() {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`${service}/province`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PROVINCE_SIT, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  
  @Action
  [Actions.GET_REGENCY_LIST]() {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`${service}/regency`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REGENCY_SIT, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_REGENCY_BY_PROV_LIST](province_id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/regency/find`, {
        params: { province_id: province_id },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REGENCY_SIT, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_VILLAGE_LIST]() {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`${service}/village`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_VILLAGE_SIT, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_VILLAGE_BY_DISTRICT_LIST](district_id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/village/find`, {
        params: { district_id: district_id },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_VILLAGE_SIT, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_DISTRICT_LIST]() {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`${service}/district`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DISTRICT_SIT, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_DISTRICT_BY_REGENCY_LIST](regency_id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/district/find`, {
        params: { regency_id: regency_id },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DISTRICT_SIT, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
