
import { defineComponent, onMounted, onBeforeMount, ref, reactive, watch, computed } from "vue";
import { useStore } from "vuex";
import { checkAccess } from "@/core/helpers/helps";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import PBI from "@/components/pbi/pbi-component.vue";
import moment from "moment";

export default defineComponent({
    components: {
        PBI,
    },
    name: "reporting",
    setup() {
        const router = useRouter();
        const store = useStore();
        const pbiToken = ref([]) as any;
        const counters = ref(0);
        const refreshTokenState = ref(false);
        const BiTokens = reactive({
            expiration: '',
            accessToken: '',
            embedUrl: '',
            id: '',
            refreshToken: ''
        }) as any;

        watch(
            () => pbiToken.value,
            (newVal, oldValvalue) => {
                if (newVal.id != null) {
                    BiTokens.expiration = newVal['expiration']
                    BiTokens.accessToken = newVal['token']
                    BiTokens.embedUrl = newVal['embedUrl']
                    BiTokens.id = newVal['id']
                }
            }
        );

        const getToken = () => {
            store.dispatch(Actions.GET_PBI_TOKEN_REPORT).then((res) => {
                pbiToken.value = res.data
            });
        }

        watch(
            () => counters.value,
            () => {
                checkExpiredTime();
            }
        );

        watch(
            () => BiTokens.accessToken,
            (oldvalue, newValue) => {
                if (oldvalue != '' && refreshTokenState.value) {
                    BiTokens.refreshToken = newValue
                }
            }
        );

        const checkExpiredTime = () => {
            if (BiTokens['expiration'] == "") {
                getToken();
            } else {
                var expired_time_pbi = moment(BiTokens['expiration'])
                    .add(-20, "minutes")
                    .locale("id")
                    .format("DD-MM-YYYY hh:mm:ss");

                var dateNow = moment().format("DD-MM-YYYY hh:mm:ss");
                if (expired_time_pbi < dateNow) {
                    getToken();
                    counters.value = 0
                    refreshTokenState.value = true
                } else {
                    refreshTokenState.value = false
                }
            }
        }

        const getProfile = computed(() => {
            return store.getters.getUserProfile;
        });

        const isolation = computed(() => {
            return store.getters.getRole;
        });

        const getChannel = computed(() => {
            if (
                getProfile.value.role._id !== '' &&
                isolation.value.isolation_permissions &&
                isolation.value.isolation_permissions[0].is_active
            ) {
                const isolate =
                    store.state.AuthModule.userProfile.tenants.length > 0 &&
                        store.state.AuthModule.userProfile.tenants[0].workspaces.length > 0
                        ? store.state.AuthModule.userProfile.tenants[0].workspaces[0]
                            .channels
                        : [];

                let data = [] as any
                isolate.forEach(element => {
                    data.push(element.name)
                });
                return data;
            } else {
                let data = [] as any
                store.getters.getListChannel.forEach(element => {
                    data.push(element.name)
                });
                return store.getters.getListChannel;
            }
        });

        onMounted(async () => {
            getToken()
            store.dispatch(Actions.GET_CHANNEL_LIST);
            if (!checkAccess('report')) {
                router.push({
                path: '/404',
                });
            }
        });

        onBeforeMount(() => {
            checkExpiredTime();

            setInterval(() => {
                counters.value += 1;
            }, 5000);
        });

        return {
            BiTokens,
            counters,
            getChannel
        }
    },
    beforeRouteLeave(to, from, next) {
        clearInterval(this.counters);
        next();
    }
})
