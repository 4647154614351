// import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { toastError, toastSuccess } from '@/core/services/Alert';
import ApiService from '@/core/services/ApiService';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '../enums/StoreEnums';

const service = process.env.VUE_APP_NODE_ENV == 'local' ? '/api/v1' : '/api/v1';

export interface ChannelDrawerStateInfo {
  ChannelDrawerState: boolean;
}

export enum DrawerModeEnum {
  ADD = 'add',
  EDIT = 'edit',
}
@Module
export default class ChannelModule extends VuexModule {
  ChannelDrawerState = false;
  drawerMode = DrawerModeEnum.ADD;
  listChannel = [];
  listWorkspace = [];
  detailChannel = [];

  get getChannelDrawerState() {
    return this.ChannelDrawerState;
  }
  get getChannelDrawerMode() {
    return this.drawerMode;
  }

  @Mutation
  [Mutations.SET_CHANNEL_DRAWER](payload): void {
    this.ChannelDrawerState = payload;
  }

  @Action
  [Actions.UPDATE_CHANNEL_DRAWER](state: boolean): void {
    this.context.commit(Mutations.SET_CHANNEL_DRAWER, state);
  }

  @Mutation
  [Mutations.SET_CHANNEL_DRAWER_MODE](mode: DrawerModeEnum) {
    this.drawerMode = mode;
  }

  @Action
  [Actions.UPDATE_CHANNEL_DRAWER_MODE](mode: DrawerModeEnum) {
    this.context.commit(Mutations.SET_CHANNEL_DRAWER_MODE, mode);
  }

  @Mutation
  [Mutations.SET_CHANNEL](data) {
    this.listChannel = data;
  }

  @Mutation
  [Mutations.SET_DETAIL_CHANNEL](data) {
    this.detailChannel = data;
  }

  @Mutation
  [Mutations.SET_WORKSPACE_LIST](data) {
    this.listWorkspace = data;
  }

  get getListChannel() {
    return this.listChannel;
  }

  get getListWorkspace() {
    return this.listWorkspace;
  }

  get getDetailChannels() {
    return this.getDetailChannel;
  }

  @Action
  [Actions.GET_CHANNEL_LIST]() {
    const activeworkspace = JSON.parse(localStorage.getItem("activeWorkspace") as any)?._id || '631063238c130d3fa0415ec2'
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/channel`, { params: { workspace_id: activeworkspace }})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CHANNEL, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.CREATE_CHANNEL](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/channel`, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CHANNEL, data);
          this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
            action : 'SETTINGS.CREATE_CHANNEL',
            data : {
              channel_detail : data?.data || data
            }
          })
          resolve(data);
        })
        .catch((err) => {
          toastError(err);
        });
    });
  }

  @Action
  [Actions.UPDATE_CHANNEL_LIST](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`${service}/channel`, payload.params.id, payload.data)
        .then(({ data }) => {
          this.context.dispatch(Actions.UPDATE_CATEGORY_ASSETS_DRAWER, false);
          this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
            action : 'SETTINGS.UPDATE_CHANNEL',
            data : {
              channel_id : payload.params.id,
              payload_updated : payload.data
            }
          })
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.DELETE_CHANNEL](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`${service}/channel/${id}`)
        .then(({ data }) => {
          this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
            action : 'SETTINGS.DELETE_CHANNEL',
            data : {
              channel_id : id,
            }
          })
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_DETAIL_CHANNEL](payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`${service}/channel`, `${payload}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DETAIL_CHANNEL, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_WORKSPACE_LIST](params) {
    console.log(params);
    
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/workspace`, {
        params : {
          tenant_id : params.tenant_id
        }
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_WORKSPACE_LIST, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.CREATE_WORKSPACE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/workspace`, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_WORKSPACE_LIST, data);
          this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
            action : 'SETTINGS.CREATE_WORKSPACE',
            data : {
              workspace_detail : data?.data || data,
            }
          })
          resolve(data);
        })
        .catch((err) => {
          toastError(err);
        });
    });
  }

  @Action
  [Actions.UPDATE_WORKSPACE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`${service}/workspace`, payload.params.id, payload.data)
        .then(({ data }) => {
          this.context.dispatch(Actions.UPDATE_CATEGORY_ASSETS_DRAWER, false);
          this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
            action : 'SETTINGS.UPDATE_WORKSPACE',
            data : {
              workspace_id :  payload.params.id,
              payload_updated : payload.data
            }
          })
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.DELETE_WORKSPACE](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`${service}/workspace/${id}`)
        .then(({ data }) => {
          this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
            action : 'SETTINGS.DELETE_WORKSPACE',
            data : {
              workspace_id :  id,
            }
          })
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
