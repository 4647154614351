
import { computed, defineComponent, watch, reactive, ref } from "vue";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n";
import { useDropzone } from "vue3-dropzone";
import axios from "axios";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { split } from "lodash";

export default defineComponent({
  name: "component-add-new-menu-modal",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const errorImport = ref(false) as any;
    
    const dialogVisible = ref(false)

    let doc = reactive([]) as any;

    const anyError = ref([]) as any;

    const nameOfDocument = ref("");

    function onDrop(acceptFiles, rejectReasons) {
      doc = acceptFiles;
      nameOfDocument.value = doc[0].name;
    }

    const convert_keterangan = (obj) => {
      const objectArray = Object.entries(obj);
      const temp = [] as any;

      objectArray.forEach(([key, value]) => {
        temp.push({
          ket: key,
          value: value,
        });
      });

      return temp;
    };

    const host = window.location.origin

    const split_upercase = (text) => {
      let temp = text.split(/(?=[A-Z])/);
      temp = temp.join(" ");
      return temp;
    };

    const { getRootProps, getInputProps, ...rest } = useDropzone({
      onDrop,
      accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    });

    const service =
      process.env.VUE_APP_NODE_ENV == "local" ? "/api/v1" : "/api/v1";

    const showProgress = ref(false);

    var progress = ref("") as any;

    const collection_error = ref([]) as any;
    const collection_success = ref([]) as any;
    const listUpdate = ref([]) as any

    const uploadExcel = () => {
      progress.value = ''
      collection_error.value = []
      showProgress.value = true;
      var config = {
        onUploadProgress: function (progressEvent) {
          progress.value = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      };

      const formData: any = new FormData();
      formData.append("file", doc[0]);
      ApiService.setHeader();
      axios
        .post(`${service}/asset/${getImportType.value === 'new' ? 'import' : 'import-update'}`, formData, config)
        .then(function (res) {
          if(getImportType.value === 'new') {
            ElNotification({
              title: "Success",
              message: `Berhasil impor ${res.data.data.total_ok} aset`,
              type: "success",
              duration: 0,
            });

            store.commit(Mutations.SET_DIALOG_MODAL_IMPORT, false)
            store.dispatch(Actions.UPDATE_GLOBAL_MODAL_STATE, false)
          } else {
            collection_error.value = res.data.data.error
            collection_success.value = res.data.data.updated_asset

            collection_error.value = collection_error.value.map((element, i) => {
              let x = ''
              x = 'er666 ' + element
              return x;
            });

            if (collection_success.value !== undefined) {
              collection_success.value = collection_success.value.map((element, i) => {
                let x = ''
                x = 'sc999 ' + element
                return x;
              });
  
              listUpdate.value = [...collection_success.value, ...collection_error.value]
              errorImport.value = true;
              dialogVisible.value = false
            } else {
              listUpdate.value = [...collection_error.value]
              errorImport.value = true;
              dialogVisible.value = false
            }
          }

          showProgress.value = false;
          nameOfDocument.value = "";
          store.dispatch(Actions.UPDATE_STATUS_UPLOAD_MASAL, true);
        })
        .catch((error) => {

          current.value = 1
          if (error.response.data.data !== null) {
            collection_error.value = error.response.data.data?.error_details;
            errorImport.value = true;

          } else {
            ElNotification({
              title: "Error",
              message: error.response.data.msg,
              type: "error",
              duration: 0,
            });
          }

          nameOfDocument.value = "";
          showProgress.value = false;
          store.dispatch(Actions.UPDATE_STATUS_UPLOAD_MASAL, true);
        });
    };

    const getImportType = computed(() => {
      return store.getters.getImportType;
    });

    const current = ref(1);
    const pageSize = ref(3);

    const indexStart = computed(() => {
      return (current.value - 1) * pageSize.value;
    });

    const indexEnd = computed(() => {
      return indexStart.value + pageSize.value;
    });

    const paginated = computed(() => {
      return collection_error.value.slice(indexStart.value, indexEnd.value);
    });

    const pageCount = computed(() => {
      let l = collection_error.value.length;
      let s = pageSize.value;
      return Math.ceil(l / s);
    });

    const prev = () => {
      current.value--;
    };

    const next = () => {
      current.value++;
    };

    const close = () => {
      errorImport.value = false;
      dialogVisible.value = false
      store.commit(Mutations.SET_DIALOG_MODAL_IMPORT, false)
      store.dispatch(Actions.UPDATE_GLOBAL_MODAL_STATE, false)
    }

    const closeInfo = () => {
      errorImport.value = false;
      dialogVisible.value = false
      store.commit(Mutations.SET_DIALOG_MODAL_IMPORT, false)
      store.dispatch(Actions.UPDATE_GLOBAL_MODAL_STATE, false)
    }

    watch(() => _.open, (first, second) => {
      dialogVisible.value = _.open
    });
    
    return {
      getRootProps,
      getInputProps,
      paginated,
      pageCount,
      indexStart,
      prev,
      next,
      current,
      nameOfDocument,
      errorImport,
      anyError,
      showProgress,
      uploadExcel,
      convert_keterangan,
      split_upercase,
      collection_error,
      progress,
      dialogVisible,
      store,
      getImportType,
      close,
      closeInfo,
      listUpdate,
      host,
      t
    };
  },
});
