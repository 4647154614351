import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { toastSuccess, toastError } from "@/core/services/Alert";
import router from "@/router";
import * as Yup from "yup";
import { ElMessage, ElNotification } from 'element-plus'
const service = process.env.VUE_APP_NODE_ENV == "local" ? "/api/v1/app/auth" : "/api/v1/app/auth";
const serviceUser = process.env.VUE_APP_NODE_ENV == 'local' ? '/api/v1' : '/api/v1';

// const service = "auth/api/v1";

const profileSchema = Yup.object().shape({
  name: Yup.string().required(),
  username: Yup.string()
    .max(255, "Must be exactly 255 character")
    .required(),
  email: Yup.string().required(),
  tlp: Yup.string().nullable(),
  industrial_sector: Yup.string().nullable(),
  company_name: Yup.string().nullable(),
  company_address: Yup.string().nullable(),
  position: Yup.string().nullable(),
});

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  token: string;
}

export interface UserAuthInfo {
  errors: Array<string>;
  user: User | null;
  isAuthenticated: boolean;
}

export interface Role {
  _id: string;
  code: string;
  name: string;
}

export interface Channel {
  _id: string;
  name: string;
}

export interface Workspace {
  _id: string;
  name: string;
  channels: Channel[];
}

export interface Tenant {
  _id: string;
  name: string;
  workspaces: Workspace[];
}

export interface UserProfileInfo {
  _id: string;
  name: string;
  username: string;
  email: string;
  phone: string;
  nik: string;
  role: Role;
  tenants: Tenant[];
  created_at: any;
  updated_at: any;
  email_verified_at?: any;
  admin_verified_at?: any;
  is_active: boolean;
  status?: string;
}

export interface WorkspaceId {
  _id: string;
  name: string;
  is_active: boolean;
  tenant_id: string;
  created_at: any;
  updated_at: any;
  __v: number;
  link: string;
}
export interface ActiveWorkspace {
  _id: string;
  name: string;
  email: string;
  phone_number: string;
  address: string;
  is_active: boolean;
  tenant_id: string;
  workspace_id: WorkspaceId;
  created_at: any;
  updated_at: any;
  __v: number;
}

export interface FormProfile {
  _id: number | null;
  name: string;
  username: string;
  email: string;
  nip: string;
  phone: string;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  error_detail = [];
  user = window.localStorage.getItem("user")
    ? JSON.parse(window.localStorage.getItem("user") as any)
    : null;
  isAuthenticated = !!JwtService.getToken();
  users = null;
  filter = {
    search: "",
    limit: 10,
    page: 1,
  };

  pagination = {
    totalPage: 1,
    totalRecord : 1,
  }

  formProfile: FormProfile = {
    _id: null,
    name: '',
    username: '',
    email: '',
    nip: '',
    phone: '',
  };
  

  userProfile:UserProfileInfo = {
    _id: '',
    name: '',
    username: '',
    email: '',
    phone: '',
    nik: '',
    role: {
      _id: '',
      code: '',
      name: '',
    },
    tenants: [
      {
        _id: "",
        name: "",
        workspaces: [
          {
            _id: "",
            name: "",
            channels: [
              {
                _id: "",
                name: ""
              }
            ]
          }
        ]
      }
    ],
    created_at: '',
    updated_at: '',
    email_verified_at: '',
    admin_verified_at: '',
    is_active: false,
  }

  activeWorkspace:ActiveWorkspace = window.localStorage.getItem("activeWorkspace")
  ? JSON.parse(window.localStorage.getItem("activeWorkspace") as any)
  : {
    _id: "",
    name: "",
    email: "",
    phone_number: "",
    address: "",
    is_active: false,
    tenant_id: "",
    workspace_id: {
      _id: "",
      name: "",
      is_active: false,
      tenant_id: "",
      created_at: "",
      updated_at: "",
      __v: 0,
      link: ""
    },
    created_at: "",
    updated_at: "",
    __v: 0
  }

  get usersFilter() {
    return this.filter;
  }

  get userPagination() {
    return this.pagination
  }

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  
  get getformProfile() {
    return this.formProfile;
  }
  
  get getUserProfile() {
    return this.userProfile;
  }

  get getDetailError() {
    return this.error_detail
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  /**
   * Get all registered users
   * @returns array
   */

  get getUsers() {
    return this.users;
  }

  /**
   * Get active workspace selected by users
   * @returns object
   */
  get getActiveWorkspace() {
    return this.activeWorkspace
  }

  @Mutation
  [Mutations.SET_ERROR_DETAIL](load) {
    this.error_detail = load;
  }
  @Mutation
  [Mutations.SET_USERS_PAGE](page) {
    this.filter.page = page;
  }

  @Action
  [Actions.UPDATE_USERS_PAGE](page) {
    this.context.commit(Mutations.SET_USERS_PAGE, page);
  }

  @Mutation
  [Mutations.SET_USERS_ITEMS_PER_PAGE](itemsPerPage) {
    this.filter.limit = itemsPerPage;
  }

  @Action
  [Actions.UPDATE_USERS_ITEMS_PER_PAGE](itemsPerPage) {
    this.context.commit(Mutations.SET_USERS_ITEMS_PER_PAGE, itemsPerPage);
  }

  @Mutation
  [Mutations.SET_USERS_KEYWORD](keyword: string) {
    this.filter.search = keyword
  }

  @Action
  [Actions.UPDATE_USERS_KEYWORD](keyword: string) {
    this.context.commit(Mutations.SET_USERS_KEYWORD, keyword);
  }
  
  @Mutation
  [Mutations.SET_USERS_TOTAL_PAGE](keyword: number) {
    this.pagination.totalPage = keyword
  }

  @Action
  [Actions.UPDATE_USERS_TOTAL_PAGE](keyword: number) {
    this.context.commit(Mutations.SET_USERS_TOTAL_PAGE, keyword);
  }
   
  @Mutation
  [Mutations.SET_USERS_TOTAL_RECORD](keyword: number) {
    this.pagination.totalRecord = keyword
  }

  @Action
  [Actions.UPDATE_USERS_TOTAL_RECORD](keyword: number) {
    this.context.commit(Mutations.SET_USERS_TOTAL_RECORD, keyword);
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = [];
  }

  @Mutation
  [Mutations.SET_TOKEN](token) {
    this.isAuthenticated = true;
    JwtService.saveToken(token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
    window.localStorage.removeItem("user");
  }

  @Mutation
  [Mutations.SET_USER_LIST](data) {
    this.users = data;
  }
  
  @Mutation
  [Mutations.SET_PROFILE_DETAIL](data) {
    this.userProfile = data;
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.loginServices(`${service}/login`, credentials)
        .then(({ data }) => {
          Promise.all([
            this.context.commit(Mutations.SET_TOKEN, data.data.token),
          ]).finally(() => {
            resolve(data);
          });
        }).catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error.response);
          reject(error);
        })
    });
  }

  @Action
  [Actions.LOGOUT](dataUser:any) {
    this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
      action : 'USER.LOGOUT',
      email : dataUser?.email,
      user_id : dataUser?.id
    }).then(() => {
      this.context.commit(Mutations.PURGE_AUTH);
      this.context.commit(Mutations.SET_ROLE_MENUS, null);
      window.localStorage.removeItem("menus");
      window.localStorage.removeItem('role');
      window.localStorage.removeItem('activeWorkspace');
      window.localStorage.removeItem('id_token');
    }).finally(() => (
      setTimeout(() => {
        window.location.href = "/sign-in"
      }, 5000)
    ));
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/register`, credentials)
        .then(({ data }) => {
          resolve(data);
          this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
            action : 'USER.CREATE',
            data : {
              ...credentials
            },
          }).then(() => resolve(data))
        })
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR_DETAIL, error)
          reject(error);
        })
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    if (payload.reset_password) {
      delete payload.reset_password;

      return new Promise<void>((resolve, reject) => {
        ApiService.post(`${service}/reset-password`, payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            // this.context.commit(Mutations.SET_ERROR, response);
            reject();
          });
      });
    } else {
      delete payload.reset_password;

      return new Promise<void>((resolve, reject) => {
        ApiService.post(`${service}/forgot-password`, payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, response);
            reject();
          });
      });
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
            action : 'USER.UPDATE',
            data : {
              user_updated : data
            }
          })
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  /**
   * Get all registered users
   * @param query object which contains page, itemsPerPage, trash, name, email, & username
   * @returns User
   */
  @Action({ rawError: true })
  [Actions.GET_USER_LIST](query) {
    ApiService.setHeader();
    
    if (query.search && query.search?.trim() === "") delete query.search;
    if (query.name && query.name?.trim() === "") delete query.name;
    if (query.email && query.email?.trim() === "") delete query.email;
    if (query.username && query.username?.trim() === "") delete query.username;
    const q = new URLSearchParams(query);
    
    return ApiService.get(`api/v1/user`, `?${q}`)
      .then(({ data }) => {
        // const filtered = data.data.filter((item) => !item.status || item.status != 'reject')
        this.context.commit(Mutations.SET_USER_LIST, data.data);
        
        this.context.dispatch(Actions.UPDATE_USERS_TOTAL_RECORD, data.meta.totalRecord)
        this.context.dispatch(Actions.UPDATE_USERS_TOTAL_PAGE, data.meta.totalPage)

        return Promise.resolve(data);
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR, error);
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  [Actions.DELETE_MEMBER](memberId) {
    return ApiService.delete(`api/v1/user/${memberId}`)
      .then(({ data }) => {
        this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
          action : 'USER.DELETE',
          data : {
            deleted_user : memberId
          }
        })

        return Promise.resolve(data);
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR, error);
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  [Actions.UPDATE_MEMBER_DETAIL](payload) {
    return ApiService.put(`/api/v1`, 'user', payload)
      .then(({ data }) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR, error);
        return Promise.reject(error);
      });
  }


  @Action({ rawError: true })
  [Actions.UPDATE_PROFILE_USER](payload) {
    return new Promise((resolve , reject) => {
      ApiService.put(`/api/v1`, `user/profile`, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
            action : 'USER.UPDATED_PROFILE',
            data : {
              user_detail : data
            }
          }).then(() => {
            // window.location.href = "/profile/detail"
            // ElMessage({
            //   message: "Sukses Edit Profile",
            //   type: 'success',
            // })
            resolve(data);
          })
        })
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error);
          reject(error);
        });
      })
  }

  @Action({ rawError: true })
  [Actions.ADD_NEW_MEMBER](payload) {
    return ApiService.post(`${service}/register`, payload)
      .then(({ data }) => {
        this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
          action : 'USER.REGISTER',
          data : {
            user_created : data
          }
        }).then(() => { 
          return Promise.resolve(data);
        })
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR, error);
        return Promise.reject(error);
      });
  }

  @Action
  [Actions.CHANGE_PROFILE_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`${serviceUser}/user/change-password`, "", payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
            action : 'USER.CHANGE_PASSWORD'
          }).then(() => { 
            return resolve(data);
          })
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          toastError(err);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.REFRESH_TOKEN]() {
    return ApiService.post(`${service}/refresh`)
      .then(({ data }) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR, error);
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  [Actions.GET_PROFILE_USER]() {
    return new Promise((resolve , reject) => {
      return ApiService.get(`role/api/v1/profile`)
      .then(({ data }) => {
        resolve(data.data)
      })
      .catch((error) => {
        reject(error);
        this.context.commit(Mutations.SET_ERROR, error);
        return Promise.reject(error);
      });
    })
   
  }
  @Action({ rawError: true })
  [Actions.GET_PROFILE_DETAIL]() {
    return new Promise((resolve , reject) => {
      return ApiService.get(`api/v1/user/profile`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROFILE_DETAIL, data.data)
        if (data.data.role !== null) {
          this.context.dispatch(Actions.GET_ROLE_BY_ID, data.data.role._id)
        }
        resolve(data.data)
      })
      .catch((error) => {
        reject(error);
        this.context.commit(Mutations.SET_ERROR, error);
        return Promise.reject(error);
      });
    })
   
  }
  
  @Action
  [Actions.CREATE_ACCOUNT](payload) {
    return new Promise((resolve , reject) => {
      return ApiService.post(`api/v1/user`, payload)
      .then(({ data }) => {
        this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
          action : 'USER.CREATE',
          data : {
            user_detail : data.data
          }
        }).then(() => { 
          return resolve(data.data);
        })
      })
      .catch((error) => {
        reject(error);
        this.context.commit(Mutations.SET_ERROR, error);
        return Promise.reject(error);
      });
    })
  }

  @Action
  [Actions.POST_EMAIL_VERIFICATION](payload) {
    return new Promise((resolve , reject) => {
      return ApiService.post(`api/v1/app/auth/email-verification`, payload)
      .then(({ data }) => {
        this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
          action : 'USER.EMAIL_VERIFICATION',
          data : {
            user_detail : data.data
          }
        }).then(() => { 
          return resolve(data.data);
        })
      })
      .catch((error) => {
        reject(error);
        this.context.commit(Mutations.SET_ERROR, error);
        return Promise.reject(error);
      });
    })
  }

  @Mutation
  [Mutations.SET_ACTIVE_WORKSPACE](data) {
    this.activeWorkspace = data;
  }

  @Action
  [Actions.GET_ACTIVE_WORKSPACE_INFO](id) {
    return new Promise((resolve , reject) => {
      return ApiService.get(`api/v1/workspace/${id}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ACTIVE_WORKSPACE, data.data)
        localStorage.setItem('activeWorkspace', JSON.stringify(data.data))
        resolve(data.data)
      })
      .catch((error) => {
        reject(error);
        this.context.commit(Mutations.SET_ERROR, error);
        return Promise.reject(error);
      });
    })
  }

  @Action({ rawError: true })
  [Actions.VERIFY_MEMBER](payload) {
    return new Promise((resolve , reject) => {
      return ApiService.post(`api/v1/app/auth/admin-verification`, { id: payload.id, type: payload.type })
      .then(({ data }) => {
        this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
          action : 'USER.MEMBER_VERIFICATION',
          data : {
            user_id : payload.id
          }
        }).then(() => { 
          return resolve(data.data);
        })
      })
      .catch((error) => {
        reject(error);
        this.context.commit(Mutations.SET_ERROR, error);
        return Promise.reject(error);
      });
    })
  }

  @Action({ rawError: true })
  [Actions.GET_USER_BY_TOKEN](token) {
    return new Promise((resolve , reject) => {
      return ApiService.get(`api/v1/app/auth/${token}`)
      .then(({ data }) => {
        resolve(data.data)
        return data.data
      })
      .catch(({error}) => {
        reject(error);
        this.context.commit(Mutations.SET_ERROR, error);
        return Promise.reject(error);
      });
    })
  }

  @Action({ rawError: true })
  [Actions.VERIFY_CAPTCHA](response) {
    return new Promise((resolve , reject) => {
      return ApiService.post(`${service}/verify_captcha`, {
        response
      })
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error);
        this.context.commit(Mutations.SET_ERROR, error);
        return Promise.reject(error);
      });
    })
   
  }
}
