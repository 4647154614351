
import {
  defineComponent,
  onMounted,
  watch,
  ref,
  reactive,
  computed,
} from "vue";

import * as pbi from "powerbi-client";
import alertToken from "./alertToken.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { checkPermissionUpdate } from "@/core/helpers/helps";

export default defineComponent({
  name: "PBI",
  components: {
    alertToken,
  },
  props: {
    accessToken: {
      type: String,
      default: "",
    },
    refreshToken: {
      type: String,
      default: "",
    },
    embedUrl: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    filter: {
      type: Array as any,
    },
  },
  setup(props) {
    watch(
      () => props.embedUrl,
      (value) => {
        if (value != "") {
          initPBI();
        }
      }
    );

		const store = useStore();
    const showAlert = ref(false);
    const alertType = ref('warning');
    onMounted(() => {
      if(checkPermissionUpdate('setting-env','setting.admin.accesstoken_edit')) {
        store.dispatch(Actions.GET_POWERBI_TOKEN).then((response) => {
          if(response.data.usuage?.additionalInfo?.usage >= 80 && response.data.usuage?.additionalInfo?.usage < 90) {
            alertType.value = 'warning'
            showAlert.value = true
          } else if(response.data.usuage?.additionalInfo?.usage > 90) {
            alertType.value = 'error'
            showAlert.value = true
          } else showAlert.value = false
        })
      }
      if (props.embedUrl != "") {
        initPBI();
      }
    });

    const initPBI = async () => {
      const permissions = pbi.models.Permissions.All;
      const config = {
        type: "report",
        tokenType: pbi.models.TokenType.Embed,
        accessToken: props.accessToken,
        embedUrl: props.embedUrl,
        id: props.id,
        pageView: "actualSize",
        permissions: permissions,
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: false,
        },
      };

      let powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );

      let dashboardContainer = document.getElementById("container") as any;
      const dashboardOverlay = document.getElementById("overlay") as any;
      const dashboardprintBTN = document.getElementById("printBTN") as any;

      dashboardContainer.style.visibility = "hidden";

      const dashboard = powerbi.embed(dashboardContainer, config);
      dashboard.on("loaded", function () {
        dashboardOverlay.style.visibility = "hidden";
        dashboardContainer.style.visibility = "visible";
        // dashboardprintBTN.style.visibility = "visible";
        dashboard.off("loaded");
        if (props.filter.length > 0) {
          spesificBUMN();
        }
      });

      dashboard.off("rendered");
      dashboard.on("error", function () {
        dashboard.off("error");
      });
    };

    const spesificBUMN = async () => {
      let powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );

      const element = document.getElementById("container") as any;
      const report = powerbi.get(element) as any;

      const basicStateFilter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "summary",
          column: "data.nama_bumn",
        },
        operator: "In",
        values: props.filter,
      };

      report.setFilters([basicStateFilter]);
    };
    const printPDF = async () => {
      let powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );

      const element = document.getElementById("container") as any;
      const report = powerbi.get(element) as any;

      try {
        await report.print();
      } catch (errors) {
        console.log(errors);
      }
    };

    watch(
      () => props.refreshToken,
      (oldvalue, newValue) => {
        if (newValue != "" && oldvalue != newValue) {
          refreshTokenFunc(newValue);
        }
      }
    );

    const refreshTokenFunc = async (token) => {
      console.log("refreshTokenFunc", token);
      let powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );

      var element = document.getElementById("container") as any;
      var report = powerbi.get(element) as any;
      if (token != "") {
        await report.setAccessToken(token);
      }
    };

    return {
      printPDF,
      spesificBUMN,
      refreshTokenFunc,
      alertType,
      showAlert,
    };
  },
});
