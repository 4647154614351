
import {
  computed,
  ref,
  watch,
  onMounted,
  getCurrentInstance,
  reactive,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { Getters } from "@/store/enums/StoreTutorialEnums";
import IntroJs from "intro.js";
import { useStore } from "vuex";

export default {
  setup() {
    const $instance = getCurrentInstance();
    const store = useStore();
    const next = ref(0);
    const nextStepTutorial = ref(0);
    const show = ref(false);
    const filterStep = reactive({
      name: "",
      bool: false,
    });
    const router = useRouter();
    const { fullPath } = useRoute();

    const comFilterStep = computed(() => store.getters[Getters.FILTER_STEP]);
    const localStorageFun = (name: string) => {
      try {
        if (name) {
          const a = localStorage.getItem(name) as any;
          return a ? JSON.parse(a) : undefined;
        } else {
          return name;
        }
      } catch (e) {
        console.log("localStorageFun", e);
      }
    };
    const localStorageFunSave = (name: string) => {
      try {
        if (name) {
          let array = [];
          array = localStorageFun("introjs-tutorial");
          array.push(name as never);
          localStorage.setItem("introjs-tutorial", JSON.stringify(array));
        } else {
          return name;
        }
      } catch (e) {
        console.log("localStorageFun", e);
      }
    };
    const listStep = () => {
      return $instance?.proxy?.$nextTick(() => {
        const intro = IntroJs(".header-menu.align-items-stretch").setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".menu-link.py-3.fs-5.dashboard"
              )[0] as HTMLElement,
              intro: `<div class="introjs-3">
                <div>Lihat Dasbor berdasarkan proyek Anda!</div>
                  <div>
                    Menu infografis berisi rangkuman seluruh data dan informasi dalam
                    Aplikasi Tomps
                  </div>
                  <div class="grid-tutorial">
                    <div  class="lewati">Lewati</div>
                    <button class="next">Mengerti</button>
                  </div>
              </div>`,
            },
            {
              element: document.querySelectorAll(
                ".menu-link.py-3.fs-5.proyek"
              )[0] as HTMLElement,
              intro: `<div class="introjs-3" id="step-2">
                <div>Lihat Proyek yang Anda buat!</div>
                <div>
                  Menu ini menampilkan data proyek yang telah Anda buat
                </div>
                <div class="grid-tutorial">
                  <div  class="lewati">Lewati</div>
                  <button class="next">Mengerti</button>
                </div>
              </div>`,
            },
          ],
        });
        // http://localhost:8080/dashboard/index

        intro.start();
        const funDocument = (name: string, status = "1", local?: string) => {
          return new Promise((resolve) => {
            switch (status) {
              case "1":
                {
                  const doc = document.querySelectorAll(name);
                  doc[0]
                    ?.getElementsByClassName("next")[0]
                    ?.addEventListener("click", () => {
                      resolve("step-1");
                    });
                  doc[0]
                    ?.getElementsByClassName("lewati")[0]
                    ?.addEventListener("click", () => {
                      resolve("step-2");
                      if (local) {
                        localStorage.setItem("saveSettingTutorial", local);
                      }
                    });
                }
                break;
              case "2":
                {
                  const time = setInterval(() => {
                    const doc = document.querySelectorAll(name);
                    if (doc.length > 0) {
                      doc[0]
                        ?.getElementsByClassName("next")[0]
                        ?.addEventListener("click", () => {
                          resolve("step-1");
                          if (local) {
                            localStorage.setItem("saveSettingTutorial", local);
                          }
                        });

                      doc[0]
                        ?.getElementsByClassName("lewati")[0]
                        ?.addEventListener("click", () => {
                          resolve("step-2");
                          if (local) {
                            localStorage.setItem("saveSettingTutorial", local);
                          }
                        });
                      clearInterval(time);
                    }
                  });
                }
                break;
            }
          });
        };
        funDocument(".introjs-3").then((status) => {
          console.log("status", status);
          switch (status) {
            case "step-1":
              nextStepTutorial.value = 1;
              intro.nextStep();
              break;
            case "step-2":
              intro.exit();
              localStorageFunSave("on-boarding-2");
              break;
          }
        });

        intro.onafterchange(() => {
          if (nextStepTutorial.value === 1) {
            funDocument(".introjs-3#step-2", "2").then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 2;
                  intro.nextStep();
                  router.push("/report/dokumen/direktori-dokumen");
                  break;
                case "step-2":
                  intro.exit();
                  localStorageFunSave("on-boarding-2");
                  break;
              }
            });
          }
        });
      });
    };
    const listStep2 = () => {
      if (fullPath === "/report/dokumen/direktori-dokumen") {
        $instance?.proxy?.$nextTick(() => {
          const intro2 = IntroJs().setOptions({
            showButtons: false,
            showBullets: false,
            showProgress: false,
            exitOnOverlayClick: false,
            exitOnEsc: false,
            skipLabel: "",

            steps: [
              {
                element: document.querySelectorAll(
                  ".menu-link.py-3.fs-5.laporan"
                )[0] as HTMLElement,
                intro: `<div class="introjs-3" id="step-3">
 <div>Lihat dokumen Laporan Anda!</div>
            <div>
             Menu ini berisi dokumen penting yang memuat informasi proyek yang Anda kerjakan
            </div>
  <div class="grid-tutorial">
    <div  class="lewati">Lewati</div>
    <button class="next">Mengerti</button>
  </div>
</div>`,
              },
            ],
          });
          intro2.start();
          const funDocument = (name: string, status = "1", local?: string) => {
            return new Promise((resolve) => {
              switch (status) {
                case "1":
                  {
                    const doc = document.querySelectorAll(name);
                    doc[0]
                      ?.getElementsByClassName("next")[0]
                      ?.addEventListener("click", () => {
                        resolve("step-1");
                      });
                    doc[0]
                      ?.getElementsByClassName("lewati")[0]
                      ?.addEventListener("click", () => {
                        resolve("step-2");
                        if (local) {
                          localStorage.setItem("saveSettingTutorial", local);
                        }
                      });
                  }
                  break;
                case "2":
                  {
                    const time = setInterval(() => {
                      const doc = document.querySelectorAll(name);
                      if (doc.length > 0) {
                        doc[0]
                          ?.getElementsByClassName("next")[0]
                          ?.addEventListener("click", () => {
                            resolve("step-1");
                            if (local) {
                              localStorage.setItem(
                                "saveSettingTutorial",
                                local
                              );
                            }
                          });

                        doc[0]
                          ?.getElementsByClassName("lewati")[0]
                          ?.addEventListener("click", () => {
                            resolve("step-2");
                            if (local) {
                              localStorage.setItem(
                                "saveSettingTutorial",
                                local
                              );
                            }
                          });
                        clearInterval(time);
                      }
                    });
                  }
                  break;
              }
            });
          };
          funDocument(".introjs-3#step-3").then((status) => {
            switch (status) {
              case "step-1":
                nextStepTutorial.value = 1;
                intro2.nextStep();
                router.push("/setting/organization/general-setting");
                break;
              case "step-2":
                intro2.exit();
                localStorageFunSave("on-boarding-2");
                break;
            }
          });
        });
      }
      if (fullPath === "/setting/organization/general-setting") {
        $instance?.proxy?.$nextTick(() => {
          setTimeout(() => {
            const intro2 = IntroJs().setOptions({
              showButtons: false,
              showBullets: false,
              showProgress: false,
              exitOnOverlayClick: false,
              exitOnEsc: false,
              skipLabel: "",

              steps: [
                {
                  element: document.querySelectorAll(
                    ".menu-link.py-3.fs-5.setting"
                  )[0] as HTMLElement,
                  intro: `<div class="introjs-3" id="step-4">
            <div>Pengaturan aplikasi TOMPS!</div>
            <div>
             Menu ini digunakan untuk mengatur Aplikasi Tomps serta mengatur hak akses anggota dan Master Data
            </div>
            <div class="grid-tutorial">
    <div  class="lewati">Lewati</div>
    <button class="next">Mengerti</button>
  </div>
      </div>`,
                },
              ],
            });
            intro2.start();
            const funDocument = (
              name: string,
              status = "1",
              local?: string
            ) => {
              return new Promise((resolve) => {
                switch (status) {
                  case "1":
                    {
                      const doc = document.querySelectorAll(name);
                      doc[0]
                        ?.getElementsByClassName("next")[0]
                        ?.addEventListener("click", () => {
                          resolve("step-1");
                        });
                      doc[0]
                        ?.getElementsByClassName("lewati")[0]
                        ?.addEventListener("click", () => {
                          resolve("step-2");
                          if (local) {
                            localStorage.setItem("saveSettingTutorial", local);
                          }
                        });
                    }
                    break;
                  case "2":
                    {
                      const time = setInterval(() => {
                        const doc = document.querySelectorAll(name);
                        if (doc.length > 0) {
                          doc[0]
                            ?.getElementsByClassName("next")[0]
                            ?.addEventListener("click", () => {
                              resolve("step-1");
                              if (local) {
                                localStorage.setItem(
                                  "saveSettingTutorial",
                                  local
                                );
                              }
                            });

                          doc[0]
                            ?.getElementsByClassName("lewati")[0]
                            ?.addEventListener("click", () => {
                              resolve("step-2");
                              if (local) {
                                localStorage.setItem(
                                  "saveSettingTutorial",
                                  local
                                );
                              }
                            });
                          clearInterval(time);
                        }
                      });
                    }
                    break;
                }
              });
            };

            funDocument(".introjs-3#step-4", "1").then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 1;
                  localStorage.setItem(
                    "saveSettingTutorial",
                    JSON.stringify("/dashboard/index")
                  );
                  intro2.nextStep();
                  router.push("/dashboard/index");
                  break;
                case "step-2":
                  intro2.exit();
                  localStorageFunSave("on-boarding-2");
                  break;
              }
            });
          }, 1000);
        });
      }
    };
    const listStep3 = () => {
      if (fullPath === "/dashboard/index") {
        $instance?.proxy?.$nextTick(() => {
          const intro2 = IntroJs("#parent-introjs-1").setOptions({
            showButtons: false,
            showBullets: false,
            showProgress: false,
            exitOnOverlayClick: false,
            exitOnEsc: false,
            skipLabel: "",

            steps: [
              {
                element: document.querySelectorAll(
                  "#introjs-5"
                )[0] as HTMLElement,
                intro: `<div class="introjs-3" id="step-5">
            <div>Sesuaikan dengan bahasa Anda!</div>
            <div>
              Ubah bahasa yang ingin anda gunakan sesuaikan dengan Anda</div>
            <div class="grid-tutorial">
    <div  class="lewati">Lewati</div>
    <button class="next">Mengerti</button>
  </div>
      </div>`,
              },
            ],
          });
          intro2.start();
          const funDocument = (name: string, status = "1", local?: string) => {
            return new Promise((resolve) => {
              switch (status) {
                case "1":
                  {
                    const doc = document.querySelectorAll(name);
                    doc[0]
                      ?.getElementsByClassName("next")[0]
                      ?.addEventListener("click", () => {
                        resolve("step-1");
                      });
                    doc[0]
                      ?.getElementsByClassName("lewati")[0]
                      ?.addEventListener("click", () => {
                        resolve("step-2");
                        if (local) {
                          localStorage.setItem("saveSettingTutorial", local);
                        }
                      });
                  }
                  break;
                case "2":
                  {
                    const time = setInterval(() => {
                      const doc = document.querySelectorAll(name);
                      if (doc.length > 0) {
                        doc[0]
                          ?.getElementsByClassName("next")[0]
                          ?.addEventListener("click", () => {
                            resolve("step-1");
                            if (local) {
                              localStorage.setItem(
                                "saveSettingTutorial",
                                local
                              );
                            }
                          });

                        doc[0]
                          ?.getElementsByClassName("lewati")[0]
                          ?.addEventListener("click", () => {
                            resolve("step-2");
                            if (local) {
                              localStorage.setItem(
                                "saveSettingTutorial",
                                local
                              );
                            }
                          });
                        clearInterval(time);
                      }
                    });
                  }
                  break;
              }
            });
          };
          funDocument(".introjs-3#step-5", "1").then((status) => {
            switch (status) {
              case "step-1":
                nextStepTutorial.value = 1;
                intro2.nextStep();
                router.push("/approval/list");
                break;
              case "step-2":
                intro2.exit();
                localStorageFunSave("on-boarding-2");
                break;
            }
          });
        });
      }

      if (fullPath === "/approval/list") {
        $instance?.proxy?.$nextTick(() => {
          const intro2 = IntroJs(".header-menu.align-items-stretch").setOptions(
            {
              showButtons: false,
              showBullets: false,
              showProgress: false,
              exitOnOverlayClick: false,
              exitOnEsc: false,
              skipLabel: "",

              steps: [
                {
                  element: document.querySelectorAll(
                    "#introjs-6"
                  )[0] as HTMLElement,

                  intro: `<div class="introjs-3" id="step-6">
            <div>Lihat Approval untuk anda!</div>
            <div>
              Menu ini berisi list approval dan  history approval Anda</div>
            <div class="grid-tutorial">
    <div  class="lewati">Lewati</div>
    <button class="next">Mengerti</button>
  </div>
      </div>`,
                },
              ],
            }
          );
          intro2.start();
          const funDocument = (name: string, status = "1", local?: string) => {
            return new Promise((resolve) => {
              switch (status) {
                case "1":
                  {
                    const doc = document.querySelectorAll(name);
                    doc[0]
                      ?.getElementsByClassName("next")[0]
                      ?.addEventListener("click", () => {
                        resolve("step-1");
                      });
                    doc[0]
                      ?.getElementsByClassName("lewati")[0]
                      ?.addEventListener("click", () => {
                        resolve("step-2");
                        if (local) {
                          localStorage.setItem("saveSettingTutorial", local);
                        }
                      });
                  }
                  break;
                case "2":
                  {
                    const time = setInterval(() => {
                      const doc = document.querySelectorAll(name);
                      if (doc.length > 0) {
                        doc[0]
                          ?.getElementsByClassName("next")[0]
                          ?.addEventListener("click", () => {
                            resolve("step-1");
                            if (local) {
                              localStorage.setItem(
                                "saveSettingTutorial",
                                local
                              );
                            }
                          });

                        doc[0]
                          ?.getElementsByClassName("lewati")[0]
                          ?.addEventListener("click", () => {
                            resolve("step-2");
                            if (local) {
                              localStorage.setItem(
                                "saveSettingTutorial",
                                local
                              );
                            }
                          });
                        clearInterval(time);
                      }
                    });
                  }
                  break;
              }
            });
          };
          funDocument(".introjs-3#step-6", "1").then((status) => {
            switch (status) {
              case "step-1":
                nextStepTutorial.value = 1;
                intro2.nextStep();
                localStorage.setItem(
                  "saveSettingTutorial",
                  JSON.stringify("/approval/list")
                );
                router.push("/dashboard/index");
                break;
              case "step-2":
                intro2.exit();
                localStorageFunSave("on-boarding-2");
                break;
            }
          });
        });
      }
    };
    const listStep4 = () => {
      if (fullPath === "/dashboard/index") {
        $instance?.proxy?.$nextTick(() => {
          const intro2 = IntroJs(".header-menu.align-items-stretch").setOptions(
            {
              showButtons: false,
              showBullets: false,
              showProgress: false,
              exitOnOverlayClick: false,
              exitOnEsc: false,
              skipLabel: "",

              steps: [
                {
                  element: document.querySelectorAll(
                    "#introjs-7"
                  )[0] as HTMLElement,
                  intro: `<div class="introjs-3" id="step-7">
            <div>Lihat Notifikasi untuk anda</div>
            <div>
              Menu ini berisi pemberitahuan penting mengenai proyek anda</div>
            <div class="grid-tutorial">
    <div  class="lewati">Lewati</div>
    <button class="next">Mengerti</button>
  </div>
      </div>`,
                },
              ],
            }
          );
          intro2.start();
          const funDocument = (name: string, status = "1", local?: string) => {
            return new Promise((resolve) => {
              switch (status) {
                case "1":
                  {
                    const doc = document.querySelectorAll(name);
                    doc[0]
                      ?.getElementsByClassName("next")[0]
                      ?.addEventListener("click", () => {
                        resolve("step-1");
                      });
                    doc[0]
                      ?.getElementsByClassName("lewati")[0]
                      ?.addEventListener("click", () => {
                        resolve("step-2");
                        if (local) {
                          localStorage.setItem("saveSettingTutorial", local);
                        }
                      });
                  }
                  break;
                case "2":
                  {
                    const time = setInterval(() => {
                      const doc = document.querySelectorAll(name);
                      if (doc.length > 0) {
                        doc[0]
                          ?.getElementsByClassName("next")[0]
                          ?.addEventListener("click", () => {
                            resolve("step-1");
                            if (local) {
                              localStorage.setItem(
                                "saveSettingTutorial",
                                local
                              );
                            }
                          });

                        doc[0]
                          ?.getElementsByClassName("lewati")[0]
                          ?.addEventListener("click", () => {
                            resolve("step-2");
                            if (local) {
                              localStorage.setItem(
                                "saveSettingTutorial",
                                local
                              );
                            }
                          });
                        clearInterval(time);
                      }
                    });
                  }
                  break;
              }
            });
          };
          funDocument(".introjs-3#step-7", "1").then((status) => {
            switch (status) {
              case "step-1":
                nextStepTutorial.value = 1;
                intro2.nextStep();
                localStorageFunSave("on-boarding-2");
                localStorage.removeItem("saveSettingTutorial");
                break;
              case "step-2":
                intro2.exit();
                localStorageFunSave("on-boarding-2");
                break;
            }
          });
        });
      }
    };
    const funcStep = () => {
      let localStroage = localStorageFun("saveSettingTutorial");

      const save = localStorageFun("introjs-tutorial");
      if (!save?.includes("on-boarding-2") && save?.includes("on-boarding")) {
        if (
          fullPath !== "/report/dokumen/direktori-dokumen" &&
          fullPath !== "/setting/organization/general-setting"
        ) {
          if (!save?.includes("on-boarding-2")) {
            switch (localStroage) {
              case "/dashboard/index": {
                listStep3();
                break;
              }
              case "/approval/list": {
                listStep4();
                break;
              }
              default: {
                listStep();
                break;
              }
            }
          }
        } else {
          listStep2();
        }
      }
    };
    watch(
      comFilterStep,
      (varnew) => {
        if (
          varnew?.name === "project-onBoarding/step-2" &&
          varnew?.bool === true
        ) {
          filterStep.name = varnew?.name;
          $instance?.proxy?.$nextTick(() => {
            funcStep();
          });
        }
      },
      { deep: true }
    );

    onMounted(() => {
      $instance?.proxy?.$nextTick(() => {
        funcStep();
      });
    });
    return {
      next,
      show,
    };
  },
};
