import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { LoaderStatus } from "@googlemaps/js-api-loader";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export enum GeoShapeEnum {
  POINT = "Point",
  POLYGON = "Polygon",
  POLYLINE = "LineString",
}

export interface IGeoJson {
  type: GeoShapeEnum;
  coordinate: Array<number> | Array<Array<number>>;
}

// NOTE FOR GEOLOC
// Data provided from API is following geojson convention, where
// geoloc = [ lng, lat ]
// However, google maps does not follow geojson convention.
// LatLng class use latitude first, example
// const latLngObject = new google.maps.LatLng({ lat: geoloc[1], lng: geoloc[0] })
export interface ICity {
  id: number;
  name: string;
  province_id: number;
  geoloc: number[];
}

@Module
export default class GoogleMapModule extends VuexModule {
  mapStatus: LoaderStatus = LoaderStatus.LOADING;

  get isMapLoaded() {
    return this.mapStatus === LoaderStatus.SUCCESS;
  }

  @Mutation
  [Mutations.SET_MAP_STATUS](status: LoaderStatus) {
    this.mapStatus = status;
  }

  @Action
  [Actions.UPDATE_MAP_STATUS](status: LoaderStatus) {
    this.context.commit(Mutations.SET_MAP_STATUS, status);
  }
}
