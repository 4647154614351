
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import L from "leaflet";
import "@elfalem/leaflet-curve";
import "leaflet-groupedlayercontrol"
import "@tweenjs/tween.js";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { checkPermissionUpdate } from '@/core/helpers/helps';
import "leaflet.markercluster";
import "leaflet-geosearch/dist/geosearch.css";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet-draw";
import "leaflet-fullscreen";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import images from '@/assets/ts/images.js';
import { useStore } from 'vuex';
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useRoute } from "vue-router";
import { useI18n } from 'vue-i18n';
import { Search } from '@element-plus/icons-vue'
import {
    getNameId
} from '@/core/helpers/helps';
import {
    defineComponent,
    onMounted,
    onBeforeUnmount,
    watch,
    ref,
    reactive,
    computed,
    CSSProperties
} from "vue";

export default defineComponent({
    name: 'LeafletMap',
    props: {
        mapName: {
            type: String,
            default: "mapPreview",
        },
        positionType: {
            type: String,
            default: "absolute",
        },
        width: {
            type: Number,
            default: 100,
        },
        widthType: {
            type: String,
            default: '%',
        },
        height: {
            type: Number,
            default: 85,
        },
        heightType: {
            type: String,
            default: 'px',
        },
        lat: {
            type: String,
            default: '-6.175360400261532',
        },
        lng: {
            type: String,
            default: '106.82713134232675',
        },
        zoom: {
            type: Number,
            default: 5,
        },
        viewOnly: {
            type: Boolean,
            default: true,
        },
        polygon: {
            type: Array,
            default: Array,
        },
        polyline: {
            type: Array,
            default: Array,
        },
        search: {
            type: Boolean,
            default: true,
        },
        isMarker: {
            type: Boolean,
            default: false,
        },
        setPin: {
            type: Boolean,
            default: true,
        },
        showPin: {
            type: Object,
            default: () => {
                return {
                    Pin: true,
                    Polyline: false,
                    Polygon: false
                }
            }
        },
        zoomPosition: {
            type: String,
            default: 'topleft',
        },
    },
    setup(props, { emit }) {
        const route = useRoute();
        const { t } = useI18n();
        let container = null as any;
        const center = ref([]) as any;
        const loading = ref(true);
        const pinPoint = ref("");
        const selectedPin = ref({ lat: "", lng: "", label: "" });
        const mapList = reactive([]) as any;
        const marker = ref("");
        const pinMarkerCoor = ref({});
        const polygonMarker = ref([]) as any;
        const polylineMarker = ref([]) as any;
        const pinMarker = ref({ lat: "", lng: "" });
        const pinPolygon = ref([]) as any;
        const pinPolyline = ref([]) as any;
        const isMapCenter = ref(false);
        const map_center_data = ref({});
        const store = useStore();
        const imaps = images.imaps
        const noimage = images.noimage
        const pointmapInput = images.pointmapInputColor;
        const polygonInput = images.polygon;
        const polylineInput = images.polylineNew;

        const loadingData = computed(() => {
            return store.getters.LoadingLeaftlet
        })
        const mapStyle = computed(() => ({
            width: `${props.width}${props.widthType}`,
            height: `${props.height}${props.heightType}`,
            position: props.positionType || 'relative',
            // Add other styles if needed
        } as CSSProperties));

        watch(() => props.showPin, () => {
            if (container) {
                container.remove();
                initMap();
            }
        });

        const mapOption = ref({
            fullscreenControl: false,
            fullscreenControlOptions: {
                position: props.zoomPosition,
            },
            zoomControl: false
        });

        const map_list = computed(() => {
            return store.getters.getListMap;
        });

        const getTileLayers = computed(() => {
            return store.getters.getTileLayers;
        });

        watch(map_list, () => {
            if (container) {
                container.remove();
                initMap();
            }
        });

        const map_center = computed(() => {
            return store.getters.getCenterMap;
        });

        watch(map_center, () => {
            map_center_data.value = map_center.value
            center.value = [map_center.value.coordinate.lat, map_center.value.coordinate.long]
            isMapCenter.value = true;
            if (container) {
                container.remove();
                initMap();
            }
        });

        const imagePin = (element) => {
            let icon = '';
            let color = '';
            if (element.hasOwnProperty.call(element, 'jenis_asset')) {
                if (element.jenis_asset.toLowerCase() == 'tanah') {
                    if (element.status_legalitas == '') {
                        icon = images.mapPinTransparant;
                        color = "#C1B4B1"
                    } else if (element.status_legalitas_code == "CLN-CLR") {
                        icon = images.tanahPinGreen;
                        color = "#11964E"
                    } else if (element.status_legalitas_code == "UNC-CLR") {
                        icon = images.tanahPinYellow;
                        color = "#F2BD00"
                    } else if (element.status_legalitas_code == "CLN-UNR") {
                        icon = images.tanahPinBlue;
                        color = "#0072E7"
                    } else {
                        icon = images.tanahPinRed;
                        color = "#D01932"
                    }
                } else {
                    if (element.status_legalitas == '') {
                        icon = images.mapPinTransparant;
                        color = "#C1B4B1"
                    } else if (element.status_legalitas_code == "CLN-CLR") {
                        icon = images.mapPinGreen;
                        color = "#11964E"
                    } else if (element.status_legalitas_code == "UNC-CLR") {
                        icon = images.mapPinYellow;
                        color = "#F2BD00"
                    } else if (element.status_legalitas_code == "CLN-UNR") {
                        icon = images.mapPinBlue;
                        color = "#0072E7"
                    } else {
                        icon = images.mapPinRed;
                        color = "#D01932"
                    }
                }
            } else {
                icon = require("leaflet/dist/images/marker-icon-2x.png");
                color = "#0072E7"
            }

            let data = {
                'color': color,
                'icon': icon,
            }
            return data;
        }

        var basemaps = {
            Osm: L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 18,
                minZoom: 4,
                attribution: '&copy; <a href="http://openstreetmap.org">OpenStreetMap</a>',
                fullscreenControl: false,
                fullscreenControlOptions: {
                    position: props.zoomPosition
                },
                zoomControl: false,
                groupCheckboxes: true
            }),
            Satelite: L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
                maxZoom: 18,
                minZoom: 4,
                attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN the GIS User Community',
                fullscreenControl: false,
                fullscreenControlOptions: {
                    position: props.zoomPosition
                },
                zoomControl: false,
                groupCheckboxes: true
            })
        };

        const initMap = async () => {
            loading.value = true

            container = L.map(props.mapName, {
                layers: [getTileLayers.value === 'osmMap' ? basemaps.Osm : basemaps.Satelite]
            }).setView(center.value, isMapCenter.value ? 15 : props.zoom);

            L.control.groupedLayers(basemaps, null, { position: 'bottomright' }).addTo(container);


            container.on('baselayerchange', function (e) {
                var layerName = e.layer.options.attribution === '&copy; <a href="http://openstreetmap.org">OpenStreetMap</a>' ? 'osmMap' : 'sateliteMap'
                store.commit(Mutations.SET_TILE_LAYER, layerName)
            });

            let payload = '' as any
            if (!props.viewOnly) {
                if (isMapCenter.value) {
                    /**
                     * Untuk Preview Map Detail Dashboard
                     */
                    payload = map_center_data.value

                    let icon = imagePin(payload).icon;

                    let century21icon = L.icon({
                        iconUrl: icon,
                        iconSize: [40, 30],
                    });

                    var markerZoom = L.marker(center.value, {
                        icon: century21icon,
                        title: payload._id
                    }).addTo(container)

                    // markerZoom.on('click', (e) => {
                    store.dispatch(Actions.GET_DETAIL_ASSETS, payload._id).then((res) => {
                        if (res) {
                            markerZoom
                                .bindPopup(content(res.data, 'master'))
                                .on("popupclose", (e) => {
                                    content({}, 'clear')
                                }).openPopup()
                        }
                    })
                    // });

                } else {
                    /**
                     * Untuk Preview Map Dashboard
                     */
                    // var markers = new L.MarkerClusterGroup();
                    const markers = new L.MarkerClusterGroup({
                        iconCreateFunction: function (cluster) {
                            return L.divIcon({
                                html: cluster.getChildCount(),
                                className: "mycluster",
                                iconSize: null,
                            });
                        },
                    });
                    
                    const temp = map_list.value?.data
                    if (temp !== undefined) {
                        temp.forEach((element, i) => {
                            const icon = imagePin(element).icon;
                            const color = imagePin(element).color;

                            const century21icon = L.icon({
                                iconUrl: icon,
                                iconSize: [40, 30],
                            });

                            if (/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/.test(element.coordinate.lat) && /^[-+]?((1[0-7]\d(\.\d+)?)|([1-9]?\d(\.\d+)?)|180(\.0+)?)$/.test(element.coordinate.long)) {
                                markers.addLayer(
                                    L.marker([parseFloat(element.coordinate.lat), parseFloat(element.coordinate.long)], {
                                        icon: century21icon,
                                        title: element._id
                                    })
                                );
                            }

                            /**
                             * Polygon & Polyline
                             */
                            if (element?.polygon && element.polygon.length > 0) {
                                const regexPattern = /^-?\d+(\.\d+)?$/;
                                let polygonLang = [] as any;

                                element.polygon.forEach((polygonData: any) => {
                                    if (Array.isArray(polygonData)) {
                                        const validCoordinates = polygonData.filter((coord) => {
                                            return regexPattern.test(coord["lat"]) && regexPattern.test(coord["long"]);
                                        });

                                        if (validCoordinates.length > 0) {
                                            const marker = L.polygon(validCoordinates.map(coord => [coord["lat"], coord["long"]]), {
                                                color: color
                                            }).addTo(container);

                                            marker.on('click', function () {
                                                store.dispatch(Actions.GET_DETAIL_ASSETS, element._id).then((res) => {
                                                    const popupContent = content(res.data, 'master');
                                                    marker.bindPopup(popupContent).on("popupclose", () => {
                                                        console.log('Clear Popup');
                                                        content({}, 'clear');
                                                    }).openPopup();
                                                });
                                            });
                                        }
                                    } else if (polygonData.long != null) {
                                        polygonLang.push([polygonData.lat, polygonData.long]);
                                    }
                                });
                                if (polygonLang.length > 0) {
                                    var popUpMarker = [] as any;
                                    var marker1 = L.polygon(polygonLang, { color: color }).addTo(container);
                                    popUpMarker.push(marker1);

                                    marker1.on('click', function (ev) {
                                        store.dispatch(Actions.GET_DETAIL_ASSETS, element._id).then((res) => {
                                            marker1
                                                .bindPopup(content(res.data, 'master'))
                                                .on("popupclose", (e) => {
                                                    console.log('Clear Popup');
                                                    content({}, 'clear')
                                                }).openPopup()
                                        })
                                    });
                                    marker1.on('mouseout', function (ev) {
                                        marker1.closePopup();
                                    });
                                }
                            }

                            if (element?.polyline && element.polyline.length > 0) {
                                let polylineLang = [] as any;
                                element.polyline.forEach((x: any) => {
                                    var tempolylinePin = [] as any;
                                    if (Array.isArray(x)) {
                                        x.forEach((y) => {
                                            if (y["long"] != null && y["lat"] != null) {
                                                tempolylinePin.push([y["lat"], y["long"]]);
                                            }
                                        });

                                        if (tempolylinePin.length > 0) {
                                            var marker2 = L.polyline(tempolylinePin, {
                                                color: color
                                            }).addTo(container)

                                            marker2.on('mouseover', function (ev) {
                                                store.dispatch(Actions.GET_DETAIL_ASSETS, element._id).then((res) => {
                                                    marker2
                                                        .bindPopup(content(res.data, 'master'))
                                                        .on("popupclose", (e) => {
                                                            console.log('Clear Popup');
                                                            content({}, 'clear')
                                                        })
                                                })
                                            });
                                        }
                                    } else {
                                        if (x.long != null) {
                                            polylineLang.push([x.lat, x.long]);
                                        }
                                    }
                                });
                                if (polylineLang.length > 0) {
                                    var marker2 = L.polyline(polylineLang, { color: color }).addTo(container)

                                    marker2.on('mouseover', function (ev) {
                                        store.dispatch(Actions.GET_DETAIL_ASSETS, element._id).then((res) => {
                                            marker2
                                                .bindPopup(content(res.data, 'master'))
                                                .on("popupclose", (e) => {
                                                    console.log('Clear Popup');
                                                    content({}, 'clear')
                                                })
                                        })
                                    });
                                }
                            }
                        })
                    }

                    markers.on('click', function (e) {
                        const attributes = e.layer.options;
                        store.dispatch(Actions.GET_DETAIL_ASSETS, attributes.title).then((res) => {
                            if (res) {
                                markers
                                    .bindPopup(content(res.data, 'master'))
                                    .on("popupclose", (e) => {
                                        console.log('Clear Popup');
                                        content({}, 'clear')
                                    }).openPopup()
                            }
                        })
                    });

                    container.addLayer(markers);
                }
            } else {
                /**
                 * Preview Only
                 */

                if (
                    props.lat != null &&
                    props.hasOwnProperty.call(props, 'lng') &&
                    props.setPin
                ) {
                    L.Icon.Default.mergeOptions({
                        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
                        iconUrl: require('leaflet/dist/images/marker-icon.png'),
                        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
                    });

                    L.marker(center.value)
                        .addTo(container)
                }

                payload = {
                    polygon: props.polygon,
                    polyline: props.polyline,
                }
            }

            if (payload.hasOwnProperty.call(payload, 'polygon') && payload.polygon.length > 0) {
                let color = imagePin(payload).color;
                let polygonLang = [] as any;
                payload.polygon.forEach((x: any) => {
                    var tempolygonPin = [] as any;
                    if (Array.isArray(x)) {
                        x.forEach((y) => {
                            if (y["long"] != null) {
                                tempolygonPin.push([y["lat"], y["long"]]);
                            }
                        });
                        if (tempolygonPin.length > 0) {
                            L.polygon(tempolygonPin, { color: color }).addTo(container);
                        }
                    } else {
                        if (x.long != null) {
                            polygonLang.push([x.lat, x.long]);
                        }
                    }
                });
                if (polygonLang.length > 0) {
                    L.polygon(polygonLang, { color: color }).addTo(container);
                }
            }

            if (payload.hasOwnProperty.call(payload, 'polyline') && payload.polyline.length > 0) {
                let color = imagePin(payload).color;
                let polylineLang = [] as any;
                payload.polyline.forEach((x: any) => {
                    var tempolylinePin = [] as any;
                    if (Array.isArray(x)) {
                        x.forEach((y) => {
                            if (y["long"] != null) {
                                tempolylinePin.push([y["lat"], y["long"]]);
                            }
                        });
                        if (tempolylinePin.length > 0) {
                            L.polyline(tempolylinePin, { color: color }).addTo(container);
                        }
                    } else {
                        if (x.long != null) {
                            polylineLang.push([x.lat, x.long]);
                        }
                    }
                });
                if (polylineLang.length > 0) {
                    L.polyline(polylineLang, { color: color }).addTo(container);
                }
            }

            setZoomControl();
            container.on('zoomend', (e) => {
                if (isMapCenter.value && container.getZoom() === 7) {
                    isMapCenter.value = false;
                    container.remove();
                    initMap();
                }
            })
            container.invalidateSize();
            loading.value = false
        };

        const content = (payload, type) => {
            var content = '';
            if (payload?.asset_attachment && payload.asset_attachment.length && /\.(jpg|jpeg|png|webp|avif|gif)$/i.test(payload.asset_attachment[0].filename)) {
                content +=
                    "<img src='" +
                    payload.asset_attachment[0].url +
                    "'width='100%' height='200px'/>";
            } else {
                content +=
                    "<img src='" +
                    noimage +
                    "'width='100%' height='200px' style='object-fit: cover'/>";
            }
            if (type !== 'clear') {
                content +=
                    "<p><h2 class='d-flex justify-content-start px-2' style='text-align: left;'>" +
                    payload.name +
                    '</h2></p>';
                content += "<div class='row px-2' style='font-size: x-small;'>";
                content +=
                    "<div class='col-md-8 d-flex justify-content-start' style='text-align: left;'>";
                content += payload.channel.name;
                content += '</div>';

                content += "<div class='col-md-4 d-flex justify-content-end'>";
                content +=
                    "<div class='badge badge-pill badge-primary px-2' style='background:#F4D6E3;color:#D3438D'>" +
                    payload.type.name +
                    '</div>';
                content += '</div>';

                content +=
                    "<div class='col-md-8 d-flex justify-content-start mt-2' style='text-align: left; align-items: center;'>";
                content += `Luas: ` + formatPrice(payload.asset_ownership.luas_total.value) + '  ' + `<span class="text-capitalize" style="margin-left: 2px">${payload.asset_ownership.luas_total.dimension_uom.name}</span>`;
                content += '</div>';

                content +=
                    "<div class='col-md-8 d-flex justify-content-start mt-2' style='text-align: left;'>";
                content += 'Status Aset: ' + payload.acquisition_status.name;
                content += '</div>';


                content += '</div>';
                content += "<div class='row px-2 py-2'>";
                content += "<div class='col-md-6 d-flex'>";
                if (payload.asset_administration.status_legalitas_category_value == "") {
                    content +=
                        "<div class='badge badge-pill badge-primary' style='background:#dee5eb;color:#ffffff'> - </div>";
                } else if (payload.asset_administration.status_legalitas.code == "CLN-CLR") {
                    content +=
                        "<div class='badge badge-pill badge-primary' style='background:#AEF9B1;color:#45C96B; margin-right: 4px'>" +
                        payload.asset_administration.status_legalitas.name +
                        "</div>";
                } else if (payload.asset_administration.status_legalitas.code == "UNC-CLR") {
                    content +=
                        "<div class='badge badge-pill badge-primary' style='background:#f2bd00;color:#b78600; margin-right: 4px'>" +
                        payload.asset_administration.status_legalitas.name +
                        "</div>";
                } else if (payload.asset_administration.status_legalitas.code == "CLN-UNR") {
                    content +=
                        "<div class='badge badge-pill badge-primary' style='background:#0072e773;color:#0072e7; margin-right: 4px'>" +
                        payload.asset_administration.status_legalitas.name +
                        "</div>";
                } else {
                    content +=
                        "<div class='badge badge-pill badge-primary' style='background:#d019328c;color:#d01932; margin-right: 4px'>" +
                        payload.asset_administration.status_legalitas.name +
                        "</div>";
                }

                content +=
                    "<div class='badge badge-pill badge-primary' style='background:#FFB1B1;color:#D01932;margin-left: 2px;'>";
                content += payload.asset_administration.status_produktivitas.name;
                content += '</div>';
                content += '</div>';
                content += '</div>';

                content +=
                    "<div class='col-md-8 d-flex mx-2 mt-2' style='font-size: x-small; width: 96%; justify-content: space-between; align-items: center;'>";
                content += "<div style='display: flex;align-items: center;'>"
                content += `<img src='${imaps}' />`
                content += '<span>';
                content += 'Lat/Long: '
                content += '</span>';
                content += '</div>';
                content += '<div>';
                content += payload.asset_location.geolocation.coordinate.lat + " , " + payload.asset_location.geolocation.coordinate.long;
                content += '</div>';

                content += '</div>';

                if (type == 'detail') {
                    content += "<div class='row my-2 px-2'>";
                    content += "<div class='col-md-6 d-flex justify-content-start'>";
                    content += "Lat/Long";
                    content += "</div>";
                    content +=
                        "<div class='col-md-6 d-flex justify-content-end' style='text-align: end; letter-spacing: -0.003em;'>";
                    content += payload.point.lat + ", " + payload.point.long;
                    content += "</div>";
                }

                content += '</div>';
                content += '<hr>';
                if (checkPermissionUpdate('dashmap', 'dashmap.view')) {
                    content +=
                        "<div class='py-2'><a href='/assets/views/" +
                        payload._id +
                        "/informasi-assets#FSD' style='color: #B31E72; text-decoration: underline;'>Lihat Detail</a></div>";
                }
            }

            return content;
        };

        watch(pinPoint, async () => {
            selectedPin.value = { lat: "", lng: "", label: "" };
            if (pinPoint.value == "" || mapList.length) {
                mapList.splice(0);
            } else {
                const provider = new OpenStreetMapProvider({
                    params: {
                        "accept-language": "id",
                        countrycodes: "id", // limit search results to the Indonesia
                        addressdetails: 0, // include additional address detail parts
                    },
                });
                let results = await provider.search({ query: pinPoint.value });
                results.forEach((element) => {
                    mapList.push(element);
                });
            }
        });

        const searchOpenStreet = async () => {
            clearMarker()

            selectedPin.value = { lat: "", lng: "", label: "" };

            if (pinPoint.value == "" || mapList.length) {
                mapList.splice(0);
            }

            const provider = new OpenStreetMapProvider({
                params: {
                    "accept-language": "id",
                    countrycodes: "id", // limit search results to the Indonesia
                    addressdetails: 0, // include additional address detail parts
                },
            });
            let results = await provider.search({ query: pinPoint.value });
            results.forEach((element) => {
                mapList.push(element);
            });
        };

        const getCenterMap = () => {
            let latlng = container.getCenter();

            center.value = [
                latlng.lat,
                latlng.lng,
            ];
        }
        const refreshMap = () => {
            if (container) {
                container.remove();
                if (props.isMarker) {
                    setMapCenter();
                } else {
                    initMap();
                }
            }
        };

        const selectPoint = (payload) => {
            selectedPin.value = {
                lat: payload.y,
                lng: payload.x,
                label: payload.label,
            };

            center.value = [payload.y, payload.x]

            if (container) {
                container.remove();
                setMapCenter();
            }
        };

        /**
         * Map Marker
         */
        const setMapCenter = () => {
            loading.value = true
            // container = L.map(props.mapName, mapOption.value).setView(
            //     center.value,
            //     isMapCenter.value ? 15 : props.zoom
            // );

            // L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            //     maxZoom: 18,
            // }).addTo(container);

            container = L.map(props.mapName, {
                layers: [getTileLayers.value === 'osmMap' ? basemaps.Osm : basemaps.Satelite]
            }).setView(center.value, isMapCenter.value ? 15 : props.zoom);

            L.control.groupedLayers(basemaps, null, { position: 'bottomright' }).addTo(container);

            container.on('baselayerchange', function (e) {
                var layerName = e.name;
                store.commit(Mutations.SET_TILE_LAYER, layerName)
            });

            var drawnItems = new L.FeatureGroup();
            container.addLayer(drawnItems);

            var drawControl = new L.Control.Draw({
                position: "topright",
                draw: {
                    circlemarker: false,
                    rectangle: false,
                    circle: false,
                },
                // edit: {
                //   featureGroup: drawnItems,
                // },
                edit: false,
            });

            container.addControl(drawControl);
            container.on("draw:drawstart", function (e) {
                var type = e.layerType;
                if (type === "marker") {
                    if (pinMarker.value.lat != "") {
                        // drawnItems.clearLayers(); -> clear all marker
                        container.removeLayer(pinMarkerCoor.value);
                    }
                }
            });

            container.on(L.Draw.Event.CREATED, function (e) {
                var type = e.layerType,
                    layer = e.layer;
                drawnItems.addLayer(layer);

                if (type === "marker") {
                    pinMarkerCoor.value = layer;

                    pinMarker.value = layer.getLatLng();
                } else if (type === "polygon") {
                    polygonMarker.value.push(layer);

                    var polygonParam = [];

                    layer.getLatLngs().forEach((element) => {
                        polygonParam = element.map(function (point) {
                            return [point.lat, point.lng];
                        });
                    });

                    pinPolygon.value.push(polygonParam);
                    const area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
                    const areaInSquareMeters = L.GeometryUtil.formattedNumber(area, 2)
                    emit('wideArea', areaInSquareMeters);
                    // pinPolygon.value.push(layer.getLatLngs());
                } else {
                    polylineMarker.value.push(layer);

                    var polylineParam = [];
                    polylineParam = layer.getLatLngs().map(function (point) {
                        return [point.lat, point.lng];
                    });

                    pinPolyline.value.push(polylineParam);

                    var distance = 0;
                    var latlngs = layer.getLatLngs();

                    for (var i = 0; i < latlngs.length - 1; i++) {
                        var latlng1 = latlngs[i];
                        var latlng2 = latlngs[i + 1];
                        var segmentDistance = latlng1.distanceTo(latlng2); // Menghitung jarak antara dua titik
                        distance += segmentDistance;
                    }

                    emit('lengtPolyline', distance);
                    // pinPolyline.value.push(layer.getLatLngs());
                }
            });

            loading.value = false
            setZoomControl();
            setTimeout(function () {
                container.invalidateSize();
            }, 100);
        };

        const tambahMark = () => {
            const targetElement = document.querySelector('.leaflet-draw-draw-marker') as HTMLElement;
            if (targetElement) {
                targetElement.click()
            }
        }

        const tambahPolygon = () => {
            const targetElement = document.querySelector('.leaflet-draw-draw-polygon') as HTMLElement;
            if (targetElement) {
                targetElement.click()
            }
        }

        const tambahPolyline = () => {
            const targetElement = document.querySelector('.leaflet-draw-draw-polyline') as HTMLElement;
            if (targetElement) {
                targetElement.click()
            }
        }

        const reset = () => {
            emit('reset', true);
        }

        const setLatLong = (address) => {
            store.dispatch(Actions.GET_GEOCODE, address)
                .then((data) => {
                    clearMarker()

                    if (data.results[0]) {
                        const latitude = data.results[0].geometry.location.lat;
                        const longitude = data.results[0].geometry.location.lng;

                        center.value = [
                            latitude,
                            longitude
                        ];

                        let payload = {
                            x: longitude,
                            y: latitude,
                            label: address,
                        };
                        selectPoint(payload);

                        if (container) {
                            container.remove();
                        }
                        initMap();
                    }
                });
        };

        const removeMarker = (index, type) => {
            if (type == "polygon") {
                container.removeLayer(polygonMarker.value[index]);
                polygonMarker.value.splice(index, 1);
                pinPolygon.value.splice(index, 1);
            }

            if (type == "polyline") {
                container.removeLayer(polylineMarker.value[index]);
                polylineMarker.value.splice(index, 1);
                pinPolyline.value.splice(index, 1);
            }

            if (type == "marker") {
                container.removeLayer(pinMarkerCoor.value);
                pinMarkerCoor.value = {}
                pinMarker.value = { lat: "", lng: "" }
            }
        };

        const addMarker = (type, payload) => {
            if (type == 'marker') {
                center.value = [payload[0], payload[1]];
                refreshMap();
                if (payload[0] != '') {
                    container.removeLayer(pinMarkerCoor.value);
                    let marker = L.marker(payload).addTo(container)
                    pinMarkerCoor.value = marker
                }
            } else if (type == 'polygon') {
                container.removeLayer(polygonMarker.value);
                let params = '' as any
                if (payload.value == null) {
                    params = payload
                } else {
                    params = payload.value
                }
                let polygonLang = [] as any;
                params.forEach((x: any) => {
                    var tempolygonPin = [] as any;
                    if (Array.isArray(x)) {
                        x.forEach((y) => {
                            tempolygonPin.push([y[0], y[1]]);
                        });
                        let polygon = L.polygon(tempolygonPin).addTo(container);
                        polygonMarker.value.push(polygon)
                    } else {
                        if (x.long != null) {
                            polygonLang.push([x.lat, x.long]);
                        }
                    }
                });
                if (polygonLang.length > 0) {
                    let polygon = L.polygon(polygonLang).addTo(container);
                    polygonMarker.value.push(polygon)
                }
            } else {
                container.removeLayer(polylineMarker.value);
                let params = '' as any
                if (payload.value == null) {
                    params = payload
                } else {
                    params = payload.value
                }
                let polylineLang = [] as any;
                params.forEach((x: any) => {
                    var tempolylinePin = [] as any;
                    if (Array.isArray(x)) {
                        x.forEach((y) => {
                            tempolylinePin.push([y[0], y[1]]);
                        });
                        let polyline = L.polyline(tempolylinePin).addTo(container);
                        polylineMarker.value.push(polyline)
                    } else {
                        if (x.long != null) {
                            polylineLang.push([x.lat, x.long]);
                        }
                    }
                });
                if (polylineLang.length > 0) {
                    let polyline = L.polyline(polylineLang).addTo(container);
                    polylineMarker.value.push(polyline)
                }
            }
        };

        const clearMarker = () => {
            container.removeLayer(pinMarkerCoor.value);
            container.removeLayer(polygonMarker.value);
            container.removeLayer(polylineMarker.value);
            pinMarkerCoor.value = {}
            polygonMarker.value = []
            polylineMarker.value = []
            pinMarker.value = { lat: "", lng: "" }
            pinPolygon.value = []
            pinPolyline.value = []
        }

        const setCenterMap = (params, zoomLevel = props.zoom) => {
            center.value = [params.lat, params.lng]
            isMapCenter.value = false;
            if (container) {
                container.remove();
                // initMap();

                // container = L.map(props.mapName, mapOption.value).setView(
                //     center.value,
                //     zoomLevel
                // );

                // L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                //     maxZoom: 18,
                // }).addTo(container);

                container = L.map(props.mapName, {
                    layers: [getTileLayers.value === 'osmMap' ? basemaps.Osm : basemaps.Satelite]
                }).setView(center.value, isMapCenter.value ? 15 : props.zoom);

                L.control.groupedLayers(basemaps, null, { position: 'bottomright' }).addTo(container);

                container.on('baselayerchange', function (e) {
                    var layerName = e.name;
                    store.commit(Mutations.SET_TILE_LAYER, layerName)
                });

                var markers = new L.MarkerClusterGroup({
                    iconCreateFunction: function (cluster) {
                        return L.divIcon({
                            html: cluster.getChildCount(),
                            className: "mycluster",
                            iconSize: null,
                        });
                    },
                });
                map_list.value.forEach((element) => {
                    let icon = imagePin(element).icon;
                    let color = imagePin(element).color;

                    var century21icon = L.icon({
                        iconUrl: icon,
                        iconSize: [40, 30],
                    });

                    if (element.point != null && props.showPin.Pin) {
                        if (
                            element.point.lat != null &&
                            element.point.hasOwnProperty.call(element.point, 'long')
                        ) {
                            markers.addLayer(
                                L.marker([element.point.lat, element.point.long], {
                                    icon: century21icon,
                                })
                                    .bindPopup(content(element, 'master'))
                            );
                        }
                    }

                    /**
                     * Polygon & Polyline
                     */
                    if (element.hasOwnProperty.call(element, 'polygon') && element.polygon.length > 0) {
                        let polygonLang = [] as any;
                        element.polygon.forEach((x: any) => {
                            var tempolygonPin = [] as any;
                            if (Array.isArray(x)) {
                                x.forEach((y) => {
                                    if (y["long"] != null) {
                                        tempolygonPin.push([y["lat"], y["long"]]);
                                    }
                                });
                                if (tempolygonPin.length > 0) {
                                    // L.polygon(tempolygonPin).addTo(container).bindPopup(content(element, 'master'));

                                    var popUpMarker = [] as any;
                                    var marker1 = L.polygon(tempolygonPin, { color: color }).addTo(container).bindPopup(content(element, 'master'));
                                    popUpMarker.push(marker1);
                                    marker1.on('mouseover', function (ev) {
                                        marker1.openPopup();
                                    });
                                    marker1.on('mouseout', function (ev) {
                                        marker1.closePopup();
                                    });

                                }
                            } else {
                                if (x.long != null) {
                                    polygonLang.push([x.lat, x.long]);
                                }
                            }
                        });
                        if (polygonLang.length > 0) {
                            // L.polygon(polygonLang).addTo(container).bindPopup(content(element, 'master'));
                            var popUpMarker = [] as any;
                            var marker1 = L.polygon(polygonLang, { color: color }).addTo(container).bindPopup(content(element, 'master'));
                            popUpMarker.push(marker1);
                            marker1.on('mouseover', function (ev) {
                                marker1.openPopup();
                            });
                            marker1.on('mouseout', function (ev) {
                                marker1.closePopup();
                            });
                        }
                    }

                    if (element.hasOwnProperty.call(element, 'polyline') && element.polyline.length > 0) {
                        let polylineLang = [] as any;
                        element.polyline.forEach((x: any) => {
                            var tempolylinePin = [] as any;
                            if (Array.isArray(x)) {
                                x.forEach((y) => {
                                    if (y["long"] != null) {
                                        tempolylinePin.push([y["lat"], y["long"]]);
                                    }
                                });
                                if (tempolylinePin.length > 0) {
                                    L.polyline(tempolylinePin, { color: color }).addTo(container).bindPopup(content(element, 'master'));
                                }
                            } else {
                                if (x.long != null) {
                                    polylineLang.push([x.lat, x.long]);
                                }
                            }
                        });
                        if (polylineLang.length > 0) {
                            L.polyline(polylineLang, { color: color }).addTo(container).bindPopup(content(element, 'master'));
                        }
                    }
                });
                container.addLayer(markers);
                setZoomControl();
                container.setView(center.value, zoomLevel);
            }
        }

        const setZoomControl = () => {
            L.control.zoom({
                position: props.zoomPosition
            }).addTo(container);

            L.control.fullscreen({
                // title: 'Show me the fullscreen !', // change the title of the button, default Full Screen
                // titleCancel: 'Exit fullscreen mode', // change the title of the button when fullscreen is on, default Exit Full Screen
                position: props.zoomPosition, // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
                content: null, // change the content of the button, can be HTML, default null
                forceSeparateButton: true, // force separate button to detach from zoom buttons, default false
                forcePseudoFullscreen: true, // force use of pseudo full screen even if full screen API is available, default false
                fullscreenElement: false // Dom element to render in full screen, false by default, fallback to map._container
            }).addTo(container);
        }

        onMounted(() => {
            center.value = [props.lat, props.lng];
            if (props.isMarker) {
                setMapCenter();
            } else {
                initMap();
            }

            L.Icon.Default.mergeOptions({
                iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
                iconUrl: require("leaflet/dist/images/marker-icon.png"),
                shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
            });
        });

        const formatPrice = (value) => {
            let val = (value / 1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        };

        onBeforeUnmount(() => {
            isMapCenter.value = false;

            if (container) {
                container.remove();
            }
        });

        return {
            t,
            center,
            loading,
            pinPoint,
            selectedPin,
            mapList,
            marker,
            pinMarker,
            polygonMarker,
            polylineMarker,
            pinPolygon,
            pinPolyline,
            map_center_data,
            searchOpenStreet,
            selectPoint,
            setLatLong,
            getCenterMap,
            refreshMap,
            removeMarker,
            addMarker,
            clearMarker,
            setCenterMap,
            formatPrice,
            route,
            Search,
            tambahMark,
            tambahPolygon,
            tambahPolyline,
            reset,
            pointmapInput,
            polygonInput,
            polylineInput,
            loadingData,
            mapStyle
        }
    },
});
