import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

const service =
  process.env.VUE_APP_NODE_ENV == "local"
    ? "schedule/api/v1"
    : "schedule/api/v1";

@Module
export default class ScheduleModule extends VuexModule {
  @Action
  [Actions.SCHEDULE](p_id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`${service}`, p_id)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.SCURVES](charter_id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/scurvesv2`, charter_id)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PARTNER_SCHEDULE](charter_id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/partner`, {
        params: { charter_id: charter_id },
      })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.SAVE_SCHEDULE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/store/${payload.p_id}`, payload.data)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.ADD_DELIVERABLES](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/deliverable`, payload.form)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_DELIVERABLE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/deliverable/${payload.id}`, payload.form)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.DELIVERABLES_LIST](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/deliverable`, { params: query })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_DELIVERABLE_BY_CHARTER_ID](slug) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`${service}/deliverable`, slug)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject(err);
        });
    });
  }

  @Action
  [Actions.SEARCH_DELIVERABLES](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/search_deliv`, { params: query })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_HISTORY_TASK_WEIGHT_PROGRESS](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/taskprogressweight`, { params: query })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.EDIT_UPDATE_TASK_WEIGHT_PROGRESS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`${service}/taskprogressweight`, payload.id, payload.data)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_NOTIFY](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`${service}`, payload.id, payload.data)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.DELIVERABLES_HISTORIES](id_task) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`${service}/deliverable/history`, id_task)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.TASK_DELETE](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`${service}/deliverable/${id}`)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_PROGRESS_DELIVERABLE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/deliverable/update/progress/${payload.id}`, {
        progress: payload.progress.toString(),
      } as any)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_TASK_WEIGHT_PROGRESS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/taskprogressweight`, payload)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_HISTORY_TASK_WEIGHT_PROGRESS](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`${service}/taskprogressweight/${id}`)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }
}
