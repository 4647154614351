/* eslint-disable no-unused-vars */
import ApiService from "@/core/services/ApiService";
import { setAllertSuccess, setAllertError } from "@/core/services/Alert";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import * as storeTutorial from "@/store/enums/StoreTutorialEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import { currency, detectSubDomain } from "@/core/helpers/helps";
import * as Yup from "yup";
// import { useRoute } from "vue-router";
import moment from "moment";
// import router from "@/router";

// const route = useRoute();

// to import everything from yup

const delivSchema = Yup.object().shape({
  name: Yup.string().required(),
  deskripsi: Yup.string()
    .max(255, "Must be exactly 255 character")
    .required()
    .nullable(),
  nilai: Yup.string().max(16, "Can`t grater than 16 character").required(),
  kategori: Yup.string().required().nullable(),
  typeGroup: Yup.string().required().nullable(),
});

const dateSchema = Yup.object().shape({
  startDate: Yup.string().required().nullable(),
  endDate: Yup.string().required().nullable(),
  monitorDate: Yup.string().required().nullable(),
  kickOffDate: Yup.string().required().nullable(),
  address: Yup.array()
    .of(
      Yup.object()
        .shape({
          provinsi: Yup.string().required().nullable(),
          kota: Yup.string().required().nullable(),
          kecamatan: Yup.string().required().nullable(),
          kodepos: Yup.string()
            .required()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(5, "Must be exactly 5 digits")
            .max(5, "Must be exactly 5 digits")
            .nullable(),
          geo_json: Yup.array().required(),
        })
        .required()
    )
    .required(),
});

export interface StepperState {
  stepper: any;
  charterAssignStepper: boolean;
  charterDelivStepper: boolean;
  charterDateStepper: boolean;
  charterAddStepper: boolean;
  charterTerm: boolean;
  charterPartnerStepper: boolean;
  charterCostStepper: boolean;
  addForm: number;
  addColumn: number;
  completeStep1: boolean;
  listFIleDokumen: Array<any>;
}

// interface
export interface Add {
  name: string;
  deskripsi: string;
  nilai: string;
  kategori: string;
  typeGroup: string;
  tag: Array<any>;
  isStrategis: boolean;
  lop: Array<any>;
  quoute: Array<any>;
  sid: Array<any>;
  geo_json: Array<any>;
  is_private: boolean;
}

// interface
export interface ICharterDate {
  startDate: string;
  endDate: string;
  monitorDate: string;
  kickOffDate: string;
  dayWork: boolean;
  direktorat: Array<string>;
  devisi: Array<string>;
  segment: Array<string>;
  regional: Array<string>;
  hariKerja: Array<string>;
  address: CharterAddress[];
}

export interface CharterAddress {
  index?: number;
  charter_code?: string | number;
  provinsi: string;
  kabupaten: string;
  kota: string;
  kecamatan: string;
  alamat: string;
  kodepos: string;
  geo_json: Array<any>;
  province?: any;
  city?: any;
  district?: any;
  province_id?: string | number;
  city_id?: string | number;
  district_id?: string | number;
  address?: string;
  postal_code?: string;
}

export interface CharterDelive {
  namaDelive: string;
  // namaPartner: string;
  tanggalPeriod: string;
  bobot: string;
  // deliverables: string;
}

export interface CharterPartner {
  namaPartner: string;
  nomorSpk: string;
  nilaiProyek: string;
  jenisPembayaran: string;
  kategori: string;
}

export interface CharterCategory {
  id: string;
  code: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface Direktorat {
  id: string;
  code: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface Divisi {
  id: string;
  code: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface Segment {
  id: string;
  code: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface Regional {
  id: string;
  code: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface TypeGroup {
  id: string;
  code: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface Province {
  id: number;
  name: string;
}

export interface City {
  id: number;
  name: string;
  province_id: number;
}

export interface District {
  id: number;
  name: string;
  city_id: number;
}

export interface Tags {
  id: string;
  code: string;
  name: string;
}

export interface PayType {
  id: string;
  code: string;
  name: string;
  description: string;
}

export interface CharterPartnerTable {
  id: string;
  code: string;
  project_id: number;
  contract_no: string;
  partner_code: string;
  top_type: string;
  value_total: number;
  pay_type_code: string;
  remarks: string;
  deleted_at?: any;
  created_at: Date;
  updated_at: Date;
  partner_name: string;
}

export interface TermOfPayment {
  id: string;
  top_type: string;
  due_date: string;
  value: string;
  order: string;
  description: string;
  top_status: string;
  paid_at: string;
  partner_payment_code: string;
  pay_type_code: string;
  deliverables: string[];
  term: string;
}

export interface Pic {
  name: string;
  email: string;
}

export interface Deliverables {
  name: string;
  owner: string;
  start_date: string;
  end_date: string;
  bobot: string;
  charter_id: string;
  text: string;
  is_approval: string;
  planned_start: string;
  planned_end: string;
  pic: Pic[];
}

export interface Filterpartner {
  name: string;
  per_page: string;
  total: number;
  page: number;
}

export interface Partner {
  id: string;
  code: string;
  partner_name: string;
  source: string;
}

export interface AssignStakeHolder {
  am: string;
  pm: string;
  pa: Array<string>;
  namePm: Array<string>;
  namePa: Array<string>;
  nameAm: Array<string>;
  charterCode: string;
}

export interface Material {
  id: number;
  name: string;
  description: string;
}

export interface SellerData {
  id?: string | number;
  code?: string;
  name: string | null;
  description: string | null;
  deleted_at?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  charter_id?: string | null;
}

export interface CostPackageItem {
  id?: string | number;
  code?: string;
  name: string | null;
  contract: string | null;
  contract_start_at?: string | null;
  contract_end_at?: string | null;
  description: string | null;
  province_id?: string | number;
  cost_sellers_code?: string;
  deleted_at?: string | number;
  created_at?: string | number;
  updated_at?: string | number;
  seller?: SellerData;
}

export interface CostPackage {
  code?: string;
  id?: string | number;
  cost_package_code?: string;
  item_name: string | null;
  item_desc: string | null;
  item_price: string | null;
  uom?: string | null;
  cost_package_item?: CostPackageItem;
  modify?: boolean;
}

export interface CostItem {
  code?: string;
  cost_package_code?: string;
  id?: string | number;
  item_desc: string | null;
  item_name: string | null;
  item_price: string | null;
  package?: CostPackage;
  plan_volume: string | null;
  total_price: string | null;
  uom: string | null;
  created_at?: string | null;
  deleted_at?: string | null;
  updated_at?: string | null;
}

export interface CostQuota {
  id?: string | number;
  cost_package_item_code?: string | number | null;
  quota_qty: string | null;
  quota_total_price: string | null;
  package?: CostPackage;
  item?: CostItem;
}

export interface CostRealizationData {
  price_per_unit?: number | null;
  needs?: number | null;
  volume?: number | null;
}

export interface RABData {
  id?: string | number;
  code?: string;
  material: string;
  description: string | null;
  quota_qty: string | null;
  price_per_item: string | null;
  quota_total_price: string | null;
  deleted_at?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  charter_id?: string | null;
  charter_task_id?: string | null;
  qty_real: string | null;
  price_real: string | null;
  price_total_real: string | null;
  cost_seller_code?: string | null;
  plan_qty?: string | null;
  plan_price?: string | null;
  plan_total_price?: string | null;
  real_qty?: string | null;
  real_price?: string | null;
  real_total_price?: string | null;
  bottom?: any;
  modify?: boolean;
}

export interface PGUData {
  id?: string | number;
  code?: string;
  budget_year: string;
  budget_month: string;
  budget_plan: string;
  budget_realisation: string;
  budget_performance?: string;
  cost_resource_code: string;
  cost_resource?: any;
  deleted_at?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  charter_id?: string;
  modify?: boolean;
}

export interface KHSData {
  id?: string | number;
  task_id?: string | number | null;
  charter_cost_khs_id?: string | number | null;
  price_per_item: string | null;
  plan_qty: string | null;
  plan_total_price: string | null;
  real_qty: string | null;
  real_price: string | null;
  real_total_price: string | null;
  deleted_at?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  charter_id?: string | null;
  quota?: CostQuota | null;
  modify?: boolean;
}

export interface ResponseData {
  current_page: string | number;
  data: any;
  first_page_url: string | null;
  from: string | number;
  last_page: string | number;
  last_page_url: string | null;
  links: any;
  next_page_url: string | null;
  path: string | null;
  per_page: string | string | number;
  prev_page_url: null;
  to: string | number;
  total: string | number;
}

export interface DeliverableResponseData {
  total_bobot: number | string | null;
  deliverable: {
    current_page: string | number;
    data: any;
    first_page_url: string | null;
    from: string | number;
    last_page: string | number;
    last_page_url: string | null;
    links: any;
    next_page_url: string | null;
    path: string | null;
    per_page: string | string | number;
    prev_page_url: null;
    to: string | number;
    total: string | number;
  };
}

export interface DeliverableCostData {
  id?: string | number;
  start_date: string | null;
  end_date: string | null;
  text: string | null;
  bobot: string | null;
  progress: number | null;
  is_approval: boolean;
  owner: any;
  status: string | null;
  planned_start: string | null;
  planned_end: string | null;
  approval: any;
  histories: any;
  files: any;
  charter_cost: any;
  charter_cost_khs: any;
}

export interface CharterCost {
  type: string; // RAB | KHS | PGU
  realization: boolean;
  rab: {
    data: ResponseData;
    columns: any;
    realizationColumns: any;
    assignColumns: any;
    form: RABData;
  };
  khs: {
    data: ResponseData;
    sellers: any;
    packages: any;
    columns: any;
    realizationColumns: any;
    assignColumns: any;
    form: KHSData;
    formSeller: any;
    formPackage: any;
    realizationDashboardData: {
      totalAnggaran: number;
      tahapanBerlangsung: number;
      tahapanBelumBerlangsung: number;
      sisaAnggaran: number;
      realisasi: number;
    };
  };
  pgu: {
    data: ResponseData;
    total: {
      totalPaguAnggaran: string | null;
      realisation: string | null;
      presentaseTotal: number;
    };
    columns: any;
    form: PGUData;
  };
  deliverables: {
    data: DeliverableResponseData;
    columns: any;
    realizationColumns: any;
    rabColumns: any;
    form: any;
  };
  packageItem: {
    data: ResponseData;
    columns: any;
    form: CostPackage;
  };
  costFilter: any;
  // costTotalRealization: {
  //   anggaran: number;
  //   tahapan_belum_berlangsung: number;
  //   tahapan_berlangsung: number;
  //   sisa_anggaran: number;
  //   realisasi: number;
  //   progress: number;
  // };
  costTotalRealization: any;
}

@Module
export default class ProjectAddModules
  extends VuexModule
  implements StepperState
{

  dataEditAll = [];
  dokumenKb = [];
  dokumenKl = [];
  dokumenOther = [];
  listFIleDokumen = [];
  charterAssignStepper = false;
  charterDelivStepper = false;
  charterDateStepper = false;
  charterAddStepper = false;
  charterTerm = false;
  charterCostStepper = false;
  partnerListModal = false;
  charterPartnerStepper = false;
  stepper = parseInt(localStorage.getItem("step") as any) || 1;
  addColumn = 1;
  addForm = 0;
  filterData: Filterpartner = {
    name: "",
    per_page: "",
    total: 0,
    page: 1,
  };
  charterAdd: Add = {
    name: "",
    deskripsi: "",
    nilai: "",
    kategori: "WJAUP",
    typeGroup: "TYPAP",
    tag: [],
    isStrategis: false,
    lop: [{ lop: "" }],
    quoute: [{ quote: "" }],
    sid: [{ sid: "" }],
    geo_json: [],
    is_private: true
  };
  errorCharterAdd = {
    name: "",
    deskripsi: "",
    nilai: "",
    kategori: "",
    typeGroup: "",
    tag: "",
    isStrategis: "",
    lop: [],
    quoute: [],
    sid: [],
    geo_json: [],
  };
  charterDate: ICharterDate = {
    startDate: "",
    endDate: "",
    monitorDate: "",
    kickOffDate: "",
    dayWork: false,
    direktorat: [],
    devisi: [],
    segment: [],
    regional: [],
    address: [
      {
        provinsi: "",
        kabupaten: "",
        kota: "",
        kecamatan: "",
        alamat: "",
        kodepos: "",
        geo_json: [],
      },
    ],
    hariKerja: localStorage.getItem("id_step")
      ? []
      : ["senin", "selasa", "rabu", "kamis", "jumat"],
  };
  formCharterAddress: CharterAddress = {
    provinsi: "",
    kabupaten: "",
    kota: "",
    kecamatan: "",
    alamat: "",
    kodepos: "",
    geo_json: [],
  };
  charterAssignStakeHolder: AssignStakeHolder = {
    pm: "",
    am: "",
    pa: [],
    namePm: [],
    namePa: [],
    nameAm: [],
    charterCode: "",
  };
  errorcharterDate = {
    startDate: "",
    endDate: "",
    monitorDate: "",
    kickOffDate: "",
    dayWork: false,
    provinsi: "",
    kabupaten: "",
    kota: "",
    alamat: "",
    hariKerja: [],
  };

  charterPartner: CharterPartner = {
    namaPartner: "",
    nomorSpk: "",
    nilaiProyek: "",
    jenisPembayaran: "",
    kategori: "customer",
  };
  charterPartnerEdit: CharterPartner = {
    namaPartner: "",
    nomorSpk: "",
    nilaiProyek: "",
    jenisPembayaran: "",
    kategori: "customer",
  };

  charterDelive: Deliverables = {
    name: "",
    owner: "",
    start_date: "",
    end_date: "",
    bobot: "",
    charter_id: "",
    text: "",
    is_approval: "",
    planned_start: "",
    planned_end: "",
    pic: [],
  };

  charterTermOfPayment: TermOfPayment = {
    id: "",
    top_type: "",
    due_date: "",
    value: "",
    order: "",
    description: "",
    top_status: "",
    paid_at: "",
    partner_payment_code: "",
    pay_type_code: "",
    deliverables: [],
    term: detectSubDomain() !== "simapkominfo" ? "customer" : "partner",
  };
  charterTermOfPaymentEdit: TermOfPayment = {
    id: "",
    top_type: "",
    due_date: "",
    value: "",
    order: "",
    description: "",
    top_status: "",
    paid_at: "",
    partner_payment_code: "",
    pay_type_code: "",
    deliverables: [],
    term: "partner",
  };
  charterAssign = {};
  charterCost: CharterCost = {
    type: "",
    realization: false,
    rab: {
      data: {
        current_page: 1,
        data: [],
        first_page_url: null,
        from: "",
        last_page: "",
        last_page_url: null,
        links: [],
        next_page_url: null,
        path: null,
        per_page: "",
        prev_page_url: null,
        to: "",
        total: "",
      },
      columns: [
        {
          prop: "material",
          label: "Material/Service",
          sortable: true,
          html: true,
        },
        {
          prop: "description",
          label: "Description",
          sortable: false,
          html: false,
        },
        {
          prop: "uom",
          label: "UOM",
          sortable: false,
          html: false,
        },
        {
          prop: "price_per_item",
          label: "Price per Unit",
          sortable: true,
          html: false,
        },
        {
          prop: "quota_qty",
          label: "Quota",
          sortable: false,
          html: false,
        },
        {
          prop: "quota_total_price",
          label: "Total Price",
          sortable: true,
          html: false,
        },
      ],
      realizationColumns: [
        {
          prop: "material",
          label: "Material/Service",
          sortable: true,
          html: true,
        },
        {
          prop: "uom",
          label: "UOM",
          sortable: false,
          html: false,
        },
        {
          prop: "price_per_item",
          label: "Price per Unit",
          sortable: true,
          html: false,
        },
        {
          prop: "remaining_quota",
          label: "Remaining Quota",
          sortable: false,
          html: false,
        },
        {
          prop: "quota_total_price",
          label: "Total Price (Quota)",
          sortable: true,
          html: false,
        },
      ],
      assignColumns: [
        {
          prop: "material",
          label: "Material/Service",
          sortable: true,
          html: true,
        },
        {
          prop: "description",
          label: "Description",
          sortable: false,
          html: false,
        },
        {
          prop: "uom",
          label: "UOM",
          sortable: false,
          html: false,
        },
        {
          prop: "price_per_item",
          label: "Price per Unit",
          sortable: true,
          html: false,
        },
        // {
        //   prop: "quota_qty",
        //   label: "Remaining Quota",
        //   sortable: false,
        //   html: false,
        // },
        // {
        //   prop: "needs",
        //   label: "Needs",
        //   sortable: false,
        //   html: true,
        // },
        // {
        //   prop: "total_price",
        //   label: "Total Price",
        //   sortable: true,
        //   html: false,
        // },
      ],
      form: {
        id: 0,
        code: "",
        material: "",
        description: null,
        quota_qty: null,
        price_per_item: null,
        quota_total_price: null,
        deleted_at: null,
        created_at: null,
        updated_at: null,
        qty_real: null,
        price_real: null,
        price_total_real: null,
        modify: false,
      },
    },
    khs: {
      data: {
        current_page: 1,
        data: [],
        first_page_url: null,
        from: "",
        last_page: "",
        last_page_url: null,
        links: [],
        next_page_url: null,
        path: null,
        per_page: "",
        prev_page_url: null,
        to: "",
        total: "",
      },
      columns: [
        {
          prop: "item_name",
          label: "Material/Service",
          sortable: true,
          html: true,
        },
        {
          prop: "uom",
          label: "UOM",
          sortable: false,
          html: false,
        },
        {
          prop: "seller",
          label: "Seller",
          sortable: true,
          html: true,
        },
        {
          prop: "price_per_unit",
          label: "Price per Unit",
          sortable: true,
          html: false,
        },
        {
          prop: "volume",
          label: "Volume",
          sortable: false,
          html: false,
        },
        {
          prop: "total_price",
          label: "Total Price",
          sortable: true,
          html: false,
        },
      ],
      realizationColumns: [
        {
          prop: "item_name",
          label: "Material/Service",
          sortable: true,
          html: true,
        },
        // {
        //   prop: "uom",
        //   label: "UOM",
        //   sortable: false,
        //   html: false,
        // },
        {
          prop: "seller",
          label: "Seller",
          sortable: true,
          html: true,
        },
        {
          prop: "remaining_quota",
          label: "Remaining Quota",
          sortable: false,
          html: true,
        },
        {
          prop: "real_price",
          label: "Price per Unit",
          sortable: true,
          html: false,
        },
        // {
        //   prop: "real_total_price",
        //   label: "Total Price",
        //   sortable: true,
        //   html: false,
        // },
      ],
      assignColumns: [
        {
          prop: "item_name",
          label: "Material/Service",
          sortable: true,
          html: true,
        },
        {
          prop: "item_desc",
          label: "Description",
          sortable: false,
          html: false,
        },
        {
          prop: "uom",
          label: "UOM",
          sortable: false,
          html: false,
        },
        {
          prop: "seller",
          label: "Seller",
          sortable: true,
          html: true,
        },
        {
          prop: "price_per_unit",
          label: "Price per Unit",
          sortable: true,
          html: false,
        },
        {
          prop: "quota",
          label: "Remaining Quota",
          sortable: false,
          html: false,
        },
        {
          prop: "needs",
          label: "Needs",
          sortable: false,
          html: false,
        },
        {
          prop: "total_price",
          label: "Total Price",
          sortable: true,
          html: false,
        },
      ],
      form: {
        id: 0,
        price_per_item: "",
        plan_qty: "",
        plan_total_price: "",
        real_qty: "",
        real_price: "",
        real_total_price: "",
        quota: null,
        modify: false,
      },
      sellers: [],
      packages: [],
      formSeller: {
        id: 0,
        name: "",
        description: "",
      },
      formPackage: {
        id: 0,
        name: "",
        province: null,
        contract: "",
        contract_start_at: "",
        contract_end_at: "",
        description: "",
      },
      realizationDashboardData: {
        totalAnggaran: 0,
        tahapanBerlangsung: 0,
        tahapanBelumBerlangsung: 0,
        sisaAnggaran: 0,
        realisasi: 0,
      },
    },
    pgu: {
      data: {
        current_page: 1,
        data: [],
        first_page_url: null,
        from: "",
        last_page: "",
        last_page_url: null,
        links: [],
        next_page_url: null,
        path: null,
        per_page: "",
        prev_page_url: null,
        to: "",
        total: "",
      },
      total: {
        totalPaguAnggaran: "",
        realisation: "",
        presentaseTotal: 0,
      },
      columns: [
        {
          prop: "budget_year",
          label: "Tahun Anggaran",
          sortable: true,
          html: true,
        },
        {
          prop: "budget_month",
          label: "Bulan Angggaran",
          sortable: false,
          html: false,
        },
        {
          prop: "budget_plan",
          label: "Nilai Pagu Anggaran",
          sortable: true,
          html: false,
          formatter: (row: PGUData) => currency(Number(row.budget_plan) || 0),
        },
        {
          prop: "budget_realisation",
          label: "Nilai Realisasi Anggaran",
          sortable: true,
          html: false,
          formatter: (row: PGUData) =>
            currency(Number(row.budget_realisation) || 0),
        },
        {
          prop: "cost_resource_code",
          label: "Sumber Anggaran",
          sortable: false,
          html: false,
        },
        {
          prop: "cost_absorption",
          label: "Penyerapan (%)",
          sortable: false,
          html: false,
        },
      ],
      form: {
        id: 0,
        code: "",
        budget_year: "",
        budget_month: "",
        budget_plan: "",
        budget_realisation: "",
        budget_performance: "",
        cost_resource_code: "",
        deleted_at: null,
        created_at: null,
        updated_at: null,
        charter_id: "",
      },
    },
    deliverables: {
      data: {
        total_bobot: null,
        deliverable: {
          current_page: 1,
          data: [],
          first_page_url: null,
          from: "",
          last_page: "",
          last_page_url: null,
          links: [],
          next_page_url: null,
          path: null,
          per_page: "",
          prev_page_url: null,
          to: "",
          total: "",
        },
      },
      columns: [
        {
          prop: "material",
          label: "Material/Service",
          sortable: true,
          html: true,
        },
        {
          prop: "qty",
          label: "Needs",
          sortable: false,
          html: false,
        },
        {
          prop: "price_total",
          label: "Total Price",
          sortable: true,
          html: false,
        },
      ],
      realizationColumns: [
        {
          prop: "material",
          label: "Material/Service",
          sortable: true,
          html: true,
        },
        // {
        //   prop: "uom",
        //   label: "UOM",
        //   sortable: false,
        //   html: false,
        // },
        {
          prop: "real_price",
          label: "Price per Unit",
          sortable: true,
          html: false,
        },
        {
          prop: "real_qty",
          label: "Needs",
          sortable: false,
          html: false,
        },
        {
          prop: "real_total_price",
          label: "Total Price",
          sortable: true,
          html: false,
        },
      ],
      rabColumns: [
        {
          prop: "material",
          label: "Material/Service",
          sortable: true,
          html: true,
        },
        {
          prop: "plan_qty",
          label: "Needs",
          sortable: false,
          html: false,
        },
        {
          prop: "plan_total_price",
          label: "Total Price",
          sortable: true,
          html: false,
        },
        {
          prop: "quota",
          label: "Quota",
          sortable: false,
          html: false,
        },
      ],
      form: {
        id: 0,
        price_per_item: "",
        plan_qty: "",
        plan_price: "",
        plan_total_price: "",
        real_qty: "",
        real_price: "",
        real_total_price: "",
        quota: null,
        bottom: null,
        modify: false,
      },
    },
    packageItem: {
      data: {
        current_page: 1,
        data: [],
        first_page_url: null,
        from: "",
        last_page: "",
        last_page_url: null,
        links: [],
        next_page_url: null,
        path: null,
        per_page: "",
        prev_page_url: null,
        to: "",
        total: "",
      },
      columns: [
        {
          prop: "item_name",
          label: "Material/Service",
          sortable: true,
          html: true,
        },
        {
          prop: "item_desc",
          label: "Description",
          sortable: false,
          html: true,
        },
        {
          prop: "uom",
          label: "UOM",
          sortable: false,
          html: false,
        },
        {
          prop: "item_price",
          label: "Price",
          sortable: true,
          html: false,
        },
      ],
      form: {
        id: 0,
        code: "",
        item_name: "",
        item_desc: "",
        item_price: "",
        uom: "",
        modify: false,
      },
    },
    costFilter: {
      page: 1,
      itemsPerPage: 10,
      trash: 0,
      sortBy: ["created_at"],
      sortDesc: [1],
    },
    // costTotalRealization: {
    //   anggaran: 0,
    //   tahapan_belum_berlangsung: 0,
    //   tahapan_berlangsung: 0,
    //   sisa_anggaran: 0,
    //   realisasi: 0,
    //   progress: 0,
    // },
    costTotalRealization: 0,
  };
  charterCategory: CharterCategory[] = [];
  direkektorat: Direktorat[] = [];
  divisi: Divisi[] = [];
  segment: Segment[] = [];
  regional: Regional[] = [];
  typeGroup: TypeGroup[] = [];
  listProvince: Province[] = [];
  listCity: City[] = [];
  listTags: Tags[] = [];
  listDistrict: District[] = [];
  listPayType: PayType[] = [];
  tableCharterPartner: CharterPartnerTable[] = [];
  tableDeliverables: Deliverables[] = [];
  tableListTerms: TermOfPayment[] = [];
  listPartner: Partner[] = [];
  charter;
  completeStep1 = false;

  @Action
  [Actions.PROJECT_ADD_NEXT_STEPPER](numberStepper) {
    localStorage.setItem("step", numberStepper.step);
    const id_step = localStorage.getItem("id_step");
    this.context.commit(Mutations.SET_COMPLETE_STEPPER_CharterAdd, true);
    if (
      this.charterAdd.deskripsi != "" &&
      this.charterAdd.name != "" &&
      this.charterAdd.nilai != "" &&
      this.charterAdd.kategori != "" &&
      this.charterAdd.typeGroup != "" &&
      this.charterAdd.tag.length > 0
    ) {
      this.context.commit(Mutations.SET_COMPLETE_STEPPER_CharterAdd, true);
      localStorage.setItem("done_1", true as any);
    } else {
      this.context.commit(Mutations.SET_COMPLETE_STEPPER_CharterAdd, false);
      localStorage.setItem("done_1", false as any);
    }
    for (const key in this.charterDate) {
      if (this.charterDate[key] !== null && this.charterDate[key] != "") {
        this.context.commit(Mutations.SET_COMPLETE_STEPPER_Charterdate, true);
      } else {
        this.context.commit(Mutations.SET_COMPLETE_STEPPER_Charterdate, false);
      }
    }
    // this.context.commit(Mutations.SET_DEFAULT_FILTER);
    switch (numberStepper.step) {
      case 1:
        this.context.commit(Mutations.STEPPER, numberStepper.step);
        break;
      case 2:
        this.context.commit(Mutations.STEPPER, numberStepper.step);
        break;
      case 3:
        if (!id_step && numberStepper.name != "project-edit") {
          setAllertError("Anda Harus Menyelesaikan Step 1 Untuk Melanjutkan");
        } else {
          this.context.commit(Mutations.STEPPER, numberStepper.step);
        }
        break;
      case 4:
        if (!id_step && numberStepper.name != "project-edit") {
          setAllertError(`Anda Harus Menyelesaikan Step 1 Untuk Melanjutkan`);
        } else {
          this.context.commit(Mutations.STEPPER, numberStepper.step);
        }
        break;
      case 5:
        if (!id_step && numberStepper.name != "project-edit") {
          setAllertError(`Anda Harus Menyelesaikan Step 1 Untuk Melanjutkan`);
        } else {
          this.context.commit(Mutations.STEPPER, numberStepper.step);
        }
        // if (this.tableCharterPartner.length == 0) {
        //   setAllertError(
        //     "Anda Harus Mengisi Data Customer Dan Partner Di Step 3"
        //   );
        // } else {
        //   this.context.commit(Mutations.STEPPER, numberStepper.step);
        // }
        // this.context.commit(Mutations.STEPPER, numberStepper.step);

        break;
      case 6:
        if (!id_step && numberStepper.name != "project-edit") {
          setAllertError(`Anda Harus Menyelesaikan Step 1 Untuk Melanjutkan`);
        } else {
          for (const key in this.charterDate) {
            if (this.charterDate[key] !== null && this.charterDate[key] != "") {
              this.context.commit(
                Mutations.SET_COMPLETE_STEPPER_Charterdate,
                true
              );
            } else {
              this.context.commit(
                Mutations.SET_COMPLETE_STEPPER_Charterdate,
                false
              );
            }
          }
          if (
            this.charterAdd.deskripsi != "" &&
            this.charterAdd.name != "" &&
            this.charterAdd.nilai != "" &&
            this.charterAdd.kategori != "" &&
            this.charterAdd.typeGroup != "" &&
            this.charterAdd.tag.length > 0
          ) {
            this.context.commit(
              Mutations.SET_COMPLETE_STEPPER_CharterAdd,
              true
            );
            localStorage.setItem("done_1", true as any);
          } else {
            this.context.commit(
              Mutations.SET_COMPLETE_STEPPER_CharterAdd,
              false
            );
            localStorage.setItem("done_1", false as any);
          }
          this.context.commit(Mutations.STEPPER, numberStepper.step);
        }

        break;
      case 7:
        if (!id_step && numberStepper.name != "project-edit") {
          setAllertError(`Anda Harus Menyelesaikan Step 1 Untuk Melanjutkan`);
        } else {
          for (const key in this.charterAdd) {
            if (this.charterAdd[key] !== null && this.charterAdd[key] != "") {
              this.context.commit(
                Mutations.SET_COMPLETE_STEPPER_Charterassign,
                true
              );
            } else {
              this.context.commit(
                Mutations.SET_COMPLETE_STEPPER_Charterassign,
                false
              );
            }
          }
          for (const key in this.charterDate) {
            if (this.charterDate[key] !== null && this.charterDate[key] != "") {
              this.context.commit(
                Mutations.SET_COMPLETE_STEPPER_Charterdate,
                true
              );
            } else {
              this.context.commit(
                Mutations.SET_COMPLETE_STEPPER_Charterdate,
                false
              );
            }
          }
          if (
            this.charterAdd.deskripsi != "" &&
            this.charterAdd.name != "" &&
            this.charterAdd.nilai != "" &&
            this.charterAdd.kategori != "" &&
            this.charterAdd.typeGroup != "" &&
            this.charterAdd.tag.length > 0
          ) {
            this.context.commit(
              Mutations.SET_COMPLETE_STEPPER_CharterAdd,
              true
            );
            localStorage.setItem("done_1", true as any);
          } else {
            this.context.commit(
              Mutations.SET_COMPLETE_STEPPER_CharterAdd,
              false
            );
            localStorage.setItem("done_1", false as any);
          }
          this.context.commit(Mutations.STEPPER, numberStepper.step);
        }
        break;
      case 8:
        if (!id_step && numberStepper.name != "project-edit") {
          setAllertError("Anda Harus Menyelesaikan Semua Step");
        } else {
          this.context.commit(Mutations.STEPPER, numberStepper.step);
        }
        break;
      case 9:
        if (!id_step && numberStepper.name != "project-edit") {
          setAllertError("Anda Harus Menyelesaikan Semua Step");
        } else {
          this.context.commit(Mutations.STEPPER, numberStepper.step);
        }
        break;
      default:
        this.context.commit(Mutations.STEPPER, 1);
        break;
    }
  }

  @Action
  [Actions.GET_CHARTER_CATEGORY](query) {
    return ApiService.query("charter/api/v1/chartercategory", query)
      .then(({ data }) => {
        console.log(data, "ini data charter category");
        const dataArray = data.data.data.map((dt) => dt.name.toLowerCase());
        this.context.commit(Mutations.SET_CATEGORY_CHARTER, data.data.data);
        setTimeout(() => {
          if (
            !dataArray.includes(query.params.name.toLowerCase()) &&
            query.params.name != ""
          ) {
            this.context.commit(Mutations.SET_CATEGORY_CHARTER, []);
          }
        }, 1500);
        return Promise.resolve(data);
      })
      .catch((err) => {
        this.context.commit(Mutations.SET_ERROR, err);
      });
  }

  @Action
  [Actions.CHARTER_VALIDATION](field) {
    delivSchema
      .validateAt(field, this.charterAdd)
      .then(() => {
        // this.errorCharterAdd = {} as a
        this.errorCharterAdd[field] = "";
      })
      .catch((err) => {
        this.errorCharterAdd[field] = err.message;
        // err.inner.forEach((error) => {
        //   this.errorCharterAdd[error.path] = error.message;
        // });
      });
  }

  @Action
  [Actions.CHARTER_DATE_VALIDATION](field) {
    dateSchema
      .validateAt(field, this.charterDate)
      .then(() => {
        // this.errorCharterAdd = {} as a
        this.errorcharterDate[field] = "";
        const time1 = moment(this.charterDate.startDate).format("YYYY-MM-DD");
        const time2 = moment(this.charterDate.endDate).format("YYYY-MM-DD");
        const time3 = moment(this.charterDate.kickOffDate).format("YYYY-MM-DD");
        if (time2 < time1) {
          this.errorcharterDate["endDate"] =
            "endDate must be greater than start date";
        }
        if (time3 < time1) {
          this.errorcharterDate["kickOffDate"] =
            "kick off date must be greater than start date";
        }
      })
      .catch((err) => {
        console.log(err.message);
        this.errorcharterDate[field] = err.message;
        if (field.search("address") > -1) {
          const a = field.split(".")[1];
          this.errorcharterDate[field] = err.message.replace(field, a);
        }
        // err.inner.forEach((error) => {
        //   this.errorCharterAdd[error.path] = error.message;
        // });
      });
  }

  @Action
  [Actions.GET_DIREKTORAT](query) {
    ApiService.query("charter/api/v1/charterdirectorat", query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DIREKTORAT, data.data.data);
        const dataArray = data.data.data.map((item) => item.name.toLowerCase());
        setTimeout(() => {
          if (
            !dataArray.includes(query.params.name.toLowerCase()) &&
            query.params.name != ""
          ) {
            this.context.commit(Mutations.SET_DIREKTORAT, []);
          }
        }, 1500);
      })
      .catch((err) => {
        this.context.commit(Mutations.SET_ERROR, err);
      });
  }

  @Action
  [Actions.GET_DIVISI](query) {
    ApiService.query("charter/api/v1/charterdivision", query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DIVISI, data.data.data);
        const dataArray = data.data.data.map((item) => item.name.toLowerCase());
        setTimeout(() => {
          if (
            !dataArray.includes(query.params.name.toLowerCase()) &&
            query.params.name != ""
          ) {
            this.context.commit(Mutations.SET_DIVISI, []);
          }
        }, 1500);
      })
      .catch((err) => {
        this.context.commit(Mutations.SET_ERROR, err);
      });
  }

  @Action
  [Actions.GET_SEGMENT](query) {
    ApiService.query("charter/api/v1/chartersegment", query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SEGMENT, data.data.data);
        const dataArray = data.data.data.map((item) => item.name.toLowerCase());
        setTimeout(() => {
          if (
            !dataArray.includes(query.params.name.toLowerCase()) &&
            query.params.name != ""
          ) {
            this.context.commit(Mutations.SET_SEGMENT, []);
          }
        }, 1500);
      })
      .catch((err) => {
        this.context.commit(Mutations.SET_ERROR, err);
      });
  }

  @Action
  [Actions.GET_REGIONAL](query) {
    ApiService.query("charter/api/v1/charterregional", query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_REGIONAL, data.data.data);
        const dataArray = data.data.data.map((item) => item.name.toLowerCase());
        setTimeout(() => {
          if (
            !dataArray.includes(query.params.name.toLowerCase()) &&
            query.params.name != ""
          ) {
            this.context.commit(Mutations.SET_REGIONAL, []);
          }
        }, 1500);
      })
      .catch((err) => {
        this.context.commit(Mutations.SET_ERROR, err);
      });
  }

  @Action
  [Actions.GET_CHARTER_PARTNER](query) {
    return new Promise((resolve, reject) => {
      ApiService.query("termofpayment/api/v1/partnerpayment", query)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CHARTER_PARTNER, data.data.data);
          if (data.data.data.length != 0) {
            this.context.commit(Mutations.SET_FILTER, data.data);
          }

          resolve(data);

          // delete data.data.data;
          // this.context.commit(Mutations.SET_FILTER_PARTNER, data.data);
        })
        .catch((err) => {
          reject(err);
          this.context.commit(Mutations.SET_ERROR, err);
        });
    });
  }
  @Action
  [Actions.DELETE_CHARTER_PARTNER](id) {
    ApiService.delete(`termofpayment/api/v1/partnerpayment/${id}`)
      .then(({ data }) => {
        return data;
        // delete data.data.data;
        // this.context.commit(Mutations.SET_FILTER_PARTNER, data.data);
      })
      .catch((err) => {
        console.log(err.message);
        // this.context.commit(Mutations.SET_ERROR, err);
      });
  }
  @Action
  [Actions.EDIT_PARTNER_PAYMENT](payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(`termofpayment/api/v1/partnerpayment`, payload.id, payload)
        .then(({ data }) => {
          resolve(data);
          // delete data.data.data;
          // this.context.commit(Mutations.SET_FILTER_PARTNER, data.data);
          this.context.dispatch(Actions.GET_CHARTER_PARTNER, {
            params: {
              itemsPerPage: 10,
              trash: 0,
              charter_id: payload.charter_id,
            },
          });
        })
        .catch((err) => {
          reject(err);
          console.log(err.message);
          // this.context.commit(Mutations.SET_ERROR, err);
        });
    });
  }

  @Action
  [Actions.GET_TYPE_GROUP](query) {
    ApiService.query("charter/api/v1/chartertypegroup", query)
      .then(({ data }) => {
        const dataArray = data.data.data.map((item) => item.name.toLowerCase());
        this.context.commit(Mutations.SET_TYPE_GROUP, data.data.data);
        setTimeout(() => {
          if (
            !dataArray.includes(query.params.name.toLowerCase()) &&
            query.params.name != ""
          ) {
            this.context.commit(Mutations.SET_TYPE_GROUP, []);
          }
        }, 1500);
      })
      .catch((err) => {
        this.context.commit(Mutations.SET_ERROR, err);
      });
  }

  @Action
  [Actions.GET_MASTER_PARTNER_DATA](query) {
    return new Promise((resolve, reject) => {
      ApiService.query("termofpayment/api/v1/partner", query)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
          // this.context.commit(Mutations.SET_ERROR, err);
        });
    });
  }

  @Action
  [Actions.GET_LIST_USER](query) {
    return new Promise((resolve, reject) => {
      ApiService.query("auth/api/v1/lists", query)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
          // this.context.commit(Mutations.SET_ERROR, err);
        });
    });
  }

  @Action
  [Actions.GET_PROVINCE]() {
    ApiService.get("auth/api/v1/province")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROVINCE, data.data);
        this.context.commit(storeTutorial.Mutations.LOADING, true);
      })
      .catch((err) => {
        this.context.commit(Mutations.SET_ERROR, err);
      });
  }

  @Action
  [Actions.GET_CITY](query) {
    ApiService.query("auth/api/v1/city", query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CITY, data.data);
      })
      .catch((err) => {
        this.context.commit(Mutations.SET_ERROR, err);
      });
  }

  @Action
  [Actions.DELETE_TERM_OF_PAYMENT](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`termofpayment/api/v1/termofpayment/${id}`)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_DISTRICT](query) {
    ApiService.query("auth/api/v1/district", query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DISTRICT, data.data);
      })
      .catch((err) => {
        this.context.commit(Mutations.SET_ERROR, err);
      });
  }

  @Action
  [Actions.DONE_INPUT](payload) {
    const id_step = payload.id_step;
    if (!id_step || id_step == "") {
      setAllertError("Pastikan Anda Mengisi Step 1");
    } else {
      return new Promise<void>(() => {
        this.context.commit(Mutations.STEPPER, 1);
        this.context.dispatch(Actions.GET_SHOW_PROJECT, id_step);

        const user = JSON.parse(localStorage.getItem("user") as any);

        const projectAdmin = this.charterAssignStakeHolder.namePa.reduce(
          (unique, o) => {
            if (
              !unique.some(
                (obj) =>
                  obj["name"] === o["name"] && obj["email"] === o["email"]
              )
            ) {
              unique.push(o as never);
            }
            return unique;
          },
          []
        );

        const projectManager = this.charterAssignStakeHolder.namePm.reduce(
          (unique, o) => {
            if (
              !unique.some(
                (obj) =>
                  obj["name"] === o["name"] && obj["email"] === o["email"]
              )
            ) {
              unique.push(o as never);
            }
            return unique;
          },
          []
        );
        const acountManager = this.charterAssignStakeHolder.nameAm.reduce(
          (unique, o) => {
            if (
              !unique.some(
                (obj) =>
                  obj["name"] === o["name"] && obj["email"] === o["email"]
              )
            ) {
              unique.push(o as never);
            }
            return unique;
          },
          []
        );

        projectAdmin.map((a) => {
          ApiService.post("notification/api/v1/send/assign-stake", {
            name_from: a["name"],
            name_to: user.name,
            proyek_name: this.charterAdd.name,
            link: "asas",
            role: "project admin",
            to: a["email"],
          } as any);
          ApiService.query("notification/api/v1/user/data", {
            params: { email: a["email"] },
          } as any).then((ress) => {
            ApiService.post("notification/api/v1/fcm/send/multi", {
              title: a["name"],
              description: `Anda Telah Ditunjuk Sebagai Project Admin oleh ${user.name} dalam proyek Proyek  di aplikasi Tomps PRIME`,
              user_id: ress.data.data.id,
              url_click: "project",
              sender: user.id,
              type: "assign_stakeholder",
            } as any);
          });
        });
        projectManager.map((a) => {
          ApiService.post("notification/api/v1/send/assign-stake", {
            name_from: a["name"],
            name_to: user.name,
            proyek_name: this.charterAdd.name,
            link: "asas",
            role: "project manager",
            to: a["email"],
          } as any);

          ApiService.query("notification/api/v1/user/data", {
            params: { email: a["email"] },
          } as any).then((ress) => {
            ApiService.post("notification/api/v1/fcm/send/multi", {
              title: a["name"],
              description: `Anda Telah Ditunjuk Sebagai Project Manager oleh ${user.name} dalam proyek Proyek  di aplikasi Tomps PRIME`,
              user_id: ress.data.data.id,
              url_click: "project",
              sender: user.id,
              type: "assign_stakeholder",
            } as any);
          });
        });
        acountManager.map((a) => {
          ApiService.post("notification/api/v1/send/assign-stake", {
            name_from: a["name"],
            name_to: user.name,
            proyek_name: this.charterAdd.name,
            link: "asas",
            role: "Acount Manager",
            to: a["email"],
          } as any);

          ApiService.query("notification/api/v1/user/data", {
            params: { email: a["email"] },
          } as any).then((ress) => {
            ApiService.post("notification/api/v1/fcm/send/multi", {
              title: a["name"],
              description: `Anda Telah Ditunjuk Sebagai Acount Manager oleh ${user.name} dalam proyek Proyek  di aplikasi Tomps PRIME`,
              user_id: ress.data.data.id,
              url_click: "project",
              sender: user.id,
              type: "assign_stakeholder",
            } as any);
          });
        });
        const payload = {
          charter_code: this.charterAssignStakeHolder.charterCode,
          stakeholder_am: this.charterAssignStakeHolder.nameAm,
          stakeholder_pm: this.charterAssignStakeHolder.namePm,
          stakeholder_pa: this.charterAssignStakeHolder.namePa,
        };
        this.context.dispatch(Actions.CREATE_STAKEHOLDER, payload);
        // localStorage.removeItem("id_step");
      });
    }
  }

  @Action
  [Actions.GET_LIST_DELIVERABLES](query) {
    ApiService.get(`schedule/api/v1/${query.params.charter_id}`)
      .then(({ data }) => {
        if (data.data.data.length != 0) {
          this.context.commit(Mutations.SET_DELIVERABLE_LIST, data.data.data);
          this.context.commit(Mutations.SET_FILTER, data.data);
        }
      })
      .catch((err) => {
        this.context.commit(Mutations.SET_ERROR, err);
      });
  }

  @Action
  [Actions.GET_LIST_TERM](query) {
    return new Promise((resolve, reject) => {
      ApiService.query("termofpayment/api/v1/termofpayment", query)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LIST_TERM, data.data.data);
          resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_COST_RAB](query): Promise<void> {
    const url = "/charter/api/v1/cost";

    return new Promise((resolve, reject) => {
      ApiService.query(url, {
        params: query,
      })
        .then((res) => {
          this.context.commit(Mutations.SET_COST_RAB, res.data.data);
          resolve(res.data.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  @Action
  [Actions.GET_COST_KHS](query): Promise<void> {
    let url = "/charter/api/v1/costKhs/plan/project";

    if (query.isRealization) {
      url = "/charter/api/v1/costKhs";
    }

    return new Promise((resolve, reject) => {
      ApiService.query(url, {
        params: query,
      })
        .then((res) => {
          this.context.commit(Mutations.SET_COST_KHS, res.data.data);
          resolve(res.data.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  @Action
  [Actions.GET_SELLERS](query): Promise<void> {
    const url = "/charter/api/v1/costSeller";

    return new Promise((resolve, reject) => {
      ApiService.query(url, {
        params: query,
      })
        .then((res) => {
          this.context.commit(Mutations.SET_SELLERS, res.data.data.data);
          resolve(res.data.data.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  @Action
  [Actions.GET_SELLER_PACKAGES](query): Promise<void> {
    const url = "/charter/api/v1/costPackage";

    return new Promise((resolve, reject) => {
      ApiService.query(url, {
        params: query,
      })
        .then((res) => {
          this.context.commit(
            Mutations.SET_SELLER_PACKAGES,
            res.data.data.data
          );
          resolve(res.data.data.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  @Action
  [Actions.GET_COST_ITEMS](query): Promise<void> {
    const url = "/charter/api/v1/costPackageItem";

    return new Promise((resolve, reject) => {
      ApiService.query(url, {
        params: query,
      })
        .then((res) => {
          this.context.commit(Mutations.SET_COST_ITEMS, res.data.data);
          resolve(res.data.data.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  @Action
  [Actions.GET_COST_PGU](query): Promise<void> {
    const url = "/charter/api/v1/CharterCostPagu";

    return new Promise((resolve, reject) => {
      ApiService.query(url, {
        params: query,
      })
        .then((res) => {
          this.context.commit(Mutations.SET_COST_PGU, res.data.data);
          resolve(res.data.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  @Action
  [Actions.GET_COST_PGU_TOTAL](query): Promise<void> {
    const url = "/charter/api/v1/CharterCostPagu/total";

    return new Promise((resolve, reject) => {
      ApiService.query(url, {
        params: query,
      })
        .then((res) => {
          this.context.commit(Mutations.SET_COST_PGU_TOTAL, res.data.data);
          resolve(res.data.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  @Action
  [Actions.GET_COST_TOTAL_REALIZATION](query): Promise<void> {
    let url = "/charter/api/v1/cost/total/anggaran";

    if (query.cost_type === "PKBY9") {
      url = "/charter/api/v1/cost/dashboard";
    } else if (query.cost_type === "PKVE4") {
      url = "/charter/api/v1/costKhs/realisation/dashboard";
    }

    return new Promise((resolve, reject) => {
      ApiService.query(url, {
        params: query,
      })
        .then((res) => {
          this.context.commit(
            Mutations.SET_COST_TOTAL_REALIZATION,
            res.data.data
          );
          resolve(res.data.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  @Action
  [Actions.GET_KHS_REALIZATION_DASHBOARD](query): Promise<void> {
    const url = "/charter/api/v1/costKhs/realisation/dashboard";

    return new Promise((resolve, reject) => {
      ApiService.query(url, {
        params: query,
      })
        .then((res) => {
          this.context.commit(
            Mutations.SET_KHS_REALIZATION_DASHBOARD_DATA,
            res.data.data
          );
          resolve(res.data.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  @Action
  [Actions.GET_TAG](query) {
    return new Promise((resolve, reject) => {
      ApiService.query("charter/api/v1/chartertag", query)
        .then(({ data }) => {
          const dataArray = data.data.data.map((item) => item.name);
          resolve(data);
          this.context.commit(Mutations.SET_TAG, data.data.data);
          setTimeout(() => {
            if (
              !dataArray.includes(query.params.name.toLowerCase()) &&
              query.params.name != ""
            ) {
              this.context.commit(Mutations.SET_TAG, []);
            }
          }, 1500);
        })
        .catch((err) => {
          reject(err);
          // this.context.commit(Mutations.SET_ERROR, err);
        });
    });
  }

  @Action
  [Actions.USE_TEMPLATE](id) {
    return new Promise((resolve, reject) => {
      ApiService.post(`charter/api/v1/template/import/${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
          // this.context.commit(Mutations.SET_ERROR, err);
        });
    });
  }

  @Action
  [Actions.STEPPER_ONE](payload) {
    delivSchema
      .validate(this.charterAdd, { abortEarly: false })
      .then(() => {
        if (
          this.charterAdd.deskripsi != "" &&
          this.charterAdd.name != "" &&
          this.charterAdd.nilai != "" &&
          this.charterAdd.kategori != "" &&
          this.charterAdd.typeGroup != "" &&
          this.charterAdd.tag.length > 0
        ) {
          this.context.commit(Mutations.SET_COMPLETE_STEPPER_CharterAdd, true);
        } else {
          this.context.commit(Mutations.SET_COMPLETE_STEPPER_CharterAdd, false);
        }
        this.context.commit(Mutations.STEPPER, 2);
        const id_step = localStorage.getItem("id_step") ?? "";
        // const id_template = localStorage.getItem("id_template") ?? "";
        if (!id_step || id_step == "" || id_step == null) {
          return new Promise<void>((resolve, reject) => {
            ApiService.post(`charter/api/v1/charter`, payload)
              .then(({ data }) => {
                localStorage.setItem("id_step", data.data.id);
                return resolve(data);
              })
              .catch((err) => {
                setAllertError(err);
                this.context.commit(Mutations.SET_ERROR, err);
                reject();
              });
          });
        } else {
          return new Promise<void>((resolve, reject) => {
            const newpayload = {
              ...payload,
              step: 1,
            };
            ApiService.put(`charter/api/v1/charter`, id_step, newpayload)
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                setAllertError(err);
                this.context.commit(Mutations.SET_ERROR, err);
                reject();
              });
          });
        }
      })
      .catch((err) => {
        this.context.commit(Mutations.STEPPER, 1);
        this.context.commit("UBAH_COK", false);
        err?.inner?.forEach((error) => {
          this.errorCharterAdd[error.path] = error.message;
        });
      });
  }

  @Action
  [Actions.STEPPER_TWO](payload) {
    this.context.commit(Mutations.STEPPER, 3);
    dateSchema
      .validate(this.charterDate, { abortEarly: false })
      .then(() => {
        console.log("masuk then");
        // for (const key in this.charterDate) {
        //   if (this.charterDate[key] !== null && this.charterDate[key] != "") {
        //     this.context.commit(
        //       Mutations.SET_COMPLETE_STEPPER_Charterdate,
        //       true
        //     );
        //     console.log("state nya sudah terisi semua");
        //   } else {
        //     this.context.commit(
        //       Mutations.SET_COMPLETE_STEPPER_Charterdate,
        //       false
        //     );
        //   }
        // }
        this.context.commit(Mutations.SET_COMPLETE_STEPPER_Charterdate, true);
        const id_step = localStorage.getItem("id_step") ?? "";
        if (!id_step) {
          setAllertError(`Anda Harus Menyelesaikan Step 1 Untuk Melanjutkan`);
        } else {
          console.log("masuk sini");
          const time1 = moment(this.charterDate.startDate).format("YYYY-MM-DD");
          const time2 = moment(this.charterDate.endDate).format("YYYY-MM-DD");
          const time3 = moment(this.charterDate.kickOffDate).format(
            "YYYY-MM-DD"
          );
          if (time2 < time1) {
            this.errorcharterDate["endDate"] =
              "endDate must be greater than start date";
          } else if (time3 < time1) {
            this.errorcharterDate["kickOffDate"] =
              "kick off date must be greater than start date";
          } else {
            this.context.commit(Mutations.STEPPER, 3);
            const newPayload = {
              ...payload,
              charter_id: id_step,
            };
            return new Promise<void>((resolve, reject) => {
              ApiService.put(`charter/api/v1/charter`, id_step, newPayload)
                .then(({ data }) => {
                  resolve(data);
                })
                .catch((err) => {
                  setAllertError(err);
                  this.context.commit(Mutations.SET_ERROR, err);
                  reject();
                });
            });
          }
        }
      })
      .catch((err) => {
        this.context.commit(Mutations.SET_COMPLETE_STEPPER_Charterdate, false);
        this.context.commit(Mutations.STEPPER, 2);
        err?.inner?.forEach((error) => {
          console.log(error, "aaa");
          console.log();
          this.errorcharterDate[error.path] = error.message;
        });
      });
  }

  @Action
  [Actions.STEPPER_THREE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`termofpayment/api/v1/partnerpayment`, payload.payload)
        .then(({ data }) => {
          setAllertSuccess("Sukses");
          this.context.dispatch(Actions.GET_CHARTER_PARTNER, {
            params: {
              itemsPerPage: 10,
              trash: 0,
              charter_id: payload.id,
            },
          });
          // this.context.commit(Mutations.SET_STPPER_THREE, data.data);
          return resolve(data);
        })
        .catch((err) => {
          setAllertError("Error");
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.STEP1_EDIT](payload) {
    return new Promise<void>((resolve, reject) => {
      const newpayload = {
        ...payload.payload,
        step: 1,
      };

      delivSchema
        .validate(this.charterAdd, { abortEarly: false })
        .then(() => {
          ApiService.put(`charter/api/v1/charter`, payload.id, newpayload)
            .then(({ data }) => {
              // setAllertSuccess("sukses edit");
              this.dataEditAll.push("step1" as never);
              resolve(data);
            })
            .catch((err) => {
              this.context.commit(Mutations.SET_ERROR, err);
              reject();
            });
        })
        .catch((err) => {
          err?.inner?.forEach((error) => {
            setAllertError("pastikan semua data terisi")
            this.errorCharterAdd[error.path] = error.message;
          });
        });
    });
  }

  @Action
  [Actions.GET_LIST_FILE]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`file/api/v1/project_file/list`)
        .then(({ data }) => {
          resolve(data);
          this.context.commit(Mutations.SET_LIST_FILE, data.data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.STEP2_EDIT](payload) {
    return new Promise<void>((resolve, reject) => {
      const newpayload = {
        ...payload.payload,
        step: 2,
      };
      dateSchema
        .validate(this.charterDate, { abortEarly: false })
        .then(() => {
          ApiService.put(`charter/api/v1/charter`, payload.id, newpayload)
            .then(({ data }) => {
              setAllertSuccess("sukses edit");
              this.dataEditAll.push("step1" as never);
              this.context.dispatch(Actions.GET_SHOW_PROJECT, payload.id);
              resolve(data);
            })
            .catch((err) => {
              this.context.commit(Mutations.SET_ERROR, err);
              reject();
            });
        })
        .catch((err) => {
          this.context.commit(
            Mutations.SET_COMPLETE_STEPPER_Charterdate,
            false
          );
          err?.inner?.forEach((error) => {
            setAllertError("pastikan semua data terisi")
            this.errorcharterDate[error.path] = error.message;
          });
        });
        
    });
  }

  @Action
  [Actions.STEPPER_FOUR](payload) {
    const newPayload = {
      ...payload.payload,
      charter_id: payload.id,
    };
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`schedule/api/v1/deliverable`, newPayload)
        .then(({ data }) => {
          setAllertSuccess("Sukses");
          this.context.commit(Mutations.SET_PUSH_TABLE_DELIV, data.data);
          return resolve(data);
        })
        .catch((err) => {
          setAllertError(err);
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.STEPPER_FIVE](payload) {
    const newPayload = {
      ...payload.payload,
      charter_id: payload.id,
    };
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`termofpayment/api/v1/termofpayment`, newPayload)
        .then(({ data }) => {
          setAllertSuccess("Sukses");
          this.context.commit(Mutations.SET_LIST_ADD_TERM, data.data);
          return resolve(data);
        })
        .catch((err) => {
          setAllertError("error");
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_TERM_OF_PAYMENT](payload) {
    // const newPayload = {
    //   ...payload.payload,
    //   charter_id: payload.id,
    // };
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`termofpayment/api/v1/termofpayment`, payload.id, payload)
        .then(({ data }) => {
          setAllertSuccess("Sukses");
          this.context.commit(Mutations.SET_LIST_ADD_TERM, data.data);
          return resolve(data);
        })
        .catch((err) => {
          setAllertError("error");
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.STEPPER_EIGHT](payload) {
    const id_step = localStorage.getItem("id_step") ?? "";
    const newPayload = { ...payload };

    if (!newPayload.charter_id) {
      if (id_step) {
        newPayload.charter_id = id_step;
      }
    }

    if (newPayload.cost_type_code === "PKBY9") {
      return new Promise<void>((resolve, reject) => {
        ApiService.post(`charter/api/v1/cost`, newPayload)
          .then(({ data }) => {
            setAllertSuccess("Sukses");
            // this.context.commit(Mutations.SET_COST_PGU_LIST_ADD, data.data);
            return resolve(data);
          })
          .catch((err) => {
            setAllertError("error");
            this.context.commit(Mutations.SET_ERROR, err);
            reject();
          });
      });
    } else if (newPayload.cost_type_code === "WJATT") {
      return new Promise<void>((resolve, reject) => {
        ApiService.post(`charter/api/v1/CharterCostPagu`, newPayload)
          .then(({ data }) => {
            setAllertSuccess("Sukses");
            // this.context.commit(Mutations.SET_COST_PGU_LIST_ADD, data.data);
            return resolve(data);
          })
          .catch((err) => {
            setAllertError("error");
            this.context.commit(Mutations.SET_ERROR, err);
            reject();
          });
      });
    }
  }

  @Action
  [Actions.STEPPER_EIGHT_EDIT](payload: {
    data: any;
    costType: string;
    type?: string;
  }) {
    if (payload.costType === "PKBY9") {
      let url = "charter/api/v1/cost";

      if (payload.type === "deliverable" || payload.type === "realization") {
        url = "charter/api/v1/cost/realisation";
      }

      return new Promise<void>((resolve, reject) => {
        ApiService.put(url, payload.data.id, payload.data)
          .then(({ data }) => {
            setAllertSuccess("Sukses");
            // this.context.commit(Mutations.SET_COST_PGU_LIST_ADD, data.data);
            return resolve(data);
          })
          .catch((err) => {
            setAllertError("error");
            this.context.commit(Mutations.SET_ERROR, err);
            reject();
          });
      });
    } else if (payload.costType === "WJATT") {
      return new Promise<void>((resolve, reject) => {
        ApiService.put(
          `charter/api/v1/CharterCostPagu`,
          payload.data.id,
          payload.data
        )
          .then(({ data }) => {
            setAllertSuccess("Sukses");
            // this.context.commit(Mutations.SET_COST_PGU_LIST_ADD, data.data);
            return resolve(data);
          })
          .catch((err) => {
            setAllertError("error");
            this.context.commit(Mutations.SET_ERROR, err);
            reject();
          });
      });
    } else if (payload.costType === "PKVE4") {
      let url = "charter/api/v1/costKhs";

      if (payload.type === "plan") {
        url = "charter/api/v1/costKhs/plan/project";
      }

      return new Promise<void>((resolve, reject) => {
        ApiService.put(url, payload.data.id, payload.data)
          .then(({ data }) => {
            setAllertSuccess("Sukses");
            return resolve(data);
          })
          .catch((err) => {
            setAllertError("error");
            this.context.commit(Mutations.SET_ERROR, err);
            reject();
          });
      });
    }
  }

  @Action
  [Actions.UPDATE_COST_ITEM](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`charter/api/v1/costPackageItem`, payload.id, payload.data)
        .then(({ data }) => {
          setAllertSuccess("Sukses");
          return resolve(data);
        })
        .catch((err) => {
          setAllertError("error");
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_COST_TYPE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`charter/api/v1/charter`, payload.id, payload)
        .then(({ data }) => {
          setAllertSuccess("Sukses");
          this.context.commit(Mutations.SET_COST_TYPE, payload.cost_type_code);
          return resolve(data);
        })
        .catch((err) => {
          setAllertError("error");
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_COST_RAB_MATERIAL](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`charter/api/v1/cost/${payload.id}`)
        .then(({ data }) => {
          setAllertSuccess("Sukses");
          this.context.commit(
            Mutations.SET_COST_RAB_LIST,
            this.charterCost.rab.data.data.filter(
              (item) => item.id !== payload.id
            )
          );
          return resolve(data);
        })
        .catch((err) => {
          setAllertError("error");
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_COST_KHS](payload) {
    let url = `charter/api/v1/costKhs/${payload.id}`;

    if (payload.type === "plan") {
      url = `charter/api/v1/costKhs/plan/project/${payload.id}`;
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.delete(url)
        .then(({ data }) => {
          setAllertSuccess("Sukses");
          this.context.commit(
            Mutations.SET_COST_KHS_LIST,
            this.charterCost.khs.data.data.filter(
              (item) => item.id !== payload.id
            )
          );
          return resolve(data);
        })
        .catch((err) => {
          setAllertError("error");
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_COST_RAB](payload) {
    let url = `charter/api/v1/cost/${payload.id}`;

    if (payload.type === "realization") {
      url = `charter/api/v1/cost/realisation/${payload.id}`;
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.delete(url)
        .then(({ data }) => {
          setAllertSuccess("Sukses");
          this.context.commit(
            Mutations.SET_COST_RAB_LIST,
            this.charterCost.rab.data.data.filter(
              (item) => item.id !== payload.id
            )
          );
          return resolve(data);
        })
        .catch((err) => {
          setAllertError("error");
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_COST_PGU](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`charter/api/v1/CharterCostPagu/${payload.id}`)
        .then(({ data }) => {
          setAllertSuccess("Sukses");
          this.context.commit(
            Mutations.SET_COST_PGU_LIST,
            this.charterCost.pgu.data.data.filter(
              (item) => item.id !== payload.id
            )
          );
          return resolve(data);
        })
        .catch((err) => {
          setAllertError("error");
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_COST_ITEM](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`charter/api/v1/costPackageItem/${payload.id}`)
        .then(({ data }) => {
          setAllertSuccess("Sukses");
          this.context.commit(
            Mutations.SET_COST_ITEM_LIST,
            this.charterCost.packageItem.data?.data?.filter(
              (item) => item.id !== payload.id
            )
          );
          return resolve(data);
        })
        .catch((err) => {
          setAllertError("error");
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_SELLER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`charter/api/v1/costSeller/${payload.id}`)
        .then(({ data }) => {
          setAllertSuccess("Sukses");
          this.context.commit(
            Mutations.SET_SELLERS,
            this.charterCost.khs.sellers?.filter(
              (item) => item.id !== payload.id
            )
          );
          return resolve(data);
        })
        .catch((err) => {
          setAllertError("error");
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_SELLER_PACKAGE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`charter/api/v1/costPackage/${payload.id}`)
        .then(({ data }) => {
          setAllertSuccess("Sukses");
          this.context.commit(
            Mutations.SET_SELLER_PACKAGES,
            this.charterCost.khs.packages?.filter(
              (item) => item.id !== payload.id
            )
          );
          return resolve(data);
        })
        .catch((err) => {
          setAllertError("error");
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_STAKEHOLDER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`charter/api/v1/charter/stackholder`, payload)
        .then(({ data }) => {
          // setAllertSuccess("Sukses");
          this.context.commit(Mutations.SET_LIST_ADD_TERM, data.data);
          return resolve(data);
        })
        .catch((err) => {
          setAllertError(err);
          this.context.commit(Mutations.SET_ERROR, err);
          throw reject(err);
        });
    });
  }

  @Action
  [Actions.GET_FILE_DETAIL](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`file/api/v1/project_file/show`, query)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.UPLOAD_DOKUMEN](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`file/api/v1/project_file/insert`, payload)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_PAY_TYPE](query) {
    ApiService.query("termofpayment/api/v1/paytype", query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PAY_TYPE, data.data.data);
      })
      .catch((err) => {
        this.context.commit(Mutations.SET_ERROR, err);
      });
  }

  @Action
  [Actions.GET_SHOW_PROJECT](id) {
    return new Promise<void>((resolve, reject) => {
      // jika sudah add project maka execute ini
      ApiService.get(`charter/api/v1/charter/${id}`)
        .then(({ data }) => {
          if (!data.data.address || data.data.address.length < 1) {
            data.data.address[0] = {
              provinsi: "",
              kabupaten: "",
              kota: "",
              kecamatan: "",
              alamat: "",
              kodepos: "",
              geo_json: [],
            };
          }
          this.context.commit(Mutations.SET_SHOW_PROJECT, data.data);
          resolve(data.data);
        })
        .catch((err) => {
          // this.context.commit(Mutations.SET_ERROR, err);
          reject(err);
        });
    });
  }

  @Action
  [Actions.BACK_STEPPER](data) {
    // jika sudah add project maka execute ini
    this.context.commit(Mutations.STEPPER, data);
  }

  @Action
  [Actions.GET_PARTNER](query) {
    // jika sudah add project maka execute ini
    ApiService.query("termofpayment/api/v1/partner", query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LIST_PARTNER, data.data.data);
      })
      .catch((err) => {
        this.context.commit(Mutations.SET_ERROR, err);
      });
  }

  @Action
  [Actions.CREATE_CHARTER_PARTNER](payload) {
    ApiService.post("termofpayment/api/v1/partnerpayment", payload)
      .then(({ data }) => {
        Promise.resolve(data);
      })
      .catch((err) => {
        Promise.reject(err);
        this.context.commit(Mutations.SET_ERROR, err);
      });
  }

  @Action
  [Actions.CREATE_STAKEHOLDER_NEW](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("charter/api/v1/charter/stackholder/new", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
          this.context.commit(Mutations.SET_ERROR, err);
        });
    });
  }

  @Action
  [Actions.GET_STAKEHOLDER_NEW](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`charter/api/v1/charter/stackholder/new`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOP_PARTNERS, data.data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_STAKEHOLDER_NEW](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(
        `charter/api/v1/charter/stackholder/new`,
        payload.code,
        payload.form
      )
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  get getdokumenFile() {
    return this.listFIleDokumen;
  }

  get getCharterStake() {
    return this.charterAssignStakeHolder;
  }

  // validasi
  get geterrorCharterAdd() {
    return this.errorCharterAdd;
  }

  get geterrorCharterDate() {
    return this.errorcharterDate;
  }

  get getcompleStep1(): boolean {
    return this.completeStep1;
  }

  // end valiasi

  get getListTableTerms(): TermOfPayment[] {
    return this.tableListTerms;
  }

  get getcharterAdd(): Add {
    return this.charterAdd;
  }

  get getStepper(): any {
    return this.stepper;
  }

  get charterCategorys(): CharterCategory[] {
    return this.charterCategory;
  }

  get charterDates(): ICharterDate {
    return this.charterDate;
  }

  get divisis(): Divisi[] {
    return this.divisi;
  }

  get isPartnerPop() {
    return this.partnerListModal;
  }

  get direktorats(): Direktorat[] {
    return this.direkektorat;
  }

  get segments(): Segment[] {
    return this.segment;
  }

  get regionals(): Regional[] {
    return this.regional;
  }

  get typeGroups(): TypeGroup[] {
    return this.typeGroup;
  }

  get getCharterAddStepper(): boolean {
    return this.charterAddStepper;
  }

  get getCharterDateStepper(): boolean {
    return this.charterDateStepper;
  }

  get getCharterDeliveStepper(): boolean {
    return this.charterDelivStepper;
  }

  get getCharterCostStepper(): boolean {
    return this.charterCostStepper;
  }

  get getFormCharterPartner(): CharterPartner {
    return this.charterPartner;
  }
  get getFormCharterPartnerEdit(): CharterPartner {
    return this.charterPartnerEdit;
  }

  get getFormCharterDelive(): Deliverables {
    return this.charterDelive;
  }

  get getFormCostRAB(): RABData {
    return this.charterCost.rab.form;
  }

  get getFormCostKHS(): KHSData {
    return this.charterCost.khs.form;
  }

  get getFormCostPGU(): PGUData {
    return this.charterCost.pgu.form;
  }

  get getFormCostDeliverable(): KHSData {
    return this.charterCost.deliverables.form;
  }

  get getFormSeller() {
    return this.charterCost.khs.formSeller;
  }

  get getFormSellerPackage() {
    return this.charterCost.khs.formPackage;
  }

  get getaddColumn(): number {
    return this.addColumn;
  }

  get getaddForm(): number {
    return this.addForm;
  }

  get getlistProvince(): Province[] {
    return this.listProvince;
  }

  get getlistCity(): City[] {
    return this.listCity;
  }

  get getlistDistrict(): District[] {
    return this.listDistrict;
  }

  get getlistTags(): Tags[] {
    return this.listTags;
  }

  get getlistPaytype(): PayType[] {
    return this.listPayType;
  }

  get getCostType() {
    return this.charterCost.type;
  }

  get getCostRealization() {
    return this.charterCost.realization;
  }

  get getCostRAB() {
    return this.charterCost.rab.data;
  }

  get getCostKHS() {
    return this.charterCost.khs.data;
  }

  get getCostPGU() {
    return this.charterCost.pgu.data;
  }

  get getCostPGUTotal() {
    return this.charterCost.pgu.total;
  }

  get getFilterCost() {
    return this.charterCost.costFilter;
  }

  get getCostDeliverables() {
    return this.charterCost.deliverables.data.deliverable;
  }

  get getCostTotalRealization() {
    return this.charterCost.costTotalRealization;
  }

  get getKHSRealizationDashboardData() {
    return this.charterCost.khs.realizationDashboardData;
  }

  get getListRABColumns() {
    return this.charterCost.rab.columns;
  }

  get getRealizationRABColumns() {
    return this.charterCost.rab.realizationColumns;
  }

  get getListKHSColumns() {
    return this.charterCost.khs.columns;
  }

  get getListKHSRealizationColumns() {
    return this.charterCost.khs.realizationColumns;
  }

  get getListAssignKHSColumns() {
    return this.charterCost.khs.assignColumns;
  }

  get getListAssignRABColumns() {
    return this.charterCost.rab.assignColumns;
  }

  get getListPGUColumns() {
    return this.charterCost.pgu.columns;
  }

  get getCostDeliverableColumns() {
    return this.charterCost.deliverables.columns;
  }

  get getRABDeliverableColumns() {
    return this.charterCost.deliverables.rabColumns;
  }

  get getCostDeliverableRealizationColumns() {
    return this.charterCost.deliverables.realizationColumns;
  }

  get getCostSellers(): any {
    return this.charterCost.khs.sellers;
  }

  get getCostItems() {
    return this.charterCost.packageItem.data;
  }

  get getFormCostItem() {
    return this.charterCost.packageItem.form;
  }

  get getCostItemColumns() {
    return this.charterCost.packageItem.columns;
  }

  get getCostSellerPackages(): any {
    return this.charterCost.khs.packages;
  }

  get gettableCharterPartner(): CharterPartnerTable[] {
    return this.tableCharterPartner;
  }

  get gettableDeliverable(): Deliverables[] {
    return this.tableDeliverables;
  }

  get getTermOfPayment(): TermOfPayment {
    return this.charterTermOfPayment;
  }

  get getTermOfPaymentEdit(): TermOfPayment {
    return this.charterTermOfPaymentEdit;
  }

  get getFilterData(): Filterpartner {
    return this.filterData;
  }

  get getistPartner(): Partner[] {
    return this.listPartner;
  }

  get getdokumenOther() {
    return this.dokumenOther;
  }

  get getdokumenKb() {
    return this.dokumenKb;
  }

  get getdokumenKl() {
    return this.dokumenKl;
  }

  get getCharterAddressForm() {
    return this.formCharterAddress;
  }

  @Mutation
  [Mutations.SET_CHARTER_ADDRESS_FORM](data) {
    this.formCharterAddress = data;
  }

  @Mutation
  [Mutations.SET_PUSH_TABLE_DELIV](data) {
    this.tableDeliverables.push(data);
  }

  @Mutation
  ["UBAH_COK"](data) {
    this.completeStep1 = data;
  }

  @Mutation
  [Mutations.SET_LIST_ADD_TERM](data) {
    this.tableListTerms.push(data);
  }

  @Mutation
  [Mutations.SET_COST_TYPE](data) {
    this.charterCost.type = data;
  }

  @Mutation
  [Mutations.SET_COST_REALIZATION](data) {
    this.charterCost.realization = data;
  }

  @Mutation
  [Mutations.SET_COST_DELIVERABLES](data) {
    this.charterCost.deliverables.data.deliverable = data;
  }

  @Mutation
  [Mutations.SET_COST_DELIVERABLE_MATERIAL_FORM](data) {
    this.charterCost.deliverables.form = data;
  }

  @Mutation
  [Mutations.SET_COST_RAB](data) {
    this.charterCost.rab.data = data;
  }

  @Mutation
  [Mutations.SET_COST_RAB_LIST](data) {
    this.charterCost.rab.data.data = data;
  }

  @Mutation
  [Mutations.SET_COST_RAB_LIST_ADD](data) {
    this.charterCost.rab.data.data.push(data);
  }

  @Mutation
  [Mutations.SET_COST_KHS](data) {
    this.charterCost.khs.data = data;
  }

  @Mutation
  [Mutations.SET_COST_ITEMS](data) {
    this.charterCost.packageItem.data = data;
  }

  @Mutation
  [Mutations.SET_COST_ITEM_LIST](data) {
    this.charterCost.packageItem.data.data = data;
  }

  @Mutation
  [Mutations.SET_COST_ITEM_LIST_ADD](data) {
    this.charterCost.packageItem.data.data.push(data);
  }

  @Mutation
  [Mutations.SET_COST_KHS_LIST](data) {
    this.charterCost.khs.data.data = data;
  }

  @Mutation
  [Mutations.SET_COST_KHS_LIST_ADD](data) {
    this.charterCost.khs.data.data.push(data);
  }

  @Mutation
  [Mutations.SET_KHS_REALIZATION_DASHBOARD_DATA](data) {
    this.charterCost.khs.realizationDashboardData = data;
  }

  @Mutation
  [Mutations.SET_SELLERS](data) {
    this.charterCost.khs.sellers = data;
  }

  @Mutation
  [Mutations.SET_SELLER_FORM](data) {
    this.charterCost.khs.formSeller = data;
  }

  @Mutation
  [Mutations.SET_SELLER_PACKAGE_FORM](data) {
    this.charterCost.khs.formPackage = data;
  }

  @Mutation
  [Mutations.SET_SELLER_PACKAGES](data) {
    this.charterCost.khs.packages = data;
  }

  @Mutation
  [Mutations.SET_COST_PGU](data) {
    this.charterCost.pgu.data = data;
  }

  @Mutation
  [Mutations.SET_COST_PGU_TOTAL](data) {
    this.charterCost.pgu.total = data;
  }

  @Mutation
  [Mutations.SET_COST_PGU_LIST](data) {
    this.charterCost.pgu.data.data = data;
  }

  @Mutation
  [Mutations.SET_COST_PGU_LIST_ADD](data) {
    this.charterCost.pgu.data.data.push(data);
  }

  @Mutation
  [Mutations.SET_COST_DELIVERABLES_LIST](data) {
    this.charterCost.deliverables.data.deliverable.data = data;
  }

  @Mutation
  [Mutations.SET_COST_TOTAL_REALIZATION](data) {
    this.charterCost.costTotalRealization = data;
  }

  @Mutation
  [Mutations.SET_POPUP_CUSTOMER](data) {
    this.partnerListModal = data;
  }

  @Mutation
  [Mutations.SET_ADD_COLUMN](data) {
    this.addColumn = data;
  }

  @Mutation
  [Mutations.SET_TAG](data) {
    this.listTags = data;
  }

  @Mutation
  [Mutations.SET_PAY_TYPE](data) {
    this.listPayType = data;
  }

  @Mutation
  [Mutations.SET_ADD_FORM](data) {
    this.addColumn = data;
  }

  @Mutation
  [Mutations.STEPPER](data) {
    this.stepper = data;
  }

  @Mutation
  [Mutations.SET_CATEGORY_CHARTER](data) {
    this.charterCategory = data;
  }

  @Mutation
  [Mutations.SET_DIREKTORAT](data) {
    this.direkektorat = data;
  }

  @Mutation
  [Mutations.SET_DIVISI](data) {
    this.divisi = data;
  }

  @Mutation
  [Mutations.SET_SEGMENT](data) {
    this.segment = data;
  }

  @Mutation
  [Mutations.SET_REGIONAL](data) {
    this.regional = data;
  }

  @Mutation
  [Mutations.SET_TYPE_GROUP](data) {
    this.typeGroup = data;
  }

  @Mutation
  [Mutations.SET_COST_RAB_FORM](data) {
    this.charterCost.rab.form = data;
  }

  @Mutation
  [Mutations.SET_COST_KHS_FORM](data) {
    this.charterCost.khs.form = data;
  }

  @Mutation
  [Mutations.SET_COST_PGU_FORM](data) {
    this.charterCost.pgu.form = data;
  }

  @Mutation
  [Mutations.SET_COST_ITEM_FORM](data) {
    this.charterCost.packageItem.form = data;
  }

  @Mutation
  [Mutations.SET_COMPLETE_STEPPER_CharterAdd](data) {
    this.charterAddStepper = data;
  }

  @Mutation
  [Mutations.SET_COMPLETE_STEPPER_Charterdate](data) {
    this.charterDateStepper = data;
  }

  @Mutation
  [Mutations.SET_COMPLETE_STEPPER_Charterpartner](data) {
    this.charterPartnerStepper = data;
  }

  @Mutation
  [Mutations.SET_COMPLETE_STEPPER_Charterdeliv](data) {
    this.charterDelivStepper = data;
  }

  @Mutation
  [Mutations.SET_COMPLETE_STEPPER_Charterterm](data) {
    this.charterAddStepper = data;
  }

  @Mutation
  [Mutations.SET_COMPLETE_STEPPER_Charterassign](data) {
    this.charterAddStepper = data;
  }

  @Mutation
  [Mutations.SET_COMPLETE_STEPPER_Chartercost](data) {
    this.charterCostStepper = data;
  }

  @Mutation
  [Mutations.SET_CHARTER_PARTNER](data) {
    this.tableCharterPartner = data;
  }

  // province
  @Mutation
  [Mutations.SET_PROVINCE](data) {
    this.listProvince = data;
  }

  @Mutation
  [Mutations.SET_CITY](data) {
    this.listCity = data;
  }

  @Mutation
  [Mutations.SET_DISTRICT](data) {
    this.listDistrict = data;
  }

  @Mutation
  [Mutations.SET_STPPER_THREE](data) {
    this.tableCharterPartner.push(data);
  }

  @Mutation
  [Mutations.SET_DELIVERABLE_LIST](data) {
    this.tableDeliverables = data;
  }

  @Mutation
  [Mutations.SET_LIST_TERM](data) {
    this.tableListTerms = data;
  }

  @Mutation
  [Mutations.SET_LIST_PARTNER](data) {
    this.listPartner = data;
  }

  @Mutation
  [Mutations.SET_LIST_FILE](data) {
    this.listFIleDokumen = data;
  }

  @Mutation
  [Mutations.SET_SHOW_PROJECT](data) {
    const finalArray = data?.tags?.map(function (obj) {
      return obj.charter_tag_code;
    });

    // const direktorat = data.organizations
    //   .filter((obj) => obj.charter_directorat_code != null)
    //   .map((obj) => obj.charter_directorat_code);
    // const direktorat = data.organizations[0].charter_directorat_code;
    // const divisi = data.organizations
    //   .filter((obj) => obj.charter_division_code != null)
    //   .map((obj) => obj.charter_division_code);
    // // const divisi = data.organizations[0].charter_division_code;
    // const regional = data.organizations
    //   .filter((obj) => obj.charter_regional_code != null)
    //   .map(function (a) {
    //     return a.charter_regional_code;
    //   });
    // charterdate
    this.charterDate.address = data.address;
    console.log(data.organizations, "ini data organisation");
    const regional =  data.organizations.filter(a => a.charter_regional_code != null).map((a) => a.charter_regional_code);
    const divisi =  data.organizations.filter(a => a.charter_division_code != null).map((a) => a.charter_division_code);
    const direktorat =  data.organizations.filter(a => a.charter_directorat_code != null).map((a) => a.charter_directorat_code);
    this.charterAdd.name = data?.name;
    this.charterAdd.deskripsi = data?.descriptions;
    this.charterAdd.nilai = data?.value
      .split(".")[0]
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    this.charterAdd.is_private = data?.is_private;
    this.charterAdd.kategori = data?.charter_category_code;
    this.charterAdd.typeGroup = data?.charter_type_group_code;
    this.charterAdd.tag = finalArray;
    this.charterAdd.isStrategis = data?.is_strategic;
    this.charterAdd.lop =
      data?.references.lop.length == 0
        ? [{ lop: "" }]
        : data?.references.lop.map((value) => ({
            ["lop"]: value,
          }));
    this.charterAdd.sid =
      data?.references.sid.length == 0
        ? [{ sid: "" }]
        : data?.references.sid.map((value) => ({
            ["sid"]: value,
          }));
    this.charterAdd.quoute =
      data?.references.quote.length == 0
        ? [{ quote: "" }]
        : data?.references.quote.map((value) => ({
            ["quote"]: value,
          }));
    this.charterAdd.geo_json = data?.geo_json;
    // this.charterAdd.lop = data?.references.lop;
    // this.charterAdd.quoute = data?.references.quote.some((obj) =>
    //   // eslint-disable-next-line no-prototype-builtins
    //   obj.hasOwnProperty("quote")
    // )
    //   ? data?.references[2]
    //   : [{ quote: "" }];
    // this.charterAdd.sid = data?.references.sid.some((obj) =>
    //   // eslint-disable-next-line no-prototype-builtins
    //   obj.hasOwnProperty("sid")
    // )
    //   ? data?.references[1]
    //   : [{ sid: "" }];
    // this.charterAdd.kl = data?.references[2];
    // this.charterAdd.kb = data?.references[3];
    // this.charterAdd.p8 = data?.references[5];

    console.log(data.work_days, "ini data workday");

    this.charterDate.startDate = data?.planed_start_at?.split(" ")[0];
    this.charterDate.endDate = data?.planed_end_at?.split(" ")[0];
    this.charterDate.monitorDate = data?.monitoring_at?.split(" ")[0];
    this.charterDate.kickOffDate = data?.kick_off_at?.split(" ")[0];
    this.charterDate.segment =  !data?.charter_segment_code ? [] : [data?.charter_segment_code];
    this.charterDate.address = data?.address.map((data) => {
      return {
        ...data,
        provinsi: data.province_id,
        kota: data.city_id,
        kecamatan: data.district_id,
        alamat: data.address,
        kodepos: data.postal_code,
      };
    });
    this.charterDate.hariKerja =
      data?.work_days.map((data) => data).filter((a) => a != "").length > 0
        ? data?.work_days
        : ["senin", "selasa", "rabu", "kamis", "jumat"];
    this.charterDate.direktorat = direktorat;
    this.charterDate.devisi = divisi;
    this.charterDate.regional = regional;
    

    
    console.log(this.charterAdd, "ini charter adad")
    console.log(this.charterDate, "ini charter date")
    // this.charterDate.regional
  }

  // filter
  @Mutation
  [Mutations.SET_FILTER](data) {
    this.filterData = data;
  }

  @Mutation
  [Mutations.SET_DEFAULT_FILTER]() {
    this.filterData = {
      name: "",
      page: 1,
      per_page: "",
      total: 0,
    };
  }

  @Mutation
  [Mutations.SET_FILTER_COST](data) {
    this.charterCost.costFilter = data;
  }

  @Mutation
  [Mutations.SET_DOKUMEN_KB](data) {
    this.dokumenKb = data;
  }

  @Mutation
  [Mutations.SET_DOKUMEN_OTHER](data) {
    this.dokumenOther = data;
  }

  @Mutation
  [Mutations.SET_DOKUMEN_KL](data) {
    this.dokumenKl = data;
  }
}
