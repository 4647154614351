
import { defineComponent } from "vue";

export default defineComponent({
	name: "TokenPBIAlert",
	props: {
		alertType: {
			type: String,
			default: "warning",
		},
		title: {
			type: String,
			default: "",
		},
		description: {
			type: String,
			default: "",
		},
	},
	setup() {
		return {
			changeUrl: "/change",
			refundUrl: "/refund",
			changeLimit: 15,
			refundLimit: 30,
		};
	},
});
