import { Action, Module, VuexModule, Mutation } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

@Module
export default class MasterMenuModule extends VuexModule {
  serviceName =
    process.env.NODE_ENV === "local" ? "auth/api/v1" : "auth/api/v1";
  page = 1;
  itemsPerPage = 30;
  trash = 0;
  keyword = "";
  isSubmitting = false;

  get menuQueryParams() {
    if (this.keyword.trim() !== "") {
      return {
        name: this.keyword,
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        trash: this.trash,
      };
    }
    return {
      page: this.page,
      itemsPerPage: this.itemsPerPage,
      trash: this.trash,
    };
  }

  get menuIsSubmitting() {
    return this.isSubmitting;
  }

  @Mutation
  [Mutations.SET_MASTER_MENU_KEYWORD](keyword) {
    this.keyword = keyword;
  }

  @Mutation
  [Mutations.SET_MASTER_MENU_SUBMITTING](isSubmitting) {
    this.isSubmitting = isSubmitting;
  }

  @Action
  [Actions.UPDATE_MASTER_MENU_SUBMITTING](isSubmitting) {
    this.context.commit(Mutations.SET_MASTER_MENU_SUBMITTING, isSubmitting);
  }

  @Action
  [Actions.UPDATE_MASTER_MENU_KEYWORD](keyword: string) {
    this.context.commit(Mutations.SET_MASTER_MENU_KEYWORD, keyword);
  }

  @Action({ rawError: true })
  [Actions.GET_MENU_LIST]() {
    const q = new URLSearchParams(this.context.getters.menuQueryParams);
    return ApiService.get(`${this.serviceName}/menu`, `?${q.toString()}`)
      .then(({ data }) => Promise.resolve(data))
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR, error);
        return Promise.reject(error);
      });
  }

  // payload
  // name: menu name
  // slug: slug
  // parent: menu Id
  @Action({ rawError: true })
  [Actions.STORE_MENU](payload) {
    return ApiService.post(`${this.serviceName}/menu`, payload)
      .then(({ data }) => Promise.resolve(data))
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR, error);
        return Promise.reject(error);
      });
  }
}
