import ApiService from "@/core/services/ApiService";
import TelkomsatService from "@/core/services/TelkomsatService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";

@Module
export default class TelkomsatModule extends VuexModule {
  requestCridential: any = {
    username: process.env.VUE_APP_TELKOMSAT_USERNAME,
    password: process.env.VUE_APP_TELKOMSAT_PASSWORD,
  };

  reponseLogin: any = {
    page: 1,
    size: 5,
    nameApp: "mytds",
  };

  @Mutation
  [Mutations.SET_TOKEN_TELKOMSAT](token) {
    TelkomsatService.saveToken(token);
  }

  @Action
  [Actions.LOGIN_TELKOMSAT](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setTsatUrl();
      ApiService.postTelkomsat("/api/v1/telkomsat", payload)
        .then(({ data }) => {
          Promise.all([
            this.context.commit(Mutations.SET_TOKEN, data.data.token),
            this.context.dispatch(Actions.VERIFY_AUTH),
          ]).finally(() => resolve(data));
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.TELKOMSAT_GET_TOKEN]() {
    return new Promise<void>((resolve, reject) => {
      const fd = new FormData();
      fd.append("username", this.requestCridential.username);
      fd.append("password", this.requestCridential.password);
      ApiService.setTelkomsatUrl();
      ApiService.postTelkomsat("/auth/get_token", fd as any)
        .then(({ data }) => {
          console.log(data, "ini dataaa");
          this.context.commit(Mutations.SET_TOKEN_TELKOMSAT, data.data.token);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        })
        .finally(() => {
          console.log("ke finllay");
          ApiService.setUrl();
        });
    });
  }
  @Action
  [Actions.TELKOMSAT_SUBMIT_PROJECT](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setTelkomsatUrl();
      ApiService.setTokenTelkomsat();
      ApiService.postTelkomsat("/project/submit", payload)
        .then((data: any) => {
          this.context.commit(Mutations.SET_TOKEN_TELKOMSAT, data.data.token);
          resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        })
        .finally(() => {
          ApiService.setUrl();
        });
    });
  }
}
