import { Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Module, VuexModule } from "vuex-module-decorators";

export interface HashInfo {
  hash: string;
}

@Module
export default class HashModule extends VuexModule implements HashInfo {
  hash = "";

  /**
   * Get config from layout config
   * @returns {function(path, defaultValue): *}
   */
  get getHash() {
    return this.hash;
  }

  @Mutation
  [Mutations.SET_HASH](payload): void {
    this.hash = payload;
  }
}
