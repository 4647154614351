
import {
  ref,
  getCurrentInstance,
  computed,
  watch,
  reactive,
  onMounted,
  nextTick,
} from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Getters } from "@/store/enums/StoreTutorialEnums";

import IntroJs from "intro.js";
import * as storeTutorial from "@/store/enums/StoreTutorialEnums";
import { Tutorial } from "@/store/enums/interface_option/tutorial";

export default {
  setup() {
    const $instance = getCurrentInstance();
    const nextData = ref(0);
    const nextStepTutorial = ref(0);
    const saveValue = reactive({
      deliverables: "",
      deliverablesTabs: false,
      deliverablesTabsValue: 0,
      document: "",
      documentLoadApi: false,
      isuLoadApi: false,
      isu: "",
      costLoadApi: false,
      cost: "",
    });
    const show = ref(false);
    const route = useRoute();
    const valueLocal = ref("");
    const store = useStore();
    const comFilterStep = computed(() => store.getters[Getters.FILTER_STEP]);
    const routeHash = (name: string, status?: string) => {
      switch (status) {
        case "project-list":
          return saveValue.document !== undefined
            ? saveValue.document !== ""
              ? saveValue.document === name
              : false
            : route.fullPath?.split("/")[
                route.fullPath?.split("/").length - 1
              ] === name;

        default:
          return saveValue.document === name
            ? true
            : route.hash?.replace("#", "") === name;
      }
    };

    const localStorageFun = (name: string) => {
      try {
        if (name) {
          const a = localStorage.getItem(name) as any;
          return a ? JSON.parse(a) : undefined;
        } else {
          return name;
        }
      } catch (e) {
        console.log("localStorageFun", e);
      }
    };
    const localStorageFunSave = (
      name?: string | null,
      status?: boolean,
      nameStorage?: {
        name: string;
        data: string;
      }
    ) => {
      try {
        switch (status) {
          case true:
            if (nameStorage?.name) {
              let array = [];
              if (localStorageFun(nameStorage?.name)) {
                array = localStorageFun(nameStorage?.name);
              }
              array.push(nameStorage?.data as never);
              localStorage.setItem(nameStorage?.name, JSON.stringify(array));
            }
            break;
          default: {
            if (name) {
              let array = [];
              array = localStorageFun("introjs-tutorial");
              array.push(name as never);
              localStorage.setItem("introjs-tutorial", JSON.stringify(array));
            } else {
              return name;
            }
          }
        }
      } catch (e) {
        console.log("localStorageFun", e);
      }
    };

    const funDocument = (name: string, status = "1") => {
      return new Promise((resolve) => {
        switch (status) {
          case "1":
            {
              const doc = document.querySelectorAll(name);
              doc[0]
                ?.getElementsByClassName("next")[0]
                ?.addEventListener("click", () => {
                  nextData.value = 0;
                  resolve("step-1");
                });
              doc[0]
                ?.getElementsByClassName("lewati")[0]
                ?.addEventListener("click", () => {
                  nextData.value = 0;
                  resolve("step-2");
                });
            }
            break;
          case "2":
            {
              const time = setInterval(() => {
                const doc = document.querySelectorAll(name);
                if (doc.length > 0) {
                  doc[0]
                    ?.getElementsByClassName("next")[0]
                    ?.addEventListener("click", () => {
                      nextData.value = 0;
                      resolve("step-1");
                    });

                  doc[0]
                    ?.getElementsByClassName("lewati")[0]
                    ?.addEventListener("click", () => {
                      nextData.value = 0;
                      resolve("step-2");
                    });
                  clearInterval(time);
                }
              });
            }
            break;
        }
      });
    };
    /* const leftBar = (status = true) => {
      const doc = document.querySelectorAll(".aside-fixed .aside")[0];
      if (status) {
        doc.classList.add("active");
      } else {
        doc.classList.remove("active");
      }
    };*/
    // project active table
    const listStep = () => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-active-table"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-table">
                      <div >List Proyek Aktif dari proyek anda</div>
                      <div>Klik nama proyek apabila anda ingin mengetahui detail proyek yang aktif</div>
                     <div>
                     <div class="progress-bar">
                      <progress value="33.7" max="100"></progress>
                      </div>
                        <div>1 of 3</div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "right",
            },
            {
              element: document.querySelectorAll(
                ".introjs-active-table-2"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-table-2">
                      <div >Klik disini untuk update proyek yang sedang Anda kerjakan</div>
                      <div></div>
                     <div>
                     <div class="progress-bar">
                      <progress value="66" max="100"></progress>
                      </div>
                        <div>2 of 3</div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "right",
            },
            {
              element: document.querySelectorAll(
                ".introjs-active-table-3"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-table-3">
                      <div >Klik disini untuk menutup proyek yang sedang Anda kerjakan</div>
                      <div></div>
                     <div>
                     <div class="progress-bar">
                      <progress value="100" max="100"></progress>
                      </div>
                        <div>3 of 3</div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "right",
            },
          ],
        });
        intro2.start();

        funDocument(".introjs-project-edit-28#step-active-table").then(
          (status) => {
            switch (status) {
              case "step-1":
                nextStepTutorial.value = 1;
                intro2.nextStep();
                break;
              case "step-2":
                intro2.exit();
                valueLocal.value = "project-active-table";
                localStorageFunSave(null, true, {
                  name: "project-active-detail",
                  data: "project-active-table",
                });
                break;
            }
          }
        );
        intro2.onafterchange(() => {
          if (nextStepTutorial.value === 1) {
            funDocument(
              ".introjs-project-edit-28#step-active-table-2",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 2;
                  intro2.nextStep();
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = "project-active-table";
                  localStorageFunSave(null, true, {
                    name: "project-active-detail",
                    data: "project-active-table",
                  });
                  break;
              }
            });
          } else if (nextStepTutorial.value === 2) {
            funDocument(
              ".introjs-project-edit-28#step-active-table-3",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  intro2.nextStep();
                  valueLocal.value = "project-active-table";
                  localStorageFunSave(null, true, {
                    name: "project-active-detail",
                    data: "project-active-table",
                  });
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = "project-active-table";
                  localStorageFunSave(null, true, {
                    name: "project-active-detail",
                    data: "project-active-table",
                  });
                  break;
              }
            });
          }
        });
      });
    };
    // project active detail information
    const listStep2 = () => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-project-active-detail"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-information">
                      <div >Klik disini untuk menyelesaikan proyek yang sedang Anda kerjakan (Project Closed)</div>
                      <div></div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "right",
            },
            {
              element: document.querySelectorAll(
                ".introjs-project-active-detail-1"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-information-2">
                      <div >Klik disini untuk memperbarui proyek anda</div>
                      <div></div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "right",
            },
          ],
        });
        intro2.start();

        funDocument(".introjs-project-edit-28#step-active-information").then(
          (status) => {
            switch (status) {
              case "step-1":
                nextStepTutorial.value = 1;
                intro2.nextStep();
                break;
              case "step-2":
                intro2.exit();
                valueLocal.value = "project-information";
                localStorageFunSave(null, true, {
                  name: "project-active-detail",
                  data: "project-information",
                });
                break;
            }
          }
        );
        intro2.onafterchange(() => {
          if (nextStepTutorial.value === 1) {
            funDocument(
              ".introjs-project-edit-28#step-active-information-2",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 1;
                  intro2.nextStep();
                  valueLocal.value = "project-information";
                  localStorageFunSave(null, true, {
                    name: "project-active-detail",
                    data: "project-information",
                  });
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = "project-information";
                  localStorageFunSave(null, true, {
                    name: "project-active-detail",
                    data: "project-information",
                  });
                  break;
              }
            });
          }
        });
      });
    };
    // project partner
    const listStep3 = (nameLocalStorage: string, dataLocalStorage: string) => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-project-active-partner"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-partner">
                      <div >Klik disini untuk menambahkan Partner Proyek</div>
                      <div></div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
          ],
        });
        intro2.start();

        funDocument(".introjs-project-edit-28#step-active-partner").then(
          (status) => {
            switch (status) {
              case "step-1":
                nextStepTutorial.value = 1;
                intro2.nextStep();
                valueLocal.value = dataLocalStorage;
                localStorageFunSave(null, true, {
                  name: nameLocalStorage,
                  data: dataLocalStorage,
                });
                break;
              case "step-2":
                intro2.exit();
                valueLocal.value = dataLocalStorage;
                localStorageFunSave(null, true, {
                  name: nameLocalStorage,
                  data: dataLocalStorage,
                });
                break;
            }
          }
        );
      });
    };
    // project delivaerables open dropdown
    const listStep4DropDown = (
      nameLocalStorage: string,
      dataLocalStorage: string,
      data?: any
    ) => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-project-active-detail-deliverables-3"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-deliverables-3">
                      <div >Ubah Deliverable</div>
                      <div>Klik di sini untuk mengubah deliverable proyek Anda</div>
                     <div>
              <div class="progress-bar">
                              <progress value="50" max="100"></progress>
                              </div>
                                <div>2 of 4</div>
                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
            {
              element: document.querySelectorAll(
                ".introjs-project-active-detail-deliverables-4"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-deliverables-4">
                      <div >Lihat Lampiran</div>
                      <div>Klik di sini untuk melihat evidence atau lampiran pada deliverable </div>
                     <div>
              <div class="progress-bar">
                              <progress value="75" max="100"></progress>
                              </div>
                                <div>3 of 4</div>
                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
            {
              element: document.querySelectorAll(
                ".introjs-project-active-detail-deliverables-5"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-deliverables-5">
                      <div >Hapus Deliverable</div>
                      <div>Klik di sini untuk menghapus deliverable pada proyek anda</div>
                     <div>
              <div class="progress-bar">
                              <progress value="100" max="100"></progress>
                              </div>
                                <div>4 of 4</div>
                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
          ],
        });
        intro2.start();

        funDocument(".introjs-project-edit-28#step-active-deliverables-3").then(
          (status) => {
            switch (status) {
              case "step-1":
                nextStepTutorial.value = 1;
                intro2.nextStep();
                break;
              case "step-2":
                intro2.exit();
                valueLocal.value = dataLocalStorage;
                localStorageFunSave(null, true, {
                  name: nameLocalStorage,
                  data: dataLocalStorage,
                });
                store.commit(storeTutorial.Mutations.FILTER_STEP, {
                  name: "project-active/trigger-deliverable-dropDownShowNull",
                  bool: true,
                } as Tutorial.localStorage);
                break;
            }
          }
        );

        intro2.onafterchange(() => {
          if (nextStepTutorial.value === 1) {
            funDocument(
              ".introjs-project-edit-28#step-active-deliverables-4",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 2;
                  intro2.nextStep();
                  break;
                case "step-2":
                  intro2.exit();
                  store.commit(storeTutorial.Mutations.FILTER_STEP, {
                    name: "project-active/trigger-deliverable-dropDownShowNull",
                    bool: true,
                  } as Tutorial.localStorage);
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  break;
              }
            });
          } else if (nextStepTutorial.value === 2) {
            funDocument(
              ".introjs-project-edit-28#step-active-deliverables-5",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  intro2.exit();
                  store.commit(storeTutorial.Mutations.FILTER_STEP, {
                    name: "project-active/trigger-deliverable-dropDownShowNull",
                    bool: true,
                  } as Tutorial.localStorage);
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  break;
                case "step-2":
                  intro2.exit();
                  store.commit(storeTutorial.Mutations.FILTER_STEP, {
                    name: "project-active/trigger-deliverable-dropDownShowNull",
                    bool: true,
                  } as Tutorial.localStorage);
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  break;
              }
            });
          }
        });
      });
    };
    // project delivaerables
    const listStep4 = (
      nameLocalStorage: string,
      dataLocalStorage: string,
      data?: any
    ) => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-project-active-detail-deliverables"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-deliverables">
                      <div >Klik disini untuk menghapus deliverable proyek Anda</div>
                      <div></div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "top",
            },
            {
              element: document.querySelectorAll(
                ".introjs-project-active-detail-deliverables-2"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-deliverables-2">
                      <div >Klik disini untuk mengubah deliverable pada proyek anda</div>
                      <div></div>
                     <div>
                <div class="progress-bar">
                              <progress value="25" max="100"></progress>
                              </div>
                                <div>1 of 4</div>
                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
          ],
        });
        intro2.start();

        funDocument(".introjs-project-edit-28#step-active-deliverables").then(
          (status) => {
            switch (status) {
              case "step-1":
                nextStepTutorial.value = 1;
                intro2.nextStep();

                break;
              case "step-2":
                intro2.exit();
                valueLocal.value = dataLocalStorage;
                localStorageFunSave(null, true, {
                  name: nameLocalStorage,
                  data: dataLocalStorage,
                });
                break;
            }
          }
        );

        intro2.onafterchange(() => {
          if (nextStepTutorial.value === 1) {
            funDocument(
              ".introjs-project-edit-28#step-active-deliverables-2",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  intro2.exit();
                  store.commit(storeTutorial.Mutations.FILTER_STEP, {
                    name: "project-active/trigger-deliverable-1",
                    bool: true,
                  } as Tutorial.localStorage);
                  setTimeout(() => {
                    listStep4DropDown(nameLocalStorage, dataLocalStorage);
                  }, 1000);

                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  store.commit(storeTutorial.Mutations.FILTER_STEP, {
                    name: "project-active/trigger-deliverable-dropDownShowNull",
                    bool: true,
                  } as Tutorial.localStorage);
                  break;
              }
            });
          }
        });
      });
    };

    // project terms of payment
    const listStep5 = (nameLocalStorage: string, dataLocalStorage: string) => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-project-active-detail-terms-of-payment"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-terms-of-payment">
                      <div >Klik di sini untuk menambahkan Terms of payment partner untuk proyek Anda</div>
                      <div></div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
          ],
        });
        intro2.start();

        funDocument(
          ".introjs-project-edit-28#step-active-terms-of-payment"
        ).then((status) => {
          switch (status) {
            case "step-1":
              nextStepTutorial.value = 1;
              intro2.nextStep();
              valueLocal.value = dataLocalStorage;
              localStorageFunSave(null, true, {
                name: nameLocalStorage,
                data: dataLocalStorage,
              });
              break;
            case "step-2":
              intro2.exit();
              valueLocal.value = dataLocalStorage;
              localStorageFunSave(null, true, {
                name: nameLocalStorage,
                data: dataLocalStorage,
              });
              break;
          }
        });
      });
    };
    // project document
    const listStep6 = (nameLocalStorage: string, dataLocalStorage: string) => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-project-active-detail-project-document"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-project-document">
                      <div >Klik di sini untuk mengunduh seluruh dokumen yang ada pada kategori dokumen</div>
                      <div></div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
            {
              element: document.querySelectorAll(
                ".introjs-project-active-detail-project-document-2"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-project-document-2">
                      <div >Klik di sini untuk menambahkan dokumen sesuai kategori dokumen proyek</div>
                      <div></div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
          ],
        });
        intro2.start();

        funDocument(
          ".introjs-project-edit-28#step-active-project-document"
        ).then((status) => {
          switch (status) {
            case "step-1":
              nextStepTutorial.value = 1;
              intro2.nextStep();
              break;
            case "step-2":
              intro2.exit();
              valueLocal.value = dataLocalStorage;
              localStorageFunSave(null, true, {
                name: nameLocalStorage,
                data: dataLocalStorage,
              });
              break;
          }
        });
        intro2.onafterchange(() => {
          if (nextStepTutorial.value === 1) {
            funDocument(
              ".introjs-project-edit-28#step-active-project-document-2",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  intro2.nextStep();
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  break;
              }
            });
          }
        });
      });
    };
    // project isu-and-tindak-lanjut
    const listStep7 = (nameLocalStorage: string, dataLocalStorage: string) => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-project-active-isu"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-project-active-isu">
                      <div >Klik di sini untuk memperbarui Isu yang ada pada deliverable proyek anda</div>
                      <div></div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
            {
              element: document.querySelectorAll(
                ".introjs-project-active-isu-2"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-project-active-isu-2">
                      <div >Klik di sini untuk menambahkan Isu dan tindak lanjut untuk proyek anda</div>
                      <div></div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
          ],
        });
        intro2.start();

        funDocument(
          ".introjs-project-edit-28#step-active-project-active-isu"
        ).then((status) => {
          switch (status) {
            case "step-1":
              nextStepTutorial.value = 1;
              intro2.nextStep();
              break;
            case "step-2":
              intro2.exit();
              valueLocal.value = dataLocalStorage;
              localStorageFunSave(null, true, {
                name: nameLocalStorage,
                data: dataLocalStorage,
              });
              break;
          }
        });
        intro2.onafterchange(() => {
          if (nextStepTutorial.value === 1) {
            funDocument(
              ".introjs-project-edit-28#step-active-project-active-isu-2",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  intro2.nextStep();
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  break;
              }
            });
          }
        });
      });
    };
    // project project cost
    const listStep8 = (nameLocalStorage: string, dataLocalStorage: string) => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-project-active-cost-table"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-cost-table">
                      <div >Klik di sini untuk memperbarui evidence untuk Project Cost</div>
                      <div></div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
            {
              element: document.querySelectorAll(
                ".introjs-project-active-cost-table-2"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-cost-table-2">
                      <div >Klik di sini untuk melihat detail material dari project cost</div>
                      <div></div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
          ],
        });
        intro2.start();

        funDocument(".introjs-project-edit-28#step-active-cost-table").then(
          (status) => {
            switch (status) {
              case "step-1":
                nextStepTutorial.value = 1;
                intro2.nextStep();
                break;
              case "step-2":
                intro2.exit();
                valueLocal.value = dataLocalStorage;
                localStorageFunSave(null, true, {
                  name: nameLocalStorage,
                  data: dataLocalStorage,
                });
                break;
            }
          }
        );
        intro2.onafterchange(() => {
          if (nextStepTutorial.value === 1) {
            funDocument(
              ".introjs-project-edit-28#step-active-cost-table-2",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  intro2.nextStep();
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  break;
              }
            });
          }
        });
      });
    };
    // project project cost modal popUp
    const listStep9 = (nameLocalStorage: string, dataLocalStorage: string) => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-project-active-cost-modal"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-cost-modalPopUp">
                      <div >Unggah Evidence Tambahan</div>
                      <div>Tarik file anda kesini atau cari evidence untuk menambahkan evidence baru untuk project Cost</div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
          ],
        });
        intro2.start();

        funDocument(
          ".introjs-project-edit-28#step-active-cost-modalPopUp"
        ).then((status) => {
          switch (status) {
            case "step-1":
              nextStepTutorial.value = 1;
              intro2.nextStep();
              valueLocal.value = dataLocalStorage;
              localStorageFunSave(null, true, {
                name: nameLocalStorage,
                data: dataLocalStorage,
              });
              break;
            case "step-2":
              intro2.exit();
              valueLocal.value = dataLocalStorage;
              localStorageFunSave(null, true, {
                name: nameLocalStorage,
                data: dataLocalStorage,
              });
              break;
          }
        });
      });
    };
    // project project cost modal table
    const listStep10 = (nameLocalStorage: string, dataLocalStorage: string) => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-project-active-cost-modal-table"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-cost-modalPopUp-table">
                      <div >Anda dapat melihat detail material yang ada pada evidence Project Cost</div>
                      <div></div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "bottom",
            },
          ],
        });
        intro2.start();

        funDocument(
          ".introjs-project-edit-28#step-active-cost-modalPopUp-table"
        ).then((status) => {
          switch (status) {
            case "step-1":
              nextStepTutorial.value = 1;
              intro2.nextStep();
              valueLocal.value = dataLocalStorage;
              localStorageFunSave(null, true, {
                name: nameLocalStorage,
                data: dataLocalStorage,
              });
              break;
            case "step-2":
              intro2.exit();
              valueLocal.value = dataLocalStorage;
              localStorageFunSave(null, true, {
                name: nameLocalStorage,
                data: dataLocalStorage,
              });
              break;
          }
        });
      });
    };
    // project project deliverables gantt chart
    const listStep10V2 = (
      nameLocalStorage: string,
      dataLocalStorage: string
    ) => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-project-active-cost-modal-table"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-cost-modalPopUp-table">
                      <div >Anda dapat melihat detail material yang ada pada evidence Project Cost</div>
                      <div></div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "bottom",
            },
          ],
        });
        intro2.start();

        funDocument(
          ".introjs-project-edit-28#step-active-cost-modalPopUp-table"
        ).then((status) => {
          switch (status) {
            case "step-1":
              nextStepTutorial.value = 1;
              intro2.nextStep();
              valueLocal.value = dataLocalStorage;
              localStorageFunSave(null, true, {
                name: nameLocalStorage,
                data: dataLocalStorage,
              });
              break;
            case "step-2":
              intro2.exit();
              valueLocal.value = dataLocalStorage;
              localStorageFunSave(null, true, {
                name: nameLocalStorage,
                data: dataLocalStorage,
              });
              break;
          }
        });
      });
    };
    // project project deliverables modal popUp
    const listStep11 = (nameLocalStorage: string, dataLocalStorage: string) => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".el-tabs__item.is-top.is-active"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-deliverables-modal">
                      <div>Halaman Informasi</div>
                      <div>Pada halaman ini berisi informasi deliverable dari proyek anda</div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "top",
            },
            {
              element: document.querySelectorAll(
                ".el-tabs__item.is-top"
              )[1] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-deliverables-modal-1">
                      <div >Halaman Approval</div>
                      <div>Pada halaman ini anda dapat menambahkan approval untuk deliverable anda</div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "top",
            },
          ],
        });
        intro2.start();
        const intro3 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",
          scrollToElement: false,
          steps: [
            {
              element: document.querySelectorAll(
                "#introjs-project-active-cost-modal-table-deliverable"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-deliverables-modal-2">
                      <div >Tambah Evidence</div>
                      <div>Klik disini untuk menambah evidence untuk deliverable proyek anda</div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
          ],
        });
        const doc = document.getElementsByClassName("el-drawer__body")[0];
        funDocument(
          ".introjs-project-edit-28#step-active-deliverables-modal"
        ).then((status) => {
          switch (status) {
            case "step-1":
              {
                nextStepTutorial.value = 1;
                intro2.nextStep();
              }
              break;
            case "step-2":
              intro2.exit();
              valueLocal.value = dataLocalStorage;
              localStorageFunSave(null, true, {
                name: nameLocalStorage,
                data: dataLocalStorage,
              });
              break;
          }
        });

        intro2.onafterchange(() => {
          if (nextStepTutorial.value === 1) {
            funDocument(
              ".introjs-project-edit-28#step-active-deliverables-modal-1",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  doc.scrollTop = doc.scrollHeight;
                  intro2.exit();

                  setTimeout(() => {
                    nextStepTutorial.value = 2;
                    intro3.start();
                    funDocument(
                      ".introjs-project-edit-28#step-active-deliverables-modal-2"
                    ).then((status) => {
                      switch (status) {
                        case "step-1":
                          {
                            intro3.nextStep();
                            localStorageFunSave(null, true, {
                              name: nameLocalStorage,
                              data: dataLocalStorage,
                            });
                          }
                          break;
                        case "step-2":
                          intro3.exit();
                          valueLocal.value = dataLocalStorage;
                          localStorageFunSave(null, true, {
                            name: nameLocalStorage,
                            data: dataLocalStorage,
                          });
                          break;
                      }
                    });
                  }, 1000);
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  break;
              }
            });
          }
        });
      });
    };
    // project project deliverables modal popUp update
    const listStep12 = (nameLocalStorage: string, dataLocalStorage: string) => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-project-active-deliverables-modal-update"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-deliverables-modal">
                      <div>Riwayat Update</div>
                      <div>Klik disini untuk melihat riwayat update / perubahan pada deliverable proyek anda</div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
          ],
        });
        intro2.start();

        funDocument(
          ".introjs-project-edit-28#step-active-deliverables-modal"
        ).then((status) => {
          switch (status) {
            case "step-1":
              {
                nextStepTutorial.value = 1;
                intro2.nextStep();
                valueLocal.value = dataLocalStorage;
                localStorageFunSave(null, true, {
                  name: nameLocalStorage,
                  data: dataLocalStorage,
                });
              }
              break;
            case "step-2":
              intro2.exit();
              valueLocal.value = dataLocalStorage;
              localStorageFunSave(null, true, {
                name: nameLocalStorage,
                data: dataLocalStorage,
              });
              break;
          }
        });
      });
    };

    //
    // project list
    //

    // project list partner
    const listStep13 = (nameLocalStorage: string, dataLocalStorage: string) => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",
          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-project-active-update-partner"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-update-partner">
                      <div>Menambahkan Customer baru</div>
                      <div>Klik disini untuk menambahkan Customer baru untuk proyek anda</div>
                     <div>
                      <div class="progress-bar">
                        <progress value="25" max="100"></progress>
                      </div>
                      <div>1 of 4</div>
                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
            {
              element: document.querySelectorAll(
                ".introjs-project-active-update-partner-1"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-update-partner-1">
                      <div>Lihat Dokumen Customer atau Partner</div>
                      <div>Klik di sini untuk melihat dokumen yang telah diunggah pada proyek anda</div>
                     <div>
                     <div class="progress-bar">
                        <progress value="50" max="100"></progress>
                      </div>
                      <div>2 of 4</div>
                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
            {
              element: document.querySelectorAll(
                ".introjs-project-active-update-partner-2"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-update-partner-2">
                      <div>Hapus Customer</div>
                      <div>Klik disini untuk menghapus Customer yang ada pada proyek anda</div>
                     <div>
                     <div class="progress-bar">
                        <progress value="75" max="100"></progress>
                      </div>
                      <div>3 of 4</div>
                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
            {
              element: document.querySelectorAll(
                ".introjs-project-active-update-partner-3"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-update-partner-3">
                      <div>Update Customer</div>
                      <div>Klik disini untuk memperbaharui data customer pada proyek Anda</div>
                     <div>
                     <div class="progress-bar">
                        <progress value="100" max="100"></progress>
                      </div>
                      <div>4 of 4</div>
                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
          ],
        });
        intro2.start();

        funDocument(".introjs-project-edit-28#step-active-update-partner").then(
          (status) => {
            switch (status) {
              case "step-1":
                {
                  nextStepTutorial.value = 1;
                  intro2.nextStep();
                }
                break;
              case "step-2":
                intro2.exit();
                valueLocal.value = dataLocalStorage;
                localStorageFunSave(null, true, {
                  name: nameLocalStorage,
                  data: dataLocalStorage,
                });
                break;
            }
          }
        );
        intro2.onafterchange(() => {
          if (nextStepTutorial.value === 1) {
            funDocument(
              ".introjs-project-edit-28#step-active-update-partner-1",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 2;
                  intro2.nextStep();
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  break;
              }
            });
          } else if (nextStepTutorial.value === 2) {
            funDocument(
              ".introjs-project-edit-28#step-active-update-partner-2",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 3;
                  intro2.nextStep();
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  break;
              }
            });
          } else if (nextStepTutorial.value === 3) {
            funDocument(
              ".introjs-project-edit-28#step-active-update-partner-3",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  intro2.nextStep();
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  break;
              }
            });
          }
        });
      });
    };
    // project list partner add
    const listStep13V2 = (
      nameLocalStorage: string,
      dataLocalStorage: string
    ) => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",
          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-project-active-update-partner"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-active-update-partner">
                      <div>Menambahkan Customer baru</div>
                      <div>Klik disini untuk menambahkan Customer baru untuk proyek anda</div>
                     <div>
                      <div class="progress-bar">
                        <progress value="25" max="100"></progress>
                      </div>
                      <div>1 of 4</div>
                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
          ],
        });
        intro2.start();

        funDocument(".introjs-project-edit-28#step-active-update-partner").then(
          (status) => {
            switch (status) {
              case "step-1":
                {
                  nextStepTutorial.value = 1;
                  intro2.nextStep();
                }
                break;
              case "step-2":
                intro2.exit();
                valueLocal.value = dataLocalStorage;
                localStorageFunSave(null, true, {
                  name: nameLocalStorage,
                  data: dataLocalStorage,
                });
                break;
            }
          }
        );
        intro2.onafterchange(() => {
          if (nextStepTutorial.value === 1) {
            funDocument(
              ".introjs-project-edit-28#step-active-update-partner-1",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 2;
                  intro2.nextStep();
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  break;
              }
            });
          } else if (nextStepTutorial.value === 2) {
            funDocument(
              ".introjs-project-edit-28#step-active-update-partner-2",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 3;
                  intro2.nextStep();
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  break;
              }
            });
          } else if (nextStepTutorial.value === 3) {
            funDocument(
              ".introjs-project-edit-28#step-active-update-partner-3",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  intro2.nextStep();
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  break;
              }
            });
          }
        });
      });
    };
    // project list partner add project
    const listStep14 = (nameLocalStorage: string, dataLocalStorage: string) => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",
          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-project-active-update-partner-add-project"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-partner-add-project">
                      <div>Isi form untuk menambahkan customer atau partner baru</div>

                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
          ],
        });
        intro2.start();

        funDocument(".introjs-project-edit-28#step-partner-add-project").then(
          (status) => {
            switch (status) {
              case "step-1":
                {
                  intro2.nextStep();
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                }
                break;
              case "step-2":
                intro2.exit();
                valueLocal.value = dataLocalStorage;
                localStorageFunSave(null, true, {
                  name: nameLocalStorage,
                  data: dataLocalStorage,
                });
                break;
            }
          }
        );
      });
    };
    // project list partner tabs
    const listStep15 = (nameLocalStorage: string, dataLocalStorage: string) => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",
          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-project-active-update-partner-add-project"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-partner-add-project">
                      <div>Isi form untuk menambahkan customer atau partner baru</div>

                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
          ],
        });
        intro2.start();

        funDocument(".introjs-project-edit-28#step-partner-add-project").then(
          (status) => {
            switch (status) {
              case "step-1":
                {
                  intro2.nextStep();
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                }
                break;
              case "step-2":
                intro2.exit();
                valueLocal.value = dataLocalStorage;
                localStorageFunSave(null, true, {
                  name: nameLocalStorage,
                  data: dataLocalStorage,
                });
                break;
            }
          }
        );
      });
    };
    const funStepIntro = (varnew) => {
      const funDocumentDetail = (name?: string, status?, data?) => {
        switch (name) {
          case "detail-active-table":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-active-detail"
                );
                if (!save?.includes("project-active")) {
                  if (!save2?.includes("project-active-table")) {
                    setTimeout(() => {
                      listStep();
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "project-information":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-active-detail"
                );
                if (!save?.includes("project-active")) {
                  if (!save2?.includes("project-information")) {
                    setTimeout(() => {
                      listStep2();
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "project-partner":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-active-detail"
                );
                if (!save?.includes("project-active")) {
                  if (!save2?.includes("project-partner")) {
                    setTimeout(() => {
                      listStep3("project-active-detail", "project-partner");
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "project-deliverables":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-active-detail"
                );
                if (!save?.includes("project-active")) {
                  if (!save2?.includes("project-deliverables")) {
                    setTimeout(() => {
                      listStep4(
                        "project-active-detail",
                        "project-deliverables",
                        data
                      );
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "project-deliverables-gantt-chart":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-active-detail"
                );
                if (!save?.includes("project-active")) {
                  if (!save2?.includes("project-deliverables-gantt-chart")) {
                    setTimeout(() => {
                      listStep10V2(
                        "project-active-detail",
                        "project-deliverables-gantt-chart"
                      );
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "project-deliverables-modal":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-active-detail"
                );
                if (!save?.includes("project-active")) {
                  if (!save2?.includes("project-deliverables-modal")) {
                    setTimeout(() => {
                      listStep11(
                        "project-active-detail",
                        "project-deliverables-modal"
                      );
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "project-terms-of-payment":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-active-detail"
                );
                if (!save?.includes("project-active")) {
                  if (!save2?.includes("project-terms-of-payment")) {
                    setTimeout(() => {
                      listStep5(
                        "project-active-detail",
                        "project-terms-of-payment"
                      );
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "project-document":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-active-detail"
                );
                if (!save?.includes("project-active")) {
                  if (!save2?.includes("project-document")) {
                    setTimeout(() => {
                      listStep6("project-active-detail", "project-document");
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "project-isu-and-tindak-lanjut":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-active-detail"
                );
                if (!save?.includes("project-active")) {
                  if (!save2?.includes("project-isu-and-tindak-lanjut")) {
                    setTimeout(() => {
                      listStep7(
                        "project-active-detail",
                        "project-isu-and-tindak-lanjut"
                      );
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "project-cost":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-active-detail"
                );
                if (!save?.includes("project-active")) {
                  if (!save2?.includes("project-cost")) {
                    setTimeout(() => {
                      listStep8("project-active-detail", "project-cost");
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "project-cost-modal":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-active-detail"
                );
                if (!save?.includes("project-active")) {
                  if (!save2?.includes("project-cost-modal")) {
                    setTimeout(() => {
                      listStep9("project-active-detail", "project-cost-modal");
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "project-cost-modal-table":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-active-detail"
                );
                if (!save?.includes("project-active")) {
                  if (!save2?.includes("project-cost-modal-table")) {
                    setTimeout(() => {
                      listStep10(
                        "project-active-detail",
                        "project-cost-modal-table"
                      );
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "project-deliverables-modal-update":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-active-detail"
                );
                if (!save?.includes("project-active")) {
                  if (!save2?.includes("project-deliverables-modal-update")) {
                    const doc = document.querySelectorAll(".px-2.hd-view")[0];
                    const doc2 =
                      document.querySelectorAll(".el-drawer__body")[0];
                    if (doc.scrollTop) {
                      doc.scrollTop = doc?.scrollHeight;
                      doc2.scrollTop = doc2?.scrollHeight;
                    }

                    setTimeout(() => {
                      listStep12(
                        "project-active-detail",
                        "project-deliverables-modal-update"
                      );
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "project-active-update-partner":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-active-detail"
                );
                if (!save?.includes("project-active")) {
                  if (!save2?.includes("project-active-update-partner")) {
                    setTimeout(() => {
                      listStep13(
                        "project-active-detail",
                        "project-active-update-partner"
                      );
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "project-active-update-partner-2":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-active-detail"
                );
                if (!save?.includes("project-active")) {
                  if (!save2?.includes("project-active-update-partner-2")) {
                    setTimeout(() => {
                      listStep13V2(
                        "project-active-detail",
                        "project-active-update-partner-2"
                      );
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "project-active-partner-add-project-modal":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-active-detail"
                );
                if (!save?.includes("project-active")) {
                  if (
                    !save2?.includes("project-active-partner-add-project-modal")
                  ) {
                    setTimeout(() => {
                      listStep14(
                        "project-active-detail",
                        "project-active-partner-add-project-modal"
                      );
                    }, 1000);
                  }
                }
              });
            }
            break;
        }
      };
      const checkStep = (name?: string) => {
        return localStorageFun("project-active-detail")?.includes(name)
          ? true
          : valueLocal.value === name;
      };

      if (
        varnew?.name === "project-active/load-table" &&
        varnew?.bool === true
      ) {
        if (varnew?.data?.length > 0) {
          funDocumentDetail("detail-active-table");
        }
      } else if (
        varnew?.name === "project-active/detail-project-active-aside" &&
        varnew?.bool === true
      ) {
        saveValue.document = "";
        saveValue.cost = "";
        saveValue.isu = "";
        saveValue.deliverables = "";
        if (varnew?.data === "informasi") {
          // funDocumentDetail("project-information");
        } else if (varnew?.data === "partner") {
          funDocumentDetail("project-partner");
        } else if (varnew?.data === "deliverables") {
          // 1  check deliverables aside
          saveValue.deliverables = "deliverables";
        } else if (varnew?.data === "top") {
          funDocumentDetail("project-terms-of-payment");
        } else if (varnew?.data === "dokumen") {
          // 1  check documents aside
          saveValue.document = "dokumen";
          if (saveValue.documentLoadApi) {
            funDocumentDetail("project-document");
          }
        } else if (varnew?.data === "isu") {
          // 1  check isu aside
          saveValue.isu = "isu";
          if (saveValue.isuLoadApi) {
            funDocumentDetail("project-isu-and-tindak-lanjut");
          }
        } else if (varnew?.data === "cost") {
          // 1  check cost aside
          saveValue.cost = "cost";
          if (saveValue.costLoadApi) {
            funDocumentDetail("project-cost");
          }
        }
      } else if (
        varnew?.name === "project-active/detail-deliverables-tabs" &&
        varnew?.bool === true
      ) {
        // 2  check deliverables  tabs
        if (saveValue.deliverables === "deliverables" && varnew?.bool) {
          saveValue.deliverablesTabs = varnew?.bool;
        }
      } else if (
        varnew?.name === "project-active/detail-deliverables-tabs-load-table" &&
        varnew?.bool === true
      ) {
        // 3  check deliverables load api
        saveValue.deliverablesTabsValue += 1;
        if (
          routeHash("deliverables") &&
          saveValue.deliverablesTabs === true &&
          varnew?.data &&
          saveValue.deliverablesTabsValue === 2
        ) {
          funDocumentDetail("project-deliverables");
          saveValue.deliverablesTabsValue = 0;
        }
      } else if (
        varnew?.name === "project-active/detail-documents-load-table" &&
        varnew?.bool === true
      ) {
        // 2  check documents load api

        saveValue.documentLoadApi = false;
        if (routeHash("dokumen") && varnew?.data?.length > 0) {
          saveValue.documentLoadApi = true;
          funDocumentDetail("project-document");
        } else if (varnew?.data?.length > 0) {
          saveValue.documentLoadApi = true;
        }
      } else if (
        varnew?.name === "project-active/detail-isu-load-table" &&
        varnew?.bool === true
      ) {
        // 2  check isu load api

        saveValue.isuLoadApi = false;
        if (routeHash("isu") && varnew?.data?.length > 0) {
          saveValue.isuLoadApi = true;
          funDocumentDetail("project-isu-and-tindak-lanjut");
        } else if (varnew?.data?.length > 0) {
          saveValue.isuLoadApi = true;
        }
      } else if (
        varnew?.name === "project-active/detail-cost-load-table" &&
        varnew?.bool === true
      ) {
        // 2  check cost load api
        saveValue.costLoadApi = false;
        if (routeHash("cost") && varnew?.data?.length > 0) {
          saveValue.costLoadApi = true;
          funDocumentDetail("project-cost");
        } else if (varnew?.data?.length > 0) {
          saveValue.costLoadApi = true;
        }
      } else if (
        varnew?.name === "project-active/load-modal-cost" &&
        varnew?.bool === true
      ) {
        if (routeHash("cost")) {
          funDocumentDetail("project-cost-modal");
        }
      } else if (
        varnew?.name === "project-active/load-modal-cost-table" &&
        varnew?.bool === true
      ) {
        if (routeHash("cost")) {
          funDocumentDetail("project-cost-modal-table");
        }
      } else if (
        varnew?.name === "project-active/load-api-project-information" &&
        varnew?.bool === true
      ) {
        if (routeHash("informasi")) {
          funDocumentDetail("project-information");
        }
      } else if (
        varnew?.name === "project-active/project-detail-deliverables-modal" &&
        varnew?.bool === true
      ) {
        if (routeHash("deliverables")) {
          funDocumentDetail("project-deliverables-modal");
        }
      } else if (
        varnew?.name === "project-active/detail-deliverables-update" &&
        varnew?.bool === true
      ) {
        if (routeHash("deliverables")) {
          funDocumentDetail("project-deliverables-modal-update");
        }
      } else if (
        varnew?.name ===
          "project-active/detail-project-active-aside-menu-update" &&
        varnew?.bool === true
      ) {
        // 1  check project-active-update-partner aside
        saveValue.document =
          varnew?.data?.split("/")[route.fullPath?.split("/").length - 1];
      } else if (
        varnew?.name ===
          "project-active/detail-project-active-aside-menu-update/load-api-partner" &&
        varnew?.bool === true
      ) {
        // 2  check project-active-update-partner aside

        if (varnew?.data?.length > 0 && routeHash("partner", "project-list")) {
          nextTick(() => {
            const doc = document.querySelectorAll(
              ".introjs-project-active-update-partner-1"
            );

            if (doc?.length > 0) {
              funDocumentDetail("project-active-update-partner");
            }
          });
        } else {
          nextTick(() => {
            console.log("check-data");
            funDocumentDetail("project-active-update-partner-2");
          });
        }
      } else if (
        varnew?.name ===
          "project-active/detail-project-active-aside-menu-update/add-project-modal" &&
        varnew?.bool === true
      ) {
        funDocumentDetail("project-active-partner-add-project-modal");
        console.log("check-data-load");
      }
    };

    watch(
      comFilterStep,
      (varnew) => {
        if (localStorageFun("project-add-aside") !== "/project/CZONM") {
          if (route.fullPath === "/project/J7YEV") {
            if (varnew?.name === "project-active/load-table") {
              funStepIntro({ ...varnew });
            }
          } else if (
            varnew?.name === "project-active/detail-project-active-aside"
          ) {
            funStepIntro({ ...varnew });
          } else if (
            varnew?.name ===
            "project-active/detail-project-active-aside-menu-update"
          ) {
            funStepIntro({ ...varnew });
          } else if (
            varnew?.name === "project-active/detail-deliverables-tabs"
          ) {
            funStepIntro({ ...varnew });
          } else if (
            varnew?.name ===
            "project-active/detail-deliverables-tabs-load-table"
          ) {
            funStepIntro({ ...varnew });
          } else if (
            varnew?.name === "project-active/detail-documents-load-table"
          ) {
            funStepIntro({ ...varnew });
          } else if (varnew?.name === "project-active/detail-isu-load-table") {
            funStepIntro({ ...varnew });
          } else if (varnew?.name === "project-active/detail-cost-load-table") {
            funStepIntro({ ...varnew });
          } else if (varnew?.name === "project-active/load-modal-cost") {
            funStepIntro({ ...varnew });
          } else if (varnew?.name === "project-active/load-modal-cost-table") {
            funStepIntro({ ...varnew });
          } else if (
            varnew?.name === "project-active/load-api-project-information"
          ) {
            funStepIntro({ ...varnew });
          } else if (
            varnew?.name === "project-active/project-detail-deliverables-modal"
          ) {
            funStepIntro({ ...varnew });
          } else if (
            varnew?.name === "project-active/detail-deliverables-update"
          ) {
            funStepIntro({ ...varnew });
          } else if (
            varnew?.name ===
            "project-active/detail-project-active-aside-menu-update/load-api-partner"
          ) {
            funStepIntro({ ...varnew });
          } else if (
            varnew?.name ===
            "project-active/detail-project-active-aside-menu-update/add-project-modal"
          ) {
            funStepIntro({ ...varnew });
          }
        }
      },
      { deep: true, immediate: true }
    );

    return {
      show,
    };
  },
};
