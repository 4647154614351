import { useRouter } from "vue-router";
// import { detectSubDomain } from "./helps";

const router = useRouter();

export default (): string => {

  // local endpoint
  return process.env.VUE_APP_API_URL ?? "";
};
