import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  createWebHashHistory,
} from 'vue-router';
import store from '@/store';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { ResourceEnums } from '@/core/config/ResourceEnums';
import JwtService from '@/core/services/JwtService';
import { detectSubDomain, hideCaptchaBadge } from '@/core/helpers/helps';
import { redirectLogin } from '@/core/helpers/enum';
import { dashboard_map_routes } from '@/router/modules/dashboard';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/dashboard-map',
    component: () =>
      import(/* webpackChunkName: "layout" */ '@/layout/Layout.vue'),
    meta: {
      allowGuest: true,
      requiresToken: true,
    },
    children: [
      {
        path: '/master-data',
        name: 'master-data',
        redirect: '/master-data/user',
        component: () => import('@/views/master-data/MasterData.vue'),
        meta: {
          allowGuest: false,
          requiresToken: true,
        },
        children: [
          {
            path: '/master-data/users/user-listing',
            name: 'master-data-user-listing',
            component: () => import('@/views/master-data/UserListing.vue'),
            meta: {
              allowGuest: false,
              requiresToken: true,
              resource: 'master-data',
            },
          },
          {
            path: '/master-data/roles/role-listing',
            name: 'master-data-role-listing',
            component: () => import('@/views/master-data/RoleListing.vue'),
            meta: {
              allowGuest: false,
              requiresToken: true,
              resource: 'master-data',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/Profile.vue'),
    redirect: '/profile/detail',
    meta: {
      allowGuest: true,
      requiresToken: true,
    },
    children: [
      {
        path: '/profile/detail',
        name: 'profile-detail',
        component: () => import('@/views/profile/ProfileDetail.vue'),
        meta: {
          allowGuest: true,
          requiresToken: true,
        },
      },
      {
        path: '/profile/edit',
        name: 'profile-edit',
        component: () => import('@/views/profile/ProfileEdit.vue'),
        meta: {
          allowGuest: true,
          requiresToken: true,
        },
      },
      {
        path: '/profile/password',
        name: 'profile-password',
        component: () => import('@/views/profile/ChangePassword.vue'),
        meta: {
          allowGuest: true,
          requiresToken: true,
        },
      },
    ],
  },
  {
    path: '/assets/list',
    name: 'List of Assets',
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ '@/layout/LayoutListAssets.vue'
      ),
    meta: {
      allowGuest: true,
      requiresToken: true,
      title: 'Skills - MyApp'
    },
    children: [
      {
        path: '',
        name: 'assetlist',
        component: () =>
          import(
            /* webpackChunkName: "approval-list" */ '@/views/assets/list.vue'
          ),
        meta: {
          allowGuest: false,
          requiresToken: true,
          resource: 'approval',
        },
      },
    ],
  },
  {
    path: '/assets',
    name: 'assets',
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ '@/layout/LayoutAssetsViews.vue'
      ),
    meta: {
      allowGuest: true,
      requiresToken: true,
    },
    children: [
      {
        path: '',
        name: 'list_assets',
        component: () =>
          import(
            /* webpackChunkName: "approval-list" */ '@/views/assets/index.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: true,
          resource: 'approval',
        },
      },
      {
        path: '/assets/create/:slug',
        name: 'informasi_assets',
        component: () =>
          import(
            /* webpackChunkName: "approval-list" */ '@/views/assets/assets_information.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: true,
          resource: 'approval',
        },
      },
      {
        path: '/assets/views/:id/:slug',
        name: 'assetdetail',
        component: () =>
          import(
            /* webpackChunkName: "approval-list" */ '@/views/assets/views.vue'
          ),
        meta: {
          allowGuest: false,
          requiresToken: true,
          resource: 'approval',
        },
      },
      {
        path: '/assets/update/:id/:slug',
        name: 'update',
        component: () =>
          import(
            /* webpackChunkName: "approval-list" */ '@/views/assets/update-asset.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: true,
          resource: 'approval',
        },
      },
    ],
  },
  {
    path: '/approval',
    name: 'approval',
    redirect: { name: 'approval-list' },
    component: () =>
      import(
        /* webpackChunkName: "approval" */ '@/layout/NoAsideNoToolbarLayout.vue'
      ),
    meta: {
      allowGuest: true,
      requiresToken: true,
    },
    children: [
      {
        path: 'list',
        name: 'approval-list',
        component: () =>
          import(
            /* webpackChunkName: "approval-list" */ '@/views/approval/ApprovalList.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: true,
          resource: 'approval',
        },
      },
    ],
  },
  {
    path: '/report',
    name: 'report',
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ '@/layout/LayoutReportAsset.vue'
      ),
    meta: {
      allowGuest: true,
      requiresToken: true,
    },
    children: [
      {
        path: '',
        name: 'report',
        component: () =>
          import(
            /* webpackChunkName: "approval-list" */ '@/views/report/index.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: true,
        },
      },
    ],
  },
  {
    path: '/playground-coordinate',
    name: 'playground',
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ '@/layout/NoAsideNoToolbarLayout.vue'
      ),
    meta: {
      allowGuest: true,
      requiresToken: true,
    },
    children: [
      {
        path: '',
        name: 'playground',
        component: () =>
          import(
            /* webpackChunkName: "approval-list" */ '@/views/assets/playground.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: true,
        },
      },
    ],
  },
  {
    path: '/admin',
    name: 'admin',
    meta: {
      allowGuest: true,
      requiresToken: true,
    },
    component: () =>
      import(/* webpackChunkName: "setting" */ '@/layout/LayoutAssetsViews.vue'),
      children: [
        {
          path: '/admin/history',
          name: 'history-admin',
          component: () =>
            import(
              /* webpackChunkName: "general-setting" */ '@/views/admin/index.vue'
            ),
          meta: {
            allowGuest: true,
            requiresToken: true,
            resource: 'admin',
          },
        },
        {
          path: '/admin/history-activity',
          name: 'history-activity',
          component: () =>
            import(
              /* webpackChunkName: "general-setting" */ '@/views/admin/historyActivity.vue'
            ),
          meta: {
            allowGuest: true,
            requiresToken: true,
            resource: 'admin',
          },
        },
        {
          path: '/admin/coordinate',
          name: 'monitoring-coordinate',
          component: () =>
            import(
              /* webpackChunkName: "general-setting" */ '@/views/admin/coordinateMonitoring.vue'
            ),
          meta: {
            allowGuest: true,
            requiresToken: true,
            resource: 'admin',
          },
        },
      ]
  },
  {
    path: '/setting',
    name: 'setting',
    redirect: '/setting/organization/general-setting',
    meta: {
      allowGuest: true,
      requiresToken: true,
    },
    component: () =>
      import(/* webpackChunkName: "setting" */ '@/layout/Setting.vue'),
    children: [
      {
        path: '/setting/organization/general-setting',
        name: 'general-setting',
        component: () =>
          import(
            /* webpackChunkName: "general-setting" */ '@/views/setting/organization/GeneralSetting.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: true,
          resource: 'general-setting',
        },
      },
      {
        path: '/setting/organization/members',
        name: 'members',
        component: () =>
          import(
            /* webpackChunkName: "members" */ '@/views/setting/organization/Members.vue'
          ),
        children: [
          {
            path: '/setting/organization/members/:id/verify',
            name: 'verify-member',
            component: () =>
              import(
                /* webpackChunkName: "verify-member" */ '@/components/modals/VerifyMemberDrawer.vue'
              )
          }
        ],
        meta: {
          allowGuest: true,
          requiresToken: true,
          resource: 'members',
        },
      },
      {
        path: '/setting/organization/role-and-permission',
        name: 'setting-user-role',
        component: () =>
          import(
            /* webpackChunkName: "role-and-permission" */ '@/views/setting/organization/RoleAndPermission.vue'
          ),
        meta: {
          allowGuest: false,
          requiresToken: true,
          resource: 'role-and-permission',
        },
      },
      {
        path: '/setting/master-data/template',
        name: 'master-data-template',
        component: () =>
          import(
            /* webpackChunkName: "master-data-template" */ '@/views/setting/master-data/Template.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: true,
          resource: 'master-data-template',
        },
      },
      {
        path: '/setting/master-data/dashboard-link',
        name: 'master-data-dashboard-link',
        component: () =>
          import(
            /* webpackChunkName: "master-data-dashboard-link" */ '@/views/setting/master-data/DashboardLink.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: true,
          resource: 'master-data-dashboard-link',
        },
      },
      {
        path: '/setting/category-value/:id',
        name: 'category-value',
        component: () =>
          import(
            /* webpackChunkName: "master-category-value" */ '@/views/setting/asset-category-value/AssetCategoryValue.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: true,
        },
      },
      {
        path: '/setting/environment-settings/channel',
        name: 'channel-environtment',
        component: () =>
          import(
            /* webpackChunkName: "master-earning-status" */ '@/views/setting/environtment-settings/Channel.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: true,
        },
      },
      {
        path: '/setting/environment-settings/workspace',
        name: 'workspace-environtment',
        component: () =>
          import(
            /* webpackChunkName: "master-earning-status" */ '@/views/setting/environtment-settings/Workspace.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: true,
        },
      },
      {
        path: '/setting/asset-category/category',
        name: 'asset-category',
        component: () =>
          import(
            /* webpackChunkName: "master-earning-status" */ '@/views/setting/asset-category/category.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: true,
        },
      },
      {
        path: '/setting/asset-category/currency',
        name: 'category-currency',
        component: () =>
          import(
            /* webpackChunkName: "master-earning-status" */ '@/views/setting/asset-category/Currency.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: true,
          pageName: 'currencyPage',
        },
      },
      {
        path: '/setting/asset-category/document',
        name: 'category-document',
        component: () =>
          import(
            /* webpackChunkName: "master-earning-status" */ '@/views/setting/asset-category/Document.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: true,
          pageName: 'documentPage',
        },
      },
      {
        path: '/setting/admin/access-token',
        name: 'admin-access-token',
        component: () =>
          import(
            /* webpackChunkName: "master-earning-status" */ '@/views/setting/admin-settings/accessToken.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: true,
          pageName: 'adminPage',
        },
      },
    ],
  },
  {
    path: '/',
    component: () =>
      import(
        /* webpackChunkName: "auth" */ '@/components/page-layouts/Auth.vue'
      ),
    meta: {
      allowGuest: true,
      requiresToken: false,
    },
    children: [
      {
        path: '/sign-in',
        name: 'sign-in',
        component: () =>
          import(
            /* webpackChunkName: "sign-in" */ '@/views/crafted/authentication/basic-flow/SignIn.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: false,
        },
      },
      {
        path: '/sign-up',
        name: 'sign-up',
        component: () =>
          import(
            /* webpackChunkName: "sign-up" */ '@/views/crafted/authentication/basic-flow/SignUp.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: false,
        },
      },
      {
        path: '/request-password-reset',
        name: 'request-password-reset',
        component: () =>
          import(
            '@/views/crafted/authentication/basic-flow/RequestPasswordReset.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: false,
        },
      },
      {
        path: '/reset-password',
        name: 'reset-password',
        component: () =>
          import('@/views/crafted/authentication/basic-flow/PasswordReset.vue'),
        meta: {
          allowGuest: true,
          requiresToken: false,
        },
      },
      {
        path: '/verify-login',
        name: 'verify-login',
        component: () =>
          import(
            /* webpackChunkName: "verify-login" */ '@/views/crafted/authentication/basic-flow/VerifyLogin.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: false,
        },
      },
      {
        path: '/email-verification',
        name: 'email-verification',
        component: () =>
          import(
            /* webpackChunkName: "email-verification" */ '@/views/crafted/authentication/basic-flow/EmailVerification.vue'
          ),
        meta: {
          allowGuest: true,
          requiresToken: false,
        },
      },
    ],
  },
  {
    path: '/403',
    name: '403',
    component: () =>
      import(
        /* webpackChunkName: "error-403" */ '@/views/crafted/authentication/Error403.vue'
      ),
    meta: {
      allowGuest: true,
      requiresToken: false,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () =>
      import(
        /* webpackChunkName: "error-404" */ '@/views/crafted/authentication/Error404.vue'
      ),
    meta: {
      allowGuest: true,
      requiresToken: false,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
  ...dashboard_map_routes,
];

const router = createRouter({
  history: process.env.IS_ELECTRON
    ? createWebHashHistory()
    : createWebHistory(),
  routes,
  scrollBehavior: (to) => {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            el: to.hash,
            behavior: 'smooth',
            top: 120,
          });
        }, 50);
      });
    }
  },
});

function canAccess(user, menus, route): boolean {
  const foundMenu = menus?.find((menu) => menu.code == route.name) ?? null
  const userProfile = store.getters.getUserProfile
  if(userProfile._id == '630c7ec0392b52b63e92c5b2') return true;
  if (!foundMenu || !user) return false;
  return Boolean(foundMenu.is_active);
}

router.beforeEach(async (to, from, next) => {
  if(JwtService.getToken()) {
    hideCaptchaBadge('hidden')
  }
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change if no hash
  if (!to.hash) {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  }

  // check for user
  const user = store.getters.getUserProfile;
  const menus = store.getters.getRole.page_permissions;
  // if (!user || !menus) {
  //   await store.dispatch(Actions.VERIFY_AUTH);
  // }

  // check token if needed
  if (to.meta.requiresToken) {
    if (!JwtService.getToken()) {
      next({ name: 'sign-in' });
      return;
    }
  }

  if (!to.meta.allowGuest) {
    next()
    // if (canAccess(user, menus, to)) {
    //   next();
    // } else {
    //   next({ name: '403' });
    // }
  } else {
    next();
  }
});

export default router;
