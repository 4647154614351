
import { defineComponent, ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import KTTopbar from "@/layout/header/Topbar.vue";
import KTMenu from "@/layout/header/Menu.vue";
import moment from "moment";
import { detectSubDomain } from "@/core/helpers/helps";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
    KTMenu,
  },
  setup() {
    const route:any = useRoute();
    const realtimeDateLg = ref();
    const realtimeDateMd = ref();

    const detectPath = computed(() => {
      return route.path;
    });

    onMounted(() => {
      dateTime();
    });

    const dateTime = () => {
      if (route.name === "dashboard-yodya") {
        realtimeDateLg.value.textContent = moment().format(
          "dddd, MMMM DD, YYYY HH:mm:ss"
        );
        realtimeDateMd.value.textContent = moment().format(
          "dddd, MMMM DD, YYYY HH:mm:ss"
        );
      }

      setTimeout(() => {
        dateTime();
      }, 1000);
    };

    return {
      route,
      headerWidthFluid,
      dateTime,
      headerLeft,
      realtimeDateLg,
      realtimeDateMd,
      asideDisplay,
      detectSubDomain,
      detectPath,
    };
  },
});
