import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { default as i18n } from "@/core/plugins/i18n";

/**
 * Sets current page breadcrumbs
 * @param {string} pageTitle Current page title
 * @param {Array<string>} breadcrumbs Current page breadcrumbs
 */
export const setCurrentPageBreadcrumbs = (
  pageTitle: string,
  breadcrumbs: any
): void => {
  const paths = breadcrumbs.map((item) => { 
    if(item.title) return item
    else return { title: item, path: '' } })
  store.dispatch(Actions.SET_BREADCRUMB_ACTION, {
    title: pageTitle,
    pageBreadcrumbPath: paths,
  });
};

export const setCurrentPageBreadcrumbs2 = (
  pageTitle: string,
  breadcrumbs: Array<any>
): void => {
  store.dispatch(Actions.SET_BREADCRUMB_ACTION2, {
    title: pageTitle,
    paths: breadcrumbs,
  });
};

/**
 * Sets current page breadcrumbs
 * @param {string} title Current page title name
 */
export const setCurrentPageTitle = (title: string): void => {
  store.dispatch(Actions.SET_BREADCRUMB_ACTION, {
    title: title,
  });
};

export const setCurrentPageTitle2 = (title: string): void => {
  store.dispatch(Actions.SET_BREADCRUMB_ACTION2, {
    title: title,
  });
};

export const breadcrumbMapper = (routes: any): void => {
  const mapped = routes.matched.map((item) => {
    const id = routes.params.id
    const status = routes.params.status
    const splitTitle = item.path.split('/').pop()

    return {
      title: splitTitle === ':id' || status ? checkPageTitle(id, item.name, item.status) : splitTitle.replaceAll('-', ' '),
      path: item.path,
      name: item.name
    }

  })

  setCurrentPageBreadcrumbs2(routes.name, mapped)
}

export const checkPageTitle = (title, name?, status?) => {
  const customPathTitle = [
    { alias: 'CZONM', title: i18n.global.t("projectListPage.textCandidate") },
    { alias: 'J7YEV', title: i18n.global.t('projectListPage.textActive') },
    { alias: 'U5JIT', title: i18n.global.t('projectListPage.textTechClosed') },
    { alias: 'HE04F', title: i18n.global.t('projectListPage.textClosed') },
    { alias: 'CWAZO', title: i18n.global.t('projectListPage.textDraft') },
    { alias: 'ZLUIW', title: i18n.global.t('projectListPage.textCancelled') },
    { alias: "project-add", title: i18n.global.t('common.create') },
    { alias: 'project-show', title: i18n.global.t('common.view') },
    { alias: 'project-edit', title: i18n.global.t('common.edit') },
    { alias: 'project-update', title: i18n.global.t('common.update') },
    { alias: 'project-detail', title: i18n.global.t('common.detail') },
  ]
  const found = customPathTitle.find((item) => item.alias === title || item.alias === name || item.alias === status)

  if (found) return found.title.replaceAll('-', ' ')
  return title.replaceAll('-', ' ')
}
