
import ApiService from "@/core/services/ApiService";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "../enums/StoreEnums";

const service =
  process.env.VUE_APP_NODE_ENV == "local"
    ? "/api/v1"
    : "/api/v1";

@Module
export default class AllMasterModule extends VuexModule {
  listAllMaster = [];

  get getListAllMaster() {
    return this.listAllMaster;
  }

  @Action
  [Actions.GET_ALL_MASTER_ASSET]() {
    return new Promise((resolve, reject) => {
      ApiService.get(`${service}/status-category-value`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_MASTER_ASSET, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Mutation
  [Mutations.SET_ALL_MASTER_ASSET](data) {
    this.listAllMaster = data;
  }
}
