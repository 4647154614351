import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { detectSubDomain } from "@/core/helpers/helps";
import { ITheme } from "@/core/data/theme";
import { ILanguage } from "@/store/modules/LanguageModule";

@Module
export default class GeneralSettingModule extends VuexModule {
  serviceName =
    process.env.VUE_APP_NODE_ENV === "local" ? "auth/api/v1" : "auth/api/v1";
  formThemeVal = null;
  formLangVal = null;
  isSubmitting = false;

  get getFormThemeVal() {
    return this.formThemeVal;
  }

  get getFormLangVal() {
    return this.formLangVal;
  }

  get gsIsSubmitting() {
    return this.isSubmitting;
  }

  @Mutation
  [Mutations.SET_GS_FORM_THEME](value) {
    this.formThemeVal = value;
  }

  @Mutation
  [Mutations.SET_GS_FORM_LANG](value) {
    this.formLangVal = value;
  }

  @Mutation
  [Mutations.SET_GS_SUBMITTING](isSubmitting) {
    this.isSubmitting = isSubmitting;
  }

  @Action
  [Actions.UPDATE_GS_FORM_LANG](val) {
    this.context.commit(Mutations.SET_GS_FORM_LANG, val);
  }

  @Action
  [Actions.UPDATE_GS_FORM_THEME](val) {
    this.context.commit(Mutations.SET_GS_FORM_THEME, val);
  }

  @Action
  [Actions.UPDATE_GS_SUBMITTING](isSubmitting) {
    this.context.commit(Mutations.SET_GS_SUBMITTING, isSubmitting);
  }

  @Action({ rawError: true })
  [Actions.UPDATE_GENERAL_SETTING]() {
    this.context.commit(Mutations.SET_GS_SUBMITTING, true);
    const themes = this.context.rootGetters.themeList;
    const langs = this.context.rootGetters.langList;
    const params = {
      lang: langs.map((lang: ILanguage) => {
        return {
          code: lang.code,
          is_default: lang.code === this.formLangVal,
        };
      }),
      theme: themes.map((theme: ITheme) => {
        return {
          code: theme.code,
          is_default: theme.code === this.formThemeVal,
        };
      }),
    };
    const subdomain = detectSubDomain();
    if (subdomain) {
      return ApiService.put(
        `${this.serviceName}/tenant/${subdomain || "sda"}`,
        "",
        params as any
      )
        .then(({ data }) => Promise.resolve(data))
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error);
          return Promise.reject(error);
        })
        .finally(() => this.context.commit(Mutations.SET_GS_SUBMITTING, false));
    } else {
      throw new Error("Invalid subdomain");
    }
  }
}
