import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import ElementPlus from "element-plus";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import i18n from "./core/plugins/i18n";
import images from "@/assets/ts/images.js";
import VueApexCharts from "vue3-apexcharts";
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import preventDoubleClick from './preventDoubleClick';

import "./core/plugins/keenthemes";
import "./core/plugins/prismjs";
import "bootstrap";
import "element-plus/lib/theme-chalk/display.css";
import "intro.js/introjs.css";
const app = createApp(App);
app.provide("$images", images);

ApiService.init(app);
if (JwtService.getToken()) ApiService.setHeader();

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(VueApexCharts);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

initInlineSvg(app);

app.config.errorHandler = () => null;
app.config.warnHandler = () => null;

// app.config.globalProperties.$messaging = firebaseMessaging;

// if (firebaseMessaging != "") {
//   firebaseMessaging.onMessage(function (payload) {
//     const notificationTitle = `${payload.data.title}`;
//     const notificationOptions = {
//       body: `${payload?.data?.body}`,
//       icon: "https://firebasestorage.googleapis.com/v0/b/yes-4-web.appspot.com/o/pontonos%2Ficons%2Fandroid-chrome-192x192.png?alt=media&token=35616a6b-5e70-43a0-9284--d780793fa076",
//     };
//     console.log("Message received. ", payload);
//     const notification = new Notification(
//       notificationTitle,
//       notificationOptions
//     );
//     notification.onclick = function (event) {
//       window.open(`/${payload.data.url}`);
//       event.preventDefault();
//       notification.close();
//     };
//     // ...
//   });
// }

// Initialize Firebase

// import { register } from "register-service-worker";
// // import { Actions, Mutations } from "./store/enums/StoreEnums";
// // import { setAllertError } from "./core/services/Alert";
// // import JwtMytensService from "./core/services/JwtMytensService";

// register("firebase-messaging-sw.js");
import { VueReCaptcha } from "vue-recaptcha-v3";
app.directive('prevent-double-click', preventDoubleClick);
app.use(VueReCaptcha, { 
  siteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
  loaderOptions:{autoHideBadge: false }
})
app.use(i18n);
app.mount("#app");
