import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

const service =
  process.env.VUE_APP_NODE_ENV == "local" ? "file/api/v1" : "file/api/v1";

export interface IProjectFileSearchQuery {
  filterFileName?: string;
  filterCategory?: string;
  filterProjectID?: string;
}

export interface IProjectFileCategory {
  id: string;
  code: string;
  category_name: string;
  created_at: string;
  updated_at: string;
}

export interface IProjectFile {
  id: string;
  code: string;
  filename: string;
  Path: string;
  URL: string;
  Mime_Type: string;
  TenantID: string;
  IssueID: string | null;
  Category: string;
  ProjectID: string;
  DeliverablesID: string;
  ActionPlanID: string | null;
  UserID: string | null;
  CustomerID: string | null;
  PartnerID: string | null;
  TopID: string | null;
  StackholderID: string | null;
  created_at: string;
  updated_at: string;
  file_size: string;
}

@Module
export default class FileModule extends VuexModule {
  @Action
  [Actions.GET_LIST_PROJECT_FILE](payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/project_file/show`, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_LIST_PROJECT_FILE_2](payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/project_file/list_project`, {
        params: { ...payload },
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.DOWNLOAD_SINGLE_PROJECT_FILE](file_id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`${service}/project_file/download/${file_id}`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_CATEGORY_FILE]() {
    return new Promise((resolve, reject) => {
      ApiService.get(`${service}/category_file/list`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.DELETE_ALL_PROJECT_FILE](payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `${service}/project_file/delete_all/${payload.charter_id}/${payload.category}`
      )
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.DELETE_PROJECT_FILE](file_id) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`${service}/project_file/${file_id}`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.SEARCH_PROJECT_FILE](query: IProjectFileSearchQuery) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/project_file/search`, {
        params: query,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.FILE_DOWNLOAD_ALL](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/project_file/download_all`, payload)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.FILE_UPLOAD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/project_file/insert`, payload)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_PROJECT_FILE_PARTNER](partnerID: string) {
    return new Promise((resolve, reject) => {
      ApiService.get(`${service}/project_file/delete?partnerID=${partnerID}`)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject(err);
        });
    });
  }
}
