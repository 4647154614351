import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, VuexModule } from "vuex-module-decorators";

const service =
  process.env.VUE_APP_NODE_ENV == "local"
    ? "approval/api/v1"
    : "approval/api/v1";

@Module
export default class ApprovalModule extends VuexModule {
  @Action
  [Actions.GET_APPROVAL_SHOW](approval_id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`${service}/approval`, approval_id)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.APPROVAL_USER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/approval/approval_user`, { params: payload })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.APPROVAL_ACTION](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/approval/action`, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.ADMIN_ACTIVITY_LOG, {
            action : 'ASSET.APPROVAL',
            data : {
              approval_detail : payload
            }
          })
          
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }
}
