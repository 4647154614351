import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
export interface ILanguage {
  id: string;
  code: string;
  lang: string;
  name: string;
  description: string;
  icon: string;
  deleted_at: Date | null;
  created_at: Date;
  updated_at: Date;
}
@Module
export default class LanguageModule extends VuexModule {
  serviceName =
    process.env.VUE_APP_NODE_ENV === "local" ? "auth/api/v1" : "auth/api/v1";
  loading = false;
  page = 1;
  itemsPerPage = 100;
  trash = 0;
  name = "";
  languages = Array<ILanguage>();

  @Mutation
  [Mutations.SET_LANGUAGE_LOADING](loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  [Mutations.SET_LANGUAGES](languages) {
    this.languages = languages;
  }

  get langIsLoading(): boolean {
    return this.loading;
  }

  get langQueryParams() {
    return {
      page: this.page,
      itemsPerPage: this.itemsPerPage,
      trash: this.trash,
    };
  }

  get langList(): Array<ILanguage> {
    return this.languages;
  }

  @Action({ rawError: true })
  [Actions.GET_LANGUAGE_LIST]() {
    this.context.commit(Mutations.SET_LANGUAGE_LOADING, true);
    const url = new URLSearchParams(this.context.getters.langQueryParams);
    return ApiService.get(`${this.serviceName}/language`, `?${url.toString()}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LANGUAGES, data.data.data);
        return Promise.resolve(data);
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR, error);
        return Promise.reject(error);
      })
      .finally(() =>
        this.context.commit(Mutations.SET_LANGUAGE_LOADING, false)
      );
  }
}
