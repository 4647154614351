
import {
  ref,
  getCurrentInstance,
  computed,
  watch,
  reactive,
  onMounted,
} from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Getters } from "@/store/enums/StoreTutorialEnums";

import IntroJs from "intro.js";
import * as storeTutorial from "@/store/enums/StoreTutorialEnums";
import { Tutorial } from "@/store/enums/interface_option/tutorial";

export default {
  setup() {
    // code start introjs-7

    const $instance = getCurrentInstance();
    const nextData = ref(0);
    const nextData2 = reactive({
      url: null,
      status: null,
    });
    const nextStepTutorial = ref(0);
    const show = ref(false);
    const route = useRoute();
    // const router = useRouter();

    let fullPath = ref("");
    const store = useStore();
    const comFilterStep = computed(() => store.getters[Getters.FILTER_STEP]);
    const localStorageFun = (name: string) => {
      try {
        if (name) {
          const a = localStorage.getItem(name) as any;
          return a ? JSON.parse(a) : undefined;
        } else {
          return name;
        }
      } catch (e) {
        console.log("localStorageFun", e);
      }
    };
    const localStorageFunSave = (
      name?: string | null,
      status?: boolean,
      nameStorage?: {
        name: string;
        data: string;
      }
    ) => {
      try {
        switch (status) {
          case true:
            if (nameStorage?.name) {
              let array = [];
              if (localStorageFun(nameStorage?.name)) {
                array = localStorageFun(nameStorage?.name);
              }
              array.push(nameStorage?.data as never);
              localStorage.setItem(nameStorage?.name, JSON.stringify(array));
            }
            break;
          default: {
            if (name) {
              let array = [];
              array = localStorageFun("introjs-tutorial");
              array.push(name as never);
              localStorage.setItem("introjs-tutorial", JSON.stringify(array));
            } else {
              return name;
            }
          }
        }
      } catch (e) {
        console.log("localStorageFun", e);
      }
    };

    const funDocument = (name: string, status = "1") => {
      return new Promise((resolve) => {
        switch (status) {
          case "1":
            {
              const doc = document.querySelectorAll(name);
              doc[0]
                ?.getElementsByClassName("next")[0]
                ?.addEventListener("click", () => {
                  nextData.value = 0;
                  resolve("step-1");
                });
              doc[0]
                ?.getElementsByClassName("lewati")[0]
                ?.addEventListener("click", () => {
                  nextData.value = 0;
                  resolve("step-2");
                });
            }
            break;
          case "2":
            {
              const time = setInterval(() => {
                const doc = document.querySelectorAll(name);
                if (doc.length > 0) {
                  doc[0]
                    ?.getElementsByClassName("next")[0]
                    ?.addEventListener("click", () => {
                      nextData.value = 0;
                      resolve("step-1");
                    });

                  doc[0]
                    ?.getElementsByClassName("lewati")[0]
                    ?.addEventListener("click", () => {
                      nextData.value = 0;
                      resolve("step-2");
                    });
                  clearInterval(time);
                }
              });
            }
            break;
        }
      });
    };

    // project document
    const listStep = () => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-document-aside"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-document-aside">
                      <div>Menu ini berisi dokumen proyek yang sedang Anda kerjakan</div>
                      <div></div>
                     <div>
                        <div class="progress-bar">
                        <progress value="50" max="100"></progress>
                        </div>
                          <div>1 of 2</div>
                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Selanjutnya</button>
                      </div>
                    </div>`,
              position: "right",
            },
            {
              element: document.querySelectorAll(
                ".introjs-document-stakeholder"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-document-stakeholder">
                      <div>Menu Menu Stakeholder berisikan member dan project manager yang menangani proyek Anda </div>
                      <div></div>
                     <div>
                        <div class="progress-bar">
                        <progress value="100" max="100"></progress>
                        </div>
                          <div>2 of 2</div>
                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Selanjutnya</button>
                      </div>
                    </div>`,
              position: "right",
            },
          ],
        });
        intro2.start();

        funDocument(".introjs-project-edit-28#step-document-aside").then(
          (status) => {
            switch (status) {
              case "step-1":
                nextStepTutorial.value = 1;
                intro2.nextStep();
                break;
              case "step-2":
                intro2.exit();
                localStorageFunSave(null, true, {
                  name: "project-document-child",
                  data: "project-aside",
                });
                break;
            }
          }
        );
        intro2.onafterchange(() => {
          if (nextStepTutorial.value === 1) {
            funDocument(
              ".introjs-project-edit-28#step-document-stakeholder",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 1;
                  intro2.nextStep();
                  localStorageFunSave(null, true, {
                    name: "project-document-child",
                    data: "project-aside",
                  });
                  break;
                case "step-2":
                  intro2.exit();
                  localStorageFunSave(null, true, {
                    name: "project-document-child",
                    data: "project-aside",
                  });
                  break;
              }
            });
          }
        });
      });
    };

    // project document table
    const listStep2 = () => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-document-table"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-document-table">
                      <div>Klik di sini untuk mengetahui aksi lain</div>
                      <div>Anda dapat melihat isi folder dan juga mendownload file proyek yang ada pada folder direktori dokumen</div>
                     <!--<div>
                        <div class="progress-bar">
                        <progress value="50" max="100"></progress>
                        </div>
                          <div>1 of 3</div>
                    </div>-->
                      <div class="grid-tutorial">
<!--                        <div class="lewati">Lewati</div>-->
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
          ],
        });
        intro2.start();

        funDocument(".introjs-project-edit-28#step-document-table").then(
          (status) => {
            switch (status) {
              case "step-1":
                nextStepTutorial.value = 1;
                intro2.nextStep();
                localStorageFunSave(null, true, {
                  name: "project-document-child",
                  data: "project-table",
                });
                break;
              case "step-2":
                intro2.exit();
                localStorageFunSave(null, true, {
                  name: "project-document-child",
                  data: "project-table",
                });
                break;
            }
          }
        );
      });
    };
    const funStepIntro = (varnew) => {
      const funDocumentDetail = (name?: string) => {
        switch (name) {
          case "project-document-aside":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-document-child"
                );
                if (!save?.includes("project-document")) {
                  if (!save2?.includes("project-aside")) {
                    setTimeout(() => {
                      listStep();
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "project-document-table":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-document-child"
                );
                if (save?.includes("on-boarding-2")) {
                  if (!save?.includes("project-document")) {
                    if (!save2?.includes("project-table")) {
                      setTimeout(() => {
                        listStep2();
                      }, 1000);
                    }
                  }
                }
              });
            }
            break;
        }
      };
      const boolLinkChecker = (name = "", status?: string) => {
        switch (status) {
          case "regex": {
            const regext = name || "";
            let detect = regext.match(/\/project\/show\/?([^.]*)/g);
            detect = detect?.length ? detect[0].split("#") : [];
            return detect;
          }
          case "all":
            return varnew?.data?.link === name
              ? true
              : fullPath.value === `/project/show/${varnew?.data}#${name}`;
          default:
            return nextData.value === 0 && varnew?.data?.link === name
              ? true
              : fullPath.value === `/project/show/${varnew?.data}#${name}`;
        }
      };
      if (varnew?.name === "document/load-aside" && varnew?.bool === true) {
        funDocumentDetail("project-document-aside");
      } else if (
        varnew?.name === "document/load-table" &&
        varnew?.bool === true
      ) {
        console.log("check-document");
        funDocumentDetail("project-document-table");
      }
    };

    watch(
      comFilterStep,
      (varnew) => {
        if (varnew?.name === "document/load-aside") {
          funStepIntro({ ...varnew });
        } else if (varnew?.name === "document/load-table") {
          if (localStorageFun("introjs-tutorial")?.includes("on-boarding-2")) {
            funStepIntro({ ...varnew });
          }
        }
      },
      { deep: true }
    );

    return {
      show,
    };
  },
};
