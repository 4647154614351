
/**
 * Notes, Sidebar Menu Masih belum sesuai dengan request
 */
import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { hideCaptchaBadge, ParseJwt } from "@/core/helpers/helps";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const store = useStore();
    const userProfile = computed(() => store.getters.getUserProfile)

    const signOut = () => {
      const token = localStorage.getItem("id_token");
      const dataUser = ParseJwt(token);
      const payload = {
        user_id: dataUser.sub,
        exp: dataUser.exp,
      };

      store
        .dispatch(Actions.LOGOUT, dataUser)
    };

    const user = computed(() => {
      return store.getters.getUserProfile;
    });

    const profile = () => {
      window.location.href = "/profile/detail";
    };
    const help = () => {
      window.location.href = "/program/faq";
    };
    
    onMounted(() => {
      store.dispatch(Actions.GET_PROFILE_DETAIL).then(() => {
        store.dispatch(Actions.GET_ACTIVE_WORKSPACE_INFO, '631063238c130d3fa0415ec2')
      })
      document.onreadystatechange = () => {
        hideCaptchaBadge('hidden')
      }
      
    })

    return {
      profile,
      signOut,
      help,
      user,
    };
  },
});
