import moment from "moment";
import JwtMytensService from "@/core/services/JwtMytensService";
import { useRoute } from "vue-router";
import store from "@/store";
import type { ElForm } from "element-plus";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import router from "@/router";
import { setAllertError } from "@/core/services/Alert";

export interface Filters {
  page: number;
  itemsPerPage: number;
  trash: number;
  sortBy: Array<string>;
  sortDesc: Array<number>;
}

export interface IPaginateable {
  current_page: number;
  data: Array<any>;
  first_page_url: string | null;
  from: number;
  last_page: number;
  last_page_url: string;
  links: Array<any>;
  next_page_url: string;
  path: string;
  per_page: string | number;
  prev_page_url: string | null;
  to: number;
  total: number;
}

const filters = ({
  page = 1,
  itemsPerPage = 10,
  trash = 0,
  sortBy = ["created_at"],
  sortDesc = [1],
}): Filters => {
  return {
    page,
    itemsPerPage,
    trash,
    sortBy,
    sortDesc,
  };
};

const currency = (value: number, type: string | null = null) => {
  const types = !type ? { currency: "IDR", style: "currency" } : {};

  return new Intl.NumberFormat("id-ID", types).format(value);
};

const tagDefineColor = (value: string) => {
  let color = '';
  switch (value) {
    case 'Initial':
      color = 'Initial';
      break;
    case 'Waiting for Validation':
        color = 'Pending';
        break;
    case 'Waiting for Verification':
          color = 'Waiting';
          break;
    case 'Verified':
            color = 'Accepted';
            break;
    case 'Invalid':
            color = 'Invalid';
            break;
    case 'Unverified':
          color = 'Unverified';
          break;
    default:
        color = 'Pending';
      break;
  }
  
  return color;
}

const statusValidasi  = (value: string, role: any) => {
  let status = true;
  if (role.validate) {
    if (['Initial', 'Invalid', 'Unverified'].includes(value)) {
      status = false;
    }

    if (role.validateStatus) {
      if (['Waiting for Verification'].includes(value)) {
        status = false;
      }
    }
  }


  if (role.verified) {
    if (['Initial', 'Invalid', 'Unverified'].includes(value)) {
      status = false;
    }

    if (role.validateStatus) {
      if (['Verified'].includes(value)) {
        status = false;
      }
    }

    if (role.validateStatus) {
      if (['Waiting for Verification'].includes(value)) {
        status = true;
      }
    }
  }

  return status;
}

const parseDate = (value: string) => {
  const tmp = value.split("T");

  return tmp.length > 0 ? tmp[0] : '-';
}

const parseTime = (value: string) => {
  const tmp = value.split("T");

  return tmp.length > 0 ? tmp[1].replace(".000000Z", "") : '00.00.00';
}

const currencyWithoutComma = (value: number) => {
  return new Intl.NumberFormat("id-ID", {
    currency: "IDR",
    style: "currency",
    minimumFractionDigits: 0,
  }).format(value);
};

const shortCurrency = (value: number) => {
  const curr =
    Math.abs(Number(value)) >= 1.0e12
      ? withoutRounding(Math.abs(Number(value)) / 1.0e12) + " T"
      : Math.abs(Number(value)) >= 1.0e9
      ? withoutRounding(Math.abs(Number(value)) / 1.0e9) + " M"
      : Math.abs(Number(value)) >= 1.0e6
      ? withoutRounding(Math.abs(Number(value)) / 1.0e6) + " J"
      : Math.abs(Number(value)) >= 1.0e3
      ? withoutRounding(Math.abs(Number(value)) / 1.0e3) + " R"
      : Math.abs(Number(value));

  return `Rp ${curr}`;
};

const dateFormat = (date: Date, format?: string | null) => {
  return moment(date).format(format ?? "DD MMM YYYY");
};

const dateFormatSeconds = (date: Date, format?: string | null) => {
  return moment(date).format(format ?? "DD MMM YYYY : h:mm");
};

const dateSubtract = (
  startDate: Date,
  day: number,
  format: any | undefined = "day"
) => {
  return moment(startDate).subtract(day, format);
};

const dateRange = (
  startDate: Date,
  format: any,
  endDate: Date = new Date()
) => {
  return [moment(startDate).startOf(format), moment(endDate).endOf(format)];
};

const timeAgo = (date: Date) => {
  return moment(date).fromNow();
};

const ParseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

const checkToken = () => {
  if (
    !JwtMytensService.getToken() ||
    JwtMytensService.getToken() == "undefined"
  ) {
    return false;
  } else {
    return true;
  }
};

const checkPermission = (action) => {
  try {
    const path = useRoute().name;
    const menus = store.getters.getRole.page_permissions;    
    const foundMenu = menus.find((menu) => menu.code == path) ?? null
    const foundPermission = foundMenu.permissions.find((item) => item.code == action) ?? null
    if (!foundPermission || !path || !action) return false;
    return Boolean(foundPermission.is_active);
  } catch (error) {
    console.log('err', error)
    return false
  }
};

const checkPermissionUpdate = (path, action) => {
  try {
    const menus = store.getters.getRole.page_permissions;
    const foundMenu = menus.find((menu) => menu.code == path) ?? null
    const foundPermission = foundMenu.permissions.find((item) => item.code == action) ?? null
    if (!foundPermission || !path || !action) return false;
    return Boolean(foundPermission.is_active);
  } catch (error) {
    return false
  }
};

const checkAccess = (routeName) => {
  try {
    const menus = store.getters.getRole.page_permissions;
    const foundMenu = menus.find((menu) => menu.code == routeName) ?? null
    if (!foundMenu || !routeName || !menus) return false;
    return Boolean(foundMenu.is_active);
  } catch (error) {
    return false
  }
};

const countData = (payload : any) => {
  const total = {
    total : 0,
    tanah : 0,
    bangunan : 0
  }

  payload.map((el) => {
    if (el?.jenis_asset === 'Tanah') {
      total.tanah++
    }

    if (el?.jenis_asset === 'Bangunan') {
      total.bangunan++
    }

    total.total++
  })
  
  return total;
}


const getNameId = (masterData:any, payload: any) => {
  if (masterData.length !== 1) {
    const channel = masterData.filter((el) => el._id === payload);
    if (channel.length > 0) {
      return channel[0].name;
    } else {
      return '-';
    }
  } else {
    return '-';
  }
};

const detectSubDomain = () => {
  const url = window.location.host as any;
  if (url == "simap.layanan.go.id") {
    return "simapkominfo";
  } else {
    const resolved = url.split(".")[0];
    const subdomain = resolved.split("-");
    if (resolved) {
      return subdomain[subdomain.length - 1];
    } else {
      return false;
    }
  }
  // return "simapkominfo";
};

const getTenant = () => {
  const dataUSer = localStorage.getItem("user");
  const user = JSON.parse(dataUSer as any);
  return user.tenant_id;
};

export const resetForm = (formEl: InstanceType<typeof ElForm> | undefined) => {
  if (!formEl) return;
  formEl.resetFields();
};

const capitalize = (text: string) => {
  if (text)
    return text
      .toLowerCase()
      .split(" ")
      .map((t) => t.charAt(0).toUpperCase() + t.substring(1))
      .join(" ");
};

const isEmpty = (data: [] | Record<string, any> | string | null): boolean => {
  let result = false;

  if (typeof data === "object") {
    if (JSON.stringify(data) === "{}" || JSON.stringify(data) === "[]")
      result = true;
    if (!data) result = true;
  } else if (typeof data === "string") {
    if (!data.trim()) result = true;
  } else if (typeof data === "undefined") {
    result = true;
  }

  return result;
};

const encodeQueryData = (data: Record<string, any>): string => {
  const res: Array<any> = [];
  const keys = Object.keys(data).map((key) => key);
  keys.forEach((d) => {
    if (d && !isEmpty(data[d])) {
      res.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`);
    }
  });

  return res.join("&");
};

const withoutRounding = (number: number) => {
  const data: any = number.toString().match(/^-?\d+(?:\.\d{0,2})?/);

  if (data[0]) {
    return data[0];
  } else {
    return 0;
  }
};

const checkUnauthenticated = async (errorValue) => {
  if (
    errorValue &&
    typeof errorValue === "string" &&
    errorValue.toLowerCase() === "unauthenticated"
  ) {
    return await store
      .dispatch(Actions.REFRESH_TOKEN)
      .then((res) => {
        return Promise.all([
          store.commit(Mutations.SET_TOKEN, res.data.token),
          store.dispatch(Actions.VERIFY_AUTH),
        ]).then(() => true);
      })
      .catch((err) => {
        const error =
          err.response && err.response.data.msg
            ? err.response.data.msg
            : err.response.data.data ?? err.message;
        store.dispatch(Actions.LOGOUT).then(() => {
          setAllertError(error).then(() => router.push({ name: "sign-in" }));
        });
      });
  } else {
    return setAllertError(errorValue);
  }
};

const validateNumberPhone = ($event: any) => {
  const keyCode = ($event.keyCode ? $event.keyCode : $event.which);
  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
      $event.preventDefault();
  }
}

/**
 * 
 * @param $event value dari event yang diberikan user, value key return character yang di berikan user
 * jika bukan character ex: symbol, number maka event ditolak
 */
const onlyTextAccept = ($event: any) => {
  if (!/^[a-z0-9]*$/.test($event.key)) {
    $event.preventDefault();
  }
}

// hide Recaptcha Badge
const hideCaptchaBadge = (type) => {
  if (document.readyState === 'complete') {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const elm = document.querySelector<HTMLElement>('.grecaptcha-badge')!;
    elm.style.visibility = type || 'hidden';
  }
}

const definceIconNotif = (action:any) => {
  if (['ASSET.VALIDATE', 'ASSET.VERIFY', 'ASSET.APPROVE', 'ASSET.REQUEST_EDIT_APPROVE'].includes(action)) {
    return {
      icon : 'fa fa-check-circle',
      background : 'rgb(17 150 78 / 43%)',
      color : '#11964E'
    }
  }

  if (['ASSET.UNVERIFY', 'ASSET.REQUEST_EDIT_REJECT', 'ASSET.INVALIDATE'].includes(action)) {
    return {
      icon : 'fa fa-times-circle',
      background : 'rgb(208 25 50 / 43%)',
      color : '#D01932'
    }
  }

  return {
    icon : 'fa fa-question-circle',
    background : '#ffa50057',
    color : 'orange'
  }

  
}

export {
  checkPermissionUpdate,
  capitalize,
  detectSubDomain,
  filters,
  currency,
  checkUnauthenticated,
  dateFormat,
  checkToken,
  ParseJwt,
  dateSubtract,
  dateRange,
  timeAgo,
  checkPermission,
  currencyWithoutComma,
  shortCurrency,
  checkAccess,
  getTenant,
  isEmpty,
  encodeQueryData,
  dateFormatSeconds,
  validateNumberPhone,
  onlyTextAccept,
  tagDefineColor,
  parseDate,
  statusValidasi,
  parseTime,
  getNameId,
  countData,
  hideCaptchaBadge,
  definceIconNotif
};
