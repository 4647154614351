import Layout from "@/layout/Layout.vue";
import NoAsideNoToolbarLayout from "@/layout/NoAsideNoToolbarLayout.vue";
import DashboardMap from "@/views/dashboard/DashboardMap.vue";
import DashboardSummary from "@/views/dashboard/DashboardSummary.vue";
import Reporting from "@/views/dashboard/Reporting.vue";

export const dashboard_map_routes = [
  {
    path: "/",
    component: () => Layout,
    meta: {
      allowGuest: true,
      requiresToken: true,
    },
    children: [
      {
        path: "/dashboard-map",
        name: "dashmap",
        component: () => DashboardMap,
      },
    ],
  },
  {
    path: "",
    component: () => NoAsideNoToolbarLayout,
    meta: {
      allowGuest: true,
      requiresToken: true,
    },
    children: [
      {
        path: "/dashboard-summary",
        name: "dashsum",
        component: () => DashboardSummary,
      },
    ],
  },
  {
    path: "",
    component: () => NoAsideNoToolbarLayout,
    meta: {
      allowGuest: true,
      requiresToken: true,
    },
    children: [
      {
        path: "/reporting",
        name: "report",
        component: () => Reporting,
      },
    ],
  },
];
