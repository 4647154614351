import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";

const service =
  process.env.VUE_APP_NODE_ENV == "local"
    ? "sysreport/api/v1"
    : "sysreport/api/v1";

@Module
export default class ReportModule extends VuexModule {
  datapartnerProyek = [];
  dataprojectManager = [];
  dataDokumen = {
    data: [],
  };
  totalDokumen = [];
  dataSubfolder = [];
  dataFile = [];
  filter = {
    page: 1,
    itemsPerPage: 10,
    trash: 0,
    sortBy: ["created_at"],
    sortDesc: [1],
    filterName: "",
    filterNamaPartner: "",
  };

  filterDokumen = {
    nameDIrektori: "",
  };
  filterPADate = "";
  filterScale = [];
  activePage = "";
  dataReport = [];
  filterBudgetModel = [];
  filterBudgetStatus = [];
  filterTopModel = [];
  filterTopStatus = [];
  filterSearch = {
    search: "",
  };
  subDocPage = ""
  spkFilter = {
    date: "",
    payment: []
  }
  topKbKlFilter = {
    segment: [],
    payment: [],
    status: []
  }
  lopwinFilter = {
    segment: []
  }
  allSegment = [];
  valueDL = "";
  filterRegional = {
    status: [],
    regional: [],
    pic: [],
    progressFrom: 0,
    progressTo: 0
  };
  allPic = [];
  allRegion = [];

  @Mutation
  [Mutations.SET_REPORT_PARTNER_PROYEK](data) {
    this.datapartnerProyek = data;
  }
  @Mutation
  [Mutations.UPDATE_FILTER_REPORT_DOKUMEN](data) {
    this.filterDokumen = data;
  }

  @Mutation
  [Mutations.SET_SUB_FOLDER](data) {
    this.dataSubfolder = data;
  }

  @Mutation
  [Mutations.SET_REPORT_PROJECT_MANAGER](data) {
    this.dataprojectManager = data.data.data;
  }

  @Mutation
  [Mutations.SET_REPORT_HIRARKI_DOKUMENT](data) {
    this.dataDokumen.data = data;
  }
  @Mutation
  [Mutations.SET_TOTAL_DOKUMEN](data) {
    this.totalDokumen = data;
  }

  @Mutation
  [Mutations.SET_REPORT_FILE](data) {
    this.dataFile = data;
  }

  @Mutation
  [Mutations.SET_FILTER_REPORT](payload) {
    this.filter = payload;
  }
  @Mutation
  [Mutations.SET_RESET_FILTER_REPORT](payload) {
    this.filter = {
      page: 1,
      itemsPerPage: 10,
      trash: 0,
      sortBy: ["created_at"],
      sortDesc: [1],
      ...payload,
    };
  }
  @Mutation
  [Mutations.SET_SEARCH_FILTER_REPORT](payload) {
    this.filterSearch = {
      search: payload,
    };
  }

  @Mutation
  [Mutations.SET_ACTIVE_PAGE](data) {
    this.activePage = data;
  }

  @Mutation
  [Mutations.SET_FILTER_PA_DATE](data) {
    this.filterPADate = data;
  }

  @Mutation
  [Mutations.SET_FILTER_SCALE](data) {
    this.filterScale = data.filter((i) => i !== "");
  }

  @Mutation
  [Mutations.SET_FILTER_REGIONAL](data) {
    this.filterRegional = {
      status: data.status.filter((i) => i !== ""),
      regional: data.regional,
      pic: data.pic,
      progressFrom: data.progressFrom,
      progressTo: data.progressTo
    };
  }

  @Mutation
  [Mutations.SET_FILTER_BUDGET_MODEL](data) {
    this.filterBudgetModel = data.filter((i) => i !== "");
  }

  @Mutation
  [Mutations.SET_FILTER_BUDGET_STATUS](data) {
    this.filterBudgetStatus = data.filter((i) => i !== "");
  }

  @Mutation
  [Mutations.SET_FILTER_TOP_MODEL](data) {
    this.filterTopModel = data.filter((i) => i !== "");
  }

  @Mutation
  [Mutations.SET_FILTER_TOP_STATUS](data) {
    this.filterTopStatus = data.filter((i) => i !== "");
  }

  @Mutation
  [Mutations.SET_REPORT_DATA](data) {
    this.dataReport = data;
  }

  @Mutation
  [Mutations.SET_SUB_DOC_PAGE](page) {
    this.subDocPage = page
  }

  @Mutation
  [Mutations.SET_FILTER_SPK](obj) {
    this.spkFilter = obj
  }
  @Mutation
  [Mutations.SET_FILTER_TOP_KB_KL](obj) {
    this.topKbKlFilter = {
      segment: obj.segment.filter((i) => i !== ""),
      status: obj.status.filter((i) => i !== ""),
      payment: obj.payment.filter((i) => i !== "")
    }
  }
  @Mutation
  [Mutations.SET_FILTER_LOP_WIN](obj) {
    this.lopwinFilter = {
      segment: obj.segment.filter((i) => i !== "")
    }
  }
  @Mutation
  [Mutations.SET_ALL_SEGMENT_DATA](data) {
    this.allSegment = data
  }
  @Mutation
  [Mutations.SET_VALUE_DOWNLOAD](data) {
    this.valueDL = data
  }
  @Mutation
  [Mutations.SET_ALL_PIC](data) {
    this.allPic = data
  }
  @Mutation
  [Mutations.SET_ALL_REGION](data) {
    this.allRegion = data
  }

  @Action
  [Actions.GET_REPORT_PARTNER_PROYEK](payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/stackholder/listPartnerProyek`, {
        params: payload,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REPORT_PARTNER_PROYEK, data.data);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  @Action
  [Actions.SEARCH_REPORT_PARTNER_PROYEK](payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/stackholder/searchpartnerproyek`, {
        params: payload,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REPORT_PARTNER_PROYEK, data);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  @Action
  [Actions.GET_REPORT_PROJECT_MANAGER](payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/stackholder/listProjectManager`, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REPORT_PROJECT_MANAGER, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_DETAIL_REPORT_PROJECT_MANAGER](id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`${service}/stackholder/ProjectManagerDetail/${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.SEARCH_REPORT_PROJECT_MANAGER](payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/stackholder/searchprojectmanager`, {
        params: payload,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REPORT_PROJECT_MANAGER, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  @Action
  [Actions.GET_REPORT_HIRARAKI_DOKUMEN]() {
    return new Promise((resolve, reject) => {
      ApiService.get(`${service}/dokument/listDirektoriDocumentByHirarki`)
        .then(({ data }) => {
          const datas = JSON.parse(data.data_hirarki);
          this.context.commit(
            Mutations.SET_REPORT_HIRARKI_DOKUMENT,
            datas.data
          );
          this.context.commit(Mutations.SET_TOTAL_DOKUMEN, datas.data);
          resolve(datas);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_REPORT_PROGRESS_ACHIEVEMENT](query) {
    return new Promise((resolve, reject) => {
      ApiService.query(
        `${service}/laporan/progressPencapaian/getLaporanAllProgressPencapaian`,
        {
          params: query,
        }
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REPORT_DATA, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_REPORT_SCALE](query) {
    return new Promise((resolve, reject) => {
      ApiService.query(
        `${service}/laporan/skalaProyek/getLaporanAllSkalaProyek`,
        {
          params: query,
        }
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REPORT_DATA, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_REPORT_ISSUE](query) {
    return new Promise((resolve, reject) => {
      ApiService.query(
        `${service}/laporan/issueProyek/getLaporanAllIssueProyek`,
        {
          params: query,
        }
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REPORT_DATA, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_REPORT_LOCATION](query) {
    return new Promise((resolve, reject) => {
      ApiService.query(
        `${service}/laporan/lokasiProyek/getLaporanAllLokasiProyek`,
        {
          params: query,
        }
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REPORT_DATA, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_REPORT_CATEGORY_SEGMENT](query) {
    return new Promise((resolve, reject) => {
      ApiService.query(
        `${service}/laporan/categorySegmentProyek/getLaporanAllCategorySegmentProyek`,
        {
          params: query,
        }
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REPORT_DATA, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_REPORT_BUDGET](query) {
    return new Promise((resolve, reject) => {
      ApiService.query(
        `${service}/laporan/anggaranProyek/getLaporanAllAnggaranProyek`,
        {
          params: query,
        }
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REPORT_DATA, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_REPORT_TOP](query) {
    return new Promise((resolve, reject) => {
      ApiService.query(
        `${service}/laporan/termOfPaymentProyek/getLaporanAllTermOfPaymentProyek`,
        {
          params: query,
        }
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REPORT_DATA, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_REPORT_STAKEHOLDER_CUSTPRT](query) {
    return new Promise((resolve, reject) => {
      ApiService.query(
        `${service}/laporan/partnerCustomer/getLaporanAllPartnerCustomerProyek`,
        {
          params: query,
        }
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REPORT_DATA, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_DOC_LOP_WIN](query) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/laporan/dokumen/documentLopWin`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REPORT_DATA, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_DOC_SPK](query) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/laporan/dokumen/documentSpkMitra`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REPORT_DATA, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_DOC_TOP_KL](query) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/laporan/dokumen/documentTopKl`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REPORT_DATA, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_DOC_TOP_KB](query) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/laporan/dokumen/documentTopKb`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REPORT_DATA, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_ALL_SEGMENT_DATA](query) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/laporan/dokumen/getAllSegment`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_SEGMENT_DATA, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_REPORT_REGIONAL](query) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/laporan/dokumen/regional`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REPORT_DATA, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_DOWNLOAD_REPORT](query) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}${query.urlData}`, {
        params: query.param,
        responseType: "blob"
      })
        .then(({ data }) => {
          const fileURL = window.URL.createObjectURL(new Blob([data], {
            type: 'application/vnd.ms-excel'
          }));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `${query.filename}.xlsx`);
          document.body.appendChild(fileLink);
        
          fileLink.click();
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  @Action
  [Actions.GET_ALL_PIC](query) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/laporan/dokumen/get-pic`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_PIC, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  @Action
  [Actions.GET_ALL_REGION](query) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/laporan/dokumen/get-regional`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_REGION, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  get listReportPartnerProyek() {
    return this.datapartnerProyek;
  }
  get listReportProjectmanager() {
    return this.dataprojectManager;
  }
  get listHirarkiDokumen() {
    return this.dataDokumen;
  }
  get totalHirarkiDokumen() {
    return this.totalDokumen;
  }
  get listSubfolder() {
    return this.dataSubfolder;
  }

  get listReportFile() {
    return this.dataFile;
  }

  get getFilterReport() {
    return this.filter;
  }

  get getfilterReportDokumen() {
    return this.filterDokumen;
  }

  get getActivePage() {
    return this.activePage;
  }

  get getFilterPADate() {
    return this.filterPADate;
  }
  get getFilterScale() {
    return this.filterScale;
  }

  get getReportData() {
    return this.dataReport;
  }
  get getFilterBudgetModel() {
    return this.filterBudgetModel;
  }
  get getFilterBudgetStatus() {
    return this.filterBudgetStatus;
  }
  get getFilterTopModel() {
    return this.filterTopModel;
  }
  get getFilterTopStatus() {
    return this.filterTopStatus;
  }
  get getSearchFilter() {
    return this.filterSearch;
  }
  get getSubDocPage() {
    return this.subDocPage;
  }
  get getSpkFilter() {
    return this.spkFilter;
  }
  get getTopKbKlFilter() {
    return this.topKbKlFilter;
  }
  get getLopwinFilter() {
    return this.lopwinFilter;
  }
  get getAllSegment() {
    return this.allSegment;
  }
  get getValueDL () {
    return this.valueDL;
  }
  get getFilterRegional() {
    return this.filterRegional;
  }
  get getAllPicData() {
    return this.allPic;
  }
  get getAllRegionData() {
    return this.allRegion;
  }
}
