import ApiService from "@/core/services/ApiService";
import { ActionsAdminValidasi, MutationsAdminValidasi } from "@/store/enums/StoreAdminValidasiEnums";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { ElNotification } from "element-plus";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
const serviceApproval = process.env.VUE_APP_NODE_ENV == 'local' ? '/approval/api/v1' : '/approval/api/v1';
const service = process.env.VUE_APP_NODE_ENV == 'local' ? '/api/v1' : '/api/v1';

@Module
export default class AdminValidasiModule extends VuexModule {
    modalHistory = false;
    modalBulkRequestReview = false;
    assetSelected = {};
    switchList = false;
    loading = false;
    requestEdit = []
    feedback: any = {
        status : false,
        approval : false,
        validasi : false
    }
    approvalList: any = {
        status : false,
        type: '',
        entity_id : '',
        rejected : false,
        approved : false,
        validated : false,
        request_review : false,
        verified : false,
        buttonTitle : '',
        message : '',
        update_requested: false,
    };
    validasiList: any = {
        status : false,
        request_review : false,
        validated : false,
        verified : false,
        type: '',
        entity_id : '',
        rejected : false,
        approved : false,
        buttonTitle : '',
        message : ''
    }
    payloadAction: any =  {
        user_id : '',
        entity_id : '',
        action : '',
        entity : 'ASSET_KBUMN',
        init_role : '',
        feedback : ''
    }
    activityHistory: any = [];
    activityLoading = false;
    activityParams : any = {
        channel_id : '',
        user_id : '',
        page_num : 1,
        data_limit : 5,
        total_page : 1,
        total_data : 0
    }
    approvalHistory: any = [];
    approvalLoading = false;
    approvalParams : any = {
        asset_id : '',
        search : '',
        page_num : 1,
        data_limit : 5,
        total_page : 1,
        total_data : 0
    }

    requestHistoryParams : any = {
        search : '',
        page_num : 1,
        data_limit : 10,
        total_page : 1,
        total_data : 0
    }

    lastActivity = []

    get LoadingAdminValidasi() {
        return this.loading;
    }

    get LoadingActivityHistory() {
        return this.activityLoading
    }

    get LoadingApprovalHistory() {
        return this.approvalLoading
    }

    get getAssetSelected () {
        return this.assetSelected;
    }

    get ActivityHistory () {
        return this.activityHistory;
    }

    get ApprovalHistory () {
        return this.approvalHistory;
    }

    get ApprovalHistoryParams () {
        return this.approvalParams;
    }

    get RequestHistoryParams () {
        return this.requestHistoryParams;
    }

    get ActivityHistoryParams () {
        return this.activityParams;
    }

    get getReqEdit () {
        return this.requestEdit;
    }

    get gettingLastActivity () {
        return this.lastActivity
    }

    get getRequestReviewModal () {
        return this.modalBulkRequestReview
    }

    @Mutation
    [MutationsAdminValidasi.SET_LAST_ACTIVITY](payload) {
        this.lastActivity = payload
    }

    @Mutation
    [MutationsAdminValidasi.SET_BULK_REQUESTREVIEW_MODAL](payload) {
        this.modalBulkRequestReview = payload
    }

    @Mutation
    [MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI](payload) {
        this[payload.name] = payload.status;
        if (payload?.data) {
            this.assetSelected = payload?.data
        }
    }

    approvalStatusList: any = [];

    get ApprovalStatusList() {
        return this.approvalStatusList;
    }

    get getSwitch() {
        return this.switchList;
    }

    @Mutation
    [MutationsAdminValidasi.SET_APPROVAL_STATUS](data: any) {
        this.approvalStatusList = data;
    }

    @Action
    [ActionsAdminValidasi.GET_APPROVAL_STATUS]() {
        return new Promise((resolve, reject) => {
        ApiService.get(`${service}/approval-status`)
            .then(({ data }) => {
                this.context.commit(MutationsAdminValidasi.SET_APPROVAL_STATUS, data.data);
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
        });
    }

    get ModalHistory() {
        return this.modalHistory;
    }

    @Mutation
    [MutationsAdminValidasi.SET_MODAL_HISTORY](data) {
        this.modalHistory = data;
    }

    @Mutation
    [MutationsAdminValidasi.SET_SWITCH](data) {
        this.switchList = data
    }

    @Mutation
    [MutationsAdminValidasi.SET_DATA_REQUEST_EDIT](data) {
        this.requestEdit = data.data
    }

    @Action
    [ActionsAdminValidasi.OPEN_MODAL_HISTORY](payload) {
        return new Promise((resolve, reject) => {
            try {
                this.context.commit(MutationsAdminValidasi.SET_MODAL_HISTORY, payload)
                resolve(payload)
            } catch (error) {
                reject(error)
            }
      })
    }

    get ApprovalList() {
        return this.approvalList;
    }

    @Mutation
    [MutationsAdminValidasi.SET_APPROVAL_REQUEST_UPDATE](data) {
        this.approvalList = {
            ...this.approvalList,
            ...data
        };
    }

    @Action
    [ActionsAdminValidasi.OPEN_APPROVAL_REQUEST_UPDATE](payload) {
        return new Promise((resolve, reject) => {
            try {
                this.context.commit(MutationsAdminValidasi.SET_APPROVAL_REQUEST_UPDATE, payload)
                resolve(payload)
            } catch (error) {
                reject(error)
            }
      })
    }

    get ValidasiList() {
        return this.validasiList;
    }

    @Mutation
    [MutationsAdminValidasi.SET_VALIDASI_LIST](data) {
        this.validasiList = {
            ...this.validasiList,
            ...data
        };
    }

    @Action
    [ActionsAdminValidasi.OPEN_VALIDASI_LIST](payload) {
        return new Promise((resolve, reject) => {
            try {
                this.context.commit(MutationsAdminValidasi.SET_VALIDASI_LIST, payload)
                setTimeout(() => {
                    this.context.commit(Mutations.SET_UPDATE_ASSETS_FLAG, false);
                }, 1000);
                resolve(payload)
            } catch (error) {
                reject(error)
            }
      })
    }

    get PayloadAction() {
        return this.payloadAction;
    }

    get statusFeedback() {
        return this.feedback;
    }

    @Mutation
    [MutationsAdminValidasi.SET_STATUS_MODAL_FEEDBACK](payload) {
        this.feedback = payload;
    }

    

    @Mutation
    [MutationsAdminValidasi.SET_ACTION_STATUS_ASSET](payload) {
        this.payloadAction = payload;
    }

    @Action
    [ActionsAdminValidasi.POST_STATUS_ASSET]() {
        this.context.commit(MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI, {
            name : 'loading',
            status : true
        });
        const payload = new URLSearchParams(this.payloadAction);

        ApiService.setApprovalurl();
        ApiService.setHeaderApproval();
        return new Promise<void>((resolve, reject) => {
          ApiService.post(`${serviceApproval}/action`, payload)
            .then(({ data }) => {
                this.context.dispatch(Actions.ADMIN_ACTIVITY_LOG, {
                    action : 'ASSET.APPROVAL',
                    data : {
                        approval_detail : this.payloadAction
                    }
                })

                ElNotification({
                    title: 'Approval',
                    message: data.msg + `${this.payloadAction.action === 'ASSET.request_edit_reject' ? 'Verified' : ''}`,
                    type: 'success',
                  })
              return resolve(data);
            })
            .catch((err) => {
                this.context.commit(MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI, {
                    name : 'loading',
                    status : false
                });
                this.context.commit(MutationsAdminValidasi.SET_STATUS_MODAL_FEEDBACK, {
                    status : false,
                    approval : false,
                    validasi : false
                });
                this.context.commit(Mutations.SET_ERROR, err);
                reject();
            })
            .finally(() => {
                this.context.commit(MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI, {
                    name : 'loading',
                    status : false
                });
                this.context.commit(MutationsAdminValidasi.SET_STATUS_MODAL_FEEDBACK, {
                    status : false,
                    approval : false,
                    validasi : false
                });
                ApiService.setUrl();
            })
        });
    }

    @Action
    [ActionsAdminValidasi.POST_BULK_STATUS_ASSET](payloadValidasi:any) {

        this.context.commit(MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI, {
            name : 'loading',
            status : true
        });
        const payload = new URLSearchParams(payloadValidasi?.user_id ? payloadValidasi : this.payloadAction);
        

        ApiService.setApprovalurl();
        ApiService.setHeaderApproval();
        return new Promise<void>((resolve, reject) => {
          ApiService.post(`${serviceApproval}/batch_action`, payload)
            .then(({ data }) => {
                this.context.dispatch(Actions.ADMIN_ACTIVITY_LOG, {
                    action : 'ASSET.APPROVAL',
                    data : {
                        approval_detail : this.payloadAction
                    }
                })

                ElNotification({
                    title: 'Approval',
                    message: data.msg,
                    type: 'success',
                  })
              return resolve(data);
            })
            .catch((err) => {
                this.context.commit(MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI, {
                    name : 'loading',
                    status : false
                });
                this.context.commit(MutationsAdminValidasi.SET_STATUS_MODAL_FEEDBACK, {
                    status : false,
                    approval : false,
                    validasi : false
                });
                this.context.commit(Mutations.SET_ERROR, err);
                reject();
            })
            .finally(() => {
                this.context.commit(MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI, {
                    name : 'loading',
                    status : false
                });
                this.context.commit(MutationsAdminValidasi.SET_STATUS_MODAL_FEEDBACK, {
                    status : false,
                    approval : false,
                    validasi : false
                });
                ApiService.setUrl();
            })
        });
    }

    @Mutation
    [MutationsAdminValidasi.SET_ACTITVITY_HISTORY](payload) {
        this.activityHistory = payload?.data || [];
        this.activityParams = {
            ...this.activityParams,
            data_limit : parseInt(payload.meta.limit),
            page_num : parseInt(payload.meta.current_page),
            total_page : payload.meta.total_pages,
            total_data : payload.meta.total_data
        }
    }

    @Action
    [ActionsAdminValidasi.GET_ACTITVITY_HISTORY](payload: any) {
        this.context.commit(MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI, {
            name : 'activityLoading',
            status : true,
            data : payload
        });
        ApiService.setApprovalurl();
        ApiService.setHeaderApproval();
        /**
         * run get data
         */
        return new Promise<void>((resolve, reject) => {
          ApiService.query(`${process.env.VUE_APP_AUDITTRAIL_URL}/api/v1/audit_trail`, {
            params : {
                asset_id : payload?._id || payload?.id,
                limit : payload?.limit ||this.activityParams.data_limit,
                page : payload?.page || this.activityParams.page_num
            }
          })
            .then(({ data }) => {
                this.context.commit(MutationsAdminValidasi.SET_ACTITVITY_HISTORY, data);
                return resolve(data);
            })
            .catch((err) => {
                this.context.commit(Mutations.SET_ERROR, {
                    err,
                    show : false
                });
                return resolve(err);
            })
            .finally(() => {
                this.context.commit(MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI, {
                    name : 'activityLoading',
                    status : false
                });

                ApiService.setUrl();
            })
        });
    }

    @Mutation
    [MutationsAdminValidasi.SET_APPROVAL_HISTORY](payload) {
        this.approvalHistory = payload?.data || [];
        this.approvalParams = {
            ...this.approvalParams,
            asset_id : payload?.asset_id,
            search : payload?.search,
            data_limit : parseInt(payload?.meta?.limit_data_per_page) || this.approvalParams.data_limit,
            page_num : parseInt(payload?.meta?.current_page) || this.approvalParams.page_num,
            total_page : parseInt(payload?.meta?.total_page) || this.approvalParams.total_page,
            total_data : parseInt(payload?.meta?.total_data_this_page) || this.approvalParams.total_data
        };
    }

    @Action
    [ActionsAdminValidasi.GET_APPROVAL_HISTORY](payloadId: any) {
        this.context.commit(MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI, {
            name : 'approvalLoading',
            status : true
        });
        ApiService.setApprovalurl();
        ApiService.setHeaderApproval();
        return new Promise<void>((resolve, reject) => {
          ApiService.query(`${serviceApproval}/activitylog`, {
            params : {
                asset_id : typeof payloadId === 'object' ? payloadId.asset_id : payloadId,
                search : typeof payloadId === 'object' ? payloadId.search : '',
                data_limit : payloadId?.limit || this.approvalParams.data_limit,
                page_num : payloadId?.page || this.approvalParams.page_num
            }
          })
            .then(({ data }) => {
                this.context.commit(MutationsAdminValidasi.SET_APPROVAL_HISTORY, {
                    ...data,
                    asset_id : typeof payloadId === 'object' ? payloadId.asset_id : payloadId
                });
                return resolve(data);
            })
            .catch((err) => {
                this.context.commit(MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI, {
                    name : 'approvalLoading',
                    status : false
                });
                this.context.commit(Mutations.SET_ERROR, err);
                reject();
            })
            .finally(() => {
                this.context.commit(MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI, {
                    name : 'approvalLoading',
                    status : false
                });

                ApiService.setUrl();
            })
        });
    }

    @Action
    [ActionsAdminValidasi.GET_REQUEST_EDIT_HISTORY](payload: any) {
        this.context.commit(MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI, {
            name : 'loading',
            status : true
        });
        // ApiService.setApprovalurl();
        // ApiService.setHeaderApproval();
        return new Promise<void>((resolve, reject) => {
          ApiService.query(`${service}/asset`, {
            params : {
                // user_id : payload.user_id,
                limit : this.requestHistoryParams.data_limit,
                page : this.requestHistoryParams.page_num,
                approval_status: '631ed2f9f97fb56ec2fcc0e8,631ed318f97fb56ec2fcc0ea,631ed357f97fb56ec2fcc0f0',
                request_edit: 1
            }
          })
            .then(({ data }) => {
                this.context.commit(Mutations.SET_FILTER_ASSET, data.meta)
                this.context.commit(MutationsAdminValidasi.SET_DATA_REQUEST_EDIT, data);
                return resolve(data);
            })
            .catch((err) => {
                this.context.commit(MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI, {
                    name : 'loading',
                    status : false
                });
                this.context.commit(Mutations.SET_ERROR, err);
                reject();
            })
            .finally(() => {
                // this.context.commit(MutationsAdminValidasi.SET_LOADING_ADMIN_VALIDASI, {
                //     name : 'loading',
                //     status : false
                // });
                ApiService.setUrl();
            })
        });
    }

    @Action
    [ActionsAdminValidasi.GET_DETAIL_USER_ACTIVITY](payload: any) {
        ApiService.setApprovalurl();
        ApiService.setHeaderApproval();
        return new Promise((resolve, reject) => {
            ApiService.query(`${process.env.VUE_APP_AUDITTRAIL_URL}/api/v1/audit_trail`, {
                params : {
                    user_id : payload.id,
                    limit : 100,
                    page : payload.page || this.activityParams.page_num,
                    show_all: payload.showAll
                }
              })
                .then(({ data }) => {
                    return resolve(data);
                })
                .catch((err) => {
                    return resolve(err);
                })
                .finally(() => {
                    ApiService.setUrl();
                })
      })
    }

    @Action
    [ActionsAdminValidasi.GET_LAST_ACTIVITY]() {
        ApiService.setHeaderApproval();
        return new Promise((resolve, reject) => {
        ApiService.get(`${process.env.VUE_APP_AUDITTRAIL_URL}/api/v1/last_activity`)
            .then(({ data }) => {
                this.context.commit(MutationsAdminValidasi.SET_LAST_ACTIVITY, data.data)
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
        });
    }
}
