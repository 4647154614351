import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";

const service =
  process.env.VUE_APP_NODE_ENV == "local"
    ? "sysreport/api/v1"
    : "sysreport/api/v1";

@Module
export default class DashboardGeneralModule extends VuexModule {
  scaleGeneral = [];
  statusGeneral = [];
  maxStatusGeneral = 0;
  categoryGeneral = [];
  segmentGeneral = [];
  progressGeneral = [];
  maxProgressGeneral = 0;
  budgetLeft = [];
  budgetRight = [];
  topGeneral = [];
  topDetailGeneral = [];
  progressGrafikGeneral = [];
  maxProgGrafikGeneral = 0;
  stakeholderGeneral = [];
  issueGeneral = [];
  maxIssueGeneral = 0;
  locationGeneral = [];
  lessonGeneral = [];
  qualityGeneral = [];
  riskGeneral = [];
  sourceGeneral = [];
  costGeneral = [];
  scheduleGeneral = [];
  errors = [];

  // global usage
  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  // general scale
  get getscaleGeneral() {
    return this.scaleGeneral;
  }

  @Mutation
  [Mutations.SET_GENERAL_SCALE](data) {
    this.scaleGeneral = data;
  }

  @Action
  [Actions.GET_GENERAL_SCALE](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/skalaProyek/getCountAllSkalaProyekGeneral`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERAL_SCALE, data.data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  // general status
  get getstatusGeneral() {
    return this.statusGeneral;
  }
  get maxstatusTotalGeneral() {
    return this.maxStatusGeneral;
  }

  @Mutation
  [Mutations.SET_GENERAL_STATUS](data) {
    this.statusGeneral = data;
  }
  @Mutation
  [Mutations.SET_GENERAL_MAX_STATUS](data) {
    this.maxStatusGeneral = data;
  }

  @Action
  [Actions.GET_GENERAL_STATUS](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/dashboard/getStatusProyekGeneral/`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERAL_STATUS, data.data);
          this.context.commit(Mutations.SET_GENERAL_MAX_STATUS, data.totalProyek);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  // general category
  get getcategoryGeneral() {
    return this.categoryGeneral;
  }

  @Mutation
  [Mutations.SET_GENERAL_CATEGORY](data) {
    if (data.length > 5) {
      this.categoryGeneral = data
        .slice(0, 5)
        .filter((item) => item.Total_Proyek > 0);
    } else {
      this.categoryGeneral = data.filter((item) => item.Total_Proyek > 0);
    }
  }

  @Action
  [Actions.GET_GENERAL_CATEGORY](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/kategoriProyek/getAllKatergoriProyekGeneral`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERAL_CATEGORY, data.data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  // general segment
  get getsegmentGeneral() {
    return this.segmentGeneral;
  }

  @Mutation
  [Mutations.SET_GENERAL_SEGMENT](data) {
    if (data.length > 5) {
      this.segmentGeneral = data
        .slice(0, 5)
        .filter((item) => item.Total_Proyek > 0);
    } else {
      this.segmentGeneral = data.filter((item) => item.Total_Proyek > 0);
    }
  }

  @Action
  [Actions.GET_GENERAL_SEGMENT](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/segmenProyek/getAllSegmenProyekGeneral`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERAL_SEGMENT, data.data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  // general progress
  get getprogressGeneral() {
    return this.progressGeneral;
  }
  get maxprogressGeneral() {
    return this.maxProgressGeneral;
  }

  @Mutation
  [Mutations.SET_GENERAL_PROGRESS](data) {
    this.progressGeneral = data;
  }
  
  @Mutation
  [Mutations.SET_GENERAL_MAX_PROGRESS](data) {
    this.maxProgressGeneral = data.reduce((a, b) => {
      return a + b;
    });
  }

  @Action
  [Actions.GET_GENERAL_PROGRESS](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/progressProyek/getCountAllProgressProyekGeneral`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERAL_PROGRESS, data.data);
          this.context.commit(Mutations.SET_GENERAL_MAX_PROGRESS, data.data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  // general budget
  get getbudgetLeftGeneral() {
    return this.budgetLeft;
  }

  get getbudgetRightGeneral() {
    return this.budgetRight;
  }

  @Mutation
  [Mutations.SET_GENERAL_BUDGET](data) {
    this.budgetLeft = data.filter((item) => {
      return (
        item.type === "totalNilaiProyek" || item.type === "penyerapanAnggaran"
      );
    });
    this.budgetRight = data.filter((item) => {
      return (
        item.type !== "totalNilaiProyek" && item.type !== "penyerapanAnggaran"
      );
    });
  }

  @Action
  [Actions.GET_GENERAL_BUDGET](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/anggaranProyek/getCountAllAnggaranProyekGeneral`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERAL_BUDGET, data.data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  // general TOP
  get gettopGeneral() {
    return this.topGeneral;
  }

  @Mutation
  [Mutations.SET_GENERAL_TOP](data) {
    this.topGeneral = data;
  }

  @Action
  [Actions.GET_GENERAL_TOP](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/termOfPayment/getAllTermOfPaymentProyekGeneral`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERAL_TOP, data.data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  // general TOP Detail
  get gettopDetailGeneral() {
    return this.topDetailGeneral;
  }

  @Mutation
  [Mutations.SET_GENERAL_TOP_DETAIL](data) {
    this.topDetailGeneral = data;
  }

  @Action
  [Actions.GET_GENERAL_TOP_DETAIL](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/detail-pembayaran/getDetailPembayaranGeneral`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERAL_TOP_DETAIL, data.data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  // general progress scatter chart
  get getproggrafikGeneral() {
    return this.progressGrafikGeneral;
  }
  get maxproggrafikGeneral() {
    return this.maxProgGrafikGeneral;
  }
  @Mutation
  [Mutations.SET_GENERAL_PROGRESS_CHART](data) {
    data.map((item) => {
      item.data.map((val) => {
        val.valueMoney = val.x;
        val.x = val.x / 1000000;
      });
    });
    this.progressGrafikGeneral = data;
  }

  @Mutation
  [Mutations.SET_GENERAL_MAX_PROGRESS_CHART](data) {
    this.maxProgGrafikGeneral = data;
  }

  @Action
  [Actions.GET_GENERAL_PROGRESS_CHART](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/progressProyek/getGrafikAllProgressProyekGeneral`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERAL_PROGRESS_CHART, data.data);
          this.context.commit(
            Mutations.SET_GENERAL_MAX_PROGRESS_CHART,
            data.totalData
          );
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  // general stakeholder
  get getstakeholderGeneral() {
    return this.stakeholderGeneral;
  }
  
  @Mutation
  [Mutations.SET_GENERAL_STAKEHOLDER](data) {
    if (data.length > 5) {
      this.stakeholderGeneral = data
        .slice(0, 5)
        .filter((item) => item.proyek > 0);
    } else {
      this.stakeholderGeneral = data.filter((item) => item.proyek > 0);
    }
  }

  @Action
  [Actions.GET_GENERAL_STAKEHOLDER](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/stakeHolder/getAllStakeHolderGeneral`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERAL_STAKEHOLDER, data.data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  // general issue
  get getissueGeneral() {
    return this.issueGeneral;
  }

  get getmaxissueGeneral() {
    return this.maxIssueGeneral;
  }

  @Mutation
  [Mutations.SET_GENERAL_ISSUE](data) {
    if (data.length > 5) {
      this.issueGeneral = data
        .slice(0, 5)
        .filter((item) => item.totalProyek > 0);
    } else {
      this.issueGeneral = data.filter((item) => item.totalProyek > 0);
    }
  }

  @Mutation
  [Mutations.SET_GENERAL_MAX_ISSUE](data) {
    this.maxIssueGeneral = data;
  }

  @Action
  [Actions.GET_GENERAL_ISSUE](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/issueProyek/getAllIssueProyekGeneral`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERAL_ISSUE, data.data);
          this.context.commit(Mutations.SET_GENERAL_MAX_ISSUE, data.maxIssue);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  // general location
  get locationgraphGeneral() {
    return this.locationGeneral;
  }

  @Mutation
  [Mutations.SET_GENERAL_LOCATION](data) {
    this.locationGeneral = data;
  }

  @Action
  [Actions.GET_GENERAL_LOCATION](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/dashboard/getLokasiGeneral`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERAL_LOCATION, data.data);
          resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  // general lesson
  get getlessonGeneral() {
    return this.lessonGeneral;
  }

  @Mutation
  [Mutations.SET_GENERAL_LESSON](data) {
    this.lessonGeneral = data;
  }

  @Action
  [Actions.GET_GENERAL_LESSON](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/dashboard/getLessonProyekGeneral`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERAL_LESSON, data.data);
          resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  // general quality
  get getqualityGeneral() {
    return this.qualityGeneral;
  }

  @Mutation
  [Mutations.SET_GENERAL_QUALITY](data) {
    this.qualityGeneral = data.slice(0, 5);
  }

  @Action
  [Actions.GET_GENERAL_QUALITY](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/anggaran/getPeringkatProyekGeneral`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERAL_QUALITY, data.data);
          resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  // general risk level
  get getriskGeneral() {
    return this.riskGeneral;
  }

  @Mutation
  [Mutations.SET_GENERAL_RISK](data) {
    data.map((item) => {
      console.log(item.name.split(" "))
      if (item.name.split(" ").length > 1) {
        item.name = item.name.split(" ").join("_")
        console.log(item.name)
      }
      item.name = item.name.toLowerCase()
    })
    this.riskGeneral = data.filter((item) => item.name !== "very_low");
  }

  @Action
  [Actions.GET_GENERAL_RISK](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/dashboard/getResikoGeneral`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERAL_RISK, data.data);
          resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  // general source budget
  get getSourceBudgetGeneral() {
    return this.sourceGeneral;
  }

  @Mutation
  [Mutations.SET_GENERAL_SOURCE_BUDGET](data) {
    this.sourceGeneral = data;
  }

  @Action
  [Actions.GET_GENERAL_SOURCE_BUDGET](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/anggaran/getSumberAnggaranGeneral`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERAL_SOURCE_BUDGET, data.data);
          resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  // general cost conclusion
  get getCostGeneral() {
    return this.costGeneral;
  }

  @Mutation
  [Mutations.SET_GENERAL_COST](data) {
    this.costGeneral = data;
    console.log(this.costGeneral)
  }

  @Action
  [Actions.GET_GENERAL_COST](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/dashboard/costConclusion`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERAL_COST, data.data);
          resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  // general schedule conclusion
  get getScheduleGeneral() {
    return this.scheduleGeneral;
  }

  @Mutation
  [Mutations.SET_GENERAL_SCHEDULE](data) {
    this.scheduleGeneral = data;
    console.log(this.scheduleGeneral)
  }

  @Action
  [Actions.GET_GENERAL_SCHEDULE](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/dashboard/scheduleConclusion`, {
        params: query,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERAL_SCHEDULE, data.data);
          resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }
}
