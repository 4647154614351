const images = {
  multiBlock: require("@/assets/images/multi-block.svg"),
  multiUser: require("@/assets/images/multi-user.svg"),
  multiUser2: require("@/assets/images/multi-user-2.svg"),
  map: require("@/assets/images/map.svg"),
  donut: require("@/assets/images/donutIcon.svg"),
  budgetPlan: require("@/assets/images/budget-plan.svg"),
  credit: require("@/assets/images/credit.svg"),
  roof: require("@/assets/images/roofing.svg"),
  apartment: require("@/assets/images/apartment.svg"),
  cottage: require("@/assets/images/cottage.svg"),
  homeWork: require("@/assets/images/home_work.svg"),
  tooltip: require("@/assets/images/tooltipArrow.svg"),
  tooltipUser: require("@/assets/images/single-user.svg"),
  empty: require("@/assets/images/empty-cuate.svg"),
  hotel: require("@/assets/images/hotel.svg"),
  bhome: require("@/assets/images/big-home.svg"),
  edit: require("@/assets/images/edit.svg"),
  delete: require("@/assets/images/delete.svg"),
  mapMarker: require("@/assets/images/map-pin.svg"),
  leftOver: require("@/assets/images/leftover-budget.svg"),
  leftOverRed: require("@/assets/images/leftover-budget-red.svg"),
  totalPayment: require("@/assets/images/total_payment.svg"),
  paymentReceive: require("@/assets/images/payment_receive.svg"),
  haventPay: require("@/assets/images/havent_pay.svg"),
  calendar: require("@/assets/images/calendar.svg"),
  budgetAbsorb: require("@/assets/images/budget-absorb.svg"),
  budgetAbsorbRed: require("@/assets/images/budget-absorb-red.svg"),
  boy: require("@/assets/images/boy.svg"),
  infoIcon: require("@/assets/images/info_icon.svg"),
  projectValue: require("@/assets/images/project_value.svg"),
  throphy: require("@/assets/images/throphy.svg"),
  gold: require("@/assets/images/gold.svg"),
  silver: require("@/assets/images/silver.svg"),
  bronze: require("@/assets/images/bronze.svg"),
  dummy: require("@/assets/images/dummy.png"),
  projectComplete: require("@/assets/images/projectComplete.svg"),
  blueUser: require("@/assets/images/blueUser.svg"),
  temp: require("@/assets/images/temp.svg"),
  human: require("@/assets/images/human.svg"),
  closeChips: require("@/assets/images/close-chips.svg"),
  mapsLayer: require("@/assets/images/maps-layer.svg"),
  seeDetail: require("@/assets/images/see-detail.svg"),
  building: require("@/assets/images/map/building.svg"),
  buildingRed: require("@/assets/images/map/building-red.svg"),
  buildingBlue: require("@/assets/images/map/building-blue.svg"),
  buildingYellow: require("@/assets/images/map/building-yellow.svg"),
  buildingTransparant: require("@/assets/images/map/building-transparant.svg"),
  avatar: require("@/assets/images/avatar.svg"),
  close: require("@/assets/images/close.svg"),
  addPoint: require("@/assets/images/addPoint.svg"),
  polygon: require("@/assets/images/polygon.svg"),
  polyline: require("@/assets/images/polyline.svg"),
  trash: require("@/assets/images/trash.svg"),
  approval: require("@/assets/images/approval.svg"),
  download: require("@/assets/images/download.svg"),
  doubleArrow: require("@/assets/images/doubleArrow.svg"),
  pointmapInput: require("@/assets/images/pointmapInput.svg"),
  pointmapInputColor: require("@/assets/images/pointmapInputColor.svg"),
  polylineInput: require("@/assets/images/polylineInput.svg"),
  polylineNew: require("@/assets/images/polylineNew.svg"),
  polygonInput: require("@/assets/images/polygonInput.svg"),
  btnGreen: require("@/assets/images/btnGreen.svg"),
  btnRed: require("@/assets/images/btnRed.svg"),
  btnEdit: require("@/assets/images/btnEdit.svg"),
  btnDelete: require("@/assets/images/btnDelete.svg"),
  addPhoto: require("@/assets/images/addPhoto.svg"),
  filterArcgis: require("@/assets/images/filterArcgis.svg"),
  filterDashboard: require("@/assets/images/filterDashboard.svg"),
  mapPinBlue: require("@/assets/images/map/map-pin-blue.svg"),
  mapPinGreen: require("@/assets/images/map/map-pin-green.svg"),
  mapPinRed: require("@/assets/images/map/map-pin-red.svg"),
  mapPinYellow: require("@/assets/images/map/map-pin-yellow.svg"),
  mapPinTransparant: require("@/assets/images/map/map-pin-transparant.svg"),
  doubleBack: require("@/assets/images/double-back.svg"),
  filter: require("@/assets/images/filter.svg"),
  asetTanah: require("@/assets/images/map/aset-tanah.svg"),
  asetBangunan: require("@/assets/images/map/aset-bangunan.svg"),
  asetTanahBangunan: require("@/assets/images/map/aset-tanah-bangunan.svg"),
  dummyMap: require("@/assets/images/assets3.png"),
  editBlue: require('@/assets/images/editblue.svg'),
  ketPinBlue: require('@/assets/images/map/ket-pin-blue.svg'),
  ketPinGreen: require('@/assets/images/map/ket-pin-green.svg'),
  ketPinRed: require('@/assets/images/map/ket-pin-red.svg'),
  ketPinYellow: require('@/assets/images/map/ket-pin-yellow.svg'),
  timer: require('@/assets/images/timer.svg'),
  pdf: require('@/assets/images/pdf.svg'),
  docx: require('@/assets/images/docx.svg'),
  xlsx: require('@/assets/images/xlsx.svg'),
  rar: require('@/assets/images/rar.svg'),
  csv: require('@/assets/images/csv.svg'),
  xml: require('@/assets/images/xml.svg'),
  cancel: require('@/assets/images/cancel.svg'),
  tanahMapRed: require("@/assets/images/map/tanah-map-red.svg"),
  tanahMapBlue: require("@/assets/images/map/tanah-map-blue.svg"),
  tanahMapYellow: require("@/assets/images/map/tanah-map-yellow.svg"),
  tanahMapGreen: require("@/assets/images/map/tanah-map-green.svg"),
  tanahPinRed: require("@/assets/images/map/tanah-pin-red.svg"),
  tanahPinBlue: require("@/assets/images/map/tanah-pin-blue.svg"),
  tanahPinYellow: require("@/assets/images/map/tanah-pin-yellow.svg"),
  tanahPinGreen: require("@/assets/images/map/tanah-pin-green.svg"),
  check: require("@/assets/images/check.svg"),
  close2: require("@/assets/images/close2.svg"),
  assetValidasi: require("@/assets/images/assetValidasi.svg"),
  buttonmore: require("@/assets/images/buttonmore.svg"),
  history: require("@/assets/images/history.svg"),
  askedit: require("@/assets/images/askedit.svg"),
  validated: require("@/assets/images/validated.svg"),
  imaps: require("@/assets/images/imaps.svg"),
  noimage: require("@/assets/images/noimage.svg"),
  satelite: require("@/assets/images/satelite.svg"),
  trashbucket: require("@/assets/images/trashbucket.svg"),
  needPolygon: require("@/assets/images/need-polygon.svg")
};

export default images;
