// import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { setAllertError, setAllertSuccess } from "@/core/services/Alert";
import ApiService from "@/core/services/ApiService";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "../enums/StoreEnums";

const service =
  process.env.VUE_APP_NODE_ENV == "local"
    ? "systenant/api/v1"
    : "systenant/api/v1";

@Module
export default class AdminTompsModule extends VuexModule {
  listTenant = [];
  tenantShow = {
    tenant_name: "",
    tenant_url: "",
    tenant_status: "",
  };

  @Mutation
  [Mutations.SET_TENANT_LSIT](data) {
    this.listTenant = data;
  }

  @Mutation
  [Mutations.SET_TENANT_SHOW](data) {
    this.tenantShow = data;
  }

  get getListTenant() {
    return this.listTenant;
  }

  get getTenantShow() {
    return this.tenantShow;
  }

  @Action
  [Actions.GET_TENANT_LIST]() {
    return new Promise((resolve, reject) => {
      ApiService.get(`${service}/admin_tenant/list`)
        .then(({ data }) => {
          console.log(data, "data data");
          this.context.commit(Mutations.SET_TENANT_LSIT, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  @Action
  [Actions.TENANT_LIST_INSERT](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${service}/admin_tenant/insert`, payload)
        .then(({ data }) => {
          console.log(data, "data data");
          //   this.context.commit(Mutations.SET_TENANT_LSIT, data.data);
          this.context.dispatch(Actions.GET_TENANT_LIST);
          setAllertSuccess("Sukses Menambahkan Tenant");
          resolve(data);
        })
        .catch((err) => {
          setAllertError(err);
          reject(err);
        });
    });
  }
  @Action
  [Actions.TENANT_LIST_DELETE](id) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`${service}/admin_tenant/delete/${id}`)
        .then(({ data }) => {
          console.log(data, "data data");
          //   this.context.commit(Mutations.SET_TENANT_LSIT, data.data);
          this.context.dispatch(Actions.GET_TENANT_LIST);
          resolve(data);
        })
        .catch((err) => {
          setAllertError(err);
          reject(err);
        });
    });
  }
  @Action
  [Actions.TENANT_LIST_EDIT](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${service}/admin_tenant/update/`, payload)
        .then(({ data }) => {
          console.log(data, "data data");
          //   this.context.commit(Mutations.SET_TENANT_LSIT, data.data);
          this.context.dispatch(Actions.GET_TENANT_LIST);
          setAllertSuccess("suksess edit");
          resolve(data);
        })
        .catch((err) => {
          setAllertError(err);
          reject(err);
        });
    });
  }
}
