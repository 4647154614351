import ApiService from '@/core/services/ApiService';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '../enums/StoreEnums';

const service = process.env.VUE_APP_NODE_ENV == 'local' ? '/api/v1' : '/api/v1';

export interface CategoryDrawerStateInfo {
  CategoryDrawerState: boolean;
}

export enum DrawerModeEnum {
  ADD = 'add',
  EDIT = 'edit',
}
export interface ChannelId {
  _id: string;
  name: string;
  email: string;
  phone_number: string;
  address: string;
  is_active: boolean;
  tenant_id: string;
  workspace_id: string;
  created_at: string;
  updated_at: string;
  __v: number;
}
interface assetInfo {
  _id: string;
  name: string;
  is_multiple_choice: boolean;
  description: string;
  code: string;
  is_active: boolean;
  channel_id: ChannelId | null;
  created_at: string;
  updated_at: string;
  __v: number;
}
export interface PageCurrencyList {
  masterList: any;
}
export interface CurrencyForm {
  _id: string;
  name: string;
  description: string;
  code: string;
  is_active: boolean;
  channel_id: string;
  currency_master: string;
  formBuilder: any;
}

export interface DocumentForm {
  _id: string;
  name: string;
  channel_id: string;
  formBuilder: any;
}

@Module
export default class AssetsDataMasterModule extends VuexModule {
  CategoryDrawerState = false;
  drawerMode = DrawerModeEnum.ADD;
  listCategory = [];
  listLegalStatus = [];
  listStatusTanah = [];
  listStatusPerolehan = [];
  listKeteranganAset = [];
  listProduktivitas = [];
  listPengguna = [];
  listPengelolaan = [];
  listUpayaPemanfaatan = [];
  listOfCurrency = [];
  listOfDocument = [];
  listAllStatusCategory = [];
  dimensionUomList = [];
  dimensionCategories = [];
  tenant_id = process.env.VUE_APP_TENANT_ID;

  get getTenantId(){
    return this.tenant_id;
  }

  get getListUpayaPemanfaatan() {
    return this.listUpayaPemanfaatan;
  }

  get getListPengelolaan() {
    return this.listPengelolaan;
  }

  get getListPengguna() {
    return this.listPengguna;
  }

  get getListProduktivitas() {
    return this.listProduktivitas;
  }

  get getKeteranganAset() {
    return this.listKeteranganAset;
  }

  get getAllStatusCategory() {
    return this.listAllStatusCategory;
  }

  get getAllDimensionUomList() {
    return this.dimensionUomList;
  }

  get getAllDimensionCategoriesList() {
    return this.dimensionCategories
  }

  statusCategory: assetInfo = {
    _id: '',
    name: '',
    is_multiple_choice: false,
    description: '',
    code: '',
    is_active: true,
    channel_id: {
      _id: '',
      name: '',
      email: '',
      phone_number: '',
      address: '',
      is_active: true,
      tenant_id: '',
      workspace_id: '',
      created_at: '',
      updated_at: '',
      __v: 0,
    },
    created_at: '',
    updated_at: '',
    __v: 0,
  };

  get getCategoryDrawerState() {
    return this.CategoryDrawerState;
  }

  get getCategoryDrawerMode() {
    return this.drawerMode;
  }

  get getListCategory() {
    return this.listCategory;
  }

  get getListLegal() {
    return this.listLegalStatus;
  }

  get getListStatusTanah() {
    return this.listStatusTanah;
  }

  get getStatusCategory() {
    return this.statusCategory;
  }

  get getStatusPerolehan() {
    return this.listStatusPerolehan;
  }

  /**
   * start currency Module
   * @description Action & Mutation Currency
   * @params listOfCurrency: array
   */
  get getListCurrency() {
    return this.listOfCurrency;
  }
  @Action
  [Actions.GET_CURRENCY](workspace_id) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/currency-type`, { params: { workspace_id: workspace_id } })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENCY, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  @Action
  [Actions.CREATE_CURRENCY](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${service}/currency-type`, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENCY, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  @Action
  [Actions.UPDATE_CURRENCY](payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(
        `${service}/currency-type`,
        payload.params.id,
        payload.data
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  @Action
  [Actions.DELETE_CURRENCY](id) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`${service}/currency-type/${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  @Mutation
  [Mutations.SET_CURRENCY](data) {
    this.listOfCurrency = data;
  }

  /**
   * end currency Module
   */

  /**
   * start Document Module
   * @description Action & Mutation Document
   * @params listOfDocument: array
   */
  get getListDocument() {
    return this.listOfDocument;
  }
  @Action
  [Actions.GET_DOCUMENT](workspace_id) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/document-type`, { params: { workspace_id }})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DOCUMENT, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  @Action
  [Actions.CREATE_DOCUMENT](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${service}/document-type`, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DOCUMENT, data.data);
          this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
            action : 'SETTINGS.CREATE_DOCUMENT',
            data : {
              document : data.data
            }
          });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  @Action
  [Actions.UPDATE_DOCUMENT](payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(
        `${service}/document-type`,
        payload.params.id,
        payload.data
      )
        .then(({ data }) => {
          this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
            action : 'SETTINGS.UPDATE_DOCUMENT',
            data : {
              document : payload.data
            }
          });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  @Action
  [Actions.DELETE_DOCUMENT](id) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`${service}/document-type/${id}`)
        .then(({ data }) => {
          this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
            action : 'SETTINGS.DELETE_DOCUMENT',
            data : {
              document_id : id
            }
          });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  @Mutation
  [Mutations.SET_DOCUMENT](data) {
    this.listOfDocument = data;
  }

  /**
   * end document Module
   */

  @Action
  [Actions.GET_UPAYA_PEMANFAATAN](status_category_id) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/status-category-value/find`, {
        params: { status_category_id: status_category_id },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_UPAYA_PEMANFAATAN, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_STATUS_PENGELOLAAN](status_category_id) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/status-category-value/find`, {
        params: { status_category_id: status_category_id },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_STATUS_PENGELOLAAN, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_USER_ASSETS](status_category_id) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/status-category-value/find`, {
        params: { status_category_id: status_category_id },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER_ASSETS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_STATUS_PRODUKTIVITAS](status_category_id) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/status-category-value/find`, {
        params: { status_category_id: status_category_id },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_STATUS_PRODUKTIVITAS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_KETERANGAN_ASSETS](status_category_id) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/status-category-value/find`, {
        params: { status_category_id: status_category_id },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_KETERANGAN_ASSETS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_STATUS_LEGAL_ASSETS](status_category_id) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/status-category-value/find`, {
        params: { status_category_id: status_category_id },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_STATUS_LEGAL_ASSETS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_STATUS_CATEGORY](status_category_id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`${service}/status-category/${status_category_id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_STATUS_CATEGORY, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_STATUS_TANAH_ASSETS](status_category_id) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/status-category-value/find`, {
        params: { status_category_id: status_category_id },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_STATUS_TANAH_ASSETS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.CREATE_CATEGORY_ASSETS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/status-category-value`, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.UPDATE_CATEGORY_ASSETS_DRAWER, false);
          this.context.dispatch(Actions.ASSET_ACTIVITY_LOG, {
            action : 'SETTINGS.CREATE_CATEGORY_ASSETS',
            data : {
              category_assets : data?.data || data
            }
          });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.UPDATE_CATEGORY_ASSETS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`${service}/status-category-value`, payload.id, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.UPDATE_CATEGORY_ASSETS_DRAWER, false);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.DELETE_CATEGORY_ASSETS](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`${service}/status-category-value/${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.UPDATE_CATEGORY_ASSETS_DRAWER](state: boolean): void {
    this.context.commit(Mutations.SET_CATEGORY_ASSETS_DRAWER, state);
  }

  @Action
  [Actions.UPDATE_CATEGORY_ASSETS_DRAWER_MODE](mode: DrawerModeEnum) {
    this.context.commit(Mutations.SET_CATEGORY_ASSETS_DRAWER_MODE, mode);
  }

  @Action
  [Actions.GET_ASSET_CATEGORY_LIST](params) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/status-category-value/find`, {
        params: { status_category_id: params, workspace_id: '631063238c130d3fa0415ec2' }
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DATA_KIND_ASSET, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_STATUS_PEROLEHAN_LIST](status_category_id) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/status-category-value/find`, {
        params: { status_category_id: status_category_id },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_STATUS_PEROLEHAN_LIST, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_ALL_STATUS_CATEGORY]() {
    return new Promise((resolve, reject) => {
      ApiService.get(`${service}/status-category`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_STATUS_CATEGORY, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_DIMENSION_UOM_LIST](workspace_id) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/dimension-uom`, { params: { workspace_id: workspace_id } })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DATA_KIND_ASSET, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  
  @Action
  [Actions.CREATE_ASET_DIMENSION](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/dimension-uom`, payload)
        .then(({ data }) => {
            this.context.dispatch(Actions.UPDATE_CATEGORY_ASSETS_DRAWER, false);
            resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.UPDATE_ASET_DIMENSION](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`${service}/dimension-uom`, payload.id, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.UPDATE_CATEGORY_ASSETS_DRAWER, false);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  
  @Action
  [Actions.DELETE_ASET_DIMENSION](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`${service}/dimension-uom/${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  
  @Action
  [Actions.GET_CATEGORIES_ASET_DIMENSION](workspace_id) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/dimension-category`, { params: { workspace_id: workspace_id } })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DIMENSION_UOM_CATEGORIES_LIST, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Mutation
  [Mutations.SET_DIMENSION_UOM_LIST](data): void {
    this.dimensionUomList = data;
  }

  @Mutation
  [Mutations.SET_DIMENSION_UOM_CATEGORIES_LIST](data): void {
    this.dimensionCategories = data;
  }


  @Mutation
  [Mutations.SET_STATUS_PEROLEHAN_LIST](data): void {
    this.listStatusPerolehan = data;
  }

  @Mutation
  [Mutations.SET_CATEGORY_ASSETS_DRAWER](payload): void {
    this.CategoryDrawerState = payload;
  }

  @Mutation
  [Mutations.SET_CATEGORY_ASSETS_DRAWER_MODE](mode: DrawerModeEnum) {
    this.drawerMode = mode;
  }

  @Mutation
  [Mutations.SET_DATA_KIND_ASSET](data) {
    this.listCategory = data;
  }

  @Mutation
  [Mutations.SET_STATUS_LEGAL_ASSETS](data) {
    this.listLegalStatus = data;
  }

  @Mutation
  [Mutations.SET_STATUS_TANAH_ASSETS](data) {
    this.listStatusTanah = data;
  }

  @Mutation
  [Mutations.SET_STATUS_CATEGORY](data) {
    this.statusCategory = data;
  }
  @Mutation
  [Mutations.SET_KETERANGAN_ASSETS](data) {
    this.listKeteranganAset = data;
  }

  @Mutation
  [Mutations.SET_STATUS_PRODUKTIVITAS](data) {
    this.listProduktivitas = data;
  }

  @Mutation
  [Mutations.SET_USER_ASSETS](data) {
    this.listPengguna = data;
  }

  @Mutation
  [Mutations.SET_STATUS_PENGELOLAAN](data) {
    this.listPengelolaan = data;
  }

  @Mutation
  [Mutations.SET_UPAYA_PEMANFAATAN](data) {
    this.listUpayaPemanfaatan = data;
  }

  @Mutation
  [Mutations.SET_ALL_STATUS_CATEGORY](data) {
    this.listAllStatusCategory = data;
  }
}
