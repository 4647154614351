// preventDoubleClick.ts

import { DirectiveBinding, ObjectDirective, ref } from 'vue';

const delay = 500; // Set the time window for double-click prevention in milliseconds

const preventDoubleClick: ObjectDirective<HTMLElement> = {
  mounted(el, binding) {
    const lastClickTime = ref(0);

    const clickHandler = () => {
      const currentTime = new Date().getTime();
      if (currentTime - lastClickTime.value < delay) {
        return;
      }
      lastClickTime.value = currentTime;
      binding.value();
    };

    el.addEventListener('click', clickHandler);
  },
};

export default preventDoubleClick;
