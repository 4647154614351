const DocMenuConfig = [
  {
    heading: "Dasbor",
    route: "/dashboard",
    available: "all"
  },
  {
    available: "all",
    pages: [
      {
        heading: "Dasbor",
        route: "/dashboard",
        available: "all",
      },
      {
        // heading: "mainMenuTop.dasbor",
        heading: "Dashboard Map",
        route: "/dashboard-map",
        available: "all",
        menu: "dashmap",
      },
      {
        heading: "Dashboard Summary",
        route: "/dashboard-summary",
        available: "all",
        menu: "dashsum",
      },
      {
        heading: "mainMenuTop.report",
        route: "/reporting",
        available: "all",
        menu: "report",
      },
      {
        heading: "mainMenuTop.listAsset",
        route: "/assets/list",
        routeName: "daftar-aset",
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        available: "all",
        menu: "assetlist"
      },
      // {
      //   heading: "Laporan",
      //   route: "/report",
      //   svgIcon: "/media/icons/duotune/art/art002.svg",
      //   fontIcon: "bi-app-indicator",
      //   available: "all",
      // },
      /* {
        heading: "Program",
        route: "/program",
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        available: "all",
      },*/
      // {
      //   heading: "Integrasi",
      //   route: "/integrasi-proyek",
      //   svgIcon: "/media/icons/duotune/art/art002.svg",
      //   fontIcon: "bi-app-indicator",
      //    available: "all",
      // },
      {
        heading: "mainMenuTop.setting",
        route: "/setting/",
        routeName: "setting",
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        available: "all",
        menu: "setting",
      },
      {
        heading: "Admin",
        route: "/admin/history",
        available: "all",
        menu: "admin",
      },
    ],
  },
  // {
  //   heading: "Master Data",
  //   route: "/master-data",
  //    available: "all",
  //   pages: [
  //     {
  //       heading: "User",
  //       route: "/master-data/users/user-listing",
  //       svgIcon: "media/icons/duotune/general/gen014.svg",
  //       fontIcon: "bi-calendar3-event",
  //       available: "all",
  //     },
  //     {
  //       heading: "Role",
  //       route: "/master-data/roles/role-listing",
  //       svgIcon: "media/icons/duotune/general/gen014.svg",
  //       fontIcon: "bi-calendar3-event",
  //       available: "all",
  //     },
  //   ],
  // },
];

export default DocMenuConfig;
