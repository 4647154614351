import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { mixinMaster } from "@/core/services/Alert";
import { detectSubDomain } from "@/core/helpers/helps";

@Module
export default class KafkaModule extends VuexModule {
	@Action({ rawError: true })
	[Actions.ASSET_ACTIVITY_LOG](payload:any) {
		const dataUSer = localStorage.getItem("role");
		const user = JSON.parse(dataUSer as any);
		const payloadPost = {
			...payload.data,
			domain : detectSubDomain()
		}

		let channel = '';
		if (payload.data?.tenants) {
			if (payload.data.tenants[0].workspaces.length > 0) {
				if (payload.data.tenants[0].workspaces[0].channels.length > 0) {
					channel = payload.data.tenants[0].workspaces[0].channels[0]
				}
			}
		}

		return new Promise<void>((resolve, reject) => {
			ApiService.post(`${process.env.VUE_APP_KAFKA_URL}`, {
				action: payload.action,
                asset_id: payload?.asset_id || "",
				channel : payload?.data?.channel_id || channel,
                asset_name: payload?.asset_name || "",
                user: payload?.username || user?.name || "",
				email : this.context.getters.getUserProfile?.email || "public@tomps.id",
				user_id : payload?.user_id || this.context.getters.getUserProfile?._id || "public@tomps.id",
				module : "ASSET",
				tenant_code : "KBUMN",
				payload: JSON.stringify(payloadPost),
			}, {
                params : {
                    topic : 'asset-log-activity_history'
                }
            })
				.then(({ data }) => {
					// mixinMaster().fire({
					// 	icon: 'success',
  					// 	title: `History ${payload.action} - ${payload.asset_name} Berhasil dicatat`
					// })
					return resolve(data);
				})
				.catch((err) => {
					this.context.commit(Mutations.SET_ERROR, err);
					mixinMaster().fire({
						icon: 'error',
  						title: `History ${payload.action} Gagal dicatat`
					})

					reject();
				});
		});
	}

	@Action({ rawError: true })
	[Actions.ADMIN_ACTIVITY_LOG](payload:any) {
		const dataUSer = localStorage.getItem("role");
		const user = JSON.parse(dataUSer as any);

		return new Promise<void>((resolve, reject) => {
			ApiService.post(`${process.env.VUE_APP_KAFKA_URL}`, {
				action: payload.action,
				email : payload?.email || user?.email || "public@tomps.id",
				module : "ASSET",
				tenant_code : "KBUMN",
				payload: {
                    ...payload?.data,
					domain : detectSubDomain(),
					user_id : payload?.user_id || user?.id || "public@tomps.id",
                },
			}, {
                params : {
                    topic : 'admin-log-activity_history'
                }
            })
				.then(({ data }) => {
					// mixinMaster().fire({
					// 	icon: 'success',
  					// 	title: `History ${payload.action} - ${payload.asset_name} Berhasil dicatat`
					// })
					return resolve(data);
				})
				.catch((err) => {
					this.context.commit(Mutations.SET_ERROR, err);
					mixinMaster().fire({
						icon: 'error',
  						title: `History ${payload.action} Gagal dicatat`
					})
					reject();
				});
		});
	}
}
