
import { ref, getCurrentInstance, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Getters } from "@/store/enums/StoreTutorialEnums";

import IntroJs from "intro.js";
export default {
  setup() {
    // code start introjs-7

    const $instance = getCurrentInstance();
    const next = ref(0);
    const nextStepTutorial = ref(0);
    const nextListStep = ref(0);
    const show = ref(false);
    const { fullPath } = useRoute();
    const store = useStore();
    const loadingMenu = computed(() => store.getters[Getters.LOADING]);
    const localStorageFun = (name: string) => {
      try {
        if (name) {
          const a = localStorage.getItem(name) as any;
          return a ? JSON.parse(a) : undefined;
        } else {
          return name;
        }
      } catch (e) {
        console.log("localStorageFun", e);
      }
    };
    const funDocument = (name: string, status = "1", local?: string) => {
      return new Promise((resolve) => {
        switch (status) {
          case "1":
            {
              const doc = document.querySelectorAll(name);
              doc[0]
                ?.getElementsByClassName("next")[0]
                ?.addEventListener("click", () => {
                  resolve("step-1");
                });
              doc[0]
                ?.getElementsByClassName("lewati")[0]
                ?.addEventListener("click", () => {
                  resolve("step-2");
                  if (local) {
                    localStorage.setItem("saveSettingTutorial", local);
                  }
                });
            }
            break;
          case "2":
            {
              const time = setInterval(() => {
                const doc = document.querySelectorAll(name);
                if (doc.length > 0) {
                  doc[0]
                    ?.getElementsByClassName("next")[0]
                    ?.addEventListener("click", () => {
                      resolve("step-1");
                      if (local) {
                        localStorage.setItem("saveSettingTutorial", local);
                      }
                    });

                  doc[0]
                    ?.getElementsByClassName("lewati")[0]
                    ?.addEventListener("click", () => {
                      resolve("step-2");
                      if (local) {
                        localStorage.setItem("saveSettingTutorial", local);
                      }
                    });
                  clearInterval(time);
                }
              });
            }
            break;
        }
      });
    };

    const lewati = () => {
      let array = [];
      array = localStorageFun("introjs-tutorial");
      array.push("project-candidate-1" as never);
      localStorage.setItem("introjs-tutorial", JSON.stringify(array));

      localStorage.removeItem("saveSettingTutorial");
      localStorage.setItem("project-candidate", JSON.stringify("list"));
    };
    const listStep = () => {
      return $instance?.proxy?.$nextTick(() => {
        const intro = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                "#introjs-8"
              )[0] as HTMLElement,
              intro: `<div id="introjs-kandidat-8">
  <div>Proyek berdasarkan status</div>
  <div>
    Klik salah satu status proyek disamping untuk melihat detail proyek Anda
  </div>
  <div class="grid-tutorial">
    <button class="next">Selanjutnya</button>
  </div>
</div>
`,
              position: "right",
            },
            {
              element: document.querySelectorAll(
                "#introjs-8"
              )[0] as HTMLElement,
              intro: `<div id="introjs-kandidat-9">
  <div>Definisi Status Proyek</div>
  <div>
    <ol>
      <li> Proyek Kandidat : Proyek yang akan dikelola</li>
      <li> Proyek Aktif : Proyek yang sedang di kelola</li>
      <li> Tech Closed : Proyek yang selesai namun administrasi belum lengkap</li>
      <li> Proyek Closed : Proyek yang sudah selesai</li>
      <li> Draft : Proyek yang tersimpan dan belum di publikasikan</li>
      <li>     Batal : Daftar Proyek yang di batalkan
      </li>
    </ol>
  </div>
  <div class="grid-tutorial">
    <button class="next">Mengerti</button>
  </div>
</div>`,
              position: "right",
            },
            {
              element: document.querySelectorAll(
                "#introjs-9"
              )[0] as HTMLElement,
              intro: `<div id="introjs-kandidat-10">
  <div>Buat proyek anda sekarang</div>
  <div>
    Setelah buat proyek baru,Ikuti langkah selanjutnya dengan mengisi beberapa form yang telah disediakan
  </div>
  <div>
  <div class="progress-bar">
  <progress value="33.7" max="100"></progress>
  </div>
    <div>1 of 3</div>

</div>
  <div class="grid-tutorial">
    <div class="lewati">Lewati</div>
    <button class="next">Selanjutnya</button>
  </div>
</div>`,
              position: "left",
            },
          ],
        });
        // http://localhost:8080/dashboard/index

        intro.start();
        //1
        funDocument("#introjs-kandidat-8", "1").then((status) => {
          switch (status) {
            case "step-1":
              nextStepTutorial.value = 1;
              intro.nextStep();
              break;
            case "step-2":
              intro.exit();
              lewati();
              break;
          }
        });

        intro.onafterchange(() => {
          // 2
          if (nextStepTutorial.value === 1) {
            funDocument("#introjs-kandidat-9", "2").then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 2;
                  intro.nextStep();
                  break;
                case "step-2":
                  intro.exit();
                  lewati();
                  break;
              }
            });
          } else if (nextStepTutorial.value === 2) {
            // 3
            funDocument("#introjs-kandidat-10", "2").then((status) => {
              switch (status) {
                case "step-1":
                  intro.nextStep();
                  // next table,
                  nextListStep.value = 1;
                  break;
                case "step-2":
                  intro.exit();
                  lewati();
                  break;
              }
            });
          }
        });
      });
    };
    const listStep2 = () => {
      if (fullPath === "/project/CZONM") {
        $instance?.proxy?.$nextTick(() => {
          const intro2 = IntroJs().setOptions({
            showButtons: false,
            showBullets: false,
            showProgress: false,
            exitOnOverlayClick: false,
            exitOnEsc: false,
            skipLabel: "",

            steps: [
              {
                element: document.querySelectorAll(
                  ".introjs-10.el-dropdown-link"
                )[0] as HTMLElement,
                intro: `<div id="introjs-kandidat-11">
  <div>Edit Proyek Kandidat</div>
  <div>
    Klik di sini untuk ubah data pada Proyek Kandidat Anda
  </div>
  <div>
  <div class="progress-bar">
  <progress value="66.7" max="100"></progress>
  </div>
    <div>2 of 3</div>

</div>
  <div class="grid-tutorial">
    <div  class="lewati">Lewati</div>
    <button class="next">Selanjutnya</button>
  </div>
</div>`,
                position: "left",
              },
              {
                element: document.querySelectorAll(
                  ".introjs-11.btn.btn-sm"
                )[0] as HTMLElement,
                intro: `<div id="introjs-kandidat-12">
  <div>Mengaktifkan proyek yang akan dikelola</div>
  <div>
    Klik tombol “Activate” untuk memulai mengelola proyek kandidat yang telah di buat
  </div>
  <div>
  <div class="progress-bar">
  <progress value="100" max="100"></progress>
  </div>
    <div>3 of 3</div>

</div>
  <div class="grid-tutorial">
    <div  class="lewati">Lewati</div>
    <button class="next">Selanjutnya</button>
  </div>
</div>`,
                position: "left",
              },
            ],
          });
          intro2.start();

          funDocument("#introjs-kandidat-11").then((status) => {
            switch (status) {
              case "step-1":
                nextStepTutorial.value = 1;
                intro2.nextStep();
                break;
              case "step-2":
                intro2.exit();
                lewati();
                break;
            }
          });
          intro2.onafterchange(() => {
            if (nextStepTutorial.value === 1) {
              funDocument("#introjs-kandidat-12", "2").then((status) => {
                switch (status) {
                  case "step-1":
                    nextStepTutorial.value = 2;
                    intro2.nextStep();
                    lewati();
                    break;
                  case "step-2":
                    intro2.exit();
                    lewati();
                    break;
                }
              });
            }
          });
        });
      }
    };
    watch(loadingMenu, (varnew) => {
      if (varnew === true) {
        let save2 = localStorageFun("project-candidate");
        if (fullPath === "/project/CZONM") {
          if (nextListStep.value === 0) {
            $instance?.proxy?.$nextTick(() => {
              const save = localStorageFun("introjs-tutorial");

              if (
                !save?.includes("project-candidate-1") &&
                !save2?.includes("list" as never)
              ) {
                listStep();
              }
            });
          } else if (nextListStep.value === 1) {
            $instance?.proxy?.$nextTick(() => {
              const save = localStorageFun("introjs-tutorial");

              if (
                !save?.includes("project-candidate-1") &&
                !save2?.includes("list" as never)
              ) {
                listStep2();
              }
            });
          }
        }
      }
    });
    watch(nextListStep, (varnew) => {
      if (varnew === 0) {
        listStep();
      } else if (varnew === 1) {
        const save2 = localStorageFun("project-candidate");
        $instance?.proxy?.$nextTick(() => {
          const save = localStorageFun("introjs-tutorial");

          if (
            !save?.includes("project-candidate-1") &&
            !save2?.includes("list" as never)
          ) {
            listStep2();
          }
        });
      }
    });
    return {
      next,
      show,
    };
  },
};
