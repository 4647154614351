import { App } from "vue";
import axios from "axios";
import store from "@/store";
import router from "@/router";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import JwtMytensService from "@/core/services/JwtMytensService";
import TelkomsatService from "@/core/services/JwtMytensService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { setAllertError } from "@/core/services/Alert";
import resolveSubdomain from "@/core/helpers/env";
import { detectSubDomain } from "../helpers/helps";
import CryptoJS from "crypto-js";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL =
      detectSubDomain() == "simapkominfo"
        ? process.env.VUE_APP_GATEWAY_KOMINFO
        : resolveSubdomain();
    // this.setHeader();
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common[
      "permission-key"
    ] = CryptoJS.AES.encrypt(`${process.env.VUE_APP_PERMISSION_STRING}`, `${process.env.VUE_APP_SECRET_KEY}`).toString();
  }

  public static setHeaderApproval(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer 632c5acff60465b5f6162fc3`;
  }

  /**
   * @description set the default endpoint url
   */
  public static setMytensUrl(): void {
    ApiService.vueInstance.axios.defaults.baseURL =
      process.env.VUE_APP_MYTENS_API;
  }
  /**
   * @description set the default endpoint url
   */
  public static setTelkomsatUrl(): void {
    ApiService.vueInstance.axios.defaults.baseURL =
      process.env.VUE_APP_TELKOMSAT_URL;
  }
  /**
   * @description set the default endpoint url
   */
  public static setUrl(): void {
    (ApiService.vueInstance.axios.defaults.baseURL = resolveSubdomain()),
      this.setHeader();
  }
  /**
   * @description set the default endpoint url
   */
  public static setTsatUrl(): void {
    ApiService.vueInstance.axios.defaults.baseURL =
      process.env.VUE_APP_TSAT_URL;
  }
  /**
   * @description set the default endpoint approval url
   */
   public static setApprovalurl(): void {
    ApiService.vueInstance.axios.defaults.baseURL =
      process.env.VUE_APP_API_URL_APPROVAL;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setTokenMytens(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtMytensService.getToken()}`;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setTokenTelkomsat(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${TelkomsatService.getToken()}`;
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    return ApiService.vueInstance.axios
      .get(resource, params)
      .catch((err) => {
        if (err.response?.status === 401) {
          store
            .dispatch(Actions.REFRESH_TOKEN)
            .then((res) => {
              window.location.reload();
              ApiService.vueInstance.axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${JwtService.getToken()}`;
              return Promise.all([
                window.location.reload(),
                store.commit(Mutations.SET_TOKEN, res.data.token),
                store.dispatch(Actions.VERIFY_AUTH),
              ])
                .then(() => true)
                .finally(() => {
                  window.location.reload();
                });
            })
            .catch(() => {
              setTimeout(() => {
                setAllertError("Sesi Sudah Habis");
                store.dispatch(Actions.LOGOUT).then(() => {
                  router.push({ name: "sign-in" });
                });
              }, 700);
              // const error =
              //   err.response && err.response.data.msg
              //     ? err.response.data.msg
              //     : err.response.data.data ?? err.message;
            });
        }

        if (err.response.status === 401) {
          return Promise.reject("memuat ulang");
        }
        const error =
          err.response && err.response.data.msg
            ? err.response.data.msg
            : err.response.data.data ?? err.message;
        return Promise.reject(error);
      })
      .finally(() => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static queryMytens(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    return ApiService.vueInstance.axios
      .get(resource, params)
      .catch((err) => {
        return Promise.reject(err);
      })
      .finally(
        () => (
          (ApiService.vueInstance.axios.defaults.baseURL = resolveSubdomain()),
          this.setHeader()
        )
      );
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    return ApiService.vueInstance.axios
      .get(`${resource}/${slug}`)
      .catch((err) => {
        // if (err.response?.status === 401) {
        //   store.dispatch(Actions.LOGOUT).then(() => {
        //     setAllertError("Sesi anda telah berakhir");

        //     router.push({ name: "sign-in" });
        //   });
        // }

        if (err.response.status === 401) {
          store
            .dispatch(Actions.REFRESH_TOKEN)
            .then((res) => {
              window.location.reload();
              ApiService.vueInstance.axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${JwtService.getToken()}`;
              return Promise.all([
                window.location.reload(),
                store.commit(Mutations.SET_TOKEN, res.data.token),
                store.dispatch(Actions.VERIFY_AUTH),
              ])
                .then(() => true)
                .finally(() => {
                  window.location.reload();
                });
            })
            .catch(() => {
              setTimeout(() => {
                setAllertError("Sesi Sudah Habis");
                store.dispatch(Actions.LOGOUT).then(() => {
                  router.push({ name: "sign-in" });
                });
              }, 700);
              // const error =
              //   err.response && err.response.data.msg
              //     ? err.response.data.msg
              //     : err.response.data.data ?? err.message;
            });
        }

        if (err.response.status === 401) {
          return Promise.reject("memuat ulang");
        }
        const error =
          err.response && err.response.data.msg
            ? err.response.data.msg
            : err.response.data.data ?? err.message;
        return Promise.reject(error);
      })
      .finally(() => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: any
   * @param config: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params?: any,
    config?: AxiosRequestConfig | undefined
  ): Promise<AxiosResponse> {
    // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    return ApiService.vueInstance.axios
      .post(`${resource}`, params, config)
      .catch((err) => {
        // if (err.response?.status === 401) {
        //   store.dispatch(Actions.LOGOUT).then(() => {
        //     setAllertError("Sesi anda telah berakhir");
        //     router.push({ name: "sign-in" });
        //   });
        // }
        if (err.response?.status === 401) {
          store
            .dispatch(Actions.REFRESH_TOKEN)
            .then((res) => {
              window.location.reload();
              ApiService.vueInstance.axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${JwtService.getToken()}`;
              return Promise.all([
                window.location.reload(),
                store.commit(Mutations.SET_TOKEN, res.data.token),
                store.dispatch(Actions.VERIFY_AUTH),
              ])
                .then(() => true)
                .finally(() => {
                  window.location.reload();
                });
            })
            .catch(() => {
              setTimeout(() => {
                setAllertError("Sesi Sudah Habis");
                store.dispatch(Actions.LOGOUT).then(() => {
                  router.push({ name: "sign-in" });
                });
              }, 700);
              // const error =
              //   err.response && err.response.data.msg
              //     ? err.response.data.msg
              //     : err.response.data.data ?? err.message;
            });
        }

        if (err.response.status === 401) {
          return Promise.reject("memuat ulang");
        }

        if (err.response.status === 409 || err.response.status === 400) {
          const error =
          err?.response && err?.response?.data?.msg
            ? err?.response?.data?.msg
            : err?.response?.data?.data ?? err.message;
          return Promise.reject(error);
        }

        const error =
          err?.response && err?.response?.data?.msg
            ? err?.response?.data?.msg
            : err?.response?.data?.data ?? err.message;
          
        return Promise.reject(error);
      })
      .finally(() => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: any
   * @param config: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
   public static loginServices(
    resource: string,
    params?: any,
    config?: AxiosRequestConfig | undefined
  ): Promise<AxiosResponse> {
    // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    return ApiService.vueInstance.axios
      .post(`${resource}`, params, config)
      .catch((err) => {
        return Promise.reject(err);
      })
      .finally(() => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static postMytens(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    return ApiService.vueInstance.axios
      .post(`${resource}`, params)
      .catch((err) => {
        return Promise.reject(err);
      })
      .finally(
        () => (
          (ApiService.vueInstance.axios.defaults.baseURL = resolveSubdomain()),
          this.setHeader()
        )
      );
  }
  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static postTelkomsat(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    return ApiService.vueInstance.axios
      .post(`${resource}`, params)
      .catch((err) => {
        return Promise.reject(err);
      })
      .finally(
        () => (
          (ApiService.vueInstance.axios.defaults.baseURL = resolveSubdomain()),
          this.setHeader()
        )
      );
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    slug: string,
    params: any,
    config?: AxiosRequestConfig | undefined
  ): Promise<AxiosResponse> {
    // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    return ApiService.vueInstance.axios
      .put(`${resource}/${slug}`, params, config)
      .catch((err) => {
        // if (err.response?.status === 401) {
        //   store.dispatch(Actions.LOGOUT).then(() => {
        //     setAllertError("Sesi anda telah berakhir");

        //     router.push({ name: "sign-in" });
        //   });
        // }
        if (err.response?.status === 401) {
          store
            .dispatch(Actions.REFRESH_TOKEN)
            .then((res) => {
              window.location.reload();
              ApiService.vueInstance.axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${JwtService.getToken()}`;
              return Promise.all([
                window.location.reload(),
                store.commit(Mutations.SET_TOKEN, res.data.token),
                store.dispatch(Actions.VERIFY_AUTH),
              ])
                .then(() => true)
                .finally(() => {
                  window.location.reload();
                });
            })
            .catch(() => {
              setTimeout(() => {
                setAllertError("Sesi Sudah Habis");
                store.dispatch(Actions.LOGOUT).then(() => {
                  router.push({ name: "sign-in" });
                });
              }, 700);
              // const error =
              //   err.response && err.response.data.msg
              //     ? err.response.data.msg
              //     : err.response.data.data ?? err.message;
            });
        }

        if (err.response.status === 401) {
          return Promise.reject("memuat ulang");
        }
        const error =
          err.response && err.response.data.msg
            ? err.response.data.msg
            : err.response.data.data ?? err.message;
        return Promise.reject(error);
      })
      .finally(() => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      });
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    return ApiService.vueInstance.axios
      .delete(resource)
      .catch((err) => {
        // if (err.response?.status === 401) {
        //   store.dispatch(Actions.LOGOUT).then(() => {
        //     setAllertError("Sesi anda telah berakhir");

        //     router.push({ name: "sign-in" });
        //   });
        // }
        if (err.response?.status === 401) {
          store
            .dispatch(Actions.REFRESH_TOKEN)
            .then((res) => {
              window.location.reload();
              ApiService.vueInstance.axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${JwtService.getToken()}`;
              return Promise.all([
                window.location.reload(),
                store.commit(Mutations.SET_TOKEN, res.data.token),
                store.dispatch(Actions.VERIFY_AUTH),
              ])
                .then(() => true)
                .finally(() => {
                  window.location.reload();
                });
            })
            .catch(() => {
              setTimeout(() => {
                setAllertError("Sesi Sudah Habis");
                store.dispatch(Actions.LOGOUT).then(() => {
                  router.push({ name: "sign-in" });
                });
              }, 700);
              // const error =
              //   err.response && err.response.data.msg
              //     ? err.response.data.msg
              //     : err.response.data.data ?? err.message;
            });
        }

        if (err.response.status === 401) {
          return Promise.reject("memuat ulang");
        }
        const error =
          err.response && err.response.data.msg
            ? err.response.data.msg
            : err.response.data.data ?? err.message;
        return Promise.reject(error);
      })
      .finally(() => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      });
  }
  // public static deleteFcm(
  //   resource: string,
  //   params: AxiosRequestConfig
  // ): Promise<AxiosResponse> {
  //   // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
  //   return ApiService.vueInstance.axios
  //     .delete(resource, params)
  //     .catch((err) => {
  //       if (err.response?.status === 401) {
  //         store.dispatch(Actions.LOGOUT).then(() => {
  //           setAllertError("Sesi anda telah berakhir");

  //           router.push({ name: "sign-in" });
  //         });
  //       }
  //       const error =
  //         err.response && err.response.data.msg
  //           ? err.response.data.msg
  //           : err.response.data.data ?? err.message;
  //       return Promise.reject(error);
  //     })
  //     .finally(() => {
  //       store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
  //     });
  // }

  public static deleteFcm(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    return ApiService.vueInstance.axios
      .delete(`${resource}`, { data: params })
      .catch((err) => {
        if (err.response?.status === 401) {
          store.dispatch(Actions.LOGOUT).then(() => {
            setAllertError("Sesi anda telah berakhir");
            router.push({ name: "sign-in" });
          });
        }
        const error =
          err.response && err.response.data.msg
            ? err.response.data.msg
            : err.response.data.data ?? err.message;
        return Promise.reject(error);
      })
      .finally(() => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      });
  }
}

export default ApiService;
