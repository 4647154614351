import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";

const service =
  process.env.VUE_APP_NODE_ENV == "local" ? "role/api/v1" : "role/api/v1";
// const service = "role/api/v1";

@Module
export default class RoleModule extends VuexModule {
  menus = window.localStorage.getItem("menus")
    ? JSON.parse(window.localStorage.getItem("menus") as any)
    : null;

  get roleMenus() {
    return this.menus;
  }

  @Mutation
  [Mutations.SET_ROLE_MENUS](menus) {
    this.menus = menus;
  }

  @Action
  [Actions.UPDATE_ROLE_MENUS](menus) {
    this.context.commit(Mutations.SET_ROLE_MENUS, menus);
  }

  @Action({ rawError: true })
  async [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(`${service}/profile`)
        .then(async ({ data }) => {
          const res = data;
          this.context.commit(Mutations.SET_AUTH, res.data);
          window.localStorage.setItem("user", JSON.stringify(res.data));
          if (res.data.roles.length > 0) {
            const menus = {};
            res.data.roles.forEach((role) => {
              role.role.menus.forEach((menu) => {
                const perm = {};
                menu.permissions.forEach((permission) => {
                  perm[permission.permission.code] = permission;
                });
                menus[menu.menu.slug] = menu;
                menus[menu.menu.slug].permissions = perm;
              });
            });
            await this.context.dispatch(Actions.UPDATE_ROLE_MENUS, menus);
            window.localStorage.setItem("menus", JSON.stringify(menus));
          }
          return Promise.resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          return Promise.reject(err);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
      return false;
    }
  }
}
