import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    mainMenuTop: {
      dasbor: 'Dashboard',
      report: 'Reporting',
      listAsset: 'List Assets',
      setting: 'Settings',
    },
    admin: {
      adminHistory: 'History Admin',
      historyActivity: 'History Activity',
      monitoringCoordinate: 'Coordinate Monitoring',
      descCoordinate: 'List of all assets along with their locations and coordinates.',
      description: 'Activities and updates that admins do for each asset.',
      descriptionHistory: 'View activity and updates made by users for each asset.',
      name: 'Name',
      nip: 'NIP',
      last_activity: 'Last Activity',
      asset: 'Asset',
      activity: 'Activity',
      search_name: 'Search Name',
      location: 'Location',
      point: 'coordinate point',
      user: 'User',
      subactivity: 'Subactivity',
      date: 'Date'
    },
    pageListAssets: {
      searchPlaceholder: 'Search Asset',
      optionLegal: 'Legal Status',
      optionLocation: 'Location',
      descriptionTitle: 'Displaying Asset List',
      buttonAdd: 'Add Asset',
      buttonRequestReview : 'Request Validation',
      buttonImport: 'Import Asset',
      buttonDownload: 'Download',
      buttonHistoryRequestUpdate: 'History Request Update',
      listRequestUpdate: 'List Request Update Assets',
      requestUpdate: 'Update Request',
      modalDownloadAsset:{
        headerTitle : 'Download List Asset',
        buttonDownload : 'Download',
        loadingDownloadText : 'Processing Your Data',
        requiredField : 'Please select the type of download data list',
        information : 'Data of Asset Coordinate Outside Indonesia is a list of assets that are outside the region of Indonesia. Please download this data if you want to check the Latitude & Longitude of the location assets that you have imported or inputted.',
        option : {
          placeholder : 'Select List Data Downloads',
          ALL : 'List of Asset ',
          OUTSIDE : `Data of Asset Coordinate Outside Indonesia`,
          INSIDE : 'Data of Asset Coordinate Indonesia'
        }
      },
      modalImportAsset: {
        titleNew: 'Import New Asset',
        titleUpdate: 'Update Polygon & Polyline',
        detailNew: `Add a new asset by importing an excel file from your computer. Download the template if you don't have the required file format.`,
        detailUpdate: `Update the existing asset's polygons and polylines by importing a file from your computer. Download the template if you don't have the required file format.`,
        dragHere: 'Drag File Here or ',
        browse: 'Browse File',
        excelFormat: 'Excel document format.',
        actionImportNew: 'Import New Asset',
        actionImportUpdate: 'Update Polygon & Polyline',
        actionDownloadTemplate: 'Download Template',
        selection: {
          title: 'Import Asset',
          detail: 'Add a new asset or update existing asset polygons and polylines by importing an excel file.',
          newAsset: 'Import New Asset',
          detailNewAsset: 'Add a new asset by importing an excel file.',
          updateAsset: 'Update Polygon & Polyline',
          detailUpdate: 'Update existing asset data by importing an excel file.'
        }
      },
      modalHistory: {
        title : 'Activity History List',
        search : 'Search',
        tabs : {
          activityHistory : 'Activity History',
          approvalHistory : 'Approval History'
        },
        tableApproval : {
          dateTime : 'Date & Time',
          activity : 'Activity',
          note : 'Notes',
          approvalStatus : 'Status Approval'
        },
        tableActivity : {
          dateTime : 'Date & Time',
          action : 'Action',
          assetName : 'Asset Name',
          assetDescriptions : 'Description',
          assetType : 'Asset Type',
          assetLarge : 'Asset Area',
          acquisitionValue : 'Acquisition Value',
          bookValue : 'Book Value',
          legalStatus : 'Legal Status',
          landStatus : 'Land Status',
          usageStatus : 'Usage Status'
        }
      },
      bulkDeleteAsset: {
        confirms: 'Are you sure to delete this',
        yes: 'Delete',
        no: 'Cancel',
        modal: {
          delete: 'Delete',
          assets: 'Assets',
          asset: 'Asset',
          deleteOne: 'Are you sure you want to delete',
          deleteMore: 'Are you sure you want to delete',
          trimMore: ' the selected assets?'
        }
      },
      approval: {
        name: {
          initial: 'Initial',
          verified: 'Verified',
          unverified: 'Unverified',
          waiting_verification: 'Waiting Verification',
          waiting_validasi: 'Waiting Validation',
          invalid: 'Invalid',
          update_req: 'Update Requested'
        },
        validation: 'Validate Asset',
        confirm_validation: 'Are you sure you want to validate this asset?',
        yes: 'validate this asset',
        cancel: 'Cancel',
        title_update: 'Accept Update Request',
        confirm_update: 'Are you sure you want to accept the update request for this asset?',
        verification: 'Verify Asset',
        confirm_verification: 'Are you sure you want to Verify assets?',
        option: 'Optional',
        return_title: 'Return Asset data',
        confirm_return: 'Are you sure you want to return this asset data?',
        return: 'Return',
        reject: 'Reject Validation',
        reject_verif: 'Reject Verification',
        title_reject: ' Reject Validation Request',
        title_reject_verif: ' Reject Verification Request',
        title_reject_update: 'Reject Update Request',
        confirm_reject_update: 'Are you sure you want to reject the update request for this asset?',
        confirm_reject: ' Are you sure you want to reject the validation request for this asset?',
        confirm_reject_verif: ' Are you sure you want to reject the verification request for this asset?',
        placeholder: 'Enter Notes',
        approve_update: 'Approve the update request',
        sure: 'Are you sure you want to',
        notes: 'Notes',
        validate: 'Validate',
        verif: 'Verify',
        title_verif_all: 'Verify All',
        confirm_verif_all: 'Are you sure you want to verify all assets?',
        title_validate_all: 'Validate All',
        confirm_validate_all: 'Are you sure you want to validate all assets?',
        all_pass: 'Accept All'
      },
      validationPolygon: {
        title: `Asset Has No Polygon`,
        description: 'The asset must have a polygon to be validated.',
        add_polygon: 'Add Polygon',
        close: 'Close'
      },
      tab: {
        all: 'All assets',
        wait_validation: 'Waiting for Validation',
        wait_verification: 'Waiting for Verification',
        initial: 'Initial',
        wait_update_permission: 'Waiting for Update Permission',
        update_assets: 'Update Assets'
      },
      modalAskUpdate: {
        title: 'Request Update Permission',
        detail: 'Are you sure you want to request update permission for this asset?',
        note: 'Notes',
        optional: '(Optional)',
        placeholder: 'Enter notes',
        btnOk: 'Request Update Permission',
        btnCancel: 'Cancel'
      }
    },
    dashboardMapPage: {
      button: {
        back: 'Back',
        backToAssetList: 'Back to Asset List',
        applyFilter: 'Apply Filter',
        next: 'Next',
        previous: 'Previous',
        assetInformation: 'Asset Information',
        location: 'Location',
        certificate: 'Certificate/ Owner Data',
        adminData: 'Administration Data On Going',
        valueAssetData: 'Value Asset Data',
        assetPhoto: 'Asset Photo',
        doc: 'Document',
      },
      label: {
        assetType: 'Assets Type',
        productivitasStatus: 'Productivity Status',
        optionLegal: 'Legal Status',
        optionProvince: 'Province',
        optionCity: 'City',
        colorDescription: 'Color Description',
        totalAsset: 'Total Aset',
        buildingAsset: 'Building Assets',
        landAsset: 'Land Assets',
      },
      placeholder: {
        searchMap: 'Find Location',
        searchPlaceholder: 'Search Asset Name',
        optionChannel: 'Select BUMN',
        optionLegal: 'Select Legal Status',
        optionProvince: 'Select Province',
        optionCity: 'Select City',
      },
      noData: 'No assets found',
    },
    detailAssets: {
      informasiAsset: 'Assets Information',
      agencyBUMN: 'BUMN Agency',
      chooseBUMN: 'Choose BUMN',
      chooseBUMNplaceholder: 'Choose BUMN',
      jenisAsset: 'Assets Type',
      nameAsset: 'Assets Name',
      nameAssetPlaceholder: 'Input Assets Name',
      numberAsset: 'Assets Number',
      acquisitionText: 'Acquisition',
      acquisitionDate: 'Acquisition Date',
      acquisitionDatePlaceholder: 'e.g 14/02/2023',
      acquisitionStatus: 'Acquisition Status',
      acquisitionStatusPlaceholder: 'Investment/Puchase',
      acquisitionValue: 'Acquisition Value',
      locationAsset: 'Assets Location',
      provinceAsset: 'Province',
      cityAsset: 'City / Regency',
      districtAsset: 'District',
      villageAsset: 'Village',
      addressAsset: 'Address',
      areaPointLoc: 'Area Point Location',
      chooseCoor: 'Choose Coordinate on Map',
      certAndOwnershipAsset: 'Certificate / Ownership Data',
      certInfoAsset: 'Certificate Information',
      landStatAsset: 'Land Status',
      doc: 'Documents',
      docNumberAsset: 'Documents Number',
      docSuppNumberAsset: 'Documents Support Number',
      registeredAsset: 'Registered As',
      issuedAsset: 'Issued By',
      issuedDateAsset: 'Issued Date',
      endDateAsset: 'End Date Certificate',
      totalAreaAsset: 'Total Area',
      totalAsset: 'Total',
      noPicAsset: 'Picture Number',
      administrationDataAsset: 'Administration Data On Going',
      legalityStatAsset: 'Legality Status',
      provisionAsset: 'Provision',
      descAsset: 'Assets Description',
      areaStatusAsset: 'Area of Mastery Status',
      productivityAsset: 'Productivity Status',
      managementDataAsset: 'Data Management',
      addData: 'Add Data',
      valueDataAsset: 'Value Asset Data',
      uploadPhotoAsset: 'Upload Photo',
      uploadDocAsset: 'Upload Document',
      choosePointAsset: 'Choose Point on Map',
      effortAsset: 'Assets Utilization Data',
      effort: 'Assets Utilization',
      descUtlAsset: 'Assets Utilization Description',
      listPhotoAsset: 'List Asset Photo',
      listDocAsset: 'List Asset Document',
      docNameAsset: 'Document Name',
      photoAsset: 'Assets Photo',
      mngListAsset: 'Asset Management List',
      letterNumber: 'Letter Number',
      user: 'User',
      userName: 'User Name',
      mngStatus: 'Management Status',
      fileType: 'File Type',
      uploadDate: 'Upload Date',
      size: 'Size',
      unitArea: 'Unit Area',
      action: 'Action',
      bookValue: 'Book Value',
      valueBookAsset: 'Value',
      bookValueAction: 'Action',
      currencyBook: 'Currency',
      yearBookValue: 'Year of Book',
      revaluationValue: 'Revalutaion Value',
      saveAsset: 'Save as Initial',
      saverCoor: 'Save',
      addAssetValueData: 'Add Asset Value Data',
      editAssetValueData: 'Edit Asset Value Data',
      effortOnGoing: 'e.g Effort is On Going',
      addProduktif:
        'Description: Please click button "✓" for adding list of asset management',
      validationProductif: 'You have not filled in the management data completely',
      addAssetManagement: 'Add Asset Data Management',
      addUtilization: 'Add Dtilization Efforts',
      locationUndefined: 'Not specified ',
      confirmDelete: 'Are you sure to delete this?'
    },
    imageUpload: {
      uploadPhoto: 'Upload Photo',
      rulesUpload: 'Click on filed below to upload.',
      mainRulesUpload:
        'Please Upload Photo in jpg and png format and max size 5MB.',
      addPhoto: 'Add Photo',
      mainPhoto: 'Main Photo',
    },
    documentUpload: {
      uploadDocument: 'Upload Document',
      rulesUpload: 'Click on filed below to upload.',
      mainRulesUpload:
        'Please Upload Document in .pdf .docx .xls rar .xml .csv format and max size 5MB.',
      addDocument: 'Add Document',
      mainDocument: 'Main Document',
    },
    tableAssets: {
      kodeAsset: 'Asset Code',
      nomorAsset: 'Asset Number',
      totalAsset: 'Total Assets',
      nameAsset: 'Asset Name',
      categoryAsset: 'Asset Category',
      location: 'Location',
      valueAsset: 'Acquisition value',
      bumn: 'BUMN',
      status: 'Asset Status',
      action: 'Action',
      applicant : 'Applicant',
      requestDate : 'Request Date',
      status_validation: 'Validation Status',
      note: 'Notes'
    },
    tabAssets: {
      tabUpdateList: 'List Update Assets',
      tabCreateList: 'List Create Assets',
    },
    headingPage: {
      list: 'List of Assets',
      requestUpdate : 'List of Request Update Asset',
      requestValidate : 'List of Request Validate Asset',
      requestVerify : 'List of Request Verify Asset',
      requestReview : 'Request Validation',
      information: 'Create New Assets',
      emptyData : 'No data is found'
    },
    subHeadingPage: {
      requestReview : 'List of assets that can be submitted for validation.'
    },
    dataValueAssets: {
      addTitle: 'Add Value Assets',
      add: 'Add',
      edit: 'Edit',
      notice: {
        year: 'Please select year',
        currency: 'Please select currency',
        revaluation_value: 'Please input Revalutaion Value',
        book_value: 'Please input Book Value',
      },
      alert: 'There are still empty fields or fields that do not match, please check again',
      min_book: 'book value cannot be 0, minimum 1',
      min_rev: 'revaluation value cannot be 0, minimum 1'
    },
    signUpPage: {
      signUpText: 'Register',
      formTabPerson: '1. Personal Information',
      formTabCompany: '2. Company Information',
      formLabelUser: 'Add New User',
      formLabelName: 'Name',
      formPlaceholderName: 'Type your name',
      formLabelUsername: 'Username',
      formLabelNIK: 'NIK',
      formLabelNIP: 'NIP',
      formPlaceholderUsername: `Type your Username`,
      formLabelEmail: 'Email',
      formPlaceholderEmail: 'Type your email address',
      formLabelPhone: 'Phone Number',
      formPlaceholderPhone: 'Type your phone number',
      formLabelPassword: 'Password',
      errorValidatePassword: 'The password must be at least 8 characters long and must contain uppercase, lowercase, numbers and special characters',
      errorValidateUsername: `Username can't contain Uppercase, symbol or space`,
      formPlaceholderPassword: 'Type your password',
      formLabelConfirmPassword: 'Confirm Password',
      formLabelConfirmPasswordnotMatch: "Password didn't match",
      formPlaceholderConfirmPassword: 'Repeat your password',
      formBtnCancel: 'Cancel',
      formBtnNext: 'Next',
      formLabelIndustry: 'Industry',
      formPlaceholderIndustry: 'Select your job industry',
      formLabelCompanyName: 'Company Name',
      formPlaceholderCompanyName: 'Type company name you worked on',
      formLabelCompanyAddress: 'Company Address',
      formPlaceholderCompanyAddress:
        'Type the address of the company you worked on',
      formLabelJobTitle: 'Job Title',
      formLabelPlaceholderJobTitle: 'Type your job title',
      formLabelTOC: 'I agree with Terms and Conditions',
      formErrorConfirmPassword: 'Password doesn’t match. Please check and try again.',
      modalSuccessHeading: 'Your Registration Will Be Processed',
      modalSuccessClickDone: "Click 'Done' to verify your account.",
      modalSuccessVerificationSent: "We've sent you an verification email to ",
      modalSuccessBtnDone: 'Done',
      modalSuccessBtnCall: 'Contact Admin',
      modalFailedHeading: 'Failed to register new account',
      modalFailedApologize: 'Sorry, your registration failed',
      modalFailedSolution: 'Please try to register your account again',
      modalFailedButtonDone: 'Register Again',
      modalSuccessButtonCall: 'Call Admin',
      modalSuccessButtonDone: 'Done',
      textIsActive: 'Status',
      textActive: 'Active',
      textInactive: 'Inactive',
      warningNik: 'NIK must be only digits',
      personalData: 'Personal Data',
      agency: 'Agency',
      formPhone: 'Number starts with the digit 0, not more than 13 digits.',
      unvalid: 'There are still empty fields or incorrect entries, please check again.'
    },
    signInPage: {
      signInText: 'Login',
      noAccountText: "Don't have an Account?",
      formLabelEmail: 'Email',
      formPlaceholderEmail: 'Type your email',
      formLabelPassword: 'Password',
      formPlaceholderPassword: 'Type your password',
      formLabelRemember: 'Remember me',
      forgotPasswordText: 'Forgot Password',
      formBtnSignIn: 'Login',
      formBtnGoogleSignIn: 'Login with Google',
    },
    changePasswordPage: {
      changePasswordText: 'Change Password',
      changePasswordInstruction:
        'Type your email and we will send a link to reset your password',
      changePasswordHelp: 'Having trouble?',
      changePasswordHelpLink: 'See Help Page',
      formLabelEmail: 'Email',
      formPlaceholderEmail: 'Type your email',
      formBtnSendLink: 'Send Link to My Email',
    },
    passwordResetPage: {
      passwordResetText: 'New Password',
      placeholderNewPasseword: 'Enter new password',
      passwordResetDescription: 'Type your new password',
      passwordResetHelp: 'Having Trouble?',
      passwordResetHelpLink: 'See Help Page',
      formLabelNewPassword: 'New Password',
      formPlaceholderNewPassword: 'Current Password',
      placeHolderCurrentPassword: 'Enter current password',
      formLabelConfirmNewPassword: 'Confirm New Password',
      formPlaceholderConfirmNewPassword: 'Enter new password confirmation',
      formBtnResetPassword: 'Reset Password',
      modalSuccessHeading: 'Reset Password Success!',
      successChange: 'Your password has been successfully changed',
      modalSuccessBody: 'You have reset your password',
      modalSuccessBtn: 'Back to Sign In',
      modalFailedHeading: 'Reset Password Failed',
      modalFailedBody: 'Please go back to reset your password again',
      modalFailedBtn: 'Repeat Reset Password',
      minPassword: 'The password must be at least 8 characters',
      rulesNewPass: 'Min. 8 characters and must have 1 uppercase, 1 lowercase, 1 number, and 1 special character.',
      passwordNotMatch: 'Password doesn’t match. Please check and try again.',
      mustFill: 'is a required field'
    },
    projectListPage: {
      btnAddProject: 'Create Project',
      btnDownloadProjects: 'Download All Project',
      btnDeleteProject: 'Delete Project',
      btnRestoreProject: 'Restore Project',
      tooltipCardView: 'Card View',
      tooltipListView: 'Table View',
      pageTitleCandidate: 'Candidate Project',
      pageDescriptionCandidate:
        'Candidate project contains a list of project which will be managed',
      pageTitleActive: 'Active Project',
      pageDescriptionActive: 'A list of ongoing project',
      pageTitleTechClosed: 'Tech Closed Project',
      pageDescriptionTechClosed:
        'Tech closed project is a list of finished project but its administration process is still ongoing',
      pageTitleClosed: 'Closed Project',
      pageDescriptionClosed: 'A list of finished project',
      pageTitleDraft: 'Draft Project',
      pageDescriptionDraft: 'A list of unpublished project',
      pageTitleCancelled: 'Cancelled Project',
      pageDescriptionCancelled: 'A list of canceled project',
      textProjectName: 'Project Name',
      textValue: 'Value',
      textPersonInCharge: 'Person in Charge',
      textCustomer: 'Customer',
      textStatus: 'Status',
      textActions: 'Actions',
      textConsumer: 'Consumer',
      textClosedDate: 'Closed Date',
      textCandidate: 'Future',
      textActive: 'Active',
      textTechClosed: 'Tech Closed',
      textClosed: 'Closed',
      textDraft: 'Draft',
      textCancelled: 'Cancelled',
      textProject: 'Project',
      textProjectStatus: 'Project Status',
      btnFilter: 'Filter',
      btnCardView: 'View as Kanban Board',
    },
    projectDetailPage: {
      textListProject: 'Project List',
      textSnyc: 'Synchronization',
      textProjectInformation: 'Project Information',
      textProjectSummary: 'Summary',
      textProjectEarn: 'Earn Value Analysis',
      textProjectBudget: 'Budget',
      textProjectOverview: 'Project Overview',
      textPrediction: 'Prediction',
      textBudgetView: 'View Details',
      textProjectPartner: 'Project Partner',
      textDeliverables: 'Deliverables',
      textTOP: 'Terms of Payment',
      textDocuments: 'Documents',
      textBAST: 'Record of Transfer',
      textIssueFollowUp: 'Issue & Follow Up',
      textCost: 'Cost',
      textSchedule: 'Schedule',
      textCustomer: 'Customer',
      textConnectivity: 'Connectivity',
      textPartnerName: 'Partner Name',
      textCustomerName: 'Customer Name',
      textValue: 'Value',
      textType: 'Type',
      textNote: 'Note',
      textGantt: 'Gantt Chart',
      textSCurves: 'SCurves',
      textDeliverablesName: 'Deliverables Name',
      textPeriod: 'Period',
      textWeight: 'Weight',
      textStatus: 'Status',
      textApproval: 'Approval',
      textInformationStatus: 'Information dan Status',
      textProjectDescription: 'Project Description',
      textProjectValue: 'Project Value',
      textProjectStatus: 'Project Status',
      textCategory: 'Category',
      textGroupType: 'Group Type',
      textTag: 'Tag/Classification',
      textStrategicProject: 'Is a strategic project?',
      textTime: 'Time',
      textStartEndTime: 'Start & Finish Time',
      textMonitoringRequestDate: 'Monitoring Request Date',
      textKickoffDate: 'Kickoff Date',
      textWorkDay: 'Work Day',
      textAddress: 'Address',
      textProvince: 'Province',
      textCity: 'City',
      textDistrict: 'District',
      textPostalCode: 'Postal Code',
      textIdentityNumber: 'Identity Number',
      textLOPNumber: 'LOP Number',
      textQuoteNumber: 'Quote Number',
      textKLNumber: 'KL Number',
      textKBNumber: 'KB Number',
      textSIDNumber: 'SID Number',
      textP8Number: 'P8 Number',
      textStakeholders: 'Stakeholders',
      textUser: 'User/Customer',
      textAccountManager: 'Account Manager',
      textProjectManager: 'Project Manager',
      textProjectAdmin: 'Project Admin',
      textOrganization: 'Organization',
      textDirectorate: 'Directorate',
      textDivision: 'Division',
      textSegment: 'Segment',
      textRegional: 'Regional',
      textProgress: 'Progress',
      textPartner: 'Partner',
      textTerm: 'Term',
      textDeadline: 'Deadline',
      textDescription: 'Description',
      textPaymentDate: 'Payment Date',
      textDocumentCategory: 'Document Category',
      textTotalDocument: 'Total Document',
      textDocumentTitle: 'Title',
      textReceivedAt: 'Received Date',
      textSPKK8Number: 'SPK/K8 Number',
      textBASTNumber: 'Record of Transfer Number',
      textIssueCreatedAt: 'Issue Created At',
      textOccuredIssue: 'Occured Issue',
      textFollowUp: 'Follow Up',
      textAddressedTo: 'Addressed To',
      textImpact: 'Impact',
      textPersonInCharge: 'Person in Charge',
      textProjectCandidate: 'Candidate Project',
      textProjectActive: 'Active Project',
      textProjectClose: 'Closed Project',
      textProjectTechClose: 'Tech Closed Project',
      textProjectDraft: 'Draft Project',
      textProjectCancel: 'Cancel Project',
      textProjectDetail: 'Project Detail',
      textProjectCandidateDetail: 'Candidate Project Detail',
      textPaymentValue: 'Payment Value',
      textPaymentType: 'Payment Type',
      textRemarks: 'Remarks',
      textDocument: 'Document',
      textAddDocument: 'Add Document',
      btnAddPartner: 'Add Project Partner',
      btnAddCustomer: 'Add Project Customer',
      btnAddDeliverables: 'Add Deliverables',
      btnAddTOP: 'Add Terms of Payment',
      btnAddTOPPartner: 'Add Partner Terms of Payment',
      btnUploadNewDocument: 'Upload New Document',
      btnAddIssueFollowUp: 'Add Issue & Follow Up',
      btnDownloadAll: 'Download All',
      btnAddDocument: 'Add',
      btnActivateProject: 'Activate Project',
      btnFinishProject: 'Finish Project',
      btnUpdateProject: 'Update Project',
      btnReturnProject: 'Reactive Project',
      btnSaveAsTemplate: 'Save as Template',
      btnImportFromTemplate: 'Import from Template',
      btnShowProject: 'View Project Details',
      btnShareEmail: 'Share via email',
      btnDownloadProject: 'Download Project',
      descriptionProjectInformation:
        'Contains main information about this project',
      descriptionProjectSummary:
        'Project information is the main information related to this project',
      descriptionProjectPartner:
        'Project partner is a selected entity to be project partner',
      descriptionDeliverables: 'Deliverables are the mandatory project outcome',
      descriptionTOP:
        'Term of Payment is an agreement regarding payment made by two parties',
      descriptionDocuments:
        'Document contains important data about this project',
      descriptionBAST:
        'Record of Transfer are the evidence that are transferred/received within the agreement',
      descriptionIssueFollowUp:
        'Issue and follow up is an predicted event that will happen and will be solved',
      textCoordinate: 'Coordinate',
      textTotalBudget: 'Total Budget',
      textActualCost: 'Actual Cost',
      textEarnedValue: 'Earned Value (EV)',
      textPlannedValue: 'Planned Value (PV)',
      textRealization: 'Total Realization',
      textAbsorption: 'Absorption',
      textRemainingBudget: 'The remaining budget',
      textProgressProyek: 'Project Progress',
      textIsuProyek: 'Issue is Happening',
      textStatusProyek: 'Project Status',
      textLastProyek: 'Last Day',
      descLastProyek: 'Project Execution Time',
      textStackholder: 'Stackholder',
      descMegaProyek: 'Mega Project',
      descValueProyek: 'Value',
      textBigData: 'Big Data',
      textCompletion: 'Estimate at Completion (EAC)',
      textComplete: 'Estimate to Complete (ETC)',
      textConfirmActivate: 'Are you sure\n you want to activate this project?',
    },
    membersPage: {
      textName: 'Name',
      textUsername: 'Username',
      textStatus: 'Status',
      textNik: 'NIK',
      textNip: 'NIP',
      textPeran: 'Role',
      textListOfMembers: 'List of Members',
      textListOfMembersDescription:
        'List of members who have registered in the application. You can add members on this page',
      textMembers: 'Members',
      textBtnAddMember: 'Add New Member',
      textActive: 'Aktif',
      textInactive: 'Tidak Aktif',
      textEmail: 'Email',
      textPassword: 'Kata Sandi',
      textConfirmPassword: 'Ulangi Kata Sandi',
      btnSaveNewMember: 'Save New Member',
      btnDownloadMembers: 'Download List of Members',
      textConfirmDeleteMember: 'Are you sure you want to delete this user?',
      textActivateMember: 'New Account Registration Request',
      textDeclineBtn: 'Decline',
      textVerifyBtn: 'Verify',
      textApproveMember: 'Verify Account',
      textApproveMemberDescription:
        'Are you sure you want to verify this account?',
      textDeclineMember: 'Decline Account',
      textDeclineMemberDescription:
        'Are you sure you want to reject this account?',
    },
    roleAndPermissionPage: {
      textRoleName: 'Role Name',
      textMembers: 'Members',
      textCode: 'Role Code',
      textPermissions: 'Permissions',
      textAddNewTeam: 'Add New Role',
      placeholderAddTeam: 'Type new role here',
      btnSaveNewTeam: 'Save New Role',
      textRequired: '{attr} is required',
      textSuccessAddTeam: 'Add new role success',
      textPage: 'Page',
      textPermission: 'Permission',
      textInputMembers: 'Select members',
      textEditRole: 'Edit Role',
      textAddNewRole: 'Add New Role',
      btnInviteMember: 'Invite Member',
      btnEditPermission: 'Edit Access',
      btnAddMember: 'Add New Member',
      textAssignMenuRole: 'Assign Menu',
      textMenus: 'Menu',
      btnAddMenu: 'Add New Menu',
      textSlug: 'Slug',
      textMenuName: 'Menu Name',
      textMenuParent: 'Parent',
      textRole: 'Role',
      textConfirmDelete: 'Are you sure to delete this role?',
      textAccess: 'Access',
      textRoleList: 'Role List',
      textRoleListDescription: 'List of roles for each app user',
    },
    common: {
      history: 'History Activity',
      back: 'Back',
      next: 'Next',
      createBumnAccount: 'Create new Aaccount',
      save: 'Save',
      delete: 'Delete',
      edit: 'Edit',
      search: 'Search',
      home: 'Home',
      dashboard: 'Dashboard',
      report: 'Report',
      project: 'Project',
      projectIntegration: 'Project Integration',
      setting: 'Setting',
      backToHome: 'Back to Home',
      backToDashboard: 'Back to Dashboard',
      pagination: 'Showing {current} to {limit} of {total} entries',
      add: 'Add',
      create: 'Create',
      update: 'Update',
      actions: 'Actions',
      yes: 'Yes',
      no: 'No',
      createdAt: 'Created At',
      createdBy: 'Created By',
      updatedAt: 'Updated At',
      updatedBy: 'Updated By',
      uploadDate: 'Upload Date',
      size: 'Size',
      view: 'View',
      backToTop: 'Back to Top',
      cancel: 'Cancel',
      updateProgress: 'Update Progress',
      viewAttachment: 'View Attachment',
      checklist: 'Checklist',
      successVerifyLogin: 'Verification success',
      closeProject: 'Close Project',
      symtomp: 'Symtomp',
      partner: 'Partner',
      deliverables: 'Deliverables',
      termOfPayments: 'Term of Payments',
      issueAndFollowUp: 'Issue and Follow Up',
      cost: 'Cost',
      nodata: 'No Data Available',
      month: 'Month',
      year: 'Year',
      download: 'Download',
      name: 'Name',
      detail: 'Detail',
      admin: 'Admin',
      sun: 'Sunday',
      mon: 'Monday',
      tue: 'Tuesday',
      wed: 'Wednesday',
      thu: 'Thursday',
      fri: 'Friday',
      sat: 'Saturday',
      success: 'Success',
      failed: 'Failed',
      import: 'Import',
      verify: 'Verify',
      validate: 'Validate',
      reject: 'Reject',
      agree: 'Agree'
    },
    templatePage: {
      textName: 'Template Name',
      textCreatedBy: 'Created By',
      textCreatedAt: 'Created Date',
      textConfirmDelete: 'Are you sure to delete this template?',
    },
    dashboardLinkPage: {
      textAddLink: 'Add Link',
      textUrl: 'URL',
      textPage: 'Page',
      textDeleteConfirm: 'Are you sure to delete link to',
    },
    projectUpdatePartner: {
      partnerName: 'Partner Name',
      amount: 'Amount',
      paymentType: 'Payment Type',
      remarks: 'Remarks',
      documentType: 'Document Type',
      documentNumber: 'Document Number',
      document: 'Document',
      addDocument: 'Add Document',
      updateProjectPartner: 'Update Project Partner',
      deleteDocument: 'Delete Document',
      deleteDocumentConfirmation: 'Are you sure to delete {filename} ?',
      progress: 'Progress',
      cost: 'Cost',
      issue: 'Issue',
      updateHistory: 'Update History',
      updatedAt: 'Updated At',
      evidence: 'Evidence',
      viewEvidence: 'View Evidence',
      realizationAmount: 'Realization Amount',
      materialOrService: 'Material / Service',
      ppu: 'Price Per Unit',
      needs: 'Needs',
      deliverableName: 'Deliverable Name',
      issueCategory: 'Issue Category',
      issueDescription: 'Issue Description',
      deadline: 'Deadline',
      status: 'status',
      addFollowUp: 'Add Follow Up',
      followUp: 'Follow Up',
      pic: 'Addressed to',
      impact: 'Impact',
    },
    setting: 'Setting',
    organization: 'Organization',
    generalSetting: 'General Setting',
    generalSettingDescription:
      'General Setting is used to set the basic configuration settings for your site',
    members: 'Members',
    rolePermission: 'Role and Permission',
    dashboard: 'Dashboard',
    report: 'Report',
    masterData: 'Master Data',
    template: 'Template',
    dashboardGDS: 'Executive Dashboard',
    dashboardYodya: 'Dashboard Yodya',
    dashboardMap: 'Map Dashboard',
    dashboardSummary: 'Summary Dashboard',
    dashboardSummaryAdmin: 'Summary Admin Dashboard',
    dashboardLink: 'Dashboard Link',
    deliverables: 'Deliverables',
    termsOfPayment: 'Terms of Payment',
    projectCost: 'Project Cost',
    issueAndFollowUp: 'Issue and Follow Up',
    document: 'Document',
    partner: 'Partner',
    layoutBuilder: 'Layout builder',
    craft: 'Crafted',
    pages: 'Pages',
    profile: 'Profile',
    myprofile: 'My Profile',
    changePass: 'Change Password',
    profileOverview: 'Overview',
    projects: 'Projects',
    campaigns: 'Campaigns',
    documents: 'Documents',
    connections: 'Connections',
    wizards: 'Wizards',
    horizontal: 'Horizontal',
    vertical: 'Vertical',
    account: 'Account',
    accountOverview: 'Overview',
    settings: 'Settings',
    authentication: 'Authentication',
    basicFlow: 'Basic Flow',
    signIn: 'Sign-in',
    signUp: 'Sign-up',
    passwordReset: 'Password Reset',
    error404: 'Error 404',
    error500: 'Error 500',
    apps: 'Apps',
    chat: 'Chat',
    privateChat: 'Private Chat',
    groupChat: 'Group Chat',
    drawerChat: 'Drawer Chat',
    widgets: 'Widgets',
    widgetsLists: 'Lists',
    widgetsStatistics: 'Statistics',
    widgetsCharts: 'Charts',
    widgetsMixed: 'Mixed',
    widgetsTables: 'Tables',
    widgetsFeeds: 'Feeds',
    changelog: 'Changelog',
    docsAndComponents: 'Docs & Components',
    megaMenu: 'Mega Menu',
    exampleLink: 'Example link',
    modals: 'Modals',
    general: 'General',
    inviteFriends: 'Invite Friends',
    viewUsers: 'View Users',
    upgradePlan: 'Upgrade Plan',
    shareAndEarn: 'Share & Earn',
    forms: 'Forms',
    newTarget: 'New Target',
    newCard: 'New Card',
    newAddress: 'New Address',
    createAPIKey: 'Create API Key',
    twoFactorAuth: 'Two Factor Auth',
    createApp: 'Create App',
    createAccount: 'Create Account',
    documentation: 'Documentation',
    components: 'Components',
    resources: 'Resources',
    activity: 'Activity',
    customers: 'Customers',
    gettingStarted: 'Getting Started',
    customersListing: 'Customers Listing',
    customerDetails: 'Customers Details',
    calendarApp: 'Calendar',
    creditPoint: 'Credit Points',
    activityLog: 'Activity Log',
    environmentSettings: 'Environment Settings',
    tenant: 'Tenant',
    workspace: 'Workspace',
    channel: 'Channel',
    assetStatus: 'Asset Status',
    assetCategory: 'Asset Category',
    kindAsset: 'Category Assets',
    landStatus: 'Land Status',
    legalStatus: 'Legal Status',
    assetDescriptionStatus: 'Asset Description Status',
    kindCurrency: 'Currency Type',
    kindDocument: 'Document Type',
    tokenSetting: 'Access Token',
    clientID: 'Client ID',
    clientSecret: 'Client Secret',
    usage: 'Usage',
    dashboardSide: {
      justDashbaord: 'Dashboard',
      executive: 'Executive Dashboard',
      // statusTargetProject: "Status & Target Project",
      statusTargetProject: 'Status & Progress Project',
      progressScale: 'Progress & Scale Project',
      scaleProject: 'Scale Project',
      scaleDesc: 'Display information based on project scale',
      statusProject: 'Status Project',
      statusDesc: 'Display information based on project status',
      progressTarget: 'Progress & Target',
      progressProject: 'Progress Project',
      progress: 'Progress',
      progressDesc: 'Display chart based on progress achievement in project',
      progressAchievement: 'Progress Achievement',
      progressAchievementDesc:
        'Display curve based on progress achievement on project',
      targetProject: 'Target',
      targetDesc: 'Display target and realization project per month',
      budgetTop: 'Budget',
      budget: 'Budget',
      budgetProject: 'Budget Project',
      budgetDesc: 'Display information based on project budget',
      stakeholder: 'Stakeholder',
      stakeholderBast: 'Stakeholder & BAST',
      stakeholderDesc: 'Display bundle of project Information that already run',
      segmentProject: 'Segment Project',
      segment: 'Segment',
      segmentDesc:
        'Display information of segment project based on total project (all status) and value project',
      bast: 'BAST',
      baut: 'BAUT',
      category: 'Category',
      categoryScale: 'Scale & Category',
      categoryBudget: 'Category & Budget',
      categoryProject: 'Category Project',
      categoryDesc:
        'Display information of category and segment project based on total project (all status) and value project',
      achievement: 'Achievement',
      issue: 'Issue',
      location: 'Location',
      megaProject: 'Mega Project',
      bigProject: 'Big Project',
      regularProject: 'Regular Project',
      ordinaryProject: 'Ordinary Project',
      totalNilaiProyek: 'Total Value Project',
      rencanaAnggaran: 'Budget Plan',
      penyerapanAnggaran: 'Budget Absorption',
      realisasiAnggaran: 'Budget Realization',
      sisaAnggaran: 'Budget left',
      termPayment: 'Term of Payment',
      top: 'Term of Payment',
      termPaymentDesc:
        'Display Information of customer and partner payment based on Payment Type, Total Project as a whole and Total Project value',
      customer: 'Customer',
      partner: 'Partner',
      projectManager: 'Project Manager',
      pm: 'projectManager',
      paymentType: 'Payment Type',
      totalProject: 'Total Project',
      totalValue: 'Total Value',
      customerName: 'Customer Name',
      project: 'Project',
      realization: 'Realization',
      projectActive: 'Active Project',
      partnerName: 'Partner Name',
      targetMonth: 'Month',
      target: 'Target',
      percentageTarget: '% Realization',
      bastProject: 'News (BAST dan BAUT)',
      bastDesc: 'Display status of all proposed News',
      issueProject: 'Issue Project',
      topIssue: '5 Top Issue',
      topIssueDesc:
        'Display chart based on most common issue occurs in projects',
      pageDesc:
        'An infographic containing a summary of all the data and information in the application',
      allBtn: 'All',
      dbsBtn: 'DBS',
      dgsBtn: 'DGS',
      desBtn: 'DES',
      viewDetail: 'View Detail',
      viewAll: 'View All',
      selectBudget: 'Select Budget Filter',
      selectProgress: 'Select Progress Filter',
      bastEX: 'Handover News',
      bautEX: 'Acceptance Test News',
      locationProject: 'Location Project',
      locationDesc: 'Display detail information based on location project',
      activeUsageProject: 'Active Usage',
      activeUsageDesc: 'Display remaining active usage from partner',
      days: 'days',
      gigabyte: 'GB',
      of: 'of',
      totalStorage: 'Total Storage',
      totalDesc: 'Display data storage usage based on partner',
      tenantsProject: 'Tenants',
      tenantsDesc: 'Display tenants list',
      storage: 'Storage',
      users: 'Users',
      dauProject: 'Daily Active Users',
      dauDesc: 'Display Daily Active Users chart',
      statusActiveProject: 'Active Usage & Status',
      dau: 'Daily Active User',
      storageTenantProject: 'Total Storage & Tenant',
      datapackage: 'Data Package Tenant',
      emptyData: 'No Data to be showed',
      budgetSpent: 'Budget Spent',
      revenue: 'Revenue',
      totalBudgetSpent: 'Target Budget Spent',
      unit: 'Unit',
      statusUser: 'Status User',
      statusTenant: 'Status Tenant',
      statusUserDesc: 'Display Status User',
      statusTenantDesc: 'Display Status Tenant',
      registeredUser: 'Registered User',
      activatedUser: 'Activated User',
      registeredTenant: 'Registered Tenant',
      activatedTenant: 'Activated Tenant',
      totalUser: 'Total User',
      totalTenant: 'Total Tenant',
      packageNo: 'No',
      packageName: 'Tenant Name',
      packageType: 'Package Type',
      packagePaymentType: 'Payment Type',
      packagePayment: 'Total Payment',
      packageTDoc: 'Total Document',
      packageAction: 'Action',
      emptyPackage: 'Not Choosen',
      addTenant: '+ Add Tenant',
      dataPackageProject: 'Data Package Tenant',
      dataPackageDesc: 'Data Package Tenant is xxxx',
      download: 'Download Dashboard',
      notPay: "Haven't Pay",
      totalPembayaran: 'Total Payment',
      sudahDibayarkan: 'Have paid',
      belumDibayarkan: 'Not yet paid',
      proyek: 'Project',
      tenantName: 'Tenant Name',
      tenantCapacity: 'Capacity',
      tenantLeftCapacity: 'Left Capacity',
      tenantRegisteredUser: 'Registered User',
      tenantActiveUser: 'Active User',
      approachDeadline: 'Approach Deadline',
      totalProyek: 'Project',
    },
    reportUser: {
      budgetUsage: 'Budget Usage',
      revenue: 'Income',
      targetTotal: 'Target Total Project',
      realization: 'Realization',
      targetBudget: 'Budget Target',
      realizationBudget: 'Budget Realization',
      target: 'Target',
      progAchieve: 'Progress & Achievement',
      progAchieveDesc: 'Display data based on progress on project',
      lead: 'Lead',
      lag: 'Lag',
      delay: 'Delay',
      active: 'Active',
      aktif: 'Active',
      closed: 'Closed',
      techClosed: 'Tech Closed',
      pm2: 'PM',
      deliverable: 'Deliverable',
      issueOpen: 'Issue Open / Total',
      plan: 'Plan',
      progressPercent: 'Progress (%)',
      achievePercent: 'Achievement',
      valueProject: 'Project Value',
      updateDate: 'Update Date',
      mega: 'Mega',
      big: 'Big',
      regular: 'Regular',
      ordinary: 'Ordinary',
      medium: 'Medium',
      small: 'Small',
      totalValue: 'Total Value',
      segmentCode: 'Segment Code',
      totalIssue: 'Total Issue',
      date: 'Date',
      symptom: 'Symptom Content',
      symptoms: 'Symptoms',
      symptomsDesc:
        'Display data information based on symptoms exist on project',
      modelCost: 'Model Cost',
      totalValueProject: 'Total Value Project',
      budgetPlan: 'Budget Plan',
      ongoingStep: 'Ongoing Steps',
      notongoingStep: 'Not Ongoing Steps',
      percentageRealization: 'Percentage Realization',
      realizationAbsorb: 'Realization Absorption',
      budgetDifference: 'Budget Difference',
      budgetStatus: 'Budget Status',
      onBudget: 'On Budget',
      overBudget: 'Over Budget',
      candidate: 'Candidate',
      techclosed: 'Tech Closed',
      draft: 'Draft',
      cancel: 'Cancelled',
      paid: 'Paid',
      unpaid: 'Unpaid',
      paymentDate: 'Payment Date',
      deadlineDate: 'Deadline Date',
      remainingDate: 'Remaining Date',
      countHappen: 'Count Happen',
      issueDesc:
        'Display list incident or issue that happen or will happen on projects',
      am: 'AM',
      noSo: 'No SO',
      lopWin: 'LOP WIN',
      lopWinDesc: 'LoP Win is project(s) that can be done',
      spkPartner: 'SPK Partner',
      spkPartnerDesc:
        'Partner SPK is a Work Order to give responsibility to partners',
      spk: 'SPK',
      topType: 'ToP type',
      idLop: 'ID LOP',
      expectedDate: 'Expected Date',
      pnName: 'PM Name',
      paymentName: 'Payment Name',
      noPb: 'No PB',
      topKb: 'TOP KB',
      topKbDesc:
        'TOP KB is detailed information based on the terms of payment in the Joint Contract',
      topKl: 'TOP KL',
      topKlDesc:
        'Summary Top KL is detailed information based on top of payment in the service contract',
      bastDate: 'BAST Date',
      restPayment: 'Rest Payment',
      partnerDesc:
        'Partners are partners who are appointed as project implementers',
      applyFilter: 'Apply Filter',
      budgetModel: 'Budget Model (Cost)',
      apps: 'Application',
      connectivity: 'Connectivity',
      cpeOther: 'CPE & Others',
      smartBuilding: 'Smart Building',
      startDate: 'Start Date',
      endDate: 'End Date',
      totalProjects: 'Total Projects',
      achieveProjects: 'Achieve Projects',
      projectLoad: 'Projects Loads',
      projectValue: 'Projects Value',
      totalCredit: 'Total Credit Points',
      weekCredit: 'Credit Points this Week',
      point: 'Point',
      leaderBoard: 'Leaderboard',
      credit: 'credit',
      levelTier: 'You’re on {level} Tier,',
      collectPoint: 'Collect {point} points to reach {levelTo}',
      cummulativePoint: 'Cummulative Points',
      cummulativeHistory: 'Credit Points History',
      dateEvent: 'Date Event',
      on: 'on',
      no: 'No',
      personalName: 'name',
      rank: 'rank',
      projectComplete: 'Project Complete',
      paymentType: 'Payment Type',
      pic: 'PIC',
      regional: 'Regional',
      regionalProject: 'Project Regional Report',
      activityUser: 'User Activity Report',
    },
    mobile: 'Mobile',
    email: 'Email',
    nik: 'NIK',
    position: 'Position',
    level: 'Level',
    contractPeriode: 'Contract Periode',
    lastActivity: 'Last Activity',
    projectActive: 'Project Active',
    dashGeneral: {
      scope: 'Scope',
      scopeDesc:
        'Displays all matters relating to the work breakdown structure within the scope of the project',
      schedule: 'Schedule',
      scheduleDesc:
        'Displays the development, execution, and control of the project schedule.',
      cost: 'Cost',
      costDesc:
        'Displays the planned budget, budget sources and information regarding payment methods.',
      stkholder: 'Stakeholder',
      stkholderDesc:
        'Displays a list of partners related to the project, total project, and total project realization',
      info: 'Information',
      infoDesc: 'Displaying information related to the project',
      risk: 'Risk Management',
      riskDesc:
        'Displays information based on the level of probability of the occurrence of risk on the project.',
      geo: 'Geospatial',
      geoDesc:
        'Displays detailed information based on the project location of each region.',
      scheduleConcl: 'Schedule Conclusion',
      scheduleConclDesc: 'Displays project totals by project completion time',
      behind: 'Behind Schedule',
      ahead: 'Ahead Schedule',
      on: 'On Schedule',
      paymentDetail: 'Detail Payment',
      paymentDetailDesc:
        'Displays a collection of project information that has been executed',
      progressProject: 'Project Progress Implementation',
      progressDesc:
        'Display project status based on the progress of the project',
      costConcl: 'Cost Conclusion',
      progProject: '% Progress Project',
      absBudget: '% Budget Absorb',
      valuePayment: 'Payment Value',
      realPayment: 'Payment Realization',
      bast: 'BAST (Minutes of Handover)',
      baut: 'BAUT (Minutes of Acceptance Test)',
      bastDesc: 'Displays the status of all submitted Minutes',
      lesson: 'Lesson Learned Project',
      lessonDesc: 'Displaying messages/lessons learned during project work',
      lessonLearn: 'Lesson Learned',
      projectEnd: 'Project Date End',
      riskLevel: 'Risk Level',
      riskLevelDesc:
        'Displays the level of possible risk that will occur in the project',
      very_high: 'High',
      high: 'Quite High',
      medium: 'Medium',
      low: 'Low',
      quality: 'Quality',
      qualityDesc: 'Shows how to control, guarantee, and improve the project.',
      budget: 'Budget',
      deliverables: 'Deliverables',
      duration: 'Duration',
      topRank: 'Top Rank Project',
      topRankDesc: 'Displays top ranking projects by specific category',
      budgetTipDesc:
        'Click here to view projects whose budget realization is close to 100%',
      deliverDesc: 'Click here to view active projects',
      durationDesc: 'Click here to view closed projects',
      totalDeliver: 'Total Deliverables',
      targetFinish: 'Target Finish',
      realFinish: 'Realization Complete',
      bottomRank: 'Bottom Rank Project',
      bottomRankDesc: 'Displays bottom ranking projects by specific category',
      source: 'Budget Source',
      sourceDesc: 'Budget Information from source',
      feelLike: 'Feels Like',
      within: 'Within Budget',
      exceed: 'Exceed Budget',
    },
    earningStatusPage: {
      earningStatus: 'Earning Status',
      earningStatusDescription: 'Asset dimensions to be used for each asset',
      addEarningStatus: 'Add Earning Status',
      description: 'Description',
      earningStatusTextMode: '{mode} Earning Status',
    },
    channelPage: {
      channelTitle: 'List Channels',
      channelDescription:
        'List of registered channels for channel X. You can add channels on this page.',
      addChannel: 'Add Channel',
      ChannelTextMode: '{mode} Channel',
    },
    categoryAssetPage: {
      categoryTitle: 'Category Assets',
      categoryDescription:
        'List of registered category for assets X. You can add assets on this page.',
      addCategory: 'Add Assets',
      CategoryTextMode: '{mode} Assets',
    },
    workspacePage: {
      workspaceTitle: 'List Workspace',
      workspaceDescription:
        'List of registered workspaces for workspace X. You can add workspaces on this page.',
      addWorkspace: 'Add New Workspace',
      WorkspaceTextMode: '{mode} Workspace',
    },
    currencyPage: {
      title: 'Currency Type',
      description: 'The type of currency to be used for each time',
      addLabel: 'Add Currency Type',
      textMode: '{mode} Document Type',
    },
    documentPage: {
      title: 'Document Type',
      description: 'Type of document to be used for each asset',
      addLabel: 'Add Document Type',
      textMode: '{mode} Document Type',
    },
    categoryValuePage: {
      placeholderNameText: '{assetName} name',
      placeholderDescriptionText: '{assetName} description',
    },
    notificationsModal: {
      title : 'Notifications',
      buttonMore : 'View More',
      emptyNotif : 'No Notifications'
    },
    powerBiAlert: {
      warningTitle: 'Power BI Token Usage is Approaching 90%',
      warningDescription : 'Power BI token usage is getting close to the maximum capacity, approaching 90%. Update the token in the {url} menu.',
      errorTitle: 'Power BI Token Usage Has Exceeded 90%',
      errorDescription: 'Power BI embed token usage has already exceeded the maximum capacity, exceeding 90%. Update the token in the {url} menu.',
      url: 'Settings - Admin - Token Access '
    },
    playground: {
      title: 'Add Coordinates',
      description: 'To add coordinates, mark a pin point or draw polygons and polylines at the desired location. Click the "Copy" button to share the generated coordinates.',
      put: 'Mark Pin Point',
      pinpoint: 'Pin Point',
      drawPolygon: 'Draw Polygon',
      drawPolyline: 'Draw Polyline',
      reset: 'Reset',
      placeholder: 'Find location',
      wideArea: 'Estimated Total Area',
      lengthArea: 'Approximate Total Length',
      copy: 'Copy',
      alertCopy: 'Copy to clipboard'
    }
  },
  id: {
    dashboardMapPage: {
      button: {
        back: 'Kembali',
        backToAssetList: 'Kembali ke Daftar Aset',
        applyFilter: 'Terapkan',
        next: 'Lanjut',
        previous: 'Kembali',
        assetInformation: 'Informasi Aset',
        location: 'Lokasi',
        certificate: 'Sertifikat/ Data Kepemilikan',
        adminData: 'Data Administrasi Berjalan',
        valueAssetData: 'Data Nilai Aset',
        assetPhoto: 'Foto Aset',
        doc: 'Dokumen',
      },
      label: {
        assetType: 'Tipe Aset',
        productivitasStatus: 'Status Produktivitas',
        optionLegal: 'Status Legalitas',
        optionProvince: 'Provinsi',
        optionCity: 'Kota',
        colorDescription: 'Keterangan Warna',
        totalAsset: 'Total Aset',
        buildingAsset: 'Aset Bangunan',
        landAsset: 'Aset Tanah',
      },
      placeholder: {
        searchMap: 'Cari Lokasi',
        searchPlaceholder: 'Cari Aset',
        optionChannel: 'Pilih BUMN',
        optionLegal: 'Pilih Status',
        optionProvince: 'Pilih Provinsi',
        optionCity: 'Pilih Kota',
      },
      noData: 'Tidak ada aset yang ditemukan',
    },
    mainMenuTop: {
      dasbor: 'Dashboard',
      report: 'Laporan',
      listAsset: 'Daftar Aset',
      setting: 'Pengaturan',
    },
    admin: {
      adminHistory: 'Riwayat Admin',
      historyActivity: 'Riwayat Aktivitas',
      monitoringCoordinate: 'Monitoring Koordinat',
      descCoordinate: 'Daftar seluruh aset beserta lokasi dan titik koordinatnya.',
      description: 'Aktivitas dan pembaruan yang dilakukan admin untuk setiap aset.',
      descriptionHistory: 'Lihat aktivitas dan pembaruan yang dilakukan oleh pengguna untuk setiap aset.',
      name: 'Nama',
      nip: 'NIP',
      last_activity: 'Pembaruan Terakhir',
      asset: 'Aset',
      activity: 'Aktivitas',
      search_name: 'Cari Nama',
      location: 'Lokasi',
      point: 'Titik Kordinat',
      user: 'Pengguna',
      subactivity: 'Subaktivitas',
      date: 'Tanggal'
    },
    pageListAssets: {
      searchPlaceholder: 'Cari Aset',
      optionLegal: 'Status Legalitas',
      optionLocation: 'Lokasi',
      descriptionTitle: 'Menampilkan Daftar Aset',
      buttonAdd: 'Tambah Aset',
      buttonImport: 'Impor Aset',
      buttonRequestReview : 'Ajukan Validasi',
      buttonDownload: 'Unduh',
      buttonHistoryRequestUpdate: 'Riwayat Permohonan Update',
      listRequestUpdate: 'Daftar Permintaan Update Asset',
      requestUpdate: 'Ajukan Pembaruan',
      modalDownloadAsset:{
        headerTitle : 'Unduh List Aset',
        buttonDownload : 'Unduh',
        requiredField : 'Silahkan Pilih Jenis List Data Download',
        loadingDownloadText : 'Memproses Data Anda',
        information : 'Data Aset Koordinat Luar Indonesia adalah daftar aset yang berada di luar region Indonesia. Silakan unduh data ini jika Anda ingin memeriksa kembali apabila terjadi kesalahan dalam penulisan Latitude & Longitude lokasi aset yang telah Anda impor atau input.',
        option : {
          placeholder : 'Pilih List Data Download',
          ALL : 'Data Aset Keseluruhan',
          OUTSIDE : 'Data Aset Koordinat Luar Indonesia',
          INSIDE : 'Data Aset Koordinat Indonesia'
        }
      },
      modalImportAsset: {
        titleNew: 'Impor Aset Baru',
        titleUpdate: 'Perbarui Polygon & Polyline',
        detailNew: 'Tambahkan aset baru dengan mengimpor file excel dari komputer. Unduh template terlebih dulu jika belum punya format file yang dibutuhkan.',
        detailUpdate: 'Perbarui polygon dan polyline pada aset yang sudah ada dengan mengimpor file dari komputer. Unduh template terlebih dulu jika belum punya format file yang dibutuhkan.',
        dragHere: 'Tarik File atau ',
        browse: 'Telusuri File',
        excelFormat: 'Format dokumen excel.',
        actionImportNew: 'Impor Aset Baru',
        actionImportUpdate: 'Perbarui Polygon & Polyline',
        actionDownloadTemplate: 'Unduh Template Aset',
        selection: {
          title: 'Impor Aset',
          detail: 'Impor aset baru atau perbarui polygon dan polyline pada aset yang sudah ada dengan mengimpor file dari komputer.',
          newAsset: 'Impor Aset Baru',
          detailNewAsset: 'Tambah aset baru dengan mengimpor file excel.',
          updateAsset: 'Perbarui Polygon & Polyline',
          detailUpdate: 'Perbarui data aset yang sudah ada dengan mengimpor file excel.'
        }
      },
      modalHistory: {
        title : 'Daftar Riwayat Aktivitas',
        search : 'Cari',
        tabs : {
          activityHistory : 'Riwayat Aktivitas',
          approvalHistory : 'Riwayat Approval'
        },
        tableApproval : {
          dateTime : 'Tanggal & Waktu',
          activity : 'Aktivitas',
          note : 'Catatan',
          approvalStatus : 'Status Approval'
        },
        tableActivity : {
          dateTime : 'Tanggal & Waktu',
          action : 'Aksi',
          assetName : 'Nama Aset',
          assetDescriptions : 'Deskripsi',
          assetType : 'Jenis Aset',
          assetLarge : 'Luas Aset',
          acquisitionValue : 'Nilai Perolehan',
          bookValue : 'Nilai Buku',
          legalStatus : 'Status Legalitas',
          landStatus : 'Status Tanah',
          usageStatus : 'Status Penggunaan'
        }
      },
      bulkDeleteAsset: {
        confirms: 'Apakah anda yakin akan menghapus aset?',
        yes: 'Hapus',
        no: 'Batal',
        modal: {
          delete: 'Hapus',
          assets: 'Aset',
          asset: 'Aset',
          deleteOne: 'Apakah Anda yakin ingin menghapus',
          deleteMore: 'Apakah Anda yakin ingin menghapus',
          trimMore: ' aset yang dipilih?'
        }
      },
      approval: {
        name: {
          initial: 'Inisial',
          verified: 'Terverifikasi',
          unverified: 'Verifikasi Ditolak',
          waiting_verification: 'Menunggu Verifikasi',
          waiting_validasi: 'Menunggu Validasi',
          invalid: 'Validasi Ditolak',
          update_req: 'Menunggu Izin Pembaruan'
        },
        validation: 'Validasi Aset',
        confirm_validation: 'Apakah Anda yakin ingin validasi asset?',
        yes: 'memvalidasi aset ini',
        cancel: 'Batal',
        title_update: 'Setujui Permintaan Pembaruan',
        confirm_update: 'Apakah Anda yakin ingin menyetujui permintaan pembaruan untuk aset ini?',
        verification: 'Verifikasi Aset',
        confirm_verification: 'Apakah Anda yakin ingin Verifikasi asset?',
        option: 'Opsional',
        return_title: 'Kembalikan Data Aset',
        confirm_return: 'Apakah Anda yakin ingin mengembalikan data asset?',
        return: 'Kembalikan',
        reject: 'Tolak Validasi',
        reject_verif: 'Tolak Verifikasi',
        title_reject: 'Tolak Permintaan Validasi',
        title_reject_verif: 'Tolak Permintaan Verifikasi',
        title_reject_update: 'Tolak Permintaan Pembaruan',
        confirm_reject_update: 'Apakah Anda yakin ingin menolak permintaan pembaruan untuk aset ini?',
        confirm_reject: 'Apakah Anda yakin ingin menolak permintaan validasi untuk aset ini?',
        confirm_reject_verif: 'Apakah Anda yakin ingin menolak permintaan verifikasi untuk aset ini?',
        placeholder: 'Masukkan Catatan',
        approve_update: 'Terima permintaan Update',
        sure: 'Apakah Anda yakin ingin',
        notes: 'Catatan',
        validate: 'Validasi',
        verif: 'Verifikasi',
        title_verif_all: 'Verifikasi Semua',
        confirm_verif_all: 'Apakah Anda yakin ingin memverifikasi semua aset?',
        title_validate_all: 'Validasi Semua',
        confirm_validate_all: 'Apakah Anda yakin ingin memvalidasi semua aset?',
        all_pass: 'Setujui Semua'
      },
      validationPolygon: {
        title: 'Aset Belum Memiliki Polygon',
        description: 'Aset harus memiliki polygon untuk bisa divalidasi.',
        add_polygon: 'Tambah Polygon',
        close: 'Tutup'
      },
      tab: {
        all: 'Semua Aset',
        wait_validation: 'Menunggu Validasi',
        wait_verification: 'Menunggu Verifikasi',
        initial: 'Initial',
        wait_update_permission: 'Menunggu Izin Pembaruan',
        update_assets: 'Perbarui Aset'
      },
      modalAskUpdate: {
        title: 'Ajukan Permintaan Pembaruan',
        detail: 'Apakah Anda yakin ingin mengajukan permintaan pembaruan untuk aset ini?',
        note: 'Catatan',
        optional: '(Opsional)',
        placeholder: 'Masukkan catatan',
        btnOk: 'Ajukan Permintaan Pembaruan',
        btnCancel: 'Batal'
      }
    },
    tableAssets: {
      kodeAsset: 'Kode Aset',
      nomorAsset: 'Nomor Aset',
      totalAsset: 'Total Aset',
      nameAsset: 'Nama Aset',
      categoryAsset: 'Kategori Aset',
      location: 'Lokasi',
      valueAsset: 'Nilai Perolehan',
      bumn: 'BUMN',
      status: 'Status Approval',
      action: 'Action',
      applicant : 'Pemohon',
      requestDate : 'Tanggal Pengajuan',
      status_validation: 'Status Validasi',
      note: 'Notes'
    },
    detailAssets: {
      informasiAsset: 'Informasi Aset',
      agencyBUMN: 'Instansi BUMN',
      chooseBUMN: 'Pilih BUMN',
      chooseBUMNplaceholder: 'Pilih BUMN',
      jenisAsset: 'Jenis Aset',
      nameAsset: 'Nama Aset',
      nameAssetPlaceholder: 'Input Nama Aset',
      numberAsset: 'Nomor Aset',
      acquisitionText: 'Perolehan',
      acquisitionDate: 'Tanggal Perolehan',
      acquisitionDatePlaceholder: 'e.g 14/02/2023',
      acquisitionStatus: 'Status Perolehan',
      acquisitionStatusPlaceholder: 'Pembelian/Investasi',
      acquisitionValue: 'Nilai Perolehan',
      locationAsset: 'Lokasi Aset',
      provinceAsset: 'Provinsi',
      cityAsset: 'Kota / Kabupaten',
      districtAsset: 'Kecamatan',
      villageAsset: 'Kelurahan / Desa',
      addressAsset: 'Alamat',
      areaPointLoc: 'Titik Lokasi Area',
      chooseCoor: 'Pilih Koordinat pada Peta',
      certAndOwnershipAsset: 'Sertifikat / Kepemilikan Data',
      certInfoAsset: 'Informasi Sertifikat',
      landStatAsset: 'Status Tanah',
      doc: 'Dokumen',
      docNumberAsset: 'Nomor Dokumen',
      docSuppNumberAsset: 'Nomor Dokumen Pendukung',
      registeredAsset: 'Tercatat Atas Nama',
      issuedAsset: 'Dikeluarkan oleh',
      issuedDateAsset: 'Tanggal dikeluarkan',
      endDateAsset: 'Tanggal Berakhir Sertifikat',
      totalAreaAsset: 'Luas Area',
      totalAsset: 'Luas Total',
      noPicAsset: 'Nomor Gambar / Surat Ukur',
      administrationDataAsset: 'Data Administrasi Berjalan',
      legalityStatAsset: 'Status Legalitas',
      provisionAsset: 'Peruntukan',
      descAsset: 'Keterangan Aset',
      areaStatusAsset: 'Luas Total Penguasaan',
      productivityAsset: 'Status Produktifitas',
      managementDataAsset: 'Data Pengelolaan Aset',
      addData: 'Tambah Data',
      valueDataAsset: 'Data Nilai Aset',
      uploadPhotoAsset: 'Unggah Photo',
      uploadDocAsset: 'Unggah Document',
      choosePointAsset: 'Pilih titik pada peta',
      effortAsset: 'Data Upaya Pemanfaatan',
      effort: 'Upaya Pemanfaatan',
      descUtlAsset: 'Keterangan Upaya Pemanfaatan',
      listPhotoAsset: 'Daftar Foto Aset',
      listDocAsset: 'Dafat Dokumen Aset',
      docNameAsset: 'Judul Dokumen',
      photoAsset: 'Foto Aset',
      mngListAsset: 'Daftar Pengelolaan Aset',
      letterNumber: 'Nomor Surat',
      user: 'Pengguna',
      userName: 'Nama Pengguna',
      mngStatus: 'Status Pengelolaan',
      fileType: 'Jenis File',
      uploadDate: 'Tanggal Unggah',
      size: 'Ukuran',
      unitArea: 'Luas',
      action: 'Aksi',
      bookValue: 'Nilai Buku',
      valueBookAsset: 'Nilai',
      bookValueAction: 'Aksi',
      currencyBook: 'Mata Uang',
      yearBookValue: 'Tahun Buku',
      revaluationValue: 'Nilai Revaluasi',
      saveAsset: 'Simpan Sebagai Initial',
      saverCoor: 'Simpan',
      addAssetValueData: 'Tambah Data Nilai Asset',
      editAssetValueData: 'Edit Data Nilai Asset',
      effortOnGoing: 'e.g Sedang Dilakukan Pemanfaatan',
      addProduktif:
        'keterangan: tekan tombol "✓" untuk menambahkan daftar pengelolaan asset',
      validationProductif: 'Anda belum mengisi data pengelolan dengan lengkap',
      addAssetManagement: 'Tambah Data Pengelolaan Aset',
      addUtilization: 'Tambah Upaya Pemanfaatan',
      locationUndefined: 'Belum Ditentukan',
      confirmDelete: 'Apakah anda yakin menghapus ini?'
    },
    imageUpload: {
      uploadPhoto: 'Unggah Foto',
      rulesUpload: 'Klik pada kotak untuk upload Photo.',
      mainRulesUpload:
        'Upload foto dalam bentuk jpg atau png dan ukuran maksimum setiap dokumen adalah 5MB.',
      addPhoto: 'Add Photo',
      mainPhoto: 'Main Photo',
    },
    documentUpload: {
      uploadDocument: 'Upload Document',
      rulesUpload: 'Click on filed below to upload.',
      mainRulesUpload:
        'Please Upload Document in .pdf .docx .xls rar .xml .csv format and max size 5MB.',
      addDocument: 'Add Document',
      mainDocument: 'Main Document',
    },
    tabAssets: {
      tabUpdateList: 'Daftar Update Aset',
      tabCreateList: 'Daftar Aset Baru',
    },
    headingPage: {
      list: 'Daftar Aset',
      requestUpdate : 'Daftar Permintaan Update Aset',
      requestValidate : 'Daftar Permintaan Validasi Aset',
      requestVerify : 'Daftar Permintaan Verifikasi Aset',
      requestReview : 'Ajukan Validasi',
      information: 'Buat Aset Baru',
      emptyData : 'Tidak ada data yang ditemukan'
    },
    subHeadingPage: {
      requestReview : 'Daftar aset yang bisa diajukan validasi.'
    },
    dataValueAssets: {
      addTitle: 'Tambah Data Nilai Aset',
      add: 'Tambah',
      edit: 'Edit',
      notice: {
        year: 'Silakan pilih tahun',
        currency: 'Silakan pilih mata uang',
        revaluation_value: 'Silakan masukan nilai revaluasi',
        book_value: 'Silakan masukan nilai buku',
      },
      alert: 'Masih terdapat kolom kosong atau isian tidak sesuai. Silakan cek kembali',
      min_book: 'Nilai buku Tidak boleh 0, minimal 1',
      min_rev: 'Nilai Revaluasi tidak boleh 0, minimal 1'
    },
    signUpPage: {
      signUpText: 'Daftar Akun Baru',
      formTabPerson: 'Informasi Pribadi',
      formTabCompany: 'Informasi BUMN',
      formLabelUser: 'Tambah Pengguna Baru',
      formLabelName: 'Nama Lengkap',
      formPlaceholderName: 'Masukkan nama lengkap',
      formLabelUsername: 'Nama Pengguna',
      formLabelNIK: 'NIK',
      formLabelNIP: 'NIP',
      formPlaceholderUsername: 'Masukan nama pengguna anda',
      formLabelEmail: 'Email Pengguna',
      formPlaceholderEmail: 'Masukkan alamat email Anda',
      formLabelPhone: 'Nomor Telepon',
      formPlaceholderPhone: 'Masukkan nomor telepon Anda',
      formLabelPassword: 'Kata Sandi',
      errorValidatePassword: 'Password minimal 8 karakter dan harus berisi huruf besar, huruf kecil, angka, karakter khusus',
      errorValidateUsername: 'Nama Pengguna tidak boleh mengandung huruf kapital, simbol, ataupun spasi',
      formPlaceholderPassword: 'Masukkan kata sandi Anda',
      formLabelConfirmPassword: 'Ulangi Kata Sandi',
      formLabelConfirmPasswordnotMatch: 'Kata sandi tidak sama',
      formPlaceholderConfirmPassword: 'Ulangi kata sandi Anda',
      formBtnCancel: 'Batal',
      formBtnNext: 'Selanjutnya',
      formLabelIndustry: 'Jenis Industry Pekerjaan',
      formPlaceholderIndustry: 'Pilih jenis industri pekerjaan perusahaan Anda',
      formLabelCompanyName: 'Nama Perusahaan',
      formPlaceholderCompanyName: 'Masukkan nama perusahaan Anda bekerja',
      formLabelCompanyAddress: 'Alamat Perusahaan',
      formPlaceholderCompanyAddress:
        'Masukkan alamat lengkap perusahaan Anda bekerja',
      formLabelJobTitle: 'Jabatan',
      formLabelPlaceholderJobTitle: 'Masukkan jabatan Anda di perusahaan',
      formLabelTOC: 'Saya setuju dengan syarat dan ketentuan yang berlaku',
      formErrorConfirmPassword: 'Kata sandi tidak sesuai. Silakan periksa dan coba lagi.',
      modalSuccessClickDone: "Klik 'Selesai' untuk verifikasi akun.",
      modalSuccessButtonCall: 'Hubungi Admin',
      modalSuccessButtonDone: 'Done',
      textIsActive: 'Status Aktif',
      textActive: 'Aktif',
      textInactive: 'Tidak Aktif',
      warningNik: 'NIK harus berisi angka',
      modalFailedHeading: 'Gagal mendaftarkan akun baru',
      modalFailedApologize: 'Maaf, pendaftaran Anda gagal',
      modalFailedSolution: 'Silakan coba daftarkan akun Anda lagi',
      modalFailedButtonDone: 'Daftar Lagi',
      personalData: 'Data Diri',
      agency: 'Instansi',
      formPhone: 'Nomor diawali angka 0, tidak lebih dari 13 digit',
      unvalid: 'Masih terdapat field yang kosong atau isian tidak sesuai, Silahkan Cek Kembali'
    },
    signInPage: {
      signInText: 'Masuk',
      noAccountText:
        'Masukkan nama pengguna dan kata sandi untuk masuk ke dalam aplikasi.',
      formLabelEmail: 'Email',
      formPlaceholderEmail: 'Masukkan email Anda',
      formLabelPassword: 'Kata Sandi',
      formPlaceholderPassword: 'Masukkan kata sandi Anda',
      formLabelRemember: 'Ingat Saya',
      forgotPasswordText: 'Lupa Kata Sandi',
      formBtnSignIn: 'Masuk',
      formBtnGoogleSignIn: 'Masuk dengan Google',
    },
    changePasswordPage: {
      changePasswordText: 'Ganti Kata Sandi',
      changePasswordInstruction:
        'Masukkan email Anda dan kami akan mengirimkan tautan untuk mengatur ulang kata sandi Anda',
      changePasswordHelp: 'Memiliki masalah?',
      changePasswordHelpLink: 'Lihat Bantuan',
      formLabelEmail: 'Email',
      formPlaceholderEmail: 'Masukkan email Anda',
      formBtnSendLink: 'Kirim Tautan ke Email Saya',
    },
    passwordResetPage: {
      passwordResetText: 'Kata Sandi Baru',
      placeholderNewPasseword: 'Masukkan kata sandi baru',
      passwordResetDescription:
        'Masukan Kata Sandi baru Anda sesuai dengan ketentuan yang ada',
      passwordResetHelp: 'Memiliki masalah?',
      passwordResetHelpLink: 'Lihat Bantuan',
      formLabelNewPassword: 'Kata Sandi Baru',
      formPlaceholderNewPassword: 'Kata Sandi Saat Ini',
      placeHolderCurrentPassword: 'Masukkan kata sandi saat ini',
      formLabelConfirmNewPassword: 'Ulangi Kata Sandi Baru',
      formPlaceholderConfirmNewPassword: 'Ulangi kata sandi baru',
      formBtnResetPassword: 'Buat Kata Sandi Baru',
      modalSuccessHeading: 'Berhasil Ganti Kata Sandi!',
      successChange: 'Password anda telah berhasil diubah',
      modalSuccessBody: 'Anda telah berhasil membuat kata sandi baru',
      modalSuccessBtn: 'Kembali ke Halaman Masuk',
      modalFailedHeading: 'Gagal Ganti Kata Sandi ',
      modalFailedBody: 'Silakan untuk kembali membuat kata sandi baru Anda',
      modalFailedBtn: 'Buat Ulang Kata Sandi Baru',
      minPassword: 'Password minimal 8 karakter',
      rulesNewPass: 'Min. 8 karakter dan harus mengandung 1 huruf besar, 1 huruf kecil, 1 angka, and 1 karakter spesial.',
      passwordNotMatch: 'Kata sandi tidak sesuai. Silakan periksa dan coba lagi.',
      mustFill: 'adalah bagian yang harus diisi'
    },
    projectListPage: {
      btnAddProject: 'Buat Proyek Baru',
      btnDownloadProjects: 'Unduh Semua Proyek',
      btnDeleteProject: 'Hapus Proyek',
      btnRestoreProject: 'Kembalikan ke Proyek Kandidat',
      tooltipCardView: 'Tampilan Kartu',
      tooltipListView: 'Tampilan Table',
      pageTitleCandidate: 'Proyek Kandidat',
      pageDescriptionCandidate:
        'Proyek Kandidat merupakan daftar proyek yang akan dikelola',
      pageTitleActive: 'Proyek Aktif',
      pageDescriptionActive:
        'Proyek Aktif merupakan daftar proyek yang sedang berlangsung',
      pageTitleTechClosed: 'Proyek Tech Closed',
      pageDescriptionTechClosed:
        'Proyek Tech closed merupakan proyek yang secara tahapan sudah selesai namun masih dalam proses melengkapi administratif ',
      pageTitleClosed: 'Proyek Closed',
      pageDescriptionClosed:
        'Proyek closed merupakan daftar proyek yang sudah selesai ',
      pageTitleDraft: 'Proyek Draft',
      pageDescriptionDraft:
        'Proyek Draft merupakan daftar proyek yang belum di publikasikan',
      pageTitleCancelled: 'Proyek Cancelled',
      pageDescriptionCancelled:
        'Proyek cancelled merupakan daftar proyek yang dibatalkan',
      textProjectName: 'Nama Proyek',
      textValue: 'Nilai',
      textPersonInCharge: 'Penanggung Jawab',
      textCustomer: 'Customer',
      textStatus: 'Status',
      textActions: 'Aksi',
      textConsumer: 'Consumer',
      textClosedDate: 'Tanggal Ditutup',
      textCandidate: 'Kandidat',
      textActive: 'Aktif',
      textTechClosed: 'Tech Closed',
      textClosed: 'Closed',
      textDraft: 'Draft',
      textCancelled: 'Batal',
      textProject: 'Proyek',
      textProjectStatus: 'Project Status',
      btnFilter: 'Filter',
      btnCardView: 'Tampilan Kanban',
    },
    projectDetailPage: {
      textListProject: 'Daftar Proyek',
      textSnyc: 'Sinkronisasi',
      textProjectInformation: 'Informasi Proyek',
      textProjectSummary: 'Ringkasan',
      textProjectEarn: 'Analisis Nilai yang Diperoleh',
      textProjectBudget: 'Anggaran',
      textProjectOverview: 'Ulasan Proyek',
      textPrediction: 'Prediksi',
      textBudgetView: 'Lihat Detail',
      textProjectInformationDescription:
        'Informasi proyek merupakan informasi-informasi utama terkait proyek ini',
      textProjectPartner: 'Partner Proyek',
      textDeliverables: 'Deliverables',
      textTOP: 'Terms of Payment',
      textDocuments: 'Dokumen',
      textBAST: 'BAST',
      textIssueFollowUp: 'Isu & Tindak Lanjut',
      textCost: 'Biaya',
      textSchedule: 'Jadwal',
      textCustomer: 'Customer',
      textConnectivity: 'Connectivity',
      textPartnerName: 'Nama Partner',
      textCustomerName: 'Nama Customer',
      textValue: 'Nilai',
      textType: 'Jenis',
      textNote: 'Catatan',
      textGantt: 'Gantt Chart',
      textSCurves: 'Kurva',
      textDeliverablesName: 'Nama Deliverable',
      textPeriod: 'Periode',
      textWeight: 'Bobot',
      textStatus: 'Status',
      textApproval: 'Approval',
      textInformationStatus: 'Informasi dan Status',
      textProjectDescription: 'Deskripsi Proyek',
      textProjectValue: 'Nilai Proyek',
      textProjectStatus: 'Status Proyek',
      textCategory: 'Kategori',
      textGroupType: 'Tipe Grup',
      textTag: 'Tag/Kelompok',
      textStrategicProject: 'Termasuk Proyek Strategis?',
      textTime: 'Waktu',
      textStartEndTime: 'Tanggal Mulai dan Tanggal Berakhir',
      textMonitoringRequestDate: 'Tanggal Permintaan Monitoring',
      textKickoffDate: 'Tanggal Kickoff',
      textWorkDay: 'Hari Bekerja',
      textAddress: 'Alamat',
      textProvince: 'Provinsi',
      textCity: 'Kota',
      textDistrict: 'District',
      textPostalCode: 'Kode Pos',
      textIdentityNumber: 'Nomor Identitas',
      textLOPNumber: 'Nomor LOP',
      textQuoteNumber: 'Nomor Quote',
      textKLNumber: 'Nomor KL',
      textKBNumber: 'Nomor KB',
      textSIDNumber: 'Nomor SID',
      textP8Number: 'Nomor P8',
      textStakeholders: 'Stakeholders',
      textUser: 'User/Customer',
      textAccountManager: 'Account Manager',
      textProjectManager: 'Project Manager',
      textProjectAdmin: 'Project Admin',
      textOrganization: 'Organisasi',
      textDirectorate: 'Direktorat',
      textDivision: 'Divisi',
      textSegment: 'Segment',
      textRegional: 'Regional',
      textProgress: 'Progress',
      textPartner: 'Partner',
      textTerm: 'Term',
      textDeadline: 'Tenggat Waktu',
      textDescription: 'Deskripsi',
      textPaymentDate: 'Tanggal Pembayaran',
      textBASTNumber: 'Nomor BAST',
      textSPKK8Number: 'Nomor SPK/K8',
      textDocumentCategory: 'Kategori Dokumen',
      textTotalDocument: 'Jumlah Dokumen',
      textDocumentTitle: 'Judul Dokumen',
      textIssueCreatedAt: 'Tanggal Isu Dibuat',
      textOccuredIssue: 'Isu yang Terjadi',
      textPersonInCharge: 'Penanggung Jawab',
      textFollowUp: 'Tindak Lanjut',
      textAddressedTo: 'Ditujukan Kepada',
      textImpact: 'Dampak',
      textProjectCandidate: 'Proyek Kandidat',
      textProjectActive: 'Proyek Active',
      textProjectClose: 'Proyek Closed',
      textProjectTechClose: 'Proyek Tech Closed',
      textProjectDraft: 'Proyek Draft',
      textProjectCancel: 'Proyek Batal',
      textProjectDetail: 'Detail Proyek',
      textProjectCandidateDetail: 'Detail Proyek Kandidat',
      textPaymentValue: 'Nilai Pembayaran',
      textPaymentType: 'Jenis Pembayaran',
      textRemarks: 'Catatan',
      textDocument: 'Dokumen',
      textAddDocument: 'Tambah Dokumen',
      btnAddPartner: 'Tambah Partner Proyek',
      btnAddCustomer: 'Tambah Customer Proyek',
      btnAddDeliverables: 'Tambah Deliverables',
      btnAddTOP: 'Tambah Terms of Payment',
      btnAddTOPPartner: 'Tambah Terms of Payment Partner',
      btnUploadNewDocument: 'Unggah Dokumen Baru',
      btnAddIssueFollowUp: 'Tambah Isu & Tindak Lanjut',
      btnDownloadAll: 'Unduh Semua',
      btnAddDocument: 'Tambah',
      btnActivateProject: 'Aktifkan Project',
      btnFinishProject: 'Selesaikan Proyek',
      btnUpdateProject: 'Perbarui Proyek',
      btnReturnProject: 'Kembalikan Proyek',
      btnSaveAsTemplate: 'Simpan Sebagai Template',
      btnImportFromTemplate: 'Import Dari Template',
      btnShowProject: 'Lihat Detail Project',
      btnShareEmail: 'Bagikan via email',
      btnDownloadProject: 'Unduh Proyek',
      descriptionProjectInformation:
        'Informasi proyek merupakan informasi-informasi utama terkait proyek ini',
      descriptionProjectSummary:
        'Informasi proyek merupakan informasi-informasi utama terkait proyek ini',
      descriptionProjectPartner:
        'Partner Proyek merupakan mitra yang ditunjuk sebagai pelaksana proyek',
      descriptionDeliverables:
        'Deliverables merupakan hasil pelaksanaan proyek sebagai salah satu kewajiban',
      descriptionTOP:
        'Term of Payment merupakan suatu perjanjian mengenai pembayaran yang dijalin oleh dua belah pihak',
      descriptionDocuments:
        'Dokumen merupakan data penting yang memuat informasi proyek',
      descriptionBAST:
        'BAST merupakan lampiran bukti yang diserahkan/diterima pihak dalam persetujuan',
      descriptionIssueFollowUp:
        'Isu dan Tindak Lanjut merupakan suatu kejadian yang diperkirakan akan terjadi di masa mendatang yang akan diselesaikan',
      textCoordinate: 'Koordinat',
      textTotalBudget: 'Total Anggaran',
      textActualCost: 'Biaya Aktual',
      textEarnedValue: 'Nilai Diterima',
      textPlannedValue: 'Nilai Plan',
      textRealization: 'Total Realisasi',
      textAbsorption: 'Penyerapan',
      textRemainingBudget: 'Sisa Anggaran',
      textProgressProyek: 'Progress Proyek',
      textIsuProyek: 'Isu Sedang Terjadi',
      textStatusProyek: 'Status Proyek',
      textLastProyek: 'Hari Tersisa',
      descLastProyek: 'Waktu Pelaksanaan Proyek',
      textStackholder: 'Pemangku Kepentingan',
      descMegaProyek: 'Mega Proyek',
      descValueProyek: 'Nilai',
      textBigData: 'Data Besar',
      textCompletion: 'Estimasi Selesai',
      textComplete: 'Perkiraan untuk Menyelesaikan',
      textConfirmActivate: 'Apakah Anda yakin\n ingin mengaktifkan proyek ini?',
    },
    membersPage: {
      textName: 'Nama Pengguna',
      textUsername: 'Nama User',
      textStatus: 'Status',
      textNik: 'NIK',
      textNip: 'NIP',
      textPeran: 'Peran',
      textMembers: 'Pengguna',
      textListOfMembers: 'Daftar Pengguna',
      textListOfMembersDescription:
        'Daftar pengguna yang telah terdaftar di aplikasi. Anda bisa menambahkan pengguna pada halaman ini',
      textBtnAddMember: 'Tambah Pengguna Baru',
      textActive: 'Aktif',
      textInactive: 'Tidak Aktif',
      textEmail: 'Email',
      textPassword: 'Kata Sandi',
      textConfirmPassword: 'Ulangi Kata Sandi',
      btnSaveNewMember: 'Simpan Pengguna Baru',
      btnDownloadMembers: 'Unduh Daftar Pengguna',
      textConfirmDeleteMember: 'Apa Anda yakin untuk menghapus pengguna ini?',
      textActivateMember: 'Permintaan Pendaftaran Akun Baru',
      textDeclineBtn: 'Tolak',
      textVerifyBtn: 'Verifikasi',
      textApproveMember: 'Verifikasi Akun',
      textApproveMemberDescription:
        'Apakah anda yakin ingin memverifikasi akun ini?',
      textDeclineMember: 'Tolak Akun',
      textDeclineMemberDescription: 'Apakah anda yakin ingin menolak akun ini?',
    },
    roleAndPermissionPage: {
      textRoleName: 'Nama Peran',
      textMembers: 'Anggota',
      textCode: 'Kode Peran',
      textPermissions: 'Ijin',
      textAddNewTeam: 'Tambah Peran Baru',
      placeholderAddTeam: 'Buat nama peran baru disini',
      btnSaveNewTeam: 'Simpan Peran Baru',
      textRequired: '{attr} harus diisi',
      textInputMembers: 'Select members',
      textEditRole: 'Ubah Peran',
      btnInviteMember: 'Undang Pengguna',
      btnEditPermission: 'Edit Akses',
      btnAddMember: 'Tambah Pengguna',
      textAddNewRole: 'Tambah Peran Baru',
      textAssignMenuRole: 'Akses Halaman',
      textMenus: 'Halaman',
      btnAddMenu: 'Tambah Akses Halaman',
      textSlug: 'Slug',
      textMenuParent: 'Induk',
      textRole: 'Peran',
      textConfirmDelete: 'Apa Anda yakin untuk menghapus peran ini?',
      textAccess: 'Akses',
      textRoleList: 'Daftar Peran',
      textRoleListDescription: 'Daftar peran untuk setiap pengguna aplikasi',
    },
    templatePage: {
      textName: 'Nama Template',
      textCreatedBy: 'Dibuat Oleh',
      textCreatedAt: 'Tanggal Dibuat',
      textConfirmDelete: 'Apa Anda yakin untuk menghapus template ini?',
    },
    dashboardLinkPage: {
      textAddLink: 'Tambah Tautan',
      textUrl: 'URL',
      textPage: 'Halaman',
      textDeleteConfirm: 'Apakah Anda yakin untuk menghapus tautan',
    },
    projectUpdatePartner: {
      partnerName: 'Nama Partner',
      amount: 'Nilai',
      paymentType: 'Jenis Pembayaran',
      remarks: 'Catatan',
      documentType: 'Jenis Dokumen',
      documentNumber: 'Nomor Dokumen',
      document: 'Dokumen',
      addDocument: 'Tambah Dokumen',
      updateProjectPartner: 'Update Partner Proyek',
      deleteDocument: 'Hapus Dokumen',
      deleteDocumentConfirmation: 'Apa Anda yakin untuk menghapus {filename} ?',
      progress: 'Progress',
      cost: 'Cost',
      issue: 'Issue',
      updateHistory: 'Riwayat Pembaruan',
      updatedAt: 'Tanggal Diupdate',
      evidence: 'Evidence',
      viewEvidence: 'View Evidence',
      realizationAmount: 'Total Realisasi',
      materialOrService: 'Material / Service',
      ppu: 'Harga per unit',
      needs: 'Kebutuhan',
      deliverableName: 'Nama Deliverable',
      issueCategory: 'Kategori Isu',
      issueDescription: 'Isu yang terjadi',
      deadline: 'Tenggat Waktu',
      status: 'Status',
      addFollowUp: 'Tambah Tindak Lanjut',
      followUp: 'Tindak Lanjut',
      pic: 'Ditujukan kepada',
      impact: 'Dampak',
    },
    common: {
      history: 'Riwayat Aktivitas',
      add: 'Tambah',
      createBumnAccount: 'Buat Akun Baru',
      create: 'Buat',
      update: 'Perbarui',
      back: 'Kembali',
      next: 'Selanjutnya',
      save: 'Simpan',
      search: 'Cari',
      home: 'Beranda',
      dashboard: 'Dasbor',
      report: 'Laporan',
      project: 'Proyek',
      projectIntegration: 'Integrasi Proyek',
      setting: 'Pengaturan',
      backToHome: 'Kembali ke Beranda',
      backToDashboard: 'Kembali ke Dashboard',
      actions: 'Aksi',
      view: 'Lihat',
      edit: 'Ubah',
      delete: 'Hapus',
      pagination: 'Menampilkan {current} sampai {limit} dari {total} data',
      yes: 'Ya',
      no: 'Tidak',
      createdAt: 'Dibuat pada',
      createdBy: 'Dibuat oleh',
      updatedAt: 'Diperbarui pada',
      updatedBy: 'Diperbarui oleh',
      uploadDate: 'Diunggah Tanggal',
      size: 'Ukuran',
      backToTop: 'Kembali ke bagian atas',
      cancel: 'Batal',
      updateProgress: 'Perbarui Progress',
      viewAttachment: 'Lihat Lampiran',
      successVerifyLogin: 'Verifikasi berhasil',
      closeProject: 'Tutup Proyek',
      symtomp: 'Symtomp',
      partner: 'Partner',
      deliverables: 'Deliverables',
      termOfPayments: 'Term of Payments',
      issueAndFollowUp: 'Isu dan Tindak Lanjut',
      cost: 'Biaya',
      nodata: 'Tidak ada data',
      month: 'Bulan',
      year: 'Tahun',
      download: 'Unduh',
      name: 'Nama',
      detail: 'Detail',
      admin: 'Admin',
      sun: 'Minggu',
      mon: 'Senin',
      tue: 'Selasa',
      wed: 'Rabu',
      thu: 'Kamis',
      fri: 'Jumat',
      sat: 'Sabtu',
      success: 'Berhasil',
      failed: 'Gagal',
      import: 'Impor',
      verify: 'Verifikasi',
      validate: 'Validasi',
      reject: 'Tolak',
      agree: 'Setujui'
    },
    setting: 'Pengaturan',
    organization: 'Organisasi',
    generalSetting: 'Pengaturan Umum',
    generalSettingDescription:
      'Pengaturan Umum digunakan untuk mengatur pengaturan konfigurasi dasar untuk situs anda',
    members: 'Pengguna',
    rolePermission: 'Peran dan Hak Akses',
    dashboard: 'Dasbor',
    report: 'Laporan',
    masterData: 'Master Data',
    template: 'Template',
    dashboardLink: 'Tautan Dasbor',
    dashboardGDS: 'Dasbor Eksekutif',
    dashboardMap: 'Dasbor Peta',
    dashboardSummary: 'Dasbor Ringkasan',
    dashboardSummaryAdmin: 'Dasbor Ringkasan Admin',
    deliverables: 'Deliverables',
    termsOfPayment: 'Terms of Payment',
    projectCost: 'Biaya Proyek',
    issueAndFollowUp: 'Isu dan Tindak Lanjut',
    document: 'Dokumen',
    partner: 'Partner',
    layoutBuilder: 'Layout builder',
    craft: 'Crafted',
    pages: 'Pages',
    profile: 'Profile',
    myprofile: 'Profile Saya',
    changePass: 'Ganti Kata Sandi',
    profileOverview: 'Overview',
    projects: 'Projects',
    campaigns: 'Campaigns',
    documents: 'Documents',
    connections: 'Connections',
    wizards: 'Wizards',
    horizontal: 'Horizontal',
    vertical: 'Vertical',
    account: 'Account',
    accountOverview: 'Overview',
    settings: 'Settings',
    authentication: 'Authentication',
    basicFlow: 'Basic Flow',
    signIn: 'Sign-in',
    signUp: 'Sign-up',
    passwordReset: 'Password Reset',
    error404: 'Error 404',
    error500: 'Error 500',
    apps: 'Apps',
    chat: 'Chat',
    privateChat: 'Private Chat',
    groupChat: 'Group Chat',
    drawerChat: 'Drawer Chat',
    widgets: 'Widgets',
    widgetsLists: 'Lists',
    widgetsStatistics: 'Statistics',
    widgetsCharts: 'Charts',
    widgetsMixed: 'Mixed',
    widgetsTables: 'Tables',
    widgetsFeeds: 'Feeds',
    changelog: 'Changelog',
    docsAndComponents: 'Docs & Components',
    megaMenu: 'Mega Menu',
    exampleLink: 'Example link',
    modals: 'Modals',
    general: 'General',
    inviteFriends: 'Invite Friends',
    viewUsers: 'View Users',
    upgradePlan: 'Upgrade Plan',
    shareAndEarn: 'Share & Earn',
    forms: 'Forms',
    newTarget: 'New Target',
    newCard: 'New Card',
    newAddress: 'New Address',
    createAPIKey: 'Create API Key',
    twoFactorAuth: 'Two Factor Auth',
    createApp: 'Create App',
    createAccount: 'Create Account',
    documentation: 'Documentation',
    components: 'Components',
    resources: 'Resources',
    activity: 'Activity',
    customers: 'Customers',
    gettingStarted: 'Getting Started',
    customersListing: 'Customers Listing',
    customerDetails: 'Customers Details',
    calendarApp: 'Calendar',
    creditPoint: 'Poin Kredit',
    activityLog: 'Jurnal Aktifitas',
    environmentSettings: 'Pengaturan Environment',
    tenant: 'Tenant',
    workspace: 'Workspace',
    channel: 'Channel',
    assetStatus: 'Status Aset',
    assetCategory: 'Kategori',
    kindAsset: 'Jenis Aset',
    kindCurrency: 'Jenis Mata Uang',
    kindDocument: 'Jenis Dokumen',
    tokenSetting: 'Akses Token',
    clientID: 'Client ID',
    clientSecret: 'Client Secret',
    usage: 'Usage',
    landStatus: 'Status Tanah',
    legalStatus: 'Status Legalitas',
    assetDescriptionStatus: 'Status Keterangan Aset',
    dashboardSide: {
      justDashbaord: 'Dashboard',
      executive: 'Dashboard Executive',
      // statusTargetProject: "Status & Target Project",
      statusTargetProject: 'Status & Progress Project',
      progressScale: 'Progress & Scale Project',
      scaleProject: 'Skala Project',
      scaleDesc: 'Menampilkan informasi berdasarkan skala proyek',
      statusProject: 'Status Proyek',
      statusDesc: 'Menampilkan informasi berdasarkan status proyek',
      progressTarget: 'Progress & Target',
      progress: 'Progress',
      progressProject: 'Progress Proyek',
      progressAchievement: 'Pencapaian Progress',
      progressAchievementDesc:
        'Menampilkan kurva berdasarkan pencapaian progress pada proyek',
      targetProject: 'Target',
      targetDesc: 'Menampilkan jumlah target dan realisasi proyek setiap bulan',
      budgetTop: 'Anggaran',
      budget: 'Anggaran',
      budgetProject: 'Anggaran Proyek',
      budgetDesc: 'Menampilkan informasi berdasarkan anggaran proyek',
      stakeholder: 'Stakeholder',
      stakeholderBast: 'Stakeholder & BAST',
      stakeholderDesc:
        'Menampilkan kumpulan informasi proyek yang telah dijalankan',
      segmentProject: 'Segmen Proyek',
      segmentDesc:
        'Menampilkan informasi segmen proyek berdasarkan jumlah proyek keseluruhan (semua status) dan nilai proyek',
      bast: 'BAST',
      baut: 'BAUT',
      category: 'Kategori',
      categoryScale: 'Skala & Kategori',
      categoryBudget: 'Kategori & Anggaran',
      categoryProject: 'Kategori Proyek',
      categoryDesc:
        'Menampilkan informasi kategori proyek berdasarkan jumlah proyek secara keseluruhan (semua status) dan nilai proyek',
      achievement: 'Achievement',
      issue: 'Isu',
      location: 'Lokasi',
      megaProject: 'Mega Proyek',
      bigProject: 'Proyek Besar',
      regularProject: 'Proyek Reguler',
      ordinaryProject: 'Proyek Biasa',
      totalNilaiProyek: 'Total Nilai Project',
      rencanaAnggaran: 'Rencana Anggaran',
      penyerapanAnggaran: 'Penyerapan Anggaran',
      realisasiAnggaran: 'Realisasi Anggaran',
      sisaAnggaran: 'Sisa Anggaran',
      termPayment: 'Termin Pembayaran',
      top: 'Termin Pembayaran',
      termPaymentDesc:
        'Menampilkan informasi pembayaran customer dan partner berdasarkan Jenis pembayaran, total proyek secara keseluruhan dan total nilai proyek',
      customer: 'Pelanggan',
      partner: 'Partner',
      projectManager: 'Manager Proyek',
      pm: 'projectManager',
      paymentType: 'Jenis Pembayaran',
      totalProject: 'Total Proyek',
      totalValue: 'Total Nilai',
      customerName: 'Nama Customer',
      project: 'Proyek',
      realization: 'Realisasi',
      projectActive: 'Proyek aktif',
      partnerName: 'Nama Partner',
      targetMonth: 'Bulan',
      target: 'Target',
      percentageTarget: '% Realisasi',
      bastProject: 'Berita Acara (BAST dan BAUT)',
      bastDesc: 'Menampilkan status atas seluruh Berita Acara yang diajukan',
      progressDesc:
        'Menampilkan kurva berdasarkan pencapaian progress pada proyek',
      issueProject: 'Isu Proyek',
      topIssue: '5 Isu Terbesar',
      topIssueDesc:
        'Menampilkan grafik berdasarkan isu yang paling banyak terjadi dalam proyek',
      pageDesc:
        'Infografis yang berisi rangkuman semua data dan informasi dalam aplikasi',
      allBtn: 'Semua',
      dbsBtn: 'DBS',
      dgsBtn: 'DGS',
      desBtn: 'DES',
      viewDetail: 'Lihat Detail',
      viewAll: 'Lihat Semua',
      selectBudget: 'Pilih Filter Anggaran',
      selectProgress: 'Pilih Filter Progress',
      bastEX: 'Berita Acara Serah Terima',
      bautEX: 'Berita Acara Uji Terima',
      locationProject: 'Lokasi Proyek',
      locationDesc: 'Menampilkan detail informasi berdasarkan lokasi proyek',
      activeUsageProject: 'Masa Aktif',
      activeUsageDesc: 'Menampilkan data masa aktif dari partner',
      days: 'hari',
      gigabyte: 'GB',
      of: 'dari',
      totalStorage: 'Total Pemyimpanan',
      totalDesc: 'Menampilkan data pemakaian storage berdasarkan partner',
      tenantsProject: 'Penyewa',
      tenantsDesc: 'Menampilkan data penyewa',
      storage: 'Penyimpanan',
      users: 'Pengguna',
      dauProject: 'Pengguna Harian Aktif',
      dauDesc: 'Menampilkan grafik pengguna harian aktif',
      statusActiveProject: 'Masa Aktif & Status',
      dau: 'Pengguna Aktif Harian',
      storageTenantProject: 'Total Penyimpanan & Penyewa',
      datapackage: 'Data Paket Penyewa',
      emptyData: 'Tidak ada Data Untuk Ditampilkan',
      budgetSpent: 'Anggaran Terpakai',
      revenue: 'Laba',
      totalBudgetSpent: 'Target Anggaran Terpakai',
      unit: 'Unit',
      statusUser: 'Status Pengguna',
      statusTenant: 'Status Penyewa',
      statusUserDesc: 'Menampilkan Status Pengguna',
      statusTenantDesc: 'Menampilkan Status Penyewa',
      registeredUser: 'Pengguna Teregistrasi',
      activatedUser: 'Pengguna Teraktifasi',
      registeredTenant: 'Penyewa Teregistrasi',
      activatedTenant: 'Penyewa Teraktifasi',
      totalUser: 'Total Pengguna',
      totalTenant: 'Total Penyewa',
      packageNo: 'No',
      packageName: 'Nama Penyewa',
      packageType: 'Jenis Paket',
      packagePaymentType: 'Jenis Pembayaran',
      packagePayment: 'Total Pembayaran',
      packageTDoc: 'Total Dokumen',
      packageAction: 'Aksi',
      emptyPackage: 'Belum Dipilih',
      addTenant: '+ Tambah Penyewa',
      dataPackageProject: 'Data Paket Penyewa',
      dataPackageDesc: 'Data Paket Tenant merupakan xxxx',
      download: 'Unduh Dasbor',
      notPay: 'Belum Bayar',
      totalPembayaran: 'Total pembayaran',
      sudahDibayarkan: 'Sudah Dibayarkan',
      belumDibayarkan: 'Belum Dibayarkan',
      proyek: 'Proyek',
      approachDeadline: 'Mendekati Deadline',
      totalProyek: 'Proyek',
    },
    reportUser: {
      budgetUsage: 'Penggunaan Anggaran',
      revenue: 'Pendapatan',
      targetTotal: 'Target Total Proyek',
      realization: 'Realisasi',
      targetBudget: 'Target Anggaran',
      realizationBudget: 'Realisasi Budget',
      target: 'Target',
      progAchieve: 'Progress & Pencapaian',
      progAchieveDesc: 'Menampilkan data berdasarkan progress pada proyek',
      lead: 'Lead',
      lag: 'Lag',
      delay: 'Delay',
      active: 'Aktif',
      aktif: 'Aktif',
      closed: 'Ditutup',
      techClosed: 'Teknis Ditutup',
      pm2: 'PM',
      deliverable: 'Deliverable',
      issueOpen: 'Isu Open / Total',
      plan: 'Rencana',
      progressPercent: 'Progress Pencapaian (%)',
      achievePercent: 'Pencapaian',
      valueProject: 'Nilai Proyek',
      updateDate: 'Tanggal Update',
      mega: 'Mega',
      big: 'Big',
      regular: 'Regular',
      ordinary: 'Ordinary',
      medium: 'Medium',
      small: 'Small',
      totalValue: 'Total Nilai',
      segmentCode: 'Kode Segmen',
      totalIssue: 'Total Isu',
      date: 'Tanggal',
      symptom: 'Isi Symptom',
      symptoms: 'Symptoms',
      symptomsDesc:
        'Menampilkan informasi data berdasarkan kendala yang ada pada proyek',
      modelCost: 'Model Cost',
      totalValueProject: 'Total Nilai Proyek',
      budgetPlan: 'Rencana Anggaran',
      ongoingStep: 'Tahapan Berlangsung',
      notongoingStep: 'Tahapan Belum Berlangsung',
      percentageRealization: 'Persentase Realisasi',
      realizationAbsorb: 'Realisasi Penyerapan',
      budgetDifference: 'Selisih Anggaran',
      budgetStatus: 'Status Anggaran',
      onBudget: 'Dalam Anggaran',
      overBudget: 'Melebihi Anggaran',
      candidate: 'Kandidat',
      techclosed: 'Tech Closed',
      draft: 'Pengajuan',
      cancel: 'Dibatalkan',
      paid: 'Dibayar',
      unpaid: 'Belum Dibayar',
      paymentDate: 'Tanggal Bayar',
      deadlineDate: 'Tenggat waktu',
      remainingDate: 'Sisa hari',
      countHappen: 'Jumlah terjadi',
      issueDesc:
        'Menampilkan daftar kejadian atau kendala yang sedang atau akan terjadi pada proyek-proyek',
      am: 'AM',
      noSo: 'No SO',
      lopWin: 'LOP WIN',
      lopWinDesc: 'LoP Win merupakan proyek-proyek yang dapat dikerjakan',
      spk: 'SPK',
      spkPartner: 'SPK Mitra',
      topType: 'Jenis ToP',
      spkPartnerDesc:
        'SPK Mitra merupakan Surat Perintah Kerja untuk memberikan tanggung jawab pada mitra',
      idLop: 'ID LOP',
      expectedDate: 'Tanggal Penantian',
      pnName: 'Nama PM',
      paymentName: 'Nama Pembayaran',
      noPb: 'No PB',
      topKb: 'TOP KB',
      topKbDesc:
        'TOP KB merupakan detail informasi berdasarkan term of payment pada Kontrak Bersama',
      topKl: 'TOP KL',
      topKlDesc:
        'Summary Top KL merupakan detail informasi berdasarkan top of payment pada kontrak layanan',
      bastDate: 'Tanggal BAST',
      restPayment: 'Sisa Pembayaran',
      partnerDesc:
        'Partner merupakan mitra yang ditunjuk sebagai pelaksana proyek',
      applyFilter: 'Terapkan Filter',
      budgetModel: 'Model Anggaran (Biaya)',
      apps: 'Aplikasi',
      connectivity: 'Konektivitas',
      cpeOther: 'CPE & lain-lain',
      smartBuilding: 'Smart Building',
      startDate: 'Tanggal Mulai',
      endDate: 'Tanggal Berakhir',
      totalProjects: 'Total Proyek',
      achieveProjects: 'Proyek tercapai',
      projectLoad: 'Beban Proyek',
      projectValue: 'Nilai Proyek',
      totalCredit: 'Total Kredit Poin',
      weekCredit: 'Kredit Poin minggu ini',
      point: 'Poin',
      leaderBoard: 'Leaderboard',
      credit: 'credit',
      levelTier: 'Anda berada di peringkat {level},',
      collectPoint: 'Kumpulkan {point} untuk meraih {levelTo}',
      cummulativePoint: 'Pengumpulan Poin',
      cummulativeHistory: 'Riwayat Poin Kredit',
      dateEvent: 'Tanggal Event',
      on: 'pada',
      no: 'No',
      personalName: 'name',
      rank: 'rank',
      projectComplete: 'Project Complete',
      paymentType: 'Tipe Pembayaran',
      pic: 'PIC',
      regional: 'Regional',
      regionalProject: 'Laporan Proyek Regional',
      activityUser: 'Laporan Aktivitas User',
    },
    mobile: 'Handphone',
    email: 'Email',
    nik: 'NIK',
    position: 'Jabatan',
    level: 'Level',
    contractPeriode: 'Periode Kontrak',
    lastActivity: 'Aktifitas Terakhir',
    projectActive: 'Proyek Aktif',
    dashGeneral: {
      scope: 'Lingkup',
      scopeDesc:
        'Menampilkan semua hal yang berkaitan dengan struktur rincian kerja dalam ruang lingkup proyek tersebut',
      schedule: 'Jadwal',
      scheduleDesc:
        'Menampilkan pengembangan, pelaksanaan, dan pengendalian jadwal proyek.',
      cost: 'Biaya',
      costDesc:
        'Menampilkan angaran yang direncanakan, sumber anggaran dan informasi terkait metode pembayaran.',
      stkholder: 'Pemegang Saham',
      stkholderDesc:
        'Menampilkan daftar mitra yang berkaitan dengan proyek, total proyek, dan total realisasi proyek',
      info: 'Informasi',
      infoDesc: 'Menampilkan informasi-informasi yang berkaitan dengan proyek',
      risk: 'Pengaturan Resiko',
      riskDesc:
        'Menampilkan informasi berdasarkan tingkat kemungkinan terjadinya risiko pada proyek.',
      geo: 'Geospasial',
      geoDesc:
        'Menampilkan informasi detail berdasarkan lokasi proyek dari masing- masing wilayah.',
      scheduleConcl: 'Kesimpulan Jadwal',
      scheduleConclDesc:
        'Menampilkan total proyek berdasarkan waktu penyelesaian proyek',
      behind: 'Dibelakang Jawdal',
      ahead: 'Didepan Jadwal',
      on: 'Tepat jadwal',
      paymentDetail: 'Detail Pembayaran',
      paymentDetailDesc:
        'Menampilkan kumpulan informasi proyek yang telah dijalankan',
      progressProject: 'Pelaksanaan Progres Proyek',
      progressDesc: 'Menampilkan status proyek berdasarkan progres pada proyek',
      costConcl: 'Kesimpulan Biaya',
      progProject: '% Progress Proyek',
      absBudget: '% Penyerapan Anggaran',
      valuePayment: 'Nilai Pembayaran',
      realPayment: 'Realisasi Pembayaran',
      bast: 'BAST (Berita Acara Serah Terima)',
      baut: 'BAUT (Berita Acara Uji Terima)',
      bastDesc: 'Menampilkan status atas seluruh Berita Acara yang diajukan',
      lesson: 'Lesson Learned Proyek',
      lessonDesc:
        'Menampilkan pesan/pelajaran yang dapat diambil pada pengerjaan proyek',
      lessonLearn: 'Pelajaran yang diambil',
      projectEnd: 'Tanggal Berakhir Proyek',
      riskLevel: 'Tingkat Resiko',
      riskLevelDesc:
        'Menampilkan tingkat kemungkinan resiko yang akan terjadi pada proyek',
      very_high: 'Tinggi',
      high: 'Cukup Tinggi',
      medium: 'Sedang',
      low: 'Rendah',
      quality: 'Kualitas',
      qualityDesc:
        'Menampilkan bagaimana pengendalian, jaminan, dan perbaikan dari suatu proyek tersebut.',
      budget: 'Anggaran',
      deliverables: 'Deliverables',
      duration: 'Durasi',
      topRank: 'Peringkat Atas Proyek',
      topRankDesc:
        'Menampilkan peringkat teratas proyek berdasarkan kategori tertentu',
      budgetTipDesc:
        'Klik di sini untuk menampilkan proyek yang anggaran realisasinya mendekati 100%',
      deliverDesc: 'Klik di sini untuk menampilkan proyek yang aktif',
      durationDesc: 'Klik di sini untuk menampilkan proyek yang sudah closed',
      totalDeliver: 'Total Deliverables',
      targetFinish: 'Target Selesai',
      realFinish: 'Realisasi Selesai',
      bottomRank: 'Peringkat Bawah Proyek',
      bottomRankDesc:
        'Menampilkan peringkat terbawah proyek berdasarkan kategori tertentu',
      source: 'Sumber Anggaran',
      sourceDesc: 'Informasi anggaran dari sumber',
      feelLike: 'Terasa seperti',
      within: 'Didalam Anggaran',
      exceed: 'Melebihi Anggaran',
    },
    earningStatusPage: {
      earningStatus: 'Status Perolehan',
      earningStatusDescription:
        'Dimensi aset yang akan digunakan untuk setiap aset',
      addEarningStatus: 'Tambah Status Perolehan',
      description: 'Deskripsi',
      earningStatusTextMode: '{mode} Status Perolehan',
    },
    channelPage: {
      channelTitle: 'Daftar Channels',
      channelDescription:
        'Daftar channel yang telah terdaftar untuk workspace X. Anda bisa menambahkan channel pada halaman ini.',
      addChannel: 'Tambah Channel',
      ChannelTextMode: '{mode} Channel',
    },
    currencyPage: {
      title: 'Jenis Mata Uang',
      description: 'Jenis mata uang yang akan digunakan untuk setiap saat',
      addLabel: 'Tambah Mata Uang',
      textMode: '{mode} Mata Uang',
    },
    documentPage: {
      title: 'Jenis Dokumen',
      description: 'Jenis dokumen yang akan digunakan untuk setiap aset',
      addLabel: 'Tambah Jenis Dokumen',
      textMode: '{mode} Jenis Dokumen',
    },
    categoryAssetPage: {
      categoryTitle: 'Jenis Asset',
      categoryDescription: 'Jenis aset yang akan digunakan untuk setiap aset',
      addCategory: 'Add Assets',
      CategoryTextMode: '{mode} Asset',
    },
    workspacePage: {
      workspaceTitle: 'Daftar Workspace',
      workspaceDescription:
        'Daftar workspace yang telah terdaftar untuk tenant . Anda bisa menambahkan workspace pada halaman ini.',
      addWorkspace: 'Tambah Workspace Baru',
      WorkspaceTextMode: '{mode} Workspace',
    },
    categoryValuePage: {
      placeholderNameText: 'Nama {assetName}',
      placeholderDescriptionText: 'Deskripsi {assetName}',
    },
    notificationsModal: {
      title : 'Notifikasi',
      buttonMore : 'Tamplikan Semua',
      emptyNotif : 'Tidak Ada Notifikasi'
    },
    powerBiAlert: {
      warningTitle: 'Penggunaan Token Power BI Mendekati 90%',
      warningDescription : 'Token Power BI mendekati 90% kapasitas penggunaan. Silakan atur dan akses ulang token di menu {url}.',
      errorTitle: 'Penggunaan Token Power BI Melebihi 90%',
      errorDescription: 'Token Power BI sudah melebihi 90% kapasitas penggunaan. Silakan atur dan akses ulang token di menu {url}.',
      url: 'Pengaturan - Admin - Akses Token'
    },
    playground: {
      title: 'Tambah Koordinat',
      description: 'Untuk menambah koordinat, tandai titik pin atau gambar polygon dan polyline di lokasi yang diinginkan. Klik tombol “Salin” untuk membagikan koordinat yang dihasilkan.',
      put: 'Tandai Titik Pin',
      pinpoint: 'Titik Pin',
      drawPolygon: 'Gambar Polygon',
      drawPolyline: 'Gambar Polyline',
      reset: 'Reset',
      placeholder: 'Cari Lokasi',
      wideArea: 'Perkiraan Luasan Total',
      lengthArea: 'Perkiraan Panjang Total',
      copy: 'Salin',
      alertCopy: 'Berhasil disalin.'
    }
  },
};

const i18n = createI18n({
  legacy: false,
  locale: 'id',
  fallbackWarn: false,
  missingWarn: false,
  globalInjection: true,
  messages,
});

export default i18n;
