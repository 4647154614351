/* eslint-disable no-unused-vars */
import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export interface IMasterRoleFilter {
  search?: string;
  page: number;
  limit: number;
  totalPage: number;
  totalRecord:number;

}

interface IRoleDetail {
  role: IRoleItem;
  menu_with_state: Array<any>;
}

interface IRoleItem {
  id: number;
  name: string;
  code: string;
  deleted_at: Date | null;
  created_at: Date;
  updated_at: Date;
}

interface IPaginationLink {
  url: string;
  label: string;
  active: boolean;
}

export interface IMasterRoleData {
  data: Array<IRoleItem>;
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: Array<IPaginationLink>;
  next_page_url: string | null;
  path: string;
  per_page: string;
  prev_page_url: string | null;
  to: number;
  total: number;
}

export interface IMenuRolePayload {
  role_id: number | string;
  menu_id: Array<number | string>;
}

export interface Permission {
  code: string;
  name: string;
  is_active: boolean;
}

export interface PagePermission {
  page_id: string;
  code: string;
  name: string;
  is_active: boolean;
  permissions: Permission[];
}

export interface IsolationPermission {
  isolation_id: string;
  code: string;
  name: string;
  is_active: boolean;
}

export interface ICroleDetail {
  role_id: string;
  page_permissions: PagePermission[];
  isolation_permissions: IsolationPermission[];
}

const defaultRoleData = []
// {
//   data: [],
//   first_page_url: "#",
//   from: 1,
//   last_page: 1,
//   last_page_url: "#",
//   links: [],
//   next_page_url: null,
//   path: "#",
//   per_page: "#",
//   prev_page_url: null,
//   to: 0,
//   total: 0,
// };

export enum RoleModalEnum {
  DEFAULT = "",
  ADD_MEMBER = "add-member",
  EDIT_ACCESS = "edit-access",
  DELETE_ROLE = "delete-role",
  ADD_ROLE = "add-role",
  ASSIGN_MENU_ROLE = "assign-menu-role",
  ADD_MENU = "add-menu",
}

@Module
export default class MasterRoleModule extends VuexModule {
  masterRoleFilter: IMasterRoleFilter = {
    search: '',
    page: 1,
    limit: 10,
    totalPage: 1,
    totalRecord: 1,
  };
  masterRoleKeyword = "";
  masterRoleLoading = false;
  masterRoleModal = false;
  masterRoleDrawer = false;
  masterRoleData = defaultRoleData;
  masterRoleActiveModal: RoleModalEnum = RoleModalEnum.DEFAULT;
  masterRoleSelected = {
    page_permissions: [],
    isolation_permissions: []
  };
  masterRoleSelectedDetail: IRoleDetail | null = null;
  masterRoleSubmitting = false;
  masterIsolationMenus = [];
  masterMenusList = [];
  serviceName =
    process.env.VUE_APP_NODE_ENV == "local" ? "api/v1" : "api/v1";

  get roleSubmitting(): boolean {
    return this.masterRoleSubmitting;
  }

  get roleSelectedDetail(): IRoleDetail | null {
    return this.masterRoleSelectedDetail;
  }

  get roleFilter(): IMasterRoleFilter {
    return this.masterRoleFilter;
  }

  get roleKeyword(): string | undefined {
    return this.masterRoleKeyword;
  }

  get roleLoading(): boolean {
    return this.masterRoleLoading;
  }

  get roleModal(): boolean {
    return this.masterRoleModal;
  }

  get roleDrawer(): boolean {
    return this.masterRoleDrawer;
  }

  get roleData() {
    return this.masterRoleData;
  }

  get roleActiveModal() {
    return this.masterRoleActiveModal;
  }

  get roleSelected() {
    return this.masterRoleSelected;
  }

  get isolationsMenus() {
    return this.masterIsolationMenus
  }

  get menusList() {
    return this.masterMenusList
  }

  @Mutation
  [Mutations.SET_ROLE_FILTER](payload: IMasterRoleFilter) {
    console.log('payload', payload)
    this.masterRoleFilter = payload;
  }

  @Mutation
  [Mutations.SET_MASTER_ROLE_KEYWORD](keyword: string) {
    this.masterRoleKeyword = keyword;
  }

  @Mutation
  [Mutations.SET_MASTER_ROLE_LOADING](isLoading: boolean) {
    this.masterRoleLoading = isLoading;
  }

  @Mutation
  [Mutations.SET_MASTER_ROLE_SUBMITTING](isSubmitting: boolean) {
    this.masterRoleSubmitting = isSubmitting;
  }

  @Mutation
  [Mutations.SET_MASTER_ROLE_DRAWER](isOpen: boolean) {
    this.masterRoleDrawer = isOpen;
  }

  @Mutation
  [Mutations.SET_MASTER_ROLE_DATA](data: any) {
    this.masterRoleData = data;
  }

  @Mutation
  [Mutations.SET_MASTER_ROLE_DIALOG](isOpen: boolean) {
    this.masterRoleModal = isOpen;
  }

  @Mutation
  [Mutations.SET_MASTER_ROLE_ACTIVE_DIALOG](activeModal: RoleModalEnum) {
    this.masterRoleActiveModal = activeModal;
  }

  @Mutation
  [Mutations.SET_MASTER_ROLE_SELECTED](selectedRow) {
    this.masterRoleSelected = selectedRow;
  }

  @Mutation
  [Mutations.SET_MASTER_ROLE_SELECTED_DETAIL](roleDetail) {
    this.masterRoleSelectedDetail = roleDetail;
  }

  @Mutation
  [Mutations.SET_ROLE_DTL_MENU_STATE](keyValueIndex) {
    if (this.masterRoleSelectedDetail && keyValueIndex) {
      this.masterRoleSelectedDetail.menu_with_state[keyValueIndex.index][
        keyValueIndex.key
      ] = keyValueIndex.value;
    }
  }
  
  @Mutation
  [Mutations.SET_ISOLATIONS_MENUS](payload) {
    this.masterIsolationMenus = payload
  }

  @Mutation
  [Mutations.SET_ACCESS_LIST](payload) {
    this.masterMenusList = payload
  }

  @Action
  [Actions.UPDATE_ROLE_DTL_MENU_STATE](keyValueIndex) {
    this.context.commit(Mutations.SET_ROLE_DTL_MENU_STATE, keyValueIndex);
  }

  @Action
  [Actions.UPDATE_MASTER_ROLE_SELECTED_DETAIL](roleDetail) {
    this.context.commit(Mutations.SET_MASTER_ROLE_SELECTED_DETAIL, roleDetail);
  }

  @Action
  [Actions.UPDATE_MASTER_ROLE_SELECTED](selectedRow) {
    this.context.commit(Mutations.SET_MASTER_ROLE_SELECTED, selectedRow);
  }

  @Action
  [Actions.UPDATE_MASTER_ROLE_ACTIVE_DIALOG](modalName: RoleModalEnum) {
    this.context.commit(Mutations.SET_MASTER_ROLE_ACTIVE_DIALOG, modalName);
  }

  @Action
  [Actions.UPDATE_MASTER_ROLE_DIALOG](isOpen: boolean) {
    this.context.commit(Mutations.SET_MASTER_ROLE_DIALOG, isOpen);
  }

  @Action
  [Actions.UPDATE_MASTER_ROLE_KEYWORD](keyword: string) {
    this.context.commit(Mutations.SET_MASTER_ROLE_KEYWORD, keyword);
  }

  @Action
  [Actions.UPDATE_MASTER_ROLE_DRAWER](isOpen: boolean) {
    this.context.commit(Mutations.SET_MASTER_ROLE_DRAWER, isOpen);
  }

  @Action
  [Actions.UPDATE_MASTER_ROLE_LOADING](isLoading: boolean) {
    this.context.commit(Mutations.SET_MASTER_ROLE_LOADING, isLoading);
  }

  @Action
  [Actions.UPDATE_MASTER_ROLE_SUBMITTING](isSubmitting: boolean) {
    this.context.commit(Mutations.SET_MASTER_ROLE_SUBMITTING, isSubmitting);
  }

  @Action
  [Actions.UPDATE_MASTER_ROLE_PAGE](targetPage: number) {
    this.context.commit(Mutations.SET_ROLE_FILTER, {
      page: targetPage,
      search: this.masterRoleFilter.search,
      limit: this.masterRoleFilter.limit,
      totalPage: 1,
      totalRecord: 1,
    });
    this.context.dispatch(Actions.GET_ROLE_LIST);
  }

  @Action({ rawError: true })
  [Actions.GET_ROLE_LIST]() {
    this.context.commit(Mutations.SET_MASTER_ROLE_LOADING, true);
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${this.serviceName}/role`, {
        params: {
          search: this.masterRoleKeyword,
          page: this.masterRoleFilter.page,
          limit: this.masterRoleFilter.limit,
        },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_MASTER_ROLE_DATA, data.data);
          this.context.commit(Mutations.SET_ROLE_FILTER, data.meta);
          resolve(data);
        })
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error);
          reject(error);
        })
        .finally(() =>
          this.context.commit(Mutations.SET_MASTER_ROLE_LOADING, false)
        );
    });
  }

  @Action({ rawError: true })
  [Actions.GET_ROLE_DETAIL](id: string | number) {
    this.context.commit(Mutations.SET_MASTER_ROLE_LOADING, true);
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${this.serviceName}/permissions`, {
        params :{
          role_id: id
        }
      })
        .then(({ data }) => resolve(data))
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error);
          reject();
        })
        .finally(() =>
          this.context.commit(Mutations.SET_MASTER_ROLE_LOADING, false)
        );
    });
  }

  @Action({ rawError: true })
  [Actions.STORE_ROLE](payload) {
    this.context.dispatch(Actions.UPDATE_MASTER_ROLE_LOADING, true);
    return ApiService.post(`${this.serviceName}/role`, payload)
      .then(({ data }) => Promise.resolve(data))
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR, error);
        return Promise.reject(error);
      })
      .finally(() => {
        this.context.dispatch(Actions.UPDATE_MASTER_ROLE_LOADING, false);
      });
  }

  @Action
  [Actions.RESTORE_ROLE](id: string) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`${this.serviceName}/role/${id}`, "", {})
        .then(({ data }) => resolve(data))
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error);
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.UPDATE_ROLE](payload) {    
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`${this.serviceName}`, "role", payload)
        .then(({ data }) => resolve(data))
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error);
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.DESTROY_ROLE](id: string | number) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`${this.serviceName}/role/${id}`)
        .then(({ data }) => resolve(data))
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error);
          reject(error);
        });
    });
  }

  @Action
  [Actions.ASSIGN_USER_ROLE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${this.serviceName}/role/invite`, payload)
        .then(({ data }) => resolve(data))
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error);
          reject(error);
        });
    });
  }
  
  @Action
  [Actions.UNASSIGN_USER_ROLE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`${this.serviceName}/role`, 'remove-user-role', payload)
        .then(({ data }) => resolve(data))
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error);
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.ASSIGN_MENU_ROLE](payload: IMenuRolePayload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${this.serviceName}/rlmanage/assign/asrolemenu`, payload)
        .then(({ data }) => resolve(data))
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error);
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.ASSIGN_MENU_ROLE_PERM](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(
        `${this.serviceName}/rlmanage/assign/asrolemenupms`,
        payload
      )
        .then(({ data }) => resolve(data))
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error);
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.GET_ISOLATIONS_MENUS]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(
        `${this.serviceName}/isolation`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ISOLATIONS_MENUS, data.data);
          resolve(data)
        })
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error);
          reject(error);
        });
    });
  }
  
  @Action({ rawError: true })
  [Actions.GET_ACCESS_LIST]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(
        `${this.serviceName}/page`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ACCESS_LIST, data.data);
          resolve(data)
        })
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error);
          reject(error);
        });
    });
  }
}
