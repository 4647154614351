import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";

const service = process.env.VUE_APP_NODE_ENV == "local" ? "api/v1" : "api/v1";
const googleApi = "https://maps.googleapis.com/maps/api";
@Module
export default class ArcGISModule extends VuexModule {
  listMap = [];
  loadingLeaflet = false;
  bumn_list = [];
  centerMap = {};
  assetTotal = {};
  typeGeoJSON = [{
    label : 'Pin',
    value : 'point'
  }, {
    label : 'Polygon',
    value : 'polygon',
  }, {
    label : 'Polyline',
    value : 'polyline'
  }];

  tileLayers = 'osmMap'

  selectTypeGeoJSON = 'point';
  
  get getTileLayers() {
    return this.tileLayers
  }

  get TypeGeoJSON() {
    return this.typeGeoJSON
  }

  get SelectTypeGeoJSON() {
    return this.selectTypeGeoJSON
  }

  get getListMap() {
    return this.listMap;
  }

  get getCenterMap() {
    return this.centerMap;
  }

  get getAssetTotal() {
    return this.assetTotal;
  }

  get getBumnList() {
    return this.bumn_list;
  }

  get LoadingLeaftlet() {
    return this.loadingLeaflet;
  }

  @Mutation
  [Mutations.SET_TILE_LAYER](data) {
    this.tileLayers = data;
  }

  @Mutation
  [Mutations.SET_BUMN_LIST](data) {
    this.bumn_list = data;
  }

  @Mutation
  [Mutations.SET_LOADING_LEAFTLET](data) {
    this.loadingLeaflet = data;
  }

  @Mutation
  [Mutations.SET_TYPE_GEOJSONMAP](payload) {
    this.selectTypeGeoJSON = payload;
  }

  @Action
  [Actions.GET_ASSET_LIST]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`${service}/asset`)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_BUMN_LIST]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`${service}/channel`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BUMN_LIST, data.data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_MAP_LIST](payload) {
    this.context.commit(Mutations.SET_LOADING_LEAFTLET, true);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/asset/coordinate-monitoring?`, {
        params : {
          type : 'point',
          limit: 2000000,
          ...payload
        }
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_MAP_LIST, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        }).finally(() => {
          this.context.commit(Mutations.SET_LOADING_LEAFTLET, false);
        })
    });
  }

  @Action
  [Actions.GET_ASSET_TOTAL](payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/asset/total?`, {
        params : {
          ...payload
        }
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ASSET_TOTAL, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_GEOCODE](address) {
    return new Promise<void>((resolve, reject) => {
      fetch(
        googleApi +
          "/geocode/json?address=" +
          address +
          "&key=" +
          process.env.VUE_APP_GOOGLE_KEY
      )
        .then((response) => response.json())
        .then((data) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.FIND_PLACE](address) {
    return new Promise<void>((resolve, reject) => {
      fetch(
        googleApi +
          "/place/textsearch/json?query=" +
          address +
          "&key=" +
          process.env.VUE_APP_GOOGLE_KEY,
        {
          headers: {},
        }
      )
        .then((response) => response.json())
        .then((data) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Mutation
  [Mutations.SET_MAP_LIST](data) {
    this.listMap = data;
  }

  @Mutation
  [Mutations.SET_MAP_CENTER](data) {
    this.centerMap = data;
  }

  @Mutation
  [Mutations.SET_ASSET_TOTAL](data) {
    this.assetTotal = data;
  }
}
