
import { ref, toRefs, watch, defineComponent, computed } from "vue";
import imagesAsset from '@/assets/ts/images.js';
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
    name: "select-checkbox",
    props: {
        columns: {
            type: Object,
            required: true
        },
        apendIcon: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:columns'],
    setup(props, { emit }) {
        const { columns } = toRefs(props);
        const optionTypeGeoJSON : any  = computed(() => {
            return store.getters.TypeGeoJSON
        })
        const selectColumns = ref('point') as any;
        const pointmapInput = imagesAsset.pointmapInput;
        const polygonInput = imagesAsset.polygonInput;
        const polylineInput = imagesAsset.polylineInput;

        watch(selectColumns, (newValue, oldValue) => {
            if (selectColumns.value !== '') {
                emit('update:columns', { ...columns.value, [selectColumns.value] : true });
                store.commit(Mutations.SET_TYPE_GEOJSONMAP, selectColumns.value);
                store.commit(Mutations.SET_RESET_FILTER_POINT, selectColumns.value);
            }
        })

        const getResetFilterPoint = computed(() => {
            return store.getters.getResetPoint;
        });

        watch(getResetFilterPoint, () => {
            if (getResetFilterPoint.value) {
                selectColumns.value = getResetFilterPoint.value
            }
        })

        return {
            pointmapInput,
            polygonInput,
            polylineInput,
            selectColumns,
            optionTypeGeoJSON,
            store,
            getResetFilterPoint
        };
    }
});
