
import { computed, defineComponent } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";
import { detectSubDomain } from "@/core/helpers/helps";
import { useRoute } from "vue-router";


export default defineComponent({
  name: "KTFooter",
  setup() {
    const route = useRoute();

    const getYear = () => {
      return new Date().getFullYear();
    };

    const pageOpen = computed(() => {
      return route.name;
    });
    
    const stateShow = computed(() => {
      if((route.name != 'dashboard-map') && (route.name != 'dashboard-summary')){
        return true;
      }else{
        return false;
      }
    });
    return {
      footerWidthFluid,
      getYear,
      detectSubDomain,
      pageOpen,
      stateShow
    };
  },
});
