import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

const service = process.env.VUE_APP_NODE_ENV == 'local' ? '/api/v1' : '/api/v1';

export enum DrawerModeEnum {
  ADD = 'add',
  EDIT = 'edit',
}
@Module
export default class MemberModule extends VuexModule {
  drawer = false;
  drawerMode = DrawerModeEnum.ADD;
  listMember = [];

  get memberDrawerState() {
    return this.drawer;
  }

  get memberDrawerMode() {
    return this.drawerMode;
  }

  @Mutation
  [Mutations.SET_MEMBER_DRAWER_STATE](state: boolean) {
    this.drawer = state;
  }

  @Action
  [Actions.UPDATE_MEMBER_DRAWER_STATE](state: boolean) {
    this.context.commit(Mutations.SET_MEMBER_DRAWER_STATE, state);
  }

  @Mutation
  [Mutations.SET_MEMBER_DRAWER_MODE](mode: DrawerModeEnum) {
    this.drawerMode = mode;
  }

  @Mutation
  [Mutations.SET_MEMBER_LIST](data) {
    this.listMember = data;
  }

  get getListMembers() {
    return this.listMember;
  }

  @Action
  [Actions.GET_MEMBER_LIST](query) {
    return new Promise((resolve, reject) => {
      ApiService.get(`${service}/user`)
        .then(({ data }) => {
          console.log('test data member', data);
          this.context.commit(Mutations.SET_MEMBER_LIST, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_MEMBER_DETAIL](id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`${service}/user/${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  @Action
  [Actions.UPDATE_MEMBER_DRAWER_MODE](mode: DrawerModeEnum) {
    this.context.commit(Mutations.SET_MEMBER_DRAWER_MODE, mode);
  }

  @Action
  [Actions.DOWNLOAD_MEMBERS_LIST]() {
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/user/export-data`, {
        responseType: 'blob',
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Members.xlsx');
          document.body.appendChild(link);
          link.click();

          console.log('download member', response.data);
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
