
import {
  ref,
  getCurrentInstance,
  computed,
  watch,
  reactive,
  onMounted,
} from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Getters } from "@/store/enums/StoreTutorialEnums";

import IntroJs from "intro.js";

export default {
  setup() {
    // code start introjs-7

    const $instance = getCurrentInstance();
    const nextData = ref(0);
    const nextStepTutorial = ref(0);
    const nextListStep = reactive({
      number: 1,
      bool: false,
      validate: "",
      tab_active: "",
      id_step: "",
    });
    const show = ref(false);
    const router = useRoute();
    let fullPath = ref("");
    const store = useStore();
    const loadingMenu = computed(() => store.getters[Getters.LOADING]);

    const comFilterStep = computed(() => store.getters[Getters.FILTER_STEP]);
    const localStorageFun = (name: string) => {
      try {
        if (name) {
          const a = localStorage.getItem(name) as any;
          return a ? JSON.parse(a) : undefined;
        } else {
          return name;
        }
      } catch (e) {
        console.log("localStorageFun", e);
      }
    };

    const funDocument = (name: string, status = "1", local?: string) => {
      return new Promise((resolve) => {
        switch (status) {
          case "1":
            {
              const doc = document.querySelectorAll(name);
              doc[0]
                ?.getElementsByClassName("next")[0]
                ?.addEventListener("click", () => {
                  resolve("step-1");
                });
              doc[0]
                ?.getElementsByClassName("lewati")[0]
                ?.addEventListener("click", () => {
                  resolve("step-2");
                  if (local) {
                    localStorage.setItem("saveSettingTutorial", local);
                  }
                });
            }
            break;
          case "2":
            {
              const time = setInterval(() => {
                const doc = document.querySelectorAll(name);
                if (doc.length > 0) {
                  doc[0]
                    ?.getElementsByClassName("next")[0]
                    ?.addEventListener("click", () => {
                      resolve("step-1");
                      if (local) {
                        localStorage.setItem("saveSettingTutorial", local);
                      }
                    });

                  doc[0]
                    ?.getElementsByClassName("lewati")[0]
                    ?.addEventListener("click", () => {
                      resolve("step-2");
                      if (local) {
                        localStorage.setItem("saveSettingTutorial", local);
                      }
                    });
                  clearInterval(time);
                }
              });
            }
            break;
        }
      });
    };

    const lewati = () => {
      let array = [];
      array = localStorageFun("introjs-tutorial");
      array.push("general-setting" as never);
      localStorage.setItem("introjs-tutorial", JSON.stringify(array));

      localStorage.removeItem("saveSettingTutorial");
    };

    // page general
    const listStep = () => {
      if (fullPath.value === "/setting/organization/general-setting") {
        console.log("test setting", fullPath);

        setTimeout(() => {
          $instance?.proxy?.$nextTick(() => {
            const intro = IntroJs().setOptions({
              showButtons: false,
              showBullets: false,
              showProgress: false,
              exitOnOverlayClick: false,
              exitOnEsc: false,
              skipLabel: "",

              steps: [
                {
                  element: document.querySelectorAll(
                    ".tutor-setting-general-theme"
                  )[0] as HTMLElement,
                  intro: `<div class="introjs-project-edit-28">
                    <div>Ubah Tema</div>
                    <div>
                    Pilih warna yang diinginkan untuk mengubah warna tampilan
                    </div>
                    <div>
                          <div class="progress-bar">
                          <progress value="50" max="100"></progress>
                          </div>
                              <div>1 of 2</div>

                          </div>
                    <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Selanjutnya</button>
                    </div>
                </div>`,
                  position: "right",
                },
                {
                  element: document.querySelectorAll(
                    ".tutor-setting-12"
                  )[0] as HTMLElement,
                  intro: `<div class="introjs-project-edit-28" id="step-2">
                          <div>Ubah Bahasa</div>
                          <div>
                              Pilih bahasa yang ingin diterapkan
                          </div>
                          <div>
                          <div class="progress-bar">
                          <progress value="100" max="100"></progress>
                          </div>
                              <div>2 of 2</div>

                          </div>
                          <div class="grid-tutorial">
                              <div class="lewati">Lewati</div>
                              <button class="next">Selanjutnya</button>
                          </div>
                  </div>`,
                  position: "right",
                },
              ],
            });
            intro.start();
            funDocument(".introjs-project-edit-28", "1").then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 1;
                  intro.nextStep();
                  break;
                case "step-2":
                  intro.exit();
                  lewati();
                  break;
              }
            });
            intro.onafterchange(() => {
              if (nextStepTutorial.value === 1) {
                funDocument("#step-2.introjs-project-edit-28", "2").then(
                  (status) => {
                    switch (status) {
                      case "step-1":
                        nextStepTutorial.value = 2;
                        intro.nextStep();
                        lewati();
                        break;
                      case "step-2":
                        intro.exit();
                        lewati();
                        break;
                    }
                  }
                );
              }
            });
          });
        }, 1000);
      }
    };

    watch(loadingMenu, (varnew) => {
      nextListStep.bool = varnew;
    });

    const funStepIntro = (varnew) => {
      if (varnew?.name === "general-setting" && varnew?.bool === true) {
        if (
          nextData.value === 0 &&
          fullPath.value === "/setting/organization/general-setting"
        ) {
          nextData.value = 1;
          $instance?.proxy?.$nextTick(() => {
            const save: string[] = localStorageFun("introjs-tutorial");

            if (save?.includes("on-boarding-2")) {
              if (!save?.includes("general-setting")) {
                listStep();
              }
            }
          });
        }
      }
    };

    watch(
      comFilterStep,
      (varnew) => {
        if (varnew?.name === "organization/general-setting") {
          fullPath.value = router?.fullPath;

          if (fullPath.value !== "") {
            funStepIntro({ name: "general-setting", bool: varnew?.bool });
          }
        }
      },
      { deep: true }
    );

    onMounted(() => {
      const local = localStorage.getItem("step") as string;
      const local2 = localStorage.getItem("saveSettingTutorial") as string;
      if (local !== null) {
        nextListStep.number = parseInt(local);
      }
      if (local2 !== null) {
        nextListStep.validate = local2;
      }
    });

    return {
      show,
    };
  },
};
