
import { computed, defineComponent, onMounted, provide, watch, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Actions, Mutations } from "./store/enums/StoreEnums";
import { GlobalDrawerTitleEnum } from "@/store/modules/DrawerModule";
import AddProjectPartnerForm from "@/components/forms/AddProjectPartnerForm.vue";
import PopupDeleteModal from "@/components/modals/list-asset/DeleteAssetModal.vue";
import { breadcrumbMapper } from "@/core/helpers/breadcrumb"
import { detectSubDomain } from "@/core/helpers/helps";
import ImportDataAssetsModal from "@/components/modals/import-assets/ImportDataAssetsModal.vue";
export default defineComponent({
  name: "app",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    AddProjectPartnerForm,
    PopupDeleteModal,
    ImportDataAssetsModal
  },
  setup() {
       sessionStorage.setItem('versions', "[__ENV__] tomps-asset-frontend - updated-with-build-n-__bn__");
    const store = useStore();
    // check webp support
    // https://stackoverflow.com/questions/5573096/detecting-webp-support
    const el = document.createElement("canvas");
    if (el.getContext && el.getContext("2d")) {
      // was able or not to get WebP representation
      store.commit(
        Mutations.SET_WEBP_SUPPORT,
        el.toDataURL("image/webp").indexOf("data:image/webp") === 0
      );
    } else {
      // very old browser like IE 8, canvas not supported
      store.commit(Mutations.SET_WEBP_SUPPORT, false);
    }
    const route = useRoute();
    const detectPath = computed(() => {
      return route.path;
    });

    const getDialogModalImport = computed(() => {
      return store.getters.getDialogModalImport;
    });

    onMounted(() => {
      if(detectPath.value === '/dashboard-summary') {
        document.body.className = 'no-scroll';
      } else {
        document.body.className = 'yes-scroll';
      }

      let data = document.querySelectorAll('link[rel="icon"]') as any;
      data[0].href =
        detectSubDomain() == "simapkominfo"
          ? "/media/logos/logo-kominfo.png"
          : "/favicon.png";
      data[1].href =
        detectSubDomain() == "simapkominfo"
          ? "/media/logos/logo-kominfo.png"
          : "/favicon.png";

      const title = document.querySelector("title") as any;
      title.innerText = window.location.host
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      if (localStorage.getItem("locale")) {
        store.commit(Mutations.SET_LOCALE, localStorage.getItem("locale"));
      } else {
        store.commit(Mutations.SET_LOCALE, "id");
        localStorage.setItem('mode', 'telkom')
      }

      if (
        localStorage.getItem("mode") &&
        localStorage.getItem("mode") == "telkom"
      ) {
        require("@/assets/sass/plugins.telkom.scss");
        require("@/assets/sass/style.telkom.scss");
      } else {
        require("@/assets/sass/plugins.scss");
        require("@/assets/sass/style.scss");
      }
    });

    watch(
        () => detectPath.value,
        () => {
          if(detectPath.value === '/dashboard-summary' || detectPath.value === '/reporting') {
            document.body.className = 'no-scroll header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed';
          } else {
            document.body.className = 'yes-scroll header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed aside-enabled aside-fixed';
          }
        }
    );

    const drawer = computed({
      get: () => store.getters.getGlobalDrawerState,
      set: (val) => store.dispatch(Actions.UPDATE_GLOBAL_DRAWER_STATE, val),
    });
    const drawerTitle = computed(() => store.getters.getGlobalDrawerTitle);
    const openDrawer = () =>
      store.dispatch(Actions.UPDATE_GLOBAL_DRAWER_STATE, true);
    const closeDrawer = () => {
      store.dispatch(Actions.UPDATE_GLOBAL_DRAWER_STATE, false);
      store.dispatch(
        Actions.UPDATE_GLOBAL_DRAWER_TITLE,
        GlobalDrawerTitleEnum.DEFAULT
      );
    };
    const formComponent = computed(
      () => store.getters.getGlobalDrawerSelectedForm
    );

    const getDeletePopup = computed(() => {
      return store.getters.getDeletePopup;
    });


    return {
      formComponent,
      closeDrawer,
      openDrawer,
      drawer,
      drawerTitle,
      getDeletePopup,
      getDialogModalImport
    };
  },
});
