import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";

const service = "charter/api/v1";

@Module
export default class SymptomModule extends VuexModule {
  symptomList = null;
  symptomIsFetching = true;
  symptomIsSubmitting = false;

  get getSymptomIsFetching() {
    return this.symptomIsFetching;
  }

  @Mutation
  [Mutations.SET_SYMPTOM_IS_FETCHING](newState: boolean) {
    this.symptomIsFetching = newState;
  }

  @Action
  [Actions.UPDATE_SYMPTOM_IS_FETCHING](newState: boolean) {
    this.context.commit(Mutations.SET_SYMPTOM_IS_FETCHING, newState);
  }

  get getSymptoms() {
    return this.symptomList;
  }

  @Mutation
  [Mutations.SET_SYMPTOM_LIST](data) {
    this.symptomList = data;
  }

  @Action
  [Actions.GET_SYMPTOM_LIST](query) {
    this.context.commit(Mutations.SET_SYMPTOM_IS_FETCHING, true);
    return new Promise((resolve, reject) => {
      ApiService.query(`${service}/chartersymptom`, query)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SYMPTOM_LIST, data.data);
          resolve(data);
        })
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error);
          reject(error);
        })
        .finally(() =>
          this.context.commit(Mutations.SET_SYMPTOM_IS_FETCHING, false)
        );
    });
  }
}
