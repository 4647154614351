import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";

export interface earningStatusDrawerInfo {
    earningStatusDrawerState: boolean;
}
export enum DrawerModeEnum {
  ADD = "add",
  EDIT = "edit",
}

@Module
export default class EarningStatusModule extends VuexModule implements earningStatusDrawerInfo {
  earningStatusDrawerState = false;
  drawerMode = DrawerModeEnum.ADD;

  get getEarningStatusDrawerState() {
    return this.earningStatusDrawerState;
  }
  get getEarningStatusDrawerMode() {
    return this.drawerMode;
  }

  @Mutation
  [Mutations.SET_EARNING_STATUS_DRAWER](payload): void {
    this.earningStatusDrawerState = payload;
  }

  @Action
  [Actions.UPDATE_EARNING_STATUS_DRAWER](state: boolean): void {
    this.context.commit(Mutations.SET_EARNING_STATUS_DRAWER, state);
  }

  @Mutation
  [Mutations.SET_EARNING_STATUS_DRAWER_MODE](mode: DrawerModeEnum) {
    this.drawerMode = mode;
    
  }

  @Action
  [Actions.UPDATE_EARNING_STATUS_DRAWER_MODE](mode: DrawerModeEnum) {
    this.context.commit(Mutations.SET_EARNING_STATUS_DRAWER_MODE, mode);
  }
}
