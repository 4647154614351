// import ApiService from "@/core/services/ApiService";
// import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";

export interface IFileCategory {
  id: string;
  category_name: string;
  code: string;
  created_at: string;
  updated_at: string;
}

const service =
  process.env.VUE_APP_NODE_ENV == "local" ? "file/api/v1" : "file/api/v1";
// const service = "file/api/v1";
@Module
export default class FileCategoryModule extends VuexModule {
  fileCategories = Array<IFileCategory>();
  fcIsFetching = true;

  get getFCIsFetching(): boolean {
    return this.fcIsFetching;
  }

  @Mutation
  [Mutations.SET_PROJECT_FILE_IS_FETCHING](newState: boolean) {
    this.fcIsFetching = newState;
  }

  get getFileCategories(): IFileCategory[] {
    return this.fileCategories;
  }

  @Mutation
  [Mutations.SET_PROJECT_FILE_CATEGORIES](data) {
    this.fileCategories = data;
  }

  @Action
  [Actions.GET_PROJECT_FILE_CATEGORIES]() {
    this.context.commit(Mutations.SET_PROJECT_FILE_IS_FETCHING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`${service}/category_file/list`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PROJECT_FILE_CATEGORIES, data.data);
          resolve(data);
        })
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error);
          reject(error);
        })
        .finally(() =>
          this.context.commit(Mutations.SET_PROJECT_FILE_IS_FETCHING, false)
        );
    });
  }
}
