// import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { setAllertError, setAllertSuccess } from "@/core/services/Alert";
import ApiService from "@/core/services/ApiService";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "../enums/StoreEnums";

const service =
  process.env.VUE_APP_NODE_ENV == "local"
    ? "/api/v1"
    : "/api/v1";

@Module
export default class RoleAssetModule extends VuexModule {
  role = window.localStorage.getItem("role")
  ? JSON.parse(window.localStorage.getItem("role") as any)
  : [];

  @Mutation
  [Mutations.SET_ROLE_ASSETS](data) {
    this.role = data;
  }

  get getRole() {
    return this.role;
  }

  @Action
  [Actions.GET_ROLE_BY_ID](id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
        ApiService.get(`${service}/role/${id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ROLE_ASSETS, data.data);
          localStorage.setItem('role', JSON.stringify(data.data))
          return resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
