
import { defineComponent, watch, reactive, nextTick, ref, computed } from "vue";
import { toastError, toastSuccess } from "@/core/services/Alert";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import images from "@/assets/ts/images.js";

interface Option {
  label: string;
  option: string[];
}

export default defineComponent({
  name: "BulkDeleteAsset",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const dialogVisible = ref(false);
    const trashDelete = images.trashbucket;
    const store = useStore();
    const { t } = useI18n();

    let formPost = reactive({
      id: null,
      tenant_id: store.getters.getTenantId,
      name: "",
      link: "",
    });

    const PayloadAction = computed(() => {
      return store.getters.PayloadAction
    })

    const getNameAsset = computed(() => {
      return store.getters.getNameAsset
    })

    const Cancel = () => {
      store.commit(Mutations.SET_DELETE_MODAL, false)
    }

    const buttonDisabled = ref(false)
    const deleteAssets = () => {
      buttonDisabled.value = true
      store.commit(Mutations.SET_DELETE_MODAL, false)
      store.dispatch(Actions.BULK_DELETE_ASSETS, PayloadAction.value.entity_id)
      .then(() => {
        let params = {
          page: 1,
          limit: 10,
          asset_type_codes: "",
          id_channels: "",
          id_provinces: "",
          legalitas_codes: ""
        };
        store.dispatch(Actions.GET_LIST_ASSETS, params)
      })

      setTimeout(() => {
        buttonDisabled.value = false
      }, 1000);
    }

    watch(
      () => _.open,
      (first, second) => {
        dialogVisible.value = _.open;
      }
    );

    return {
      trashDelete,
      dialogVisible,
      Cancel,
      deleteAssets,
      PayloadAction,
      buttonDisabled,
      getNameAsset,
      t
    };
  },
});
