
import {
  ref,
  getCurrentInstance,
  computed,
  watch,
  reactive,
  onMounted,
} from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Getters } from "@/store/enums/StoreTutorialEnums";

import IntroJs from "intro.js";
import * as storeTutorial from "@/store/enums/StoreTutorialEnums";
import { Tutorial } from "@/store/enums/interface_option/tutorial";

export default {
  setup() {
    // code start introjs-7

    const $instance = getCurrentInstance();
    const nextData = ref(0);

    const nextStepTutorial = ref(0);
    const show = ref(false);
    const route = useRoute();
    // const router = useRouter();

    let valueLocal = ref("");
    const store = useStore();
    const comFilterStep = computed(() => store.getters[Getters.FILTER_STEP]);
    const localStorageFun = (name: string) => {
      try {
        if (name) {
          const a = localStorage.getItem(name) as any;
          return a ? JSON.parse(a) : undefined;
        } else {
          return name;
        }
      } catch (e) {
        console.log("localStorageFun", e);
      }
    };
    const localStorageFunSave = (
      name?: string | null,
      status?: boolean,
      nameStorage?: {
        name: string;
        data: string;
      }
    ) => {
      try {
        switch (status) {
          case true:
            if (nameStorage?.name) {
              let array = [];
              if (localStorageFun(nameStorage?.name)) {
                array = localStorageFun(nameStorage?.name);
              }
              array.push(nameStorage?.data as never);
              localStorage.setItem(nameStorage?.name, JSON.stringify(array));
            }
            break;
          default: {
            if (name) {
              let array = [];
              array = localStorageFun("introjs-tutorial");
              array.push(name as never);
              localStorage.setItem("introjs-tutorial", JSON.stringify(array));
            } else {
              return name;
            }
          }
        }
      } catch (e) {
        console.log("localStorageFun", e);
      }
    };

    const funDocument = (name: string, status = "1") => {
      return new Promise((resolve) => {
        switch (status) {
          case "1":
            {
              const doc = document.querySelectorAll(name);
              doc[0]
                ?.getElementsByClassName("next")[0]
                ?.addEventListener("click", () => {
                  nextData.value = 0;
                  resolve("step-1");
                });
              doc[0]
                ?.getElementsByClassName("lewati")[0]
                ?.addEventListener("click", () => {
                  nextData.value = 0;
                  resolve("step-2");
                });
            }
            break;
          case "2":
            {
              const time = setInterval(() => {
                const doc = document.querySelectorAll(name);
                if (doc.length > 0) {
                  doc[0]
                    ?.getElementsByClassName("next")[0]
                    ?.addEventListener("click", () => {
                      nextData.value = 0;
                      resolve("step-1");
                    });

                  doc[0]
                    ?.getElementsByClassName("lewati")[0]
                    ?.addEventListener("click", () => {
                      nextData.value = 0;
                      resolve("step-2");
                    });
                  clearInterval(time);
                }
              });
            }
            break;
        }
      });
    };
    const leftBar = (status = true) => {
      const doc = document.querySelectorAll(".aside-fixed .aside")[0];
      if (status) {
        doc.classList.add("active");
      } else {
        doc.classList.remove("active");
      }
    };
    // project informasi-project"
    const listStep = () => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs(".topbar-stepper").setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                "#introjs-candidate-informasi-project"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-candidate-informasi">
                      <div class="active">Klik di sini apabila Anda telah selesai mengisi form yang ada di tahapan tersebut</div>
                      <div></div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
            {
              element: document.querySelectorAll(
                "#introjs-candidate-informasi-project-2"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-candidate-informasi-2">
                      <div class="active">Klik di sini untuk menyimpan proyek yang sedang di isi</div>
                      <div></div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Mengerti</button>
                      </div>
                    </div>`,
              position: "left",
            },
          ],
        });
        leftBar(true);
        intro2.start();

        funDocument(".introjs-project-edit-28#step-candidate-informasi").then(
          (status) => {
            switch (status) {
              case "step-1":
                nextStepTutorial.value = 1;
                intro2.nextStep();
                break;
              case "step-2":
                intro2.exit();
                valueLocal.value = "project-informasi";
                localStorageFunSave(null, true, {
                  name: "project-candidate-detail",
                  data: "project-informasi",
                });
                break;
            }
          }
        );
        intro2.onafterchange(() => {
          if (nextStepTutorial.value === 1) {
            funDocument(
              ".introjs-project-edit-28#step-candidate-informasi-2",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 1;
                  intro2.nextStep();
                  valueLocal.value = "project-informasi";
                  localStorageFunSave(null, true, {
                    name: "project-candidate-detail",
                    data: "project-informasi",
                  });
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = "project-informasi";
                  localStorageFunSave(null, true, {
                    name: "project-candidate-detail",
                    data: "project-informasi",
                  });
                  break;
              }
            });
          }
        });
      });
    };
    // project candidate & partner"
    const listStep2 = () => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                "#introjs-candidate-partner"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-candidate-partner">
                      <div >Customer &  Partner!</div>
                      <div class="active">Pada tab ini anda dapat menambahkan customer dan partner untuk pengerjaan proyek Anda</div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Selanjutnya</button>
                      </div>
                    </div>`,
              position: "right",
            },
            {
              element: document.querySelectorAll(
                "#introjs-candidate-partner-2"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-candidate-partner-2">
                      <div >Tambahkan data customer dan partner</div>
                      <div class="active">Klik disini untuk mendapatkan data customer dan partner untuk proyek Anda</div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Selanjutnya</button>
                      </div>
                    </div>`,
              position: "top",
            },
          ],
        });
        leftBar(true);
        intro2.start();

        funDocument(".introjs-project-edit-28#step-candidate-partner").then(
          (status) => {
            switch (status) {
              case "step-1":
                nextStepTutorial.value = 1;
                intro2.nextStep();
                break;
              case "step-2":
                intro2.exit();
                valueLocal.value = "project-candidate-partner";
                localStorageFunSave(null, true, {
                  name: "project-candidate-detail",
                  data: "project-candidate-partner",
                });
                break;
            }
          }
        );
        intro2.onafterchange(() => {
          if (nextStepTutorial.value === 1) {
            funDocument(
              ".introjs-project-edit-28#step-candidate-partner-2",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 1;
                  intro2.nextStep();
                  valueLocal.value = "project-candidate-partner";
                  localStorageFunSave(null, true, {
                    name: "project-candidate-detail",
                    data: "project-candidate-partner",
                  });
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = "project-candidate-partner";
                  localStorageFunSave(null, true, {
                    name: "project-candidate-detail",
                    data: "project-candidate-partner",
                  });
                  break;
              }
            });
          }
        });
      });
    };
    // project deliverables
    const listStep3 = () => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                "#introjs-candidate-deliverables"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-candidate-deliverables">
                      <div class="active">Klik di sini untuk menampilkan deliverables dalam bentuk Gantt Chart</div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Selanjutnya</button>
                      </div>
                    </div>`,
              position: "right",
            },
            {
              element: document.querySelectorAll(
                "#introjs-candidate-deliverables-2"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-candidate-deliverables-2">
                      <div class="active">Pada tab ini anda dapat menambahkan customer dan partner untuk pengerjaan proyek Anda</div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Selanjutnya</button>
                      </div>
                    </div>`,
              position: "right",
            },
            {
              element: document.querySelectorAll(
                ".gantt_grid_head_cell.gantt_grid_head_add  "
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-candidate-deliverables-3">
                      <div class="active">Klik di sini untuk menambahkan deliverables baru</div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Selanjutnya</button>
                      </div>
                    </div>`,
              position: "right",
            },
          ],
        });
        leftBar(true);
        intro2.start();

        funDocument(
          ".introjs-project-edit-28#step-candidate-deliverables"
        ).then((status) => {
          switch (status) {
            case "step-1":
              nextStepTutorial.value = 1;
              intro2.nextStep();
              break;
            case "step-2":
              intro2.exit();
              valueLocal.value = "project-deliverables";
              localStorageFunSave(null, true, {
                name: "project-candidate-detail",
                data: "project-deliverables",
              });
              break;
          }
        });
        intro2.onafterchange(() => {
          if (nextStepTutorial.value === 1) {
            funDocument(
              ".introjs-project-edit-28#step-candidate-deliverables-2",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 2;
                  intro2.nextStep();
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = "project-deliverables";
                  localStorageFunSave(null, true, {
                    name: "project-candidate-detail",
                    data: "project-deliverables",
                  });
                  break;
              }
            });
          } else if (nextStepTutorial.value === 2) {
            funDocument(
              ".introjs-project-edit-28#step-candidate-deliverables-3",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 1;
                  intro2.nextStep();
                  localStorageFunSave(null, true, {
                    name: "project-candidate-detail",
                    data: "project-deliverables",
                  });
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = "project-deliverables";
                  localStorageFunSave(null, true, {
                    name: "project-candidate-detail",
                    data: "project-deliverables",
                  });
                  break;
              }
            });
          }
        });
      });
    };
    // project deliverables tabs deliverables
    const listStep4 = () => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-candidate-deliverables-tabs"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-candidate-deliverables-tabs">
                      <div class="active">Klik di sini untuk menampilkan deliverables dalam bentuk Gantt Chart</div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Selanjutnya</button>
                      </div>
                    </div>`,
              position: "top",
            },
          ],
        });
        leftBar(true);
        intro2.start();

        funDocument(
          ".introjs-project-edit-28#step-candidate-deliverables-tabs"
        ).then((status) => {
          switch (status) {
            case "step-1":
              nextStepTutorial.value = 1;
              intro2.nextStep();
              localStorageFunSave(null, true, {
                name: "project-candidate-detail",
                data: "project-deliverables-tabs",
              });
              break;
            case "step-2":
              intro2.exit();
              valueLocal.value = "project-deliverables-tabs";
              localStorageFunSave(null, true, {
                name: "project-candidate-detail",
                data: "project-deliverables-tabs",
              });
              break;
          }
        });
      });
    };
    // project document
    const listStep5 = (status?: string) => {
      switch (status) {
        case "load-api":
          $instance?.proxy?.$nextTick(() => {
            const intro2 = IntroJs().setOptions({
              showButtons: false,
              showBullets: false,
              showProgress: false,
              exitOnOverlayClick: false,
              exitOnEsc: false,
              skipLabel: "",

              steps: [
                {
                  element: document.querySelectorAll(
                    ".introjs-candidate-document"
                  )[0] as HTMLElement,
                  intro: `<div class="introjs-project-edit-28" id="step-candidate-document">
                      <div >Unggah dokumen </div>
                      <div >Klik di sini untuk unggah dokumen proyek Anda</div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Selanjutnya</button>
                      </div>
                    </div>`,
                  position: "left",
                },
                {
                  element: document.querySelectorAll(
                    ".introjs-candidate-document-2"
                  )[0] as HTMLElement,
                  intro: `<div class="introjs-project-edit-28" id="step-candidate-document-2">
                      <div >Unduh Seluruh Dokumen</div>
                      <div >Klik di sini untuk unduh seluruh dokumen proyek Anda</div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Selanjutnya</button>
                      </div>
                    </div>`,
                  position: "left",
                },
                {
                  element: document.querySelectorAll(
                    ".introjs-candidate-document-3"
                  )[0] as HTMLElement,
                  intro: `<div class="introjs-project-edit-28" id="step-candidate-document-3">
                      <div >Tambah Dokumen Proyek</div>
                      <div >Klik di sini untuk menambahkan dokumen proyek pada kategori dokumen</div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Selanjutnya</button>
                      </div>
                    </div>`,
                  position: "left",
                },
              ],
            });
            leftBar(true);
            intro2.start();

            funDocument(
              ".introjs-project-edit-28#step-candidate-document"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 1;
                  intro2.nextStep();
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = "project-document";
                  localStorageFunSave(null, true, {
                    name: "project-candidate-detail",
                    data: "project-document",
                  });
                  break;
              }
            });
            intro2.onafterchange(() => {
              if (nextStepTutorial.value === 1) {
                funDocument(
                  ".introjs-project-edit-28#step-candidate-document-2",
                  "2"
                ).then((status) => {
                  switch (status) {
                    case "step-1":
                      nextStepTutorial.value = 2;
                      intro2.nextStep();
                      break;
                    case "step-2":
                      intro2.exit();
                      valueLocal.value = "project-document";
                      localStorageFunSave(null, true, {
                        name: "project-candidate-detail",
                        data: "project-document",
                      });
                      break;
                  }
                });
              } else if (nextStepTutorial.value === 2) {
                funDocument(
                  ".introjs-project-edit-28#step-candidate-document-3",
                  "2"
                ).then((status) => {
                  switch (status) {
                    case "step-1":
                      intro2.nextStep();
                      localStorageFunSave(null, true, {
                        name: "project-candidate-detail",
                        data: "project-document",
                      });
                      break;
                    case "step-2":
                      intro2.exit();
                      valueLocal.value = "project-document";
                      localStorageFunSave(null, true, {
                        name: "project-candidate-detail",
                        data: "project-document",
                      });
                      break;
                  }
                });
              }
            });
          });

          break;
        default:
          {
            $instance?.proxy?.$nextTick(() => {
              const intro2 = IntroJs().setOptions({
                showButtons: false,
                showBullets: false,
                showProgress: false,
                exitOnOverlayClick: false,
                exitOnEsc: false,
                skipLabel: "",

                steps: [
                  {
                    element: document.querySelectorAll(
                      ".introjs-candidate-document"
                    )[0] as HTMLElement,
                    intro: `<div class="introjs-project-edit-28" id="step-candidate-document">
                      <div >Unggah dokumen </div>
                      <div >Klik di sini untuk unggah dokumen proyek Anda</div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">Selanjutnya</button>
                      </div>
                    </div>`,
                    position: "left",
                  },
                ],
              });
              leftBar(true);
              intro2.start();

              funDocument(
                ".introjs-project-edit-28#step-candidate-document"
              ).then((status) => {
                switch (status) {
                  case "step-1":
                    nextStepTutorial.value = 1;
                    intro2.nextStep();
                    localStorageFunSave(null, true, {
                      name: "project-candidate-detail",
                      data: "project-document",
                    });
                    break;
                  case "step-2":
                    intro2.exit();
                    valueLocal.value = "project-document";
                    localStorageFunSave(null, true, {
                      name: "project-candidate-detail",
                      data: "project-document",
                    });
                    break;
                }
              });
            });
          }
          break;
      }
    };
    // project cost planing
    const listStep6 = () => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",
          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-cost-planing"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-cost-planing">
                      <div>Bottom Up Project Cost</div>
                        <div>
              Pilih bottom up project cost apabila project anda berdasarkan estimasi rencana anggaran bahan
            </div>
                     <div>
            <div class="progress-bar">
              <progress value="36.7" max="100"></progress>
              </div>
                <div>1 of 3</div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">next</button>
                      </div>
                    </div>`,
              position: "right",
            },
            {
              element: document.querySelectorAll(
                ".introjs-cost-planing-2"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-cost-planing-2">
                      <div >Kontrak Harga Satuan</div>
                        <div>
              Pilih Kontrak Harga Satuan apabila project anda berdasarkan paket anggaran bahan
            </div>
                     <div>
     <div class="progress-bar">
              <progress value="66" max="100"></progress>
              </div>
                <div>2 of 3</div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">next</button>
                      </div>
                    </div>`,
              position: "left",
            },
            {
              element: document.querySelectorAll(
                ".introjs-cost-planing-3"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-cost-planing-3">
                      <div >Kontrak Harga Satuan</div>
                        <div>
              Pilih Kontrak Harga Satuan apabila project anda berdasarkan rencana anggaran pada material
            </div>
                     <div>
     <div class="progress-bar">
              <progress value="100" max="100"></progress>
              </div>
                <div>3 of 3</div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">next</button>
                      </div>
                    </div>`,
              position: "left",
            },
          ],
        });
        leftBar(true);
        intro2.start();

        funDocument(".introjs-project-edit-28#step-cost-planing").then(
          (status) => {
            switch (status) {
              case "step-1":
                nextStepTutorial.value = 1;
                intro2.nextStep();
                break;
              case "step-2":
                intro2.exit();
                valueLocal.value = "project-cost-planing";
                localStorageFunSave(null, true, {
                  name: "project-candidate-detail",
                  data: "project-cost-planing",
                });
                break;
            }
          }
        );
        intro2.onafterchange(() => {
          if (nextStepTutorial.value === 1) {
            funDocument(
              ".introjs-project-edit-28#step-cost-planing-2",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 2;
                  intro2.nextStep();
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = "project-cost-planing";
                  localStorageFunSave(null, true, {
                    name: "project-candidate-detail",
                    data: "project-cost-planing",
                  });
                  break;
              }
            });
          } else if (nextStepTutorial.value === 2) {
            funDocument(
              ".introjs-project-edit-28#step-cost-planing-3",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  intro2.nextStep();
                  valueLocal.value = "project-cost-planing";
                  localStorageFunSave(null, true, {
                    name: "project-candidate-detail",
                    data: "project-cost-planing",
                  });
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = "project-cost-planing";
                  localStorageFunSave(null, true, {
                    name: "project-candidate-detail",
                    data: "project-cost-planing",
                  });
                  break;
              }
            });
          }
        });
      });
    };
    // deliverables tabs deliverables showHide
    const listStep7 = (nameLocalStorage: string, dataLocalStorage: string) => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",
          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-candidate-show-modal .el-tabs__item.is-top.is-active"
              )[0] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-candidate-show-modal">
                      <div>Halaman Informasi</div>
                          <div>
                        Pada halaman ini berisi informasi deliverable dari proyek anda
                          </div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">next</button>
                      </div>
                    </div>`,
              position: "left",
            },
            {
              element: document.querySelectorAll(
                ".introjs-candidate-show-modal .el-tabs__item.is-top"
              )[1] as HTMLElement,
              intro: `<div class="introjs-project-edit-28" id="step-candidate-show-modal-2">
                      <div>Halaman Approval</div>
                          <div>
                        Pada halaman ini anda dapat menambahkan approval untuk deliverable anda
                          </div>
                     <div>

                    </div>
                      <div class="grid-tutorial">
                        <div class="lewati">Lewati</div>
                        <button class="next">next</button>
                      </div>
                    </div>`,
              position: "left",
            },
          ],
        });
        leftBar(true);
        intro2.start();

        funDocument(".introjs-project-edit-28#step-candidate-show-modal").then(
          (status) => {
            switch (status) {
              case "step-1":
                nextStepTutorial.value = 1;
                intro2.nextStep();
                break;
              case "step-2":
                intro2.exit();
                valueLocal.value = dataLocalStorage;
                localStorageFunSave(null, true, {
                  name: nameLocalStorage,
                  data: dataLocalStorage,
                });
                break;
            }
          }
        );
        intro2.onafterchange(() => {
          if (nextStepTutorial.value === 1) {
            funDocument(
              ".introjs-project-edit-28#step-candidate-show-modal-2",
              "2"
            ).then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 2;
                  intro2.nextStep();
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  break;
                case "step-2":
                  intro2.exit();
                  valueLocal.value = dataLocalStorage;
                  localStorageFunSave(null, true, {
                    name: nameLocalStorage,
                    data: dataLocalStorage,
                  });
                  break;
              }
            });
          }
        });
      });
    };
    const funStepIntro = (varnew) => {
      const funDocumentDetail = (name?: string, status?) => {
        switch (name) {
          case "detail-informasi-project":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-candidate-detail"
                );
                if (!save?.includes("project-candidate-2")) {
                  if (!save2?.includes("project-informasi")) {
                    setTimeout(() => {
                      listStep();
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "detail-customer-partner":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-candidate-detail"
                );
                if (!save?.includes("project-candidate-2")) {
                  if (!save2?.includes("project-candidate-partner")) {
                    setTimeout(() => {
                      listStep2();
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "deliverables":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-candidate-detail"
                );
                if (!save?.includes("project-candidate-2")) {
                  if (!save2?.includes("project-deliverables")) {
                    setTimeout(() => {
                      listStep3();
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "deliverables-tabs":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-candidate-detail"
                );
                if (!save?.includes("project-candidate-2")) {
                  if (!save2?.includes("project-deliverables-tabs")) {
                    setTimeout(() => {
                      listStep4();
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "document":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-candidate-detail"
                );
                if (!save?.includes("project-candidate-2")) {
                  if (!save2?.includes("project-document")) {
                    setTimeout(() => {
                      listStep5(status);
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "cost-planing":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-candidate-detail"
                );
                if (!save?.includes("project-candidate-2")) {
                  if (!save2?.includes("project-cost-planing")) {
                    setTimeout(() => {
                      listStep6();
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "show-add-deliverables":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-candidate-detail"
                );
                if (!save?.includes("project-candidate-2")) {
                  if (!save2?.includes("project-deliverables-modal")) {
                    setTimeout(() => {
                      listStep7(
                        "project-candidate-detail",
                        "project-deliverables-modal"
                      );
                    }, 1000);
                  }
                }
              });
            }
            break;
        }
      };
      const checkStep = (name?: string) => {
        return localStorageFun("project-candidate-detail")?.includes(name)
          ? true
          : valueLocal.value === name;
      };

      if (
        varnew?.name === "project-candidate/detail" &&
        varnew?.bool === true
      ) {
        funDocumentDetail("detail-informasi-project");
      } else if (
        varnew?.name === "project-candidate/detail-customer-partner" &&
        varnew?.bool === true
      ) {
        if (checkStep("project-informasi")) {
          funDocumentDetail("detail-customer-partner");
        }
      } else if (
        varnew?.name === "project-candidate/detail-deliverables" &&
        varnew?.bool === true
      ) {
        if (checkStep("project-informasi")) {
          funDocumentDetail("deliverables");
        }
      } else if (
        varnew?.name === "project-candidate/detail-deliverables-tabs" &&
        varnew?.bool === true
      ) {
        if (
          checkStep("project-informasi") &&
          checkStep("project-deliverables")
        ) {
          funDocumentDetail("deliverables-tabs");
        }
      } else if (
        varnew?.name === "project-candidate/detail-document" &&
        varnew?.bool === true
      ) {
        if (checkStep("project-informasi")) {
          if (varnew?.data?.length > 0) {
            funDocumentDetail("document", "load-api");
          } else {
            funDocumentDetail("document");
          }
        }
      } else if (
        varnew?.name === "project-candidate/detail-cost-planing" &&
        varnew?.bool === true
      ) {
        if (checkStep("project-informasi")) {
          funDocumentDetail("cost-planing");
        }
      } else if (
        varnew?.name === "project-candidate/showHide" &&
        varnew?.bool === true
      ) {
        console.log("project-candidate/showHide");
        if (checkStep("project-informasi")) {
          funDocumentDetail("show-add-deliverables");
        }
      }
    };

    watch(
      comFilterStep,
      (varnew) => {
        if (route.fullPath === "/project/add") {
          if (varnew?.name === "project-candidate/detail") {
            funStepIntro({ ...varnew });
          } else if (
            varnew?.name === "project-candidate/detail-customer-partner"
          ) {
            funStepIntro({ ...varnew });
          } else if (varnew?.name === "project-candidate/detail-deliverables") {
            funStepIntro({ ...varnew });
          } else if (
            varnew?.name === "project-candidate/detail-deliverables-tabs"
          ) {
            funStepIntro({ ...varnew });
          } else if (varnew?.name === "project-candidate/detail-document") {
            funStepIntro({ ...varnew });
          } else if (varnew?.name === "project-candidate/detail-cost-planing") {
            funStepIntro({ ...varnew });
          } else if (varnew?.name === "project-candidate/showHide") {
            funStepIntro({ ...varnew });
          }
        }
      },
      { deep: true }
    );

    return {
      show,
    };
  },
};
