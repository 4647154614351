import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/warning.svg'
import _imports_1 from '@/assets/images/close-chips.svg'


const _withScopeId = n => (_pushScopeId("data-v-6f6a4328"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alert-parent w-100" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: "warning",
  class: "w-25px"
}
const _hoisted_3 = {
  key: 1,
  src: _imports_1,
  alt: "close-chips",
  class: "w-25px"
}
const _hoisted_4 = { class: "title-text" }
const _hoisted_5 = { class: "description-text" }
const _hoisted_6 = { class: "svg-icon svg-icon-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["alert-body w-50 mx-auto", _ctx.alertType == 'warning' ? 'warning-alert' : 'error-alert']),
      style: {"position":"relative"}
    }, [
      (_ctx.alertType == 'warning')
        ? (_openBlock(), _createElementBlock("img", _hoisted_2))
        : (_openBlock(), _createElementBlock("img", _hoisted_3)),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.alertType == "warning"
							? _ctx.$t("powerBiAlert.warningTitle")
							: _ctx.$t("powerBiAlert.errorTitle")), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_i18n_t, {
            keypath: 
							_ctx.alertType == 'warning'
								? 'powerBiAlert.warningDescription'
								: 'powerBiAlert.errorDescription'
						
          }, {
            url: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: { name: 'admin-access-token' },
                style: {"text-decoration":"underline","color":"#0288d1","font-weight":"700"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("powerBiAlert.url")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["keypath"])
        ])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", {
          class: "btn btn-sm btn-icon btn-active-color-primary close-btn-position",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, [
          _createElementVNode("span", _hoisted_6, [
            _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr061.svg" })
          ])
        ])
      ])
    ], 2)
  ]))
}