
import { defineComponent, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute /*, useRouter*/ } from "vue-router";
import HtmlClass from "@/core/services/LayoutService";
import KTLoader from "@/components/Loader.vue";
import AsideMenuArcgis from "@/layout/aside/AsideMenuArcgis.vue";
import KTHeader from "@/layout/header/Header.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
} from "@/assets/ts/components/index";
import { removeModalBackdrop } from "@/core/helpers/dom";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  breadCrumbBar,
  themeDarkLogo,
} from "@/core/helpers/config";

export default defineComponent({
  name: "Layout",
  components: {
    AsideMenuArcgis,
    KTHeader,
    KTFooter,
    KTLoader,
    KTScrollTop,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    // const router = useRouter();

    // initialize html element classes
    HtmlClass.init();

    const catPrj = computed(() => {
      return route.path;
    });

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    const pageOpen = computed(() => {
      return route.name;
    });

    onMounted(() => {
      //check if current user is authenticated
      if (!store.getters.isUserAuthenticated) {
        // router.push({ name: "sign-in" });
      }

      DrawerComponent.bootstrap();
      ScrollComponent.bootstrap();
      DrawerComponent.updateAll();
      ScrollComponent.updateAll();
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        DrawerComponent.hideAll();

        // check if current user is authenticated
        if (!store.getters.isUserAuthenticated) {
          // router.push({ name: "sign-in" });
        }

        removeModalBackdrop();
      }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      breadCrumbBar,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
      pageOpen,
      catPrj,
    };
  },
});
