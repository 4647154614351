import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export enum GlobalDrawerTitleEnum {
  DEFAULT = "Drawer",
  ADD_PARTNER = "Add Partner",
  EDIT_PARTNER = "Edit Partner",
  ADD_CUSTOMER = "Add Customer",
  ADD_DOCUMENT = "Add Document",
  EDIT_DOCUMENT = "Edit Document",
}

export enum SelectedFormEnum {
  DEFAULT = "AddProjectPartnerForm",
  ADD_PARTNER_PROYEK = "AddProjectPartnerForm",
  ADD_CUSTOMER_PROYEK = "AddProjectCustomerForm",
  EDIT_PROJECT_PARTNER_FORM = "EditProjectPartnerForm",
  ADD_PROJECT_DOCUMENT = "AddProjectDocumentForm",
  EDIT_PROJECT_DOCUMENT = "EditProjectDocumentForm",
}

@Module
export default class DrawerModule extends VuexModule {
  globalDrawerState = false;
  globalDrawerTitle = GlobalDrawerTitleEnum.DEFAULT;
  globalDrawerSelectedForm = SelectedFormEnum.DEFAULT;
  globalDrawerSelectedData = null;

  get getGlobalDrawerSelectedData() {
    return this.globalDrawerSelectedData;
  }

  @Mutation
  [Mutations.SET_GLOBAL_DRAWER_SELECTED_DATA](data: any) {
    this.globalDrawerSelectedData = data;
  }

  @Action
  [Actions.UPDATE_GLOBAL_DRAWER_SELECTED_DATA](data: any) {
    this.context.commit(Mutations.SET_GLOBAL_DRAWER_SELECTED_DATA, data);
  }

  get getGlobalDrawerSelectedForm() {
    return this.globalDrawerSelectedForm;
  }

  @Mutation
  [Mutations.SET_GLOBAL_DRAWER_SELECTED_FORM](formComponent: SelectedFormEnum) {
    this.globalDrawerSelectedForm = formComponent;
  }

  @Action
  [Actions.UPDATE_GLOBAL_DRAWER_SELECTED_FORM](
    formComponent: SelectedFormEnum
  ) {
    this.context.commit(
      Mutations.SET_GLOBAL_DRAWER_SELECTED_FORM,
      formComponent
    );
  }

  get getGlobalDrawerState() {
    return this.globalDrawerState;
  }

  @Mutation
  [Mutations.SET_GLOBAL_DRAWER_STATE](newState: boolean) {
    this.globalDrawerState = newState;
  }

  @Action
  [Actions.UPDATE_GLOBAL_DRAWER_STATE](newState: boolean) {
    this.context.commit(Mutations.SET_GLOBAL_DRAWER_STATE, newState);
  }

  get getGlobalDrawerTitle() {
    return this.globalDrawerTitle;
  }

  @Mutation
  [Mutations.SET_GLOBAL_DRAWER_TITLE](title: GlobalDrawerTitleEnum) {
    this.globalDrawerTitle = title;
  }

  @Action
  [Actions.UPDATE_GLOBAL_DRAWER_TITLE](title: GlobalDrawerTitleEnum) {
    this.context.commit(Mutations.SET_GLOBAL_DRAWER_TITLE, title);
  }

  @Action
  [Actions.CLOSE_GLOBAL_DRAWER]() {
    this.context.commit(Mutations.SET_GLOBAL_DRAWER_STATE, false);
    this.context.commit(
      Mutations.SET_GLOBAL_DRAWER_TITLE,
      GlobalDrawerTitleEnum.DEFAULT
    );
  }
}
