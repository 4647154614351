enum Actions {}

enum Mutations {
  STEP = "dashboard/set_step",
  STEP_ASIDE = "dashboard/set_aside",
  LOADING = "dashboard/set_loading",
  LOADING_DOCUMENT = "dashboard/set_loadingDocument",
  TAB_ACTIVE = "dashboard/set_tabActive",
  FILTER_STEP = "dashboard/set_filterStep",
}

enum Getters {
  STEP = "dashboard/get_step",
  STEP_ASIDE = "dashboard/get_aside",
  LOADING = "dashboard/get_loading",
  TAB_ACTIVE = "dashboard/get_tabActive",
  LOADING_DOCUMENT = "dashboard/get_loadingDocument",
  FILTER_STEP = "dashboard/get_filterStep",
}

export { Actions, Mutations, Getters };
