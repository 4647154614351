
import { defineComponent, onMounted, ref, computed } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import NotifModal from "@/components/modals/notificationModal.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import images from '@/assets/ts/images.js'

export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
    NotifModal,
  },
  setup() {
    const drawerStatus = ref(false);
    const unRead = computed(() => {
      return store.getters.getUnread;
    });

    const iconApprove = images.approval
    const i18n = useI18n();
    const store = useStore();
    const router = useRouter();
    const visible = ref(false)
    function toggleLocale() {
      if (i18n.locale.value === "en") {
        store.dispatch(Actions.UPDATE_LOCALE, "id");
      } else {
        store.dispatch(Actions.UPDATE_LOCALE, "en");
      }

      store.commit('setBreadcrumbKey')
    }
    const closeDrawer = () => {
      setTimeout(() => (drawerStatus.value = false), 500);
    };

    const user = computed(() => {
      return store.getters.getUserProfile;
    });

    onMounted(() => {
      store.dispatch(Actions.GET_NOTIFIKASI, {
        limit : 5
      });
    });

    return {
      toggleLocale,
      closeDrawer,
      drawerStatus,
      router,
      iconApprove,
      unRead,
      visible,
      user
    };
  },
});
