
import { defineComponent, onMounted, ref, inject, computed, watch, reactive, onBeforeMount } from "vue";
import {
  setCurrentPageTitle,
  setCurrentPageBreadcrumbs,
} from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import { detectSubDomain, getTenant, checkAccess } from "@/core/helpers/helps";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import LeafletMap from "@/components/leaflet/Leaflet.vue";
import _ from "lodash";
import SelectCheckbox from "@/components/SelectCheckbox.vue"
import { OpenStreetMapProvider } from "leaflet-geosearch";

export default defineComponent({
  components: {
    LeafletMap,
    SelectCheckbox
  },
  name: "dashboard-map",
  layouts: "DashboardLayout",
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const images : any = inject("$images");
    const findPlace = ref('')
    const router = useRouter();
    const asset_total = computed(() => {
      return store.getters.getAssetTotal;
    });
    const loadingData = computed(() => {
      return store.getters.LoadingLeaftlet
    })

    const codePoint = (code) => {
      if (code === 'CLN-CLR') {
        return images.ketPinGreen
      } else if (code === 'UNC-CLR') {
        return images.ketPinYellow
      } else if (code === 'CLN-UNR') {
        return images.ketPinBlue
      } else {
        return images.ketPinRed
      }
    }

    const status_list = computed(() => {
      return store.getters.getListLegal;
    });

    const filterPin = ref({
      Pin: true,
      Polyline: false,
      Polygon: false
    })

    const asset_total_Unique = computed(() => {
      return store.getters.getAssetTotal;
    });

    const map_list = computed(() => {
      return store.getters.getListMap;
    });

  
    onMounted(async () => {
      // if (!checkAccess('dashmap')) {
      //   router.push({
      //     name: 'assetlist', 
      //   });
      // }
      setCurrentPageTitle(t("dashboard"));
      setCurrentPageBreadcrumbs(("dashboard"), []);
    });

    const domain = detectSubDomain();
    const form = ref();

    const mapList = ref("") as any;
    const selectedPin = ref({ lat: "", lng: "", label: "" });
    const searchState = ref(true);
    const zoom = ref(5);

    // watch(getResetFilterPoint, () => {
    //   if (getResetFilterPoint.value) {

    //   }
    // })

    watch(() => findPlace.value, async (value) => {
      if (value != null) {
        if (value == '') {
          searchState.value = true
          zoom.value = 5
        }
        const provider = new OpenStreetMapProvider({
            params: {
                "accept-language": "id",
                countrycodes: "id", // limit search results to the Indonesia
                addressdetails: 0, // include additional address detail parts
            },
        });
        let results = await provider.search({ query: value });
        mapList.value = results;
      }else{
        searchState.value = true
        zoom.value = 5
      }
    });

    const selectPoint = (payload) => {
      selectedPin.value = {
        lat: payload.y,
        lng: payload.x,
        label: payload.formatted_address,
      };
      zoom.value = 15
      findPlace.value = payload.formatted_address || payload.label
      searchState.value = false
      form.value.setCenterMap(selectedPin.value, zoom.value)
    };

    const resetMap = () => {
      zoom.value = 5
      findPlace.value = ''
      searchState.value = false
      selectedPin.value = {
        lat: '-2.54893',
        lng: '118.01486',
        label: '',
      };
      setTimeout(function () {
        form.value.center = ['-2.54893','118.01486']
        form.value.refreshMap()
      }, 100);
    };

    return {
      domain,
      images,
      getTenant,
      form,
      asset_total,
      asset_total_Unique,
      map_list,
      loadingData,
      filterPin,
      findPlace,
      mapList,
      selectedPin,
      searchState,
      zoom,
      selectPoint,
      resetMap,
      status_list,
      codePoint,
      t
    };
  },
});
