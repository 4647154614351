
import { defineComponent, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { computed, onMounted } from "@vue/runtime-core";
import { setAllertError } from "@/core/services/Alert";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { checkToken, getTenant } from "@/core/helpers/helps";
import { CharterPartner } from "@/store/modules/ProjectAddModules";

export default defineComponent({
  name: "AddProjectPartnerForm.vue",
  emits: ["success", "failed"],
  setup(props, { emit }) {
    const route = useRoute();
    const formValidation = ref();
    const loading = ref(false);
    const awaitingSearch = ref();
    const partners = ref([]);
    const selected = ref("customer");
    const description = ref("");
    const store = useStore();
    const fileDocument = ref([]);
    const datas = ref([]);
    const categoryFile = ref("");
    const dokumenFile = ref("");
    const getlistPaytype = computed(() => {
      return store.getters.getlistPaytype;
    });
    const uploadRef = ref();
    const changeFile = (file, fileList) => {
      console.log(fileList);
      fileDocument.value = fileList;
    };
    const formPartner = reactive<CharterPartner>({
      namaPartner: "",
      nomorSpk: "",
      nilaiProyek: "",
      jenisPembayaran: "",
      kategori: "partner",
    });
    const clickPartner = (partner) => {
      console.log(partner);
      // store.dispatch(
      //   Actions.UPDATE_GLOBAL_MODAL_TEXT,
      //   `${partner.name}<br />${partner.vendorPartNum}<br />${partner.smSupplierId}`
      // );
      // store.dispatch(Actions.UPDATE_GLOBAL_MODAL_STATE, true);
      // setAllertBasicPartner(
      //   `${partner.name}`,
      //   `${partner.vendorPartNum}`,
      //   `${partner.smSupplierId}`
      // );
    };
    const getistPartner = computed(() => {
      // const arr1 = store.getters.gettableCharterPartner;
      // const data2 = arr1.filter((a, b) => {
      //   return a.top_type == "PARTNER";
      // });

      const arr2 = store.getters.getistPartner;
      return arr2;
    });
    const chooseFile = () => {
      const fileUpload = document.getElementById("fileUpload");
      if (fileUpload) fileUpload.click();
    };
    //Create form validation object
    const PartnerValidation = Yup.object().shape({
      namaCustomer: Yup.string(),
      spk: Yup.string().required(),
      nilai: Yup.string().required(),
      jenis: Yup.string().required(),
    });
    const selectedFile = (event) => {
      console.log(event.target.files[0]);
      fileDocument.value = event.target.files[0];
    };

    const removeFile = () => {
      fileDocument.value = [];
    };
    const resetForm = () => {
      formPartner.namaPartner = "";
      formPartner.nomorSpk = "";
      formPartner.jenisPembayaran = "";
    };

    const getPayTypes = (params: { itemsPerPage: number; trash: number }) => {
      if (typeof params === "string") {
        store.dispatch(Actions.GET_PAY_TYPE, {
          params: {
            name: params,
            itemsPerPage: 10,
            trash: 0,
          },
        });
        return true;
      }
      store.dispatch(Actions.GET_PAY_TYPE, {
        params: params,
      });
    };

    onMounted(() => {
      getPayTypes({ itemsPerPage: 100, trash: 0 });
      store.dispatch(Actions.GET_LIST_FILE);
      ApiService.get("/file/api/v1/category_file/list").then((data) => {
        datas.value = data.data.data;
      });
    });

    const getToken = (method, query) => {
      store
        .dispatch(Actions.MYTENS_GET_TOKEN)
        .then(() => {
          switch (method) {
            case "getPartner":
              getPartner(query);
              break;
            default:
              console.log("generate new token mytens");
          }
        })
        .catch(() => {
          setAllertError("Terjadi kesalahan saat generate token");
        });
    };

    const getPartner = (query) => {
      if (query !== "") {
        loading.value = true;
        if (awaitingSearch.value) {
          clearTimeout(awaitingSearch.value);
          awaitingSearch.value = 0;
        }

        awaitingSearch.value = setTimeout(() => {
          if (getTenant() === "sda") {
            // Only for SDA
            // checkmytens token
            if (!checkToken) {
              return getToken("getPartner", query);
            }

            store
              .dispatch(Actions.MYTENS_GET_PARTNERS, query)
              .then((res) => {
                partners.value = res.data;
              })
              .catch(() => {
                if (
                  store.getters.getErrors &&
                  store.getters.getErrors.status == 401
                ) {
                  getToken("getPartner", query);
                } else {
                  setAllertError(
                    "Terjadi kesalahan saat mengambil data partner"
                  );
                }
              })
              .finally(() => (loading.value = false));
          } else {
            store
              .dispatch(Actions.GET_MASTER_PARTNER_DATA, {
                params: { itemsPerPage: 5, trash: 0, name: query },
              })
              .then((res) => (partners.value = res.data))
              .catch((err) => {
                setAllertError(err);
              });
          }
        }, 1000);
      } else {
        partners.value = [];
      }
    };
    const formAdd = computed(() => {
      return store.getters.getcharterAdd;
    });
    const submit = () => {
      const forms = formValidation.value;
      forms.validate((valid) => {
        if (valid) {
          ApiService.post("termofpayment/api/v1/partner", {
            partner_name: formPartner.namaPartner,
            source: "myTens",
          })
            .then((response) => {
              const payload = {
                charter_id: route.params.id,
                contract_no: formPartner.nomorSpk,
                partner_code: response.data.data.code,
                top_type: formPartner.kategori.toUpperCase(),
                value_total: formPartner.nilaiProyek.split(".").join(""),
                pay_type_code: formPartner.jenisPembayaran,
                remarks: description.value,
              };
              const data = {
                payload: payload,
                id: route.params.id,
              };
              store
                .dispatch(Actions.STEPPER_THREE, data)
                .then((response) => {
                  resetForm();
                  description.value = "";
                  fileDocument.value.map((a: any) => {
                    let formData = new FormData();
                    formData.append("TenantID", "1");
                    formData.append("Url", a.raw);
                    formData.append(
                      "Path",
                      `${formAdd.value.name}/Deliverables`
                    );
                    formData.append(
                      "Category",
                      formPartner.kategori == "customer"
                        ? "eeed1d98-a830-407d-bba6-b6fe1a3db115"
                        : "2ebb1428-6d54-4df4-8994-49e151a1d19e"
                    );
                    formData.append("PartnerID", response.data.id);
                    if (fileDocument.value) {
                      ApiService.post(
                        "file/api/v1/project_file/insert",
                        formData
                      )
                        .then((data) => {
                          console.log(data);
                        })
                        .catch(() => {
                          setAllertError("error upload file");
                        });
                    }
                  });
                })
                .catch(() => {
                  setAllertError("error menambahkan customer partner");
                })
                .finally(() => {
                  // fileDocument.value = "";
                  uploadRef.value.handleRemove();
                  uploadRef.value.clearFiles();
                  categoryFile.value = "";
                });
              emit("success");
            })
            .catch((err) => {
              setAllertError(err);
            })
            .finally(() => {
              forms.resetFields();
              // uploadRef.value.handleRemove();
              // uploadRef.value.clearFiles();
              const closeEl = document.getElementById("close");
              if (closeEl) closeEl.click();
            });
        } else {
          return false;
        }
      });
    };
    return {
      getTenant,
      formValidation,
      uploadRef,
      dokumenFile,
      categoryFile,
      removeFile,
      changeFile,
      datas,
      clickPartner,
      loading,
      partners,
      getPartner,
      selectedFile,
      chooseFile,
      fileDocument,
      PartnerValidation,
      submit,
      getlistPaytype,
      formPartner,
      selected,
      description,
      getistPartner,
    };
  },
});
