
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  watch,
} from "vue";
import images from "@/assets/ts/images.js";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import {
    countData
} from '@/core/helpers/helps';
import { ElNotification } from "element-plus";

interface Form {
  jenis_aset: string[];
  bumn: string[];
  status: string[];
  province: string[];
  regency: string[];
  status_produktivitas: string[];
}

export default defineComponent({
  name: "components-pages-project-layout-menu-list",
  components: {},
  setup(_, context) {
    const store = useStore();
    const { t } = useI18n();
    const seeDetailIcon = images.seeDetail;
    const buildingRed = images.buildingRed;
    const buildingIcon = images.building;
    const buildingBlue = images.buildingBlue;
    const buildingYellow = images.buildingYellow;
    const buildingTransparant = images.buildingTransparant;
    const tanahMapRed = images.tanahMapRed;
    const tanahMapBlue = images.tanahMapBlue;
    const tanahMapGreen = images.tanahMapGreen;
    const tanahMapYellow = images.tanahMapYellow;
    const doubleBack = images.doubleBack;
    const filter = images.filter;
    const filterState = ref(false);
    const bumns_list : any = ref([]);
    const get_map_list = ref([]);
    const detectId = ref("");
    const search : any = ref("");
    const pageNumber = ref(1);
    const pageNumberPage = ref(1);
    const size = ref(10);
    const sizeChoice = ref([10, 15, 50, 100]);
    const sizeSelect = ref("10");
    const loadingData = ref(false);
    const filterRef: any = ref({
      asset_type_codes: [],
      id_channels: [],
      id_provinces: [],
      legalitas_codes: [],
      approval_status: [],
    });
    const selectTypeGeoJSON = computed(() => {
			return store.getters.SelectTypeGeoJSON
		});

    watch(sizeSelect, () => {
      size.value = parseInt(sizeSelect.value);
    });

    const pageCount = computed(() => {
      let l = get_map_list.value.length;
      let s = size.value;
      return Math.ceil(l / s);
    });

    const paginatedData = computed(() => {
      const start = pageNumber.value * size.value,
        end = start + size.value;

      let data = get_map_list.value;
      data.sort((x, y) => (x["created_at"] > y["created_at"] ? -1 : 1));
      
      return {data: data.slice(start, end),
        page: {
          start, end
        }
      };
    });

    const pagination = computed(() => {
			return {
				current: store.getters.assetFilter.page,
				to: store.getters.assetFilter.limit * store.getters.assetFilter.page,
				...store.getters.assetFilter,
			};
		});

    const timer = ref(0) as any
    const handleSearch = (el) => {
      // Delaying saat melakukan pencarian
      if (timer.value) {
          clearTimeout(timer.value);
          timer.value = null;
      }
      timer.value = setTimeout(() => {
        if (search.value.search === '') {
          fetchData(
            {
              page: 1,
            }
          );
          search.value = [];
          search.value = false;
        } else {
          fetchData(
            {
              page: 1,
              search : el.target.value
            }
          );
        }
      }, 1500);
    };

    // watch(search, (currentState, prevCurrentState) => {
    //   if (currentState.search === '') {
    //     fetchData(
    //       {
    //         page: 1,
    //       }
    //     );
    //     search.value = [];
    //     search.value = false;
    //   } else {
    //     fetchData(
    //       {
    //         page: 1,
    //         search : currentState
    //       }
    //     );
    //   }
    // });

    watch(selectTypeGeoJSON, () => {
      fetchData({
        type : getResetFilterPoint.value
      })
    })

    const filterStatus = ref({
      jenis_aset: false,
      bumn: false,
      status: false,
      province: false,
      regency: false,
      status_produktivitas: false,
    });
    const form = ref<Form>({
      jenis_aset: [],
      bumn: [],
      status: [],
      province: [],
      regency: [],
      status_produktivitas: [],
    });

    const rStatus = ref([
      {
        name: "Informasi Aset",
        url: "informasi-assets",
      },
      {
        name: "Lokasi",
        url: "location",
      },
      {
        name: "Data Perolehan Aset",
        url: "aset-own",
      },
      {
        name: "Sertifikat/Data Kepemilikan",
        url: "sertifikat",
      },
      {
        name: "Data Administrasi Berjalan",
        url: "data-admin",
      },
      {
        name: "Data Nilai Aset",
        url: "data-nilai",
      },
    ]);

    const map_list: any = computed(() => {
      return store.getters.getListAsset;
    });

    const province_list = computed(() => {
      return store.getters.getListProvince;
    });

    const province_val = computed(() => {
      return form.value.province;
    });

    watch(province_val, () => {
      form.value.regency = [];
      store.dispatch(Actions.GET_REGENCY_BY_PROV_LIST, form.value.province);
    });

    const category_list = computed(() => {
      return store.getters.getListCategory;
    });

    const id_status_produktivitas = computed({
      get: () =>
        getAllStatusCategory.value.filter((item) =>
          item.name.toLowerCase().includes("status produktivitas")
        )[0]._id,
      set: (val) => store.dispatch(Actions.GET_STATUS_PRODUKTIVITAS, val),
    });

    const getListProduktivitas = computed(() => {
      return store.getters.getListProduktivitas;
    });

    const regency_list = computed(() => {
      return store.getters.getListRegency;
    });

    const status_list = computed(() => {
      return store.getters.getListLegal;
    });

    const getAllStatusCategory = computed(() => {
      return store.getters.getAllStatusCategory;
    });

    const getBUMNList = () => {
      store.dispatch(Actions.GET_CHANNEL_LIST).then((res) => {
        bumns_list.value = res.data;
      });
      // store.dispatch(Actions.GET_CHANNEL_LIST)
    };

    const setMap = (payload) => {
      store.dispatch(Actions.GET_DETAIL_ASSETS, payload._id).then((res) => {
          if (res?.data?.asset_location.geolocation?.coordinate?.lat && res?.data?.asset_location.geolocation?.coordinate?.long) {
            store.commit(Mutations.SET_MAP_CENTER, {
              _id : res.data._id,
              jenis_asset : res.data.type?.name || 'Bangunan',
              status_legalitas_code : res.data.asset_administration_status_legalitas_category_value?.code || 'Belum Ditentukan',
              coordinate : {
                lat : res.data.asset_location.geolocation.coordinate.lat,
                long : res.data.asset_location.geolocation.coordinate.long
              }
            });
          } else {
            ElNotification({
              title: 'Sorry!',
              message: 'Data ini tidak memiliki PinPoint Lokasi.',
              type: 'error',
            })
          }
      })
    };

    const nextPage = () => {
      pageNumber.value++;
    };

    const prevPage = () => {
      pageNumber.value--;
    };

    const resetFilter = (tag) => {
      form.value[tag] = [];
      Object.keys(form.value).forEach((key) => {
        if (form.value[key].length <= 0) {
          filterStatus.value[key] = false;
        }
      });
      setFilter('reset')
    };
    
    const setFilter = (val) => {
      loadingData.value = true;

      Object.keys(form.value).map((key) => {
        if (form.value[key].length > 0) {
          filterStatus.value[key] = true;
        }
      })
      fetchData({
        page : 1
      })
      pageNumber.value = 0;
      filterState.value = false;
      // reset pin pont selector
      if (val === 'reset') {
        store.commit(Mutations.SET_RESET_FILTER_POINT, 'point');
      }

    };

    const getProfile = computed(() => {
      return store.getters.getUserProfile;
    });

    const isolation = computed(() => {
      return store.getters.getRole;
    });

    const getChannel = computed(() => {
      if (
        getProfile.value.role._id !== '' &&
        isolation.value.isolation_permissions &&
        isolation.value.isolation_permissions[0].is_active
      ) {
        const isolate =
          store.state.AuthModule.userProfile.tenants.length > 0 &&
          store.state.AuthModule.userProfile.tenants[0].workspaces.length > 0
            ? store.state.AuthModule.userProfile.tenants[0].workspaces[0]
                .channels
            : [];
        return isolate;
      } else {
        return store.getters.getListChannel;
      }
    });

    const mapChannel = (payload: any) => {
      if (bumns_list.value.length !== 1) {
        let channel = bumns_list.value.filter((el) => el._id === payload);
        if (channel.length > 0) {
          return channel[0].name;
        } else {
          return '-';
        }
      } else {
        return '-';
      }
    };

    const getResetFilterPoint = computed(() => {
        return store.getters.getResetPoint;
    });

    const fetchData = (letParams: any) => {

      let params: { [key: string]: string } = {};

      if (form.value.jenis_aset) {
        const assetTypeCodes = form.value.jenis_aset.filter(item => item !== null && item !== "");
        if (assetTypeCodes.length > 0) {
          params.asset_type_codes = assetTypeCodes.join(",");
        }
      }

      if (form.value.status_produktivitas) {
        const assetsStatusCodes = form.value.status_produktivitas.filter(item => item !== null && item !== "");
        if (assetsStatusCodes.length > 0) {
          params.assets_status_codes = assetsStatusCodes.join(",");
        }
      }

      if (form.value.bumn) {
        const idChannels = form.value.bumn.filter(item => item !== null && item !== "");
        if (idChannels.length > 0) {
          params.id_channels = idChannels.join(",");
        }
      }

      if (form.value.province && form.value.province.length > 0) {
        params.id_provinces = form.value.province.join(",");
      }

      if (form.value.regency && form.value.regency.length > 0) {
        params.id_regencies = form.value.regency.join(",");
      }

      if (form.value.status && form.value.status.length > 0) {
        params.legalitas_codes = form.value.status.join(",");
      }

      if (!letParams.type) {
          store
          .dispatch(Actions.GET_LIST_ASSETS, {
            ...params,
            ...letParams
          })
          .finally(() => loadingData.value = false)
      }
      
      let mapParams: any = {
        ...params,
        search : letParams?.search
      }
      if (getResetFilterPoint.value !== '') {
        mapParams.type = getResetFilterPoint.value
      }

      store.dispatch(Actions.GET_MAP_LIST, mapParams).then((res) => {
        console.log('saat pertma', res)
        if (!letParams.type || ['point'].includes(letParams?.type)) {
          store.commit(Mutations.SET_ASSET_TOTAL, countData(store.getters.getListMap.data));
        }
      })
    };

    onMounted(() => {
      getBUMNList();
      loadingData.value = true;
      // store.dispatch(Actions.GET_CHANNEL_LIST);
      store.dispatch(Actions.GET_PROVINCE_LIST);
      store.dispatch(Actions.GET_ALL_STATUS_CATEGORY);
      fetchData({
        page : 1
      })
      store.dispatch(
        Actions.GET_STATUS_PRODUKTIVITAS,
        "6317d89a5f05cc92cf2d5572"
      );
      store.dispatch(
        Actions.GET_STATUS_LEGAL_ASSETS,
        "6316769d8c130d3fa041611c"
      );
      store.dispatch(
        Actions.GET_ASSET_CATEGORY_LIST,
        "631577ec8c130d3fa0415fe8"
      );

      const urlParams = new URLSearchParams(location.search);
      for (const [key, value] of urlParams) {
        detectId.value = value;
      }
    });

    const currentPage = ref(1)
    const pageSize2 = ref(10)
    const small = ref(false)
    const background = ref(false)
    const disabled = ref(false)

    const handleSizeChange = (val: number) => {
      fetchData({
        limit : val,
      });
    }
    const handleCurrentChange = (val: number) => {
      fetchData({
        page : val,
      });
    }

    return {
      t,
      currentPage,
      small,
      background,
      disabled,
      handleSizeChange,
      handleCurrentChange,
      handleSearch,
      pageSize2,
      rStatus,
      buildingRed,
      seeDetailIcon,
      buildingIcon,
      buildingBlue,
      buildingYellow,
      buildingTransparant,
      tanahMapRed,
      tanahMapBlue,
      tanahMapGreen,
      tanahMapYellow,
      doubleBack,
      filter,
      form,
      bumns_list,
      getAllStatusCategory,
      id_status_produktivitas,
      getListProduktivitas,
      category_list,
      get_map_list,
      map_list,
      province_list,
      regency_list,
      status_list,
      search,
      filterState,
      pageCount,
      paginatedData,
      pageNumber,
      sizeChoice,
      sizeSelect,
      setMap,
      nextPage,
      prevPage,
      setFilter,
      filterStatus,
      loadingData,
      getChannel,
      getProfile,
      isolation,
      mapChannel,
      pagination,
      store,
      fetchData,
      resetFilter
    }; 
  },
});
