import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Pic {
  id: number;
  name: string;
  email: string;
}

export interface ProjectClose {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  pic: Pic;
  closedDate: string;
  lessonLearned: string;
  kategoriClose: string;
  dokumenFile: string;
  categoryFile: string;
  stakeholders: Array<any>;
  nomorDokumen: string;
}

@Module
export default class ProjectCloseModule extends VuexModule {
  projectClose: ProjectClose = {
    id: 0,
    name: "",
    startDate: "",
    endDate: "",
    pic: {
      id: 0,
      name: "",
      email: "",
    },
    closedDate: "",
    lessonLearned: "",
    kategoriClose: "",
    dokumenFile: "",
    categoryFile: "1c7d4399-4542-4a44-a734-d40d7c7b1481",
    stakeholders: [],
    nomorDokumen: "",
  };

  /**
   * Get project close form
   * @returns object
   */
  get getFormProjectClose(): ProjectClose {
    return this.projectClose;
  }

  @Mutation
  [Mutations.SET_CLOSE_PROJECT_FORM](payload) {
    this.projectClose = payload;
  }

  @Mutation
  [Mutations.RESET_CLOSE_PROJECT_FORM]() {
    this.projectClose = {
      id: 0,
      name: "",
      startDate: "",
      endDate: "",
      pic: {
        id: 0,
        name: "",
        email: "",
      },
      closedDate: "",
      lessonLearned: "",
      kategoriClose: "",
      dokumenFile: "",
      categoryFile: "1c7d4399-4542-4a44-a734-d40d7c7b1481",
      stakeholders: [],
      nomorDokumen: "",
    };
  }

  @Action
  [Actions.RESET_CLOSE_PROJECT]() {
    this.context.commit(Mutations.RESET_CLOSE_PROJECT_FORM);
  }

  @Action
  [Actions.UPDATE_CLOSE_PROJECT](payload) {
    this.context.commit(Mutations.SET_CLOSE_PROJECT_FORM, payload);
  }
}
