import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

const service =
  process.env.VUE_APP_NODE_ENV == "local" ? "risk/api/v1" : "risk/api/v1";

@Module
export default class RiskModule extends VuexModule {
  @Action
  [Actions.RISK_REGISTER](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/riskRegister`, { params: query })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_IMPACT_RISK](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/riskImpact`, { params: query })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_SIDE_IMPACT_RISK](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/riskImpactSide`, query)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_SOURCE_RISK](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/riskSource`, query)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PROBABILITY_RISK](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/riskProbability`, query)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_STATUS_RISK](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/riskStatus`, { params: query })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_CATEGORY_RISK](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/riskCategory`, { params: query })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.SAVE_RISK_REGISTER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/riskRegister`, payload)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_RISK_REGISTER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`${service}/riskRegister`, payload.id, payload.data)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_RISK_RESPONSE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`${service}/riskResponsePlan`, payload.id, payload.data)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_RISK](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`${service}/riskRegister/${id}`)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_RISK_RESPONSE](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`${service}/riskResponsePlan/${id}`)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_RISK_DETAIL](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`${service}/riskRegister/${id}`)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }
}
