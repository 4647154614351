
import { defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { MenuComponent } from "@/assets/ts/components";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";
import { detectSubDomain, checkAccess } from "@/core/helpers/helps";

import PageSetting from "@/components/tutorial/setting/PageSetting.vue";
// import pageMember from "@/components/tutorial/setting/pageMember.vue";
import On_boarding_2 from "@/components/tutorial/On_boarding_2.vue";
import Project_candidate from "@/components/tutorial/Project_candidate.vue";
import Project_candidate_2 from "@/components/tutorial/Project_candidate_2.vue";
import Project_active from "@/components/tutorial/Project-active.vue";
import Project_tech_close from "@/components/tutorial/Project-tech-close.vue";
import Project_close from "@/components/tutorial/Project-close.vue";
import Project_Document from "@/components/tutorial/Document.vue";
import { ActionsAdminValidasi, MutationsAdminValidasi } from "@/store/enums/StoreAdminValidasiEnums";
import { ref } from "vue";
import { computed } from "vue";

export default defineComponent({
  name: "KTMenu",
  components: {
    On_boarding_2,
    Project_candidate,
    Project_candidate_2,
    Project_active,
    PageSetting,
    // pageMember,
    Project_tech_close,
    Project_close,
    Project_Document,
  },
  setup() {
    const { t, te } = useI18n();
    const store = useStore();
    const route = useRoute();
    const tenant = detectSubDomain();
    const ValidasiList = ref(computed(() => store.getters.ValidasiList));
    
    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const movePage = (navigate, params) => {
      navigate()
      if (params.menu === '/assets/list' && params.isActive || ValidasiList.value.request_review) {
        store.commit(MutationsAdminValidasi.SET_SWITCH, false)
        store.dispatch(ActionsAdminValidasi.OPEN_APPROVAL_REQUEST_UPDATE, {
          status: false,
          validated: false,
          request_review: false,
          verified: false,
        })
        store.dispatch(ActionsAdminValidasi.OPEN_VALIDASI_LIST, {
          status: false,
          validated: false,
          request_review: false,
          verified: false,
        });

        //reset value asset
        store.commit(MutationsAdminValidasi.SET_ACTION_STATUS_ASSET, {
          ...store.getters.PayloadAction,
          entity_id : ''
        })
      }
    }

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    const changeMode = (mode) => {
      localStorage.setItem("mode", mode);
      window.location.reload();
    };

    return {
      hasActiveChildren,
      changeMode,
      translate,
      headerMenuIcons,
      tenant,
      MainMenuConfig,
      checkAccess,
      movePage
    };
  },
});
