import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ElMessage } from "element-plus";

/**
 * @description set error alert to show on frontend
 */
export const setAllertError = (message) => {
  let text = message.value;

  if (
    typeof message === "object" &&
    !Array.isArray(message) &&
    message !== null
  ) {
    text = "Validasi Error";
  } else if (!text) {
    text = message;
  } else {
    text = "Terjadi Kesalahan";
  }

  if (message == "memuat ulang") {
    return Swal.fire({
      text: text,
      icon: "info",
      buttonsStyling: false,
      allowOutsideClick: false,
      setTimeout: 1,
      confirmButtonText: "Ok!",
      customClass: {
        confirmButton: "btn fw-bold btn-light-primary",
      },
    });
  } else {
    return Swal.fire({
      text: text,
      icon: "error",
      buttonsStyling: false,
      allowOutsideClick: false,
      setTimeout: 1,
      confirmButtonText: "Ok!",
      customClass: {
        confirmButton: "btn fw-bold btn-light-danger",
      },
    });
  }
};

/**
 * @description set success alert to show on frontend
 */
export const setAllertSuccess = (message) => {
  return Swal.fire({
    text: message,
    icon: "success",
    buttonsStyling: false,
    allowOutsideClick: false,
    confirmButtonText: "Ok!",
    customClass: {
      confirmButton: "btn fw-bold btn-light-primary",
    },
  });
};

/**
 * @description set success alert to show on frontend
 */
export const confirmAllert = () => {
  return Swal.fire({
    title: "Warning!",
    text: "anda yakin ingin menghapus data ini?",
    icon: "warning",
    buttonsStyling: false,
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonText: "Delete",
    customClass: {
      confirmButton: "btn fw-bold btn-light-primary",
      cancelButton: "btn fw-bold btn-light-info",
    },
  });
};

export const toastSuccess = (msg) => {
  return ElMessage({
    message: msg,
    type: "success",
  });
};

export const toastError = (msg) => {
  return ElMessage.error(msg);
};

export const confirmAllert2 = (text) => {
  return Swal.fire({
    title: "Warning!",
    text: `${text}`,
    icon: "warning",
    buttonsStyling: false,
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonText: "ya",
    customClass: {
      confirmButton: "btn fw-bold btn-light-primary",
      cancelButton: "btn fw-bold btn-light-info",
    },
  });
};

/**
 * @description set success alert to show on frontend
 */
export const setAllertBasic = (type, nipnas, segment) => {
  return Swal.fire(
    `${type}`,
    `<br> nipnas: ${nipnas} <br> segment: ${segment}`
  );
};
export const setAllertBasicPartner = (type, nipnas, segment) => {
  return Swal.fire(
    `${type}`,
    `<br> vendor number: ${nipnas} <br> suplier id: ${segment}`
  );
};

/**
 * loading mixin swal
 */

export const mixinMaster = () => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true
  })

  return Toast
}

export default {
  setAllertError,
  setAllertSuccess,
  confirmAllert,
  toastSuccess,
  toastError,
};
