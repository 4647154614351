import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PBI = _resolveComponent("PBI")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.BiTokens != '')
      ? (_openBlock(), _createBlock(_component_PBI, {
          key: 0,
          accessToken: _ctx.BiTokens.accessToken,
          embedUrl: _ctx.BiTokens.embedUrl,
          id: _ctx.BiTokens.id,
          refreshToken: _ctx.BiTokens.refreshToken,
          filter: _ctx.getChannel
        }, null, 8, ["accessToken", "embedUrl", "id", "refreshToken", "filter"]))
      : _createCommentVNode("", true)
  ]))
}