import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";

const service =
  process.env.VUE_APP_NODE_ENV == "local"
    ? "termofpayment/api/v1"
    : "termofpayment/api/v1";

export interface IStorePartnerPayload {
  charter_id: string;
  contract_no: string;
  partner_name: string;
  partner_correlation_id: string;
  top_type: string;
  value_total: number;
  pay_type_code: string;
  remarks: string;
  documents: [];
}

@Module
export default class TopModule extends VuexModule {
  topPartners = null;

  get getTopPartners() {
    return this.topPartners;
  }

  @Mutation
  [Mutations.SET_TOP_PARTNERS](data) {
    this.topPartners = data;
  }

  @Action
  [Actions.PARTNER_LIST](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/partnerpayment`, { params: query })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOP_PARTNERS, data.data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.PAYMENT_LIST](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/termofpayment`, { params: query })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PAY_TYPE_2](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/paytype`, { params: query })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PARTNER_2](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/partner`, { params: query })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.SAVE_PARTNER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/partnerpayment/store2`, payload)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_PAYMENT](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/termofpayment`, payload)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_PARTNER_2](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(
        `${service}/partnerpayment/update2/${payload.id}`,
        payload.form
      )
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.UPDATE_PARTNER](params) {
    return new Promise((resolve, reject) => {
      ApiService.put(`${service}/partnerpayment`, params.id, params)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject(err);
        });
    });
  }

  @Action
  [Actions.UPDATE_PAYMENT](payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(`${service}/termofpayment`, payload.id, payload.form)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject(err);
        });
    });
  }

  @Action
  [Actions.PARTNER_DELETE_2](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`${service}/partnerpayment/${id}`)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.PAYMENT_DELETE](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`${service}/termofpayment/${id}`)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }
}
