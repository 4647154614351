enum Actions {
  JOB_GROUP_POST = 'jobGroupPost',
  JOB_GROUP_PUT = 'jobGroupPut',
  JOB_GROUP_DELETE = 'jobGroupDelete',
  JOB_GROUP_QUERY = 'jobGroupGet',
  UPDATE_NOTIFY = 'updateNotify',
  GET_DATA_EXECUTIVE = 'getDataExecutive',
  DELETE_HISTORY_TASK_WEIGHT_PROGRESS = 'deleteHistoryTaskWeightProgress',
  EDIT_UPDATE_TASK_WEIGHT_PROGRESS = 'editUpdateTaskWeightProgress',
  GET_HISTORY_TASK_WEIGHT_PROGRESS = 'getHistoryTaskWeightProgress',
  UPDATE_TASK_WEIGHT_PROGRESS = 'updateTaskWeightProgress',
  GET_ADDITIONALS = 'getAdditional',
  SAVE_ADDITIONAL = 'saveAdditional',
  GET_TENANTS = 'getTenant',
  CHARTER_GET_ADDITIONAL = 'charterGetAdditional',
  CHARTER_UPDATE_ADDITIONAL = 'charterUpdateAdditional',
  GET_DASHBOARD_SETTING = 'gateDashboardSetting',
  SAVE_DASHBOARD_SETTING = 'saveDashboardSetting',
  UPDATE_PROJECT_UPDATE_CHARTER = 'updateProjectUpdateCharter',
  GET_DELIVERABLE_BY_CHARTER_ID = 'getDeliverableByCharterId',
  REFRESH_TOKEN = 'refreshToken',
  DELETE_PROJECT_FILE_PARTNER = 'deleteProjectFilePartner',
  BUDGET_PERFORMA = 'budgetPerforma',
  UPDATE_GLOBAL_MODAL_SELECTED_DATA = 'updateGlobalModalSelectedData',
  CLOSE_GLOBAL_MODAL = 'closeGlobalModal',
  UPDATE_GLOBAL_MODAL_TITLE = 'updateGlobalModalTitle',
  UPDATE_GLOBAL_MODAL_STATE = 'updateGlobalModalState',
  UPDATE_GLOBAL_MODAL_TEXT = 'updateGlobalModalText',
  UPDATE_GLOBAL_DRAWER_SELECTED_DATA = 'updateGlobalDrawerSelectedData',
  UPDATE_PARTNER_FORM = 'updatePartnerForm',
  UPDATE_GLOBAL_DRAWER_SELECTED_FORM = 'updateGlobalDrawerSelectedForm',
  UPDATE_SYMPTOM_IS_FETCHING = 'updateSymptomIsFetching',
  UPDATE_SYMPTOM_IS_SUBMITTING = 'updateSymptomIsSubmitting',
  UPDATE_SYMPTOM_LIST = 'updateSymptomList',
  GET_SYMPTOM_LIST = 'getSymptomList',
  GET_SYMPTOM_DETAIL = 'getSymptomDetail',
  SCURVES = 'sCurves',
  FILE_UPLOAD = 'fileUpload',
  DELETE_ALL_PROJECT_FILE = 'deleteAllProjectFile',
  FILE_DOWNLOAD_ALL = 'fileDownloadAll',
  PAYMENT_DELETE = 'paymentDelete',
  UPDATE_PAYMENT = 'updatePayment',
  STORE_PAYMENT = 'storePayment',
  UPDATE_FILTER_LIST_PROJECT = 'updateFilterListProject',
  SEARCH_DELIVERABLES = 'searchDeliverable',
  TASK_DELETE = 'taskDelete',
  PARTNER_DELETE_2 = 'partnerDelete2',
  UPDATE_PARTNER_2 = 'updatePartner2',
  SAVE_PARTNER = 'savePartner',
  GET_PARTNER_2 = 'getPartner2',
  GET_PAY_TYPE_2 = 'getPayType2',
  GET_CATEGORY_FILE = 'getCategoryFile',
  CLOSE_GLOBAL_DRAWER = 'closeGlobalDrawer',
  UPDATE_GLOBAL_DRAWER_STATE = 'updateGLobalDrawerState',
  UPDATE_GLOBAL_DRAWER_TITLE = 'updateGlobalDrawerTitle',
  UPDATE_PARTNER = 'updatePartner',
  GET_PROJECT_FILE_CATEGORIES = 'getProjectFileCategories',
  SEARCH_PROJECT_FILE = 'searchProjectFile',
  UPDATE_PROJECT_UPDATE_DRAWER_MODE = 'updateProjectUpdateDrawerMode',
  UPDATE_PROJECT_UPDATE_SELECTED_DATA = 'updateProjectUpdateSelectedData',
  UPDATE_PROJECT_UPDATE_IS_FETCHING = 'updateProjectUpdateIsFetching',
  UPDATE_PROJECT_UPDATE_IS_SUBMITTING = 'updateProjectUpdateIsSubmitting',
  UPDATE_PROJECT_UPDATE_DRAWER_STATE = 'updateProjectUpdateDrawerState',
  UPDATE_PROJECT_UPDATE_NAVIGATE_FROM = 'updateProjectUpdateNavigateFrom',
  UPDATE_PROJECT_UPDATE_ACTIVE_TAB = 'updateProjectUpdateActiveTab',
  RESET_CLOSE_PROJECT = 'resetCloseProject',
  DELETE_PROJECT_FILE = 'deleteProjectFile',
  DOWNLOAD_SINGLE_PROJECT_FILE = 'downloadSingleProjectFile',
  UPDATE_DASHBAORD_LINK_DETAIL = 'updateDashboardLinkDetail',
  UPDATE_DASHBOARD_LINK_DRAWER_MODE = 'updateDashboardLinkDrawerMode',
  UPDATE_DASHBOARD_LINK_DRAWER_STATE = 'updateDashboardLinkDrawerState',
  DELETE_DASHBOARD_LINK = 'deleteDashboardLink',
  SOFT_DELETE_DASHBOARD_LINK = 'softDeleteDashboardLink',
  RESTORE_DASHBOARD_LINK = 'restoreDashboardLink',
  UPDATE_DASHBOARD_LINK = 'updateDashboardLink',
  STORE_DASHBOARD_LINK = 'storeDashboardLink',
  GET_DASHBOARD_LINK_LIST = 'getDashboardLinkList',
  GET_DASHBOARD_LINK_DETAIL = 'getDashboardLinkDetail',
  UPDATE_DASHBOARD_LINK_FILTER = 'updateDashboardLinkFilter',
  ADD_BODY_CLASSNAME = 'addBodyClassName',
  REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
  ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
  REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
  ADD_CLASSNAME = 'addClassName',
  VERIFY_AUTH = 'verifyAuth',
  LOGIN = 'login',
  SCHEDULE = 'schedule',
  RISK_REGISTER = 'riskRegister',
  SAVE_SCHEDULE = 'save_schedule',
  SAVE_RISK_REGISTER = 'saveRiskRegister',
  GET_PARTNER_SCHEDULE = 'getPartnerSchedule',
  GET_IMPACT_RISK = 'getImpactRisk',
  GET_SIDE_IMPACT_RISK = 'getSideImpactRisk',
  GET_SOURCE_RISK = 'getSourceRisk',
  GET_PROBABILITY_RISK = 'getProbabilityRisk',
  GET_STATUS_RISK = 'getStatusRisk',
  GET_CATEGORY_RISK = 'getcategoryRisk',
  GET_RISK_DETAIL = 'getRiskDetail',
  GET_CHARTER_CATEGORY = 'getCharterCategory',
  GET_DIREKTORAT = 'getDirektorat',
  GET_DIVISI = 'getDivision',
  GET_SEGMENT = 'getSegment',
  GET_REGIONAL = 'getRegional',
  GET_TYPE_GROUP = 'getTypeGroup',
  GET_CITY = 'getCity',
  GET_PROVINCE = 'getProvince',
  GET_DISTRICT = 'getDistrict',
  GET_TAG = 'getTags',
  GET_PAY_TYPE = 'getPayType',
  GET_FILTER_OPTIONS_LIST = 'getFilterOptionsList',
  UPDATE_RISK_REGISTER = 'updateRiskRegister',
  UPDATE_RISK_RESPONSE = 'updateRiskResponse',
  DELETE_RISK = 'deleteRisk',
  DELETE_RISK_RESPONSE = 'deleteRiskResponse',
  LOGOUT = 'logout',
  REGISTER = 'register',
  UPDATE_USER = 'updateUser',
  STEPPER_ONE = 'stepperOne',
  STEPPER_TWO = 'stepperTwo',
  STEPPER_THREE = 'stepperThree',
  STEPPER_FOUR = 'stepperFour',
  STEPPER_FIVE = 'stepperFive',
  STEPPER_SIX = 'stepperSix',
  STEPPER_EIGHT = 'stepperEight',
  STEPPER_EIGHT_EDIT = 'stepperEightEdit',
  DELETE_COST_PGU = 'deleteCostPGU',
  DELETE_COST_KHS = 'deleteCostKHS',
  DELETE_COST_RAB = 'deleteCostRAB',
  DELETE_COST_ITEM = 'deleteCostItem',
  DELETE_COST_RAB_MATERIAL = 'deleteCostRABMaterial',
  STEPPER_SHOW = 'stepperShow',
  FORGOT_PASSWORD = 'forgotPassword',
  SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
  SET_BREADCRUMB_ACTION2 = 'setBreadcrumbAction2',
  PROJECT_LIST = 'projectList',
  PROJECT_RUNNING_STATUS = 'projectRunningStatus',
  PROJECT_ADD_NEXT_STEPPER = 'projectAddStepper',
  PROJECT_SHOW = 'projectShow',
  CREATE_CHARTER_PARTNER = 'createCharterPartner',
  GET_CHARTER_PARTNER = 'getCharterPartner',
  GET_SHOW_PROJECT = 'getShowProject',
  GET_LIST_DELIVERABLES = 'getListDeliverables',
  GET_LIST_TERM = 'getListTerm',
  PARTNER_LIST = 'partnerList',
  PAYMENT_LIST = 'paymentList',
  DELIVERABLES_LIST = 'deliverableList',
  UPDATE_LOCALE = 'updateLocale',
  BACK_STEPPER = 'backStepper',
  CHARTER_VALIDATION = 'charterValidation',
  CHARTER_DATE_VALIDATION = 'charterDateValidation',
  GET_PARTNER = 'getPartner',
  DONE_INPUT = 'doneInput',
  STEP1_EDIT = 'step1Edit',
  STEP2_EDIT = 'step2Edit',
  DELETE_TERM_OF_PAYMENT = 'deleteTermOfPayment',
  VALIDATE_MY_TENS_CRED = 'validateMyTensCred',
  GET_ROLE_LIST = 'getRoleList',
  GET_ROLE_DETAIL = 'getRoleDetail',
  STORE_ROLE = 'createRole',
  UPDATE_ROLE = 'updateRole',
  RESTORE_ROLE = 'restoreRole',
  DESTROY_ROLE = 'deleteRole',
  FORCE_DELETE_ROLE = 'forceDeleteRole',
  GET_FILE_DETAIL = 'getFileDeyail',
  UPLOAD_DOKUMEN = 'uploadDokumen',
  GET_LIST_FILE = 'getListFile',
  MYTENS_GET_TOKEN = 'mytensGetToken',
  MYTENS_GET_PARTNERS = 'mytensGetPartners',
  MYTENS_GET_CUSTOMERS = 'mytensGetCustomers',
  MYTENS_GET_APPROVERS = 'mytensGetApprovers',
  UPDATE_MASTER_ROLE_LOADING = 'updateMasterRoleLoading',
  UPDATE_MASTER_ROLE_DIALOG = 'updateMasterRoleDialog',
  UPDATE_MASTER_ROLE_DRAWER = 'updateMasterRoleDrawer',
  UPDATE_MASTER_ROLE_PAGE = 'updateMasterRolePage',
  UPDATE_MASTER_ROLE_KEYWORD = 'updateMasterRoleKeyword',
  UPDATE_MASTER_ROLE_ACTIVE_DIALOG = 'updateMasterRoleActiveDialog',
  UPDATE_MASTER_ROLE_SELECTED = 'updateMasterRoleSelected',
  UPDATE_CLOSE_PROJECT = 'updateCloseProject',
  ADD_DELIVERABLES = 'addDeliverables',
  UPDATE_DELIVERABLE = 'updateDeliverables',
  GET_APPROVAL_SHOW = 'getApprovalShow',
  DELIVERABLES_HISTORIES = 'deliverables_histories',
  APPROVAL_ACTION = 'approvalAction',
  GET_LANGUAGE_LIST = 'getLanguageList',
  GET_LANGUAGE_DETAIL = 'getLanguageDetail',
  ADD_LANGUAGE = 'storeLanguage',
  UPDATE_LANGUAGE = 'updateLanguage',
  DELETE_LANGUAGE = 'destroyLanguage',
  FORCE_DELETE_LANGUAGE = 'forcedeleteLanguage',
  RESTORE_LANGUAGE = 'restoreLanguage',
  GET_THEME_LIST = 'getThemeList',
  GET_THEME_DETAIL = 'getThemeDetail',
  ADD_THEME = 'storeTheme',
  UPDATE_THEME = 'updateTheme',
  DELETE_THEME = 'destroyTheme',
  FORCE_DELETE_THEME = 'forcedeleteTheme',
  RESTORE_THEME = 'restoreTheme',
  UPDATE_CURRENT_THEME = 'updateCurrentTheme',
  PROJECT_DELETE = 'projectDelete',
  GET_MENU_LIST = 'getMenuList',
  GET_USER_LIST = 'getUserList',
  ASSIGN_USER_ROLE = 'assignUserRole',
  ASSIGN_MENU_ROLE = 'assignMenuRole',
  ASSIGN_MENU_ROLE_PERM = 'assignMenuRolePerm',
  UPDATE_MASTER_MENU_KEYWORD = 'updateMasterMenuKeyword',
  STORE_MENU = 'storeMenu',
  UPDATE_MASTER_MENU_SUBMITTING = 'updateMastermenuSubmitting',
  UPDATE_GS_FORM_LANG = 'updateGSFormLang',
  UPDATE_GS_FORM_THEME = 'updateGSFormTheme',
  UPDATE_GENERAL_SETTING = 'updateGS',
  UPDATE_GS_SUBMITTING = 'updateGSSubmitting',
  UPDATE_USERS_FILTER = 'updateUsersFilter',
  UPDATE_USERS_PAGE = 'updateUsersPage',
  UPDATE_USERS_ITEMS_PER_PAGE = 'updateUsersItemsPerPage',
  UPDATE_USERS_KEYWORD = 'updateUsersKeyword',
  UPDATE_MASTER_ROLE_SELECTED_DETAIL = 'updateMasterRoleDtl',
  UPDATE_ROLE_DTL_MENU_STATE = 'updateRoleDtlMenuState',
  PROJECT_ACTIVE = 'projectActive',
  FCM_SAVE = 'fcmSave',
  FCM_LOGOUT = 'fcmLogout',
  UPDATE_MASTER_ROLE_SUBMITTING = 'updateMasterRoleSubmitting',
  GET_NOTIFIKASI = 'getNotifikasi',
  UPDATE_ROLE_MENUS = 'updateRoleMenus',
  UPDATE_DETAIL_PROJECT = 'updateDetailProject',
  UPDATE_COST_RAB_LIST = 'updateCostRABList',
  UPDATE_COST_KHS_LIST = 'updateCostKHSList',
  UPDATE_COST_PGU = 'updateCostPGU',
  UPDATE_COST_RAB_FORM = 'updateCostRABForm',
  UPDATE_COST_KHS_FORM = 'updateCostKHSForm',
  UPDATE_COST_PGU_FORM = 'updateCostPGUForm',
  GET_COST_RAB = 'getListCostRAB',
  GET_COST_KHS = 'getListCostKHS',
  GET_COST_PGU = 'getListCostPGU',
  GET_COST_PGU_TOTAL = 'getDataCostPGUTotal',
  GET_SELLERS = 'getSellers',
  GET_SELLER_PACKAGES = 'getSellerPackages',
  DELETE_MEMBER = 'deleteMember',
  UPDATE_MEMBER_DETAIL = 'updateMemberDetail',
  DELETE_TEMPLATE = 'deleteTemplate',
  CREATE_STAKEHOLDER = 'createStakeHolder',
  GET_LIST_COST_DELIVERABLE_MATERIAL = 'getListCostDeliverableMaterial',
  LOGIN_TELKOMSAT = 'loginTelkomsat',
  ADD_NEW_MEMBER = 'addNewMember',
  GET_MASTER_PARTNER_DATA = 'getMasterPartner',
  GET_LIST_USER = 'getListUser',
  GET_REPORT_PARTNER_PROYEK = 'getReportPartnerProyek',
  APPROVAL_USER = 'approvalUser',
  GET_REPORT_PROJECT_MANAGER = 'getReportProjectManager',
  GET_LIST_PROJECT_FILE = 'getListProjectFile',
  GET_REPORT_HIRARAKI_DOKUMEN = 'getHirarkiDokumen',
  UPDATE_MEMBER_DRAWER_STATE = 'updateMemberDrawerState',
  UPDATE_MEMBER_DRAWER_MODE = 'updateMemberDrawerMode',
  SEARCH_REPORT_PARTNER_PROYEK = 'searchReportPartnerProyek',
  SEARCH_REPORT_PROJECT_MANAGER = 'searchReportProjectManager',
  GET_DETAIL_REPORT_PROJECT_MANAGER = 'getDetailReportProjectManaget',
  GET_KANBAN_ITEMS = 'getKanbanItems',
  UPDATE_MAP_STATUS = 'updateMapStatus',
  GET_LIST_PROJECT_FILE_2 = 'getListProjectFile2',
  GET_LIST_PROJECT_TEMPLATE = 'getListProjectTemplates',
  SAVE_AS_TEMPLATE = 'saveProjectATemplate',
  GET_DETAIl_TEMPLAE = 'getDetailTemplate',
  PROJECT_INFORMATION_TEMPLATE = 'projectInformation',
  GET_TENANT_LIST = 'getTenantList',
  TENANT_LIST_INSERT = 'tenantListInsert',
  TENANT_LIST_DELETE = 'tenantListDelete',
  TENANT_LIST_EDIT = 'tenantListEdit',
  GET_SCALE_PROJECTS = 'getScaleProjects',
  UPDATE_TERM_OF_PAYMENT = 'updateTermOfPayment',
  GET_COST_PGU_LIST = 'getCostPGUList',
  GET_FILTER_DASHBOARD_DATE = 'getFilterDate',
  GET_BUDGET_PROJECTS = 'getBudgetProjects',
  UPDATE_COST_TYPE = 'updateCostType',
  GET_TOP_PROJECTS = 'getTopProjects',
  GET_CATEGORY_PROJECTS = 'getCategoryProjects',
  GET_SEGMENT_PROJECTS = 'getSegmentProjects',
  GET_PROGRESS_PROJECTS = 'getProjectProjects',
  GET_STATUS_PROJECTS = 'getStatusProjects',
  GET_STAKEHOLDER_PROJECTS = 'getStakeholderProjects',
  GET_ISSUE_PROJECTS = 'getIssueProjects',
  GET_CHANGE_ADMIN = 'getChangeAdmin',
  DELETE_CHARTER_PARTNER = 'deleteCharterPartner',
  GET_PROGRESS_GRAFIK = 'getProgressGrafik',
  GET_COST_TOTAL_REALIZATION = 'getCostTotalRealizationData',
  GET_LOCATION_PROJECT = 'getLocationProject',
  EDIT_PARTNER_PAYMENT = 'editPartnerPayment',
  GET_COST_ITEMS = 'getListCostItems',
  UPDATE_COST_ITEM = 'updateCostItem',
  UPDATE_PROGRESS_DELIVERABLE = 'updateProgressDeliverable',
  DELETE_SELLER = 'deleteSeller',
  DELETE_SELLER_PACKAGE = 'deleteSellerPackage',
  CREATE_HOSTORY_INTEGRATIONS = 'createHistoryIntegrations',
  GET_HISTORY_INTEGRATIONS = 'getHistoryIntegrations',
  TELKOMSAT_GET_TOKEN = 'telkomsatGetToken',
  TELKOMSAT_SUBMIT_PROJECT = 'telkomsatSubmitProject',
  GET_KHS_REALIZATION_DASHBOARD = 'getKHSRealizationDashboard',
  CREATE_STAKEHOLDER_NEW = 'createStakeholderNew',
  GET_STAKEHOLDER_NEW = 'getStakeholderNew',
  UPDATE_STAKEHOLDER_NEW = 'updateStakeholderNew',
  GET_REPORT_PROGRESS_ACHIEVEMENT = 'getReportProgressAchievement',
  GET_REPORT_SCALE = 'getReportScale',
  GET_REPORT_ISSUE = 'getReportIssue',
  GET_REPORT_LOCATION = 'getReportLocation',
  USE_TEMPLATE = 'useTemplate',
  GET_REPORT_CATEGORY_SEGMENT = 'getReportCatSegment',
  GET_REPORT_BUDGET = 'getReportBudget',
  GET_REPORT_TOP = 'getReportTop',
  GET_REPORT_STAKEHOLDER_CUSTPRT = 'getReportStkCustPrt',
  GET_DETAIL_SUMMARY = 'getDetailSummary',
  GET_DETAIL_SCURVE = 'getDetailScurve',
  GET_DOC_LOP_WIN = 'getDocLopWin',
  GET_DOC_SPK = 'getDocSpk',
  GET_DOC_TOP_KL = 'getDocTopKl',
  GET_DOC_TOP_KB = 'getDocTopKb',
  GET_ALL_SEGMENT_DATA = 'getAllSegmentData',
  GET_PROFILE_USER = 'getProfileUser',
  UPDATE_PROFILE_USER = 'updateProfileUser',
  GET_DOWNLOAD_REPORT = 'getDownloadReport',
  CHANGE_PROFILE_PASSWORD = 'changeProfilePassword',
  GET_CARD_EVA = 'getCardEarnValue',
  GET_CHART_EVA = 'getChartEarnValue',
  GET_REPORT_REGIONAL = 'getReportRegional',
  GET_ALL_PIC = 'getAllPic',
  GET_ALL_REGION = 'getAllRegion',
  GET_MENU_ISOLATE = 'getMenuISolate',
  ASSIGN_MENU_ISOLATE = 'assignMenuIsolate',
  CHARTER_UPDATE_IS_PRIVATE = 'charterUpdatePrivate',
  GET_DIREKTORAT_REGISTER = 'getDirektoratRegister',
  GET_DIVISI_REGISTER = 'getDivisiRegister',
  GET_PROFILE_DETAIL = 'getProfileDetail',
  GET_GENERAL_SCALE = 'getGeneralScale',
  GET_GENERAL_STATUS = 'getGeneralStatus',
  GET_GENERAL_CATEGORY = 'getGeneralCategory',
  GET_GENERAL_SEGMENT = 'getGeneralSegment',
  GET_GENERAL_PROGRESS = 'getGeneralProgress',
  GET_GENERAL_PROGRESS_CHART = 'getGeneralProgressChart',
  GET_GENERAL_BUDGET = 'getGeneralBudget',
  GET_GENERAL_TOP = 'getGeneralTop',
  GET_GENERAL_TOP_DETAIL = 'getGeneralTopDetail',
  GET_GENERAL_STAKEHOLDER = 'getGeneralStakeholder',
  GET_GENERAL_ISSUE = 'getGeneralIssue',
  GET_GENERAL_LOCATION = 'getGeneralLocation',
  GET_GENERAL_LESSON = 'getGeneralLesson',
  GET_GENERAL_QUALITY = 'getGeneralQuality',
  GET_GENERAL_RISK = 'getGeneralRisk',
  GET_GENERAL_SOURCE_BUDGET = 'getGeneralSourceBudget',
  GET_GENERAL_WEATHER = 'getGeneralWeather',
  GET_GENERAL_COST = 'getGeneralCost',
  GET_GENERAL_SCHEDULE = 'getGeneralSchedule',
  GET_PROVINCE_LIST = 'getProvinceList',
  GET_REGENCY_LIST = 'getRegencyList',
  GET_VILLAGE_LIST = 'getVillageList',
  GET_DISTRICT_LIST = 'getDistrictList',
  GET_CHANNEL_LIST = 'getChannelList',
  GET_REGENCY_BY_PROV_LIST = 'getRegencyBtProvList',
  GET_DISTRICT_BY_REGENCY_LIST = 'getDistrictBtRegencyList',
  GET_VILLAGE_BY_REGENCY_LIST = 'getVillageByDistrictList',
  GET_VILLAGE_BY_DISTRICT_LIST = 'getVillageByDistrictList',
  UPDATE_EARNING_STATUS_DRAWER = 'updateEarningStatusDrawer',
  UPDATE_EARNING_STATUS_DRAWER_MODE = 'updateEarningStatusDrawerMode',
  UPDATE_CHANNEL_DRAWER_MODE = 'updateChannelDrawerMode',
  UPDATE_CHANNEL_DRAWER = 'updateChannelDrawe',
  UPDATE_CHANNEL_LIST = 'updateChannelList',
  GET_WORKSPACE_LIST = 'getWorksapceList',
  GET_ASSET_LIST = 'GET_ASSET_LIST',
  GET_BUMN_LIST = 'GET_BUMN_LIST',
  GET_ASSET_CATEGORY_LIST = 'getAssetCategoryList',
  UPDATE_CATEGORY_ASSETS_DRAWER_MODE = 'updateCategoryAssetsDrawerMode',
  UPDATE_CATEGORY_ASSETS_DRAWER = 'updateCategoryAssetsDrawer',
  CREATE_CATEGORY_ASSETS = 'createCategoryAssets',
  DELETE_CATEGORY_ASSETS = 'deleteCategoryAssets',
  GET_STATUS_LEGAL_ASSETS = 'getStatusLegalAssets',
  GET_STATUS_TANAH_ASSETS = 'getStatusTanahAssets',
  GET_MAP_LIST = 'GET_MAP_LIST',
  GET_ALL_MASTER_ASSET = 'getAllMasterAsset',
  GET_STATUS_PEROLEHAN_LIST = 'getStatusPerolehanList',
  GET_KETERANGAN_ASSETS = 'getKeteranganAssets',
  GET_STATUS_PRODUKTIVITAS = 'getStatusProduktivitas',
  GET_USER_ASSETS = 'getUserAssets',
  GET_STATUS_PENGELOLAAN = 'getStatusPengelolaan',
  GET_UPAYA_PEMANFAATAN = 'getUpayaPemanfaatan',
  CREATE_ASSETS = 'createAssets',
  GET_STATUS_CATEGORY = 'getStatusCategoryAsset',
  UPDATE_CATEGORY_ASSETS = 'updateCategoryAssets',
  GET_DETAIL_ASSETS = 'getDetailAssets',
  GET_LIST_ASSETS = 'getListAssets',
  GET_DETAIL_CHANNEL = 'getDetailChannel',
  FILE_UPLOAD_ASSET = 'fileUploadAssets',
  GET_MEMBER_LIST = 'getMemberList',
  CREATE_ACCOUNT = 'createAccount',
  CREATE_CHANNEL = 'createChannel',
  DELETE_CHANNEL = 'deleteChannel',
  GET_ASSET_TOTAL = 'GET_ASSET_TOTAL',
  UPDATE_USERS_TOTAL_PAGE = 'updateUsersTotalPage',
  UPDATE_USERS_TOTAL_RECORD = 'updateUsersTotalRecord',
  CREATE_WORKSPACE = 'createWorkspace',
  UPDATE_WORKSPACE = 'updateWorkspace',
  DELETE_WORKSPACE = 'deleteWorkspace',
  GET_CURRENCY = 'getCurrency',
  CREATE_CURRENCY = 'postCurrency',
  UPDATE_CURRENCY = 'putCurrency',
  DELETE_CURRENCY = 'deleteCurrency',
  GET_DOCUMENT = 'getDocument',
  CREATE_DOCUMENT = 'postDocument',
  UPDATE_DOCUMENT = 'putDocument',
  DELETE_DOCUMENT = 'deleteDocument',
  GET_ISOLATIONS_MENUS = 'getIsolationsMenus',
  GET_ACCESS_LIST = 'getPagesAccessList',
  UNASSIGN_USER_ROLE = 'unassignUserRole',
  DELETE_ASSET = 'deleteAsset',
  GET_ALL_STATUS_CATEGORY = 'GET_ALL_STATUS_CATEGORY',
  GET_ROLE_BY_ID = 'getRoleById',
  CREATE_SEARCH_ASSETS = 'createTextSearchAssets',
  UPDATE_STATUS_UPLOAD_MASAL = 'updateStatusUploadMasal',
  UPDATE_ASSETS = 'updateAssets',
  GET_DIMENSION_UOM_LIST = 'getDimensionUomList',
  CREATE_ASET_DIMENSION = 'createAsetDimension',
  UPDATE_ASET_DIMENSION = 'updateAsetDimension',
  DELETE_ASET_DIMENSION = 'deleteAsetDimension',
  GET_CATEGORIES_ASET_DIMENSION = 'getCategoriesDimension',
  GET_ACTIVE_WORKSPACE_INFO = "getActiveWorkspaceInfo",
  POST_EMAIL_VERIFICATION = 'postEmailVerification',
  GET_GEOCODE = 'GET_GEOCODE',
  GET_MEMBER_DETAIL = 'getMemberDetail',
  VERIFY_MEMBER = 'verifyMember',
  GET_USER_BY_TOKEN = 'getUserbyToken',
  GET_PBI_TOKEN = "GET_PBI_TOKEN",
  APPROVAL_ASSETS = "APPROVAL_ASSETS",
  FIND_PLACE = "FIND_PLACE",
  GET_PBI_TOKEN_REPORT = "getPbiTokenReport",
  DOWNLOAD_LIST_ASSETS = "downloadListAssets",
  DOWNLOAD_OUTSIDE_INSIDE_ASSET = 'downloadOutsideInsideAsset',
  VERIFY_CAPTCHA = 'verifyCaptcha',
  GET_POWERBI_TOKEN = 'getPowerBIToken',
  EDIT_POWERBI_TOKEN = 'editPowerBIToken',
  EXPORT_EXCEL = "exportExcel",
  DOWNLOAD_OUTSIDE_INSIDE_ASSET_COUNT = 'downloadOutsideInsideAssetCount',
  DOWNLOAD_MEMBERS_LIST = "downloadMembersList",
  ASSET_ACTIVITY_LOG = 'assetActivityLog',
  ADMIN_ACTIVITY_LOG = 'adminActivityLog',
  GET_LIST_MONITORING_COORDINATE = "getListMonitoringCoordinate",
  BULK_DELETE_ASSETS = "bulkDeleteAssets",
  GET_TOTAL_APPROVAL = "getTotalApproval"
}

enum Mutations {
  // mutation types
  SET_PROJECT_UPDATE_CHARTER = 'setProjectUpdateCharter',
  SET_GLOBAL_DRAWER_SELECTED_DATA = 'setGlobalDrawerSelectedData',
  SET_PROJECT_LIST_DATA = 'setProjectListData',
  SET_GLOBAL_MODAL_SELECTED_DATA = 'setGlobalModalSelectedData',
  SET_GLOBAL_MODAL_TITLE = 'setGlobalModalTitle',
  SET_GLOBAL_MODAL_STATE = 'setGlobalModalState',
  SET_GLOBAL_MODAL_TEXT = 'setGlobalModalText',
  SET_PARTNER_FORM = 'setAddPartnerForm',
  SET_GLOBAL_DRAWER_SELECTED_FORM = 'setGlobalDrawerSelectedForm',
  SET_SYMPTOM_IS_FETCHING = 'setSymptomIsFetching',
  SET_SYMPTOM_IS_SUBMITTING = 'setSymptomIsSubmitting',
  SET_SYMPTOM_LIST = 'setSymptomList',
  SET_SYMPTOM_DETAIL = 'setSymptomDetail',
  SET_GLOBAL_DRAWER_STATE = 'setGlobalDrawerState',
  SET_GLOBAL_DRAWER_TITLE = 'setGlobalDrawerTitle',
  SET_TOP_PARTNERS = 'setTopPartners',
  SET_PROJECT_FILE_IS_FETCHING = 'setProjectFileIsFetching',
  SET_PROJECT_FILE_CATEGORIES = 'setProjectFileCategories',
  SET_PROJECT_UPDATE_DRAWER_MODE = 'setProjectUpdateDrawerMode',
  SET_PROJECT_UPDATE_SELECTED_DATA = 'setProjectUpdateSelectedData',
  SET_PROJECT_UPDATE_IS_FETCHING = 'setProjectUpdateIsFetching',
  SET_PROJECT_UPDATE_IS_SUBMITTING = 'setProjectUpdateIsSubmitting',
  SET_PROJECT_UPDATE_DRAWER_STATE = 'setProjectUpdateDrawerState',
  SET_PROJECT_UPDATE_NAVIGATE_FROM = 'setProjectUpdateNavigateFrom',
  SET_PROJECT_UPDATE_ACTIVE_TAB = 'setProjectUpdateActiveTab',
  RESET_CLOSE_PROJECT_FORM = 'resetCloseProjectForm',
  SET_DASHBOARD_LINK_DETAIL = 'setDashboardLinkDetail',
  SET_DASHBOARD_LINK_DATA = 'setDashboardLinkData',
  SET_DASHBOARD_LINK_IS_SUBMITTING = 'setDashboardLinkIsSubmitting',
  SET_DASHBOARD_LINK_DRAWER_MODE = 'setDashboardLinkDrawerMode',
  SET_DASHBOARD_LINK_DRAWER_STATE = 'setDashboardLinkDrawerState',
  SET_DASHBOARD_LINK_IS_FETCHING = 'setDashboardLinkIsFetching',
  SET_DASHBOARD_LINK_FILTER = 'setDashboardLinkFilter',
  SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
  SET_FILTER_LIST_PROJECT = 'setFilterListProject',
  SET_FILTER_OPTIONS_LIST = 'setFilterOptionsList',
  RESET_FILTER_LIST_PROJECT = 'resetFilterListProject',
  PURGE_AUTH = 'logOut',
  SET_AUTH = 'setAuth',
  SET_TOKEN = 'setToken',
  SET_HASH = 'setHash',
  SET_USER = 'setUser',
  SET_PASSWORD = 'setPassword',
  SET_ERROR = 'setError',
  SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
  SET_BREADCRUMB_MUTATION2 = 'setBreadcrumbMutation2',
  SET_LAYOUT_CONFIG = 'setLayoutConfig',
  SET_CATEGORY_CHARTER = 'setCategoryCharter',
  SET_DIREKTORAT = 'setDirektorat',
  SET_DIVISI = 'setDivison',
  SET_SEGMENT = 'setSegement',
  SET_REGIONAL = 'setRegional',
  SET_TYPE_GROUP = 'setTypeGroup',
  SET_PROVINCE = 'setProvince',
  SET_CITY = 'setCity',
  SET_DISTRICT = 'setDistrict',
  SET_ADD_COLUMN = 'setAddColumn',
  SET_ADD_FORM = 'setAddForm',
  SET_TAG = 'setTag',
  SET_PAY_TYPE = 'setPayType',
  SET_CHARTER_PARTNER = 'setChartePartner',
  SET_COMPLETE_STEPPER_CharterAdd = 'setCompleteStepperCharterAdd',
  SET_COMPLETE_STEPPER_Charterdate = 'setCompleteStepperCharterDate',
  SET_COMPLETE_STEPPER_Charterassign = 'setCompleteStepperCharterAssign',
  SET_COMPLETE_STEPPER_Charterpartner = 'setCompleteStepperCharterPartner',
  SET_COMPLETE_STEPPER_Charterterm = 'setCompleteStepperCharterTerm',
  SET_COMPLETE_STEPPER_Charterdeliv = 'setCompleteStepperCharterDeliv',
  SET_COMPLETE_STEPPER_Chartercost = 'setCompleteStepperCharterCost',
  SET_STPPER_THREE = 'setStepperThree',
  RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
  OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
  OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
  SET_WEBP_SUPPORT = 'setWebpSupport',
  STEPPER = 'stepperAddProject',
  SET_SHOW_PROJECT = 'setshowProject',
  SET_DELIVERABLE_LIST = 'setDeliverableList',
  SET_LIST_TERM = 'setListTerm',
  SET_LIST_ADD_TERM = 'setListAddTerm',
  SET_PUSH_TABLE_DELIV = 'setPushTableDelivs',
  SET_FILTER = 'setFilter',
  SET_LIST_PARTNER = 'setListPartner',
  SET_DEFAULT_FILTER = 'setDefaulFilter',
  SET_LOCALE = 'setLocale',
  SET_INTEGRATOR_LOADING = 'setIntegratorLoading',
  SET_ROLE_FILTER = 'setRoleFilter',
  SET_MASTER_ROLE_KEYWORD = 'setMasterRoleKeyword',
  SET_LIST_FILE = 'setListFIle',
  SET_DOKUMEN_OTHER = 'setDokumenOther',
  SET_DOKUMEN_KB = 'setDokumenKb',
  SET_DOKUMEN_KL = 'setDokumenKl',
  MYTENS_SET_TOKEN = 'mytensSetToken',
  SET_MASTER_ROLE_LOADING = 'setMasterRoleLoading',
  SET_MASTER_ROLE_DIALOG = 'setMasterRoleDialog',
  SET_MASTER_ROLE_DRAWER = 'setMasterRoleDrawer',
  SET_MASTER_ROLE_DATA = 'setMasterRoleData',
  SET_MASTER_ROLE_ACTIVE_DIALOG = 'setMasterRoleActiveDialog',
  SET_MASTER_ROLE_SELECTED = 'setMasterRoleSelected',
  SET_CLOSE_PROJECT_FORM = 'setCloseProjectForm',
  SET_LANGUAGE_LOADING = 'setLanguageLoading',
  SET_THEME_LOADING = 'setThemeLoading',
  SET_CURRENT_THEME = 'setCurrentTheme',
  SET_POPUP_CUSTOMER = 'setPopUpCustomer',
  SET_USER_LIST = 'setUserList',
  SET_MASTER_MENU_KEYWORD = 'setMasterMenuKeyword',
  SET_MASTER_MENU_SUBMITTING = 'setMasterMenuIsSubmitting',
  SET_GS_FORM_THEME = 'setGSFormTheme',
  SET_GS_FORM_LANG = 'setGSFormLang',
  SET_GS_SUBMITTING = 'setGSSubmitting',
  SET_THEMES = 'setThemeList',
  SET_LANGUAGES = 'setLanguageList',
  SET_USERS_FILTER = 'setUsersFilter',
  SET_USERS_ITEMS_PER_PAGE = 'setUsersItemsPerPage',
  SET_USERS_PAGE = 'setUsersPage',
  SET_USERS_KEYWORD = 'setUsersKeyword',
  SET_MASTER_ROLE_SELECTED_DETAIL = 'setMasterRoleDtl',
  SET_ROLE_DTL_MENU_STATE = 'setRoleDtlMenuState',
  SET_PROJECT_ID = 'setProjectId',
  SET_NOTIFIKASI = 'setNotifikasi',
  SET_MASTER_ROLE_SUBMITTING = 'setMasterRoleSubmitting',
  SET_ROLE_MENUS = 'setRoleMenus',
  SET_DETAIL_PROJECT = 'setDetailProject',
  SET_COST_TYPE = 'setCostType',
  SET_COST_REALIZATION = 'setCostRealization',
  SET_COST_TOTAL_REALIZATION = 'setCostTotalRealization',
  SET_COST_RAB = 'setCostRAB',
  SET_COST_RAB_LIST = 'setCostRABList',
  SET_COST_RAB_LIST_ADD = 'setCostRABListAdd',
  SET_COST_KHS = 'setCostKHS',
  SET_COST_KHS_LIST = 'setCostKHSList',
  SET_COST_KHS_LIST_ADD = 'setCostKHSListAdd',
  SET_COST_ITEMS = 'setCostItems',
  SET_COST_ITEM_FORM = 'setCostItemForm',
  SET_COST_ITEM_LIST = 'setCostItemList',
  SET_COST_ITEM_LIST_ADD = 'setCostItemListAdd',
  SET_COST_PGU_LIST = 'setCostPGUList',
  SET_COST_PGU_LIST_ADD = 'setCostPGUListAdd',
  SET_COST_DELIVERABLE_MATERIAL_LIST = 'setCostDeliverableMaterialList',
  SET_COST_DELIVERABLE_MATERIAL_LIST_ADD = 'setCostDeliverableMaterialListAdd',
  SET_COST_RAB_FORM = 'setCostRABForm',
  SET_COST_KHS_FORM = 'setCostKHSForm',
  SET_COST_PGU_FORM = 'setCostPGUForm',
  SET_COST_DELIVERABLE_MATERIAL_FORM = 'setCostDeliverableMaterialForm',
  SET_KANBAN_STATE = 'setKanbanState',
  SET_KANBAN_CHOOSEN = 'setKanbanChoosen',
  SET_KANBAN_IS_ACTIVE = 'setKanbanIsActive',
  SET_KANBAN_BOARDS = 'setKanbanBoards',
  SET_KANBAN_BOARD_ITEM = 'setKanbanBoardItem',
  SET_REPORT_PARTNER_PROYEK = 'setReportPartnerProyek',
  SET_REPORT_PROJECT_MANAGER = 'setReportProjectManager',
  SET_REPORT_HIRARKI_DOKUMENT = 'setReportHirarkiDokumen',
  SET_SUB_FOLDER = 'setSubFolder',
  SET_REPORT_FILE = 'setReportFile',
  SET_MEMBER_DRAWER_STATE = 'setMemberDrawerState',
  SET_MEMBER_DRAWER_MODE = 'setMemberDrawerMode',
  SET_FILTER_REPORT = 'setFilterReport',
  SET_RESET_FILTER_REPORT = 'setResetFilterReport',
  SET_MAP_STATUS = 'setMapStatus',
  SET_KANBAN = 'setKanban',
  SET_LIST_PROJECT_TEMPLATE = 'setListProjectTemplate',
  SET_INFORMATION_TEMPLATE = 'setINformationTemplate',
  SET_FILTER_TEMPLATE = 'setFilterTemplate',
  SET_TENANT_LSIT = 'setTenantList',
  SET_TENANT_SHOW = 'setTenantShow',
  SET_SCALE_PROJECTS = 'setScaleProjects',
  SET_FILTER_COST = 'setFilterCost',
  SET_COST_PGU = 'setCostPGU',
  SET_COST_PGU_TOTAL = 'setCostPGUTotal',
  SET_FILTER_DASHBOARD_DATE = 'setFilterDate',
  SET_BUDGET_PROJECTS = 'setBudgetProjects',
  SET_TOP_PROJECTS = 'setTopProjects',
  SET_CATEGORY_PROJECTS = 'setCategoryProjects',
  SET_SEGMENT_PROJECTS = 'setSegmentProjects',
  SET_PROGRESS_PROJECTS = 'setProjectProjects',
  SET_STATUS_PROJECTS = 'setStatusProjects',
  SET_STAKEHOLDER_PROJECTS = 'setStakeholderProjects',
  SET_ISSUE_PROJECTS = 'setIssueProjects',
  SET_CHANGE_ADMIN = 'setChangeAdmin',
  SET_MAX_ISSUE_PROJECTS = 'setMaxIssue',
  SET_MAX_STATUS = 'setMaxStatus',
  SET_MAX_PROGRESS = 'setMaxProgress',
  SET_COST_DELIVERABLES = 'setCostDeliverables',
  SET_COST_DELIVERABLES_LIST = 'setCostDeliverablesList',
  SET_PROGRESS_GRAFIK = 'setProgressGrafik',
  SET_MAX_PROGRESS_GRAFIK = 'setMaxProgressGrafik',
  SET_SELLERS = 'setSellers',
  SET_SELLER_PACKAGES = 'setSellerPackages',
  SET_LOCATION_PROJECT = 'setLocationProject',
  SET_SELLER_FORM = 'setSellerForm',
  SET_SELLER_PACKAGE_FORM = 'setSellerPackageForm',
  SET_TOTAL_DOKUMEN = 'getTotalDokumen',
  UPDATE_FILTER_REPORT_DOKUMEN = 'updatefilterReportDokumen',
  SET_TOKEN_TELKOMSAT = 'setTokenTelkomsat',
  SET_KHS_REALIZATION_DASHBOARD_DATA = 'setKHSRealizationDashboardData',
  SET_CHARTER_ADDRESS_FORM = 'setCharterAddressForm',
  SET_ACTIVE_PAGE = 'setActivePage',
  SET_FILTER_PA_DATE = 'setFilterPADate',
  SET_FILTER_SCALE = 'setFilterScale',
  SET_FILTER_2 = 'setFilter2',
  SET_REPORT_DATA = 'setReportData',
  SET_FILTER_BUDGET_MODEL = 'setFilterBudgetModel',
  SET_FILTER_BUDGET_STATUS = 'setFilterStatusModel',
  SET_FILTER_TOP_MODEL = 'setFilterTopModel',
  SET_FILTER_TOP_STATUS = 'setFilterTopStatus',
  SET_SEARCH_FILTER_REPORT = 'setSearchFilterReport',
  SET_SUB_DOC_PAGE = 'setSubDocPage',
  SET_FILTER_SPK = 'setFilterSpk',
  SET_FILTER_TOP_KB_KL = 'setFilterTopKb',
  SET_ALL_SEGMENT_DATA = 'setAllSegmentData',
  SET_FILTER_LOP_WIN = 'setFilterLop',
  SET_VALUE_DOWNLOAD = 'setValueDwonload',
  SET_CHART_EARN_VALUE = 'setChartEarnValue',
  SET_FILTER_REGIONAL = 'setFilterRegional',
  SET_ALL_PIC = 'setAllPic',
  SET_ALL_REGION = 'setAllRegion',
  SET_GENERAL_SCALE = 'setGeneralScale',
  SET_GENERAL_STATUS = 'setGeneralStatus',
  SET_GENERAL_MAX_STATUS = 'setGeneralMaxStatus',
  SET_GENERAL_CATEGORY = 'setGeneralCategory',
  SET_GENERAL_SEGMENT = 'setGeneralSegment',
  SET_GENERAL_PROGRESS = 'setGeneralProgress',
  SET_GENERAL_MAX_PROGRESS = 'setGeneralMaxProgress',
  SET_GENERAL_PROGRESS_CHART = 'setGeneralProgressChart',
  SET_GENERAL_MAX_PROGRESS_CHART = 'setGeneralMaxProgressChart',
  SET_GENERAL_BUDGET = 'setGeneralBudget',
  SET_GENERAL_TOP = 'setGeneralTop',
  SET_GENERAL_TOP_DETAIL = 'setGeneralTopDetail',
  SET_GENERAL_STAKEHOLDER = 'setGeneralStakeholder',
  SET_GENERAL_ISSUE = 'setGeneralIssue',
  SET_GENERAL_MAX_ISSUE = 'setGeneralMaxIssue',
  SET_GENERAL_LOCATION = 'setGeneralLocation',
  SET_GENERAL_LESSON = 'setGeneralLesson',
  SET_GENERAL_QUALITY = 'setGeneralQuality',
  SET_GENERAL_RISK = 'setGeneralRisk',
  SET_GENERAL_SOURCE_BUDGET = 'setGeneralSourceBudget',
  SET_GENERAL_COST = 'setGeneralCost',
  SET_GENERAL_SCHEDULE = 'setGeneralSchedule',
  SET_PROVINCE_SIT = 'setProvinceList',
  SET_REGENCY_SIT = 'setRegencySit',
  SET_VILLAGE_SIT = 'setVillageList',
  SET_DISTRICT_SIT = 'setDistrictList',
  SET_CHANNEL = 'setChannel',
  SET_EARNING_STATUS_DRAWER = 'setEarningStatusDrawer',
  SET_EARNING_STATUS_DRAWER_MODE = 'setEarningStatusDrawerMode',
  SET_CHANNEL_DRAWER = 'setChannelDrawer',
  SET_CHANNEL_DRAWER_MODE = 'setChannelDrawerMode',
  SET_WORKSPACE_LIST = 'setWorkspaceList',
  SET_DATA_KIND_ASSET = 'setDataKindAsset',
  SET_CATEGORY_ASSETS_DRAWER_MODE = 'setCategoryAssetsDrawerMode',
  SET_CATEGORY_ASSETS_DRAWER = 'setCategoryAssetsDrawer',
  SET_STATUS_LEGAL_ASSETS = 'setStatusLegalAssets',
  SET_STATUS_TANAH_ASSETS = 'setStatusTanahAssets',
  SET_MAP_LIST = 'SET_MAP_LIST',
  SET_MAP_CENTER = 'SET_MAP_CENTER',
  SET_ALL_MASTER_ASSET = 'setAllMasterAsset',
  SET_STATUS_PEROLEHAN_LIST = 'setStatusPerolehanList',
  SET_KETERANGAN_ASSETS = 'setKeteranganAssets',
  SET_STATUS_PRODUKTIVITAS = 'setStatusProduktivitas',
  SET_USER_ASSETS = 'setUserAssets',
  SET_STATUS_PENGELOLAAN = 'setStatusPengelolaan',
  SET_UPAYA_PEMANFAATAN = 'setUpayaPemanfaatan',
  SET_ASSETS = 'setAssets',
  SET_STATUS_CATEGORY = 'setStatusCategoryAsset',
  SET_DETAIL_ASSETS = 'setDetailAssets',
  SET_LIST_ASSETS = 'setListAssets',
  SET_DETAIL_CHANNEL = 'setDetailChannel',
  SET_MEMBER_LIST = 'setMemberList',
  SET_ASSET_TOTAL = 'SET_ASSET_TOTAL',
  SET_USERS_TOTAL_PAGE = 'setUsersTotalPage',
  SET_USERS_TOTAL_RECORD = 'setUsersTotalRecord',
  SET_CURRENCY = 'setCurrency',
  SET_DOCUMENT = 'setDocument',
  SET_ISOLATIONS_MENUS = 'setIsolationsMenus',
  SET_ACCESS_LIST = 'setPagesAccessList',
  SET_PROFILE_DETAIL = 'setProfileDetail',
  SET_ALL_STATUS_CATEGORY = 'SET_ALL_STATUS_CATEGORY',
  SET_ROLE_ASSETS = 'setRoleAssets',
  SET_SEARCH_ASSETS = 'setTextSearchAssets',
  SET_STATUS_UPLOAD_MASAL = 'setStatusUploadMasal',
  SET_DIMENSION_UOM_LIST = 'setDimensionUomList',
  SET_DIMENSION_UOM_CATEGORIES_LIST = 'setDimensionCategoriesList',
  SET_ACTIVE_WORKSPACE = 'setActiveWorkspace',
  SET_BREADCRUMB_KEY = 'setBreadcrumbKey',
  SET_FILTER_ASSET = 'set/filterAsset',
  SET_LOADING = 'set/loading',
  SET_UPDATE_ASSETS_FLAG = "setUpdateAssetsFlag",
  SET_PARAMS_FILTER = "setParamsFilter",
  SET_BUMN_LIST = "set/BUMN_LIST",
  SET_LOADING_LEAFTLET = 'set/LoadingLeaftlet',
  SET_TYPE_GEOJSONMAP = 'set/TypeGeoJSON',
  SET_POWERBI_TOKEN = 'setPowerBIToken',
  SET_POWERBI_TOKEN_DRAWER = 'setPowerBITokenDrawer',
  SET_ID_CHANNELS = "setIdChannels",
  SET_DIALOG_MODAL_IMPORT = "setDialogModalImport",
  SET_IMPORT_TYPE = "setImportType",
  SET_MONITORING_COORDINATE = "setMonitoringCoordinate",
  SET_RESET_FILTER_POINT = "setResetFilterPoint",
  SET_TILE_LAYER = "setTileLayer",
  SET_DELETE_MODAL = "setDeleteModal",
  SET_LAST_NAME_ASSET = "setLastNameAsset",
  SET_ERROR_DETAIL = "setErrorDetail",
  SET_ASSET_INITIAL = "setAssetInitial",
  SET_RESET_FILTER = "setResetFilter",
  SET_UPDATED_ASSET = "setUpdatedAsset",
  SET_ALL_ASSET = "setAllAsset",
  SET_SHOW_TOOLBAR = "setShowToolbar"
}

export { Actions, Mutations };
