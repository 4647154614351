import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import * as storeTutorial from '@/store/enums/StoreTutorialEnums';
import { Tutorial } from '@/store/enums/interface_option/tutorial';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { IGeoJson } from '@/store/modules/GoogleMapModule';

export interface filterProject {
  name?: string;
  charter_division_code?: Array<string>;
  charter_regional_code?: Array<string>;
  charter_running_status_code?: Array<string>;
  value_category?: Array<string>;
  progress?: Array<number>;
  partner_code?: Array<string>;
  charter_segment_code?: Array<string>;
  customer_code?: Array<string>;
  page: number;
  itemsPerPage: number;
  trash: number;
  sortBy: Array<string>;
  sortDesc: Array<number>;
}

const defaultFilter = {
  page: 1,
  itemsPerPage: 10,
  trash: 0,
  sortBy: ['created_at'],
  sortDesc: [1],
};

interface ICharter {
  charter_delivery_status_code: string | null;
  charter_running_status_code: string | null;
  charter_segment_code: string | null;
  closed_date: string | null;
  code: string;
  customers: Array<any>;
  delivery: string | null;
  geo_json: Array<IGeoJson>;
  id: string;
  is_private: boolean;
  name: string;
  progress: number;
  references: any;
  running: any;
  segment: any;
  stackholders: Array<any>;
  value: string | number;
}

const service = '/api/v1';

@Module
export default class ProjectModule extends VuexModule {
  filters: filterProject = defaultFilter;
  filterOpts: any;
  idProject: any;
  projects: any;

  get getProjects() {
    return this.projects;
  }

  /**
   * Get current filters
   * @returns object
   */
  get getFilters(): filterProject {
    return this.filters;
  }

  /**
   * Get current filter options
   * @returns object
   */
  get getFilterOpts() {
    return this.filterOpts;
  }

  /**
   * Get current filter options
   * @returns object
   */
  get getIdProject() {
    return this.idProject;
  }

  @Mutation
  [Mutations.SET_FILTER_LIST_PROJECT](payload) {
    this.filters = payload;
  }

  @Action
  [Actions.UPDATE_FILTER_LIST_PROJECT](payload) {
    this.context.commit(Mutations.SET_FILTER_LIST_PROJECT, payload);
  }

  @Mutation
  [Mutations.RESET_FILTER_LIST_PROJECT](payload) {
    this.filters = {
      page: 1,
      itemsPerPage: 10,
      trash: 0,
      sortBy: ['created_at'],
      sortDesc: [1],
      ...payload,
    };
  }

  @Mutation
  [Mutations.SET_FILTER_OPTIONS_LIST](payload) {
    this.filterOpts = payload;
  }

  @Mutation
  [Mutations.SET_PROJECT_LIST_DATA](payload) {
    this.projects = payload;
  }

  @Action
  [Actions.PROJECT_LIST](query) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(storeTutorial.Mutations.FILTER_STEP, {
        name: 'project-list-aktif',
        bool: false,
      } as Tutorial.localStorage);
      ApiService.query(`${service}/charter`, { params: query })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PROJECT_LIST_DATA, data.data);
          this.context.commit(storeTutorial.Mutations.FILTER_STEP, {
            name: 'project-list-aktif',
            bool: true,
            data: data.data,
          } as Tutorial.localStorage);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.GET_DIREKTORAT_REGISTER](payload) {
    return new Promise((resolve, reject) => {
      return ApiService.query(`${service}/channel`, { params: payload })
        .then(({ data }) => {
          // console.log('test', data);
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
          this.context.commit(Mutations.SET_ERROR, error);
          return Promise.reject(error);
        });
    });
  }
  @Action({ rawError: true })
  [Actions.GET_DIVISI_REGISTER](payload) {
    return new Promise((resolve, reject) => {
      return ApiService.query(`${service}/divisi`, { params: payload })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
          this.context.commit(Mutations.SET_ERROR, error);
          return Promise.reject(error);
        });
    });
  }

  @Action
  [Actions.PROJECT_RUNNING_STATUS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/charter`, payload)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_MENU_ISOLATE](role_id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`${service}/isolate/menu/${role_id}`)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.ASSIGN_MENU_ISOLATE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(
        `${service}/isolate/assign-menu/${payload.role_id}`,
        payload
      )
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_HISTORY_INTEGRATIONS](query) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/integrations`, { params: query })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.PROJECT_SHOW](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`${service}/charter`, id)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.CHARTER_GET_ADDITIONAL](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`${service}/charter/custom_field`, id)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_FILTER_OPTIONS_LIST]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`${service}/filter_opt_list`)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_DATA_EXECUTIVE]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`${service}/charter/executive`)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.BUDGET_PERFORMA](charter_id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/CharterCostPagu/dashboard`, charter_id)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.SAVE_ADDITIONAL](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/charter/additional`, payload)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_ADDITIONALS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/charter/get_additional`, payload)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_HOSTORY_INTEGRATIONS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/integrations`, payload)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.PROJECT_ACTIVE](p_id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`${service}/charter/active`, p_id, {})
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.CHARTER_UPDATE_ADDITIONAL](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(
        `${service}/charter/custom_field`,
        payload.charter_id,
        payload
      )
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.CHARTER_UPDATE_IS_PRIVATE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`${service}/charter`, payload.charter_id, payload)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.PROJECT_DELETE](p_id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`${service}/charter/${p_id}`)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Mutation
  [Mutations.SET_PROJECT_ID](data) {
    this.idProject = data;
  }
}

export {ICharter}