import ApiService from "@/core/services/ApiService";
import JwtMytensService from "@/core/services/JwtMytensService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";

@Module
export default class MytensModule extends VuexModule {
  requestToken: any = {
    clientId: process.env.VUE_APP_MYTENS_CLIENT_ID,
    clientSecret: process.env.VUE_APP_MYTENS_CLIENT_SECRET,
  };

  defaultPayload: any = {
    page: 1,
    size: 5,
    nameApp: "mytds",
  };

  @Mutation
  [Mutations.MYTENS_SET_TOKEN](token) {
    JwtMytensService.saveToken(token);
  }

  @Action
  [Actions.MYTENS_GET_TOKEN]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.setMytensUrl();
      ApiService.postMytens(
        "users-management/v2/auth/generatetoken",
        this.requestToken
      )
        .then(({ data }) => {
          this.context.commit(
            Mutations.MYTENS_SET_TOKEN,
            data.data.accessToken
          );
          return resolve();
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.MYTENS_GET_PARTNERS](payloads) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setMytensUrl();
      ApiService.setTokenMytens();
      ApiService.queryMytens("projects/integration/v1/partner", {
        params: {
          ...this.defaultPayload,
          search: payloads,
        },
      })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err.response);
          reject();
        });
    });
  }

  @Action
  [Actions.MYTENS_GET_CUSTOMERS](payloads) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setMytensUrl();
      ApiService.setTokenMytens();
      ApiService.queryMytens("projects/integration/v1/customer", {
        params: {
          ...this.defaultPayload,
          search: payloads,
        },
      })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err.response);
          reject();
        });
    });
  }

  @Action
  [Actions.MYTENS_GET_APPROVERS](payloads) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setMytensUrl();
      ApiService.setTokenMytens();
      ApiService.queryMytens("users-management/dashboard/v1", {
        params: {
          ...this.defaultPayload,
          search: payloads,
        },
      })
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err.response);
          reject();
        });
    });
  }
}
