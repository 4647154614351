
import {
  ref,
  getCurrentInstance,
  computed,
  watch,
  reactive,
  onMounted,
} from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Getters } from "@/store/enums/StoreTutorialEnums";

import IntroJs from "intro.js";
import * as storeTutorial from "@/store/enums/StoreTutorialEnums";
import { Tutorial } from "@/store/enums/interface_option/tutorial";

export default {
  setup() {
    // code start introjs-7

    const $instance = getCurrentInstance();
    const nextData = ref(0);
    const nextData2 = reactive({
      url: null,
      status: null,
    });
    const nextStepTutorial = ref(0);
    const nextListStep = reactive({
      number: 1,
      bool: false,
      validate: "",
      tab_active: "",
      id_step: "",
      leftBar: false,
      loading: false,
      deliverables: false,
      document: false,
      dataDocumentList: null,
      dataIsuList: null,
    });
    const show = ref(false);
    const route = useRoute();
    // const router = useRouter();

    let fullPath = ref("");
    const store = useStore();
    const loadingMenu = computed(() => store.getters[Getters.LOADING]);
    const comAsideBar = computed(() => store.getters.getStepper);
    const comTabActive = computed(() => store.getters[Getters.TAB_ACTIVE]);
    const comFilterStep = computed(() => store.getters[Getters.FILTER_STEP]);
    const localStorageFun = (name: string) => {
      try {
        if (name) {
          const a = localStorage.getItem(name) as any;
          return a ? JSON.parse(a) : undefined;
        } else {
          return name;
        }
      } catch (e) {
        console.log("localStorageFun", e);
      }
    };
    const localStorageFunSave = (
      name?: string | null,
      status?: boolean,
      nameStorage?: {
        name: string;
        data: string;
      }
    ) => {
      try {
        switch (status) {
          case true:
            if (nameStorage?.name) {
              let array = [];
              if (localStorageFun(nameStorage?.name)) {
                array = localStorageFun(nameStorage?.name);
              }
              array.push(nameStorage?.data as never);
              localStorage.setItem(nameStorage?.name, JSON.stringify(array));
            }
            break;
          default: {
            if (name) {
              let array = [];
              array = localStorageFun("introjs-tutorial");
              array.push(name as never);
              localStorage.setItem("introjs-tutorial", JSON.stringify(array));
            } else {
              return name;
            }
          }
        }
      } catch (e) {
        console.log("localStorageFun", e);
      }
    };

    const funDocument = (name: string, status = "1") => {
      return new Promise((resolve) => {
        switch (status) {
          case "1":
            {
              const doc = document.querySelectorAll(name);
              doc[0]
                ?.getElementsByClassName("next")[0]
                ?.addEventListener("click", () => {
                  resolve("step-1");
                });
              doc[0]
                ?.getElementsByClassName("lewati")[0]
                ?.addEventListener("click", () => {
                  nextData.value = 0;
                  resolve("step-2");
                });
            }
            break;
          case "2":
            {
              const time = setInterval(() => {
                const doc = document.querySelectorAll(name);
                if (doc.length > 0) {
                  doc[0]
                    ?.getElementsByClassName("next")[0]
                    ?.addEventListener("click", () => {
                      resolve("step-1");
                    });

                  doc[0]
                    ?.getElementsByClassName("lewati")[0]
                    ?.addEventListener("click", () => {
                      nextData.value = 0;
                      resolve("step-2");
                    });
                  clearInterval(time);
                }
              });
            }
            break;
        }
      });
    };

    // project/J7YEV
    const listStep = () => {
      if (fullPath.value === "/project/U5JIT") {
        $instance?.proxy?.$nextTick(() => {
          const intro2 = IntroJs().setOptions({
            showButtons: false,
            showBullets: false,
            showProgress: false,
            exitOnOverlayClick: false,
            exitOnEsc: false,
            skipLabel: "",

            steps: [
              {
                element: document.querySelectorAll(
                  ".introjs-project-tech-closed-1"
                )[0] as HTMLElement,
                intro: `<div class="introjs-3" id="step-tech-closed">
 <div>Klik proyek untuk melihat detail proyek yang sudah selesai</div>

  <div class="grid-tutorial">
    <div  class="lewati">Lewati</div>
    <button class="next">Mengerti</button>
  </div>
</div>`,
                position: "top",
              },
              {
                element: document.querySelectorAll(
                  ".introjs-project-tech-closed-2"
                )[0] as HTMLElement,
                intro: `<div class="introjs-3" id="step-tech-closed-2">
 <div>Klik proyek untuk melihat detail proyek yang sudah selesai</div>
 <div>Klik di sini untuk menutup proyek jika administrasi proyek sudah lengkap</div>

  <div class="grid-tutorial">
    <div  class="lewati">Lewati</div>
    <button class="next">Mengerti</button>
  </div>
</div>`,
                position: "left",
              },
            ],
          });
          intro2.start();

          funDocument(".introjs-3#step-tech-closed").then((status) => {
            switch (status) {
              case "step-1":
                nextStepTutorial.value = 1;
                intro2.nextStep();
                break;
              case "step-2":
                intro2.exit();
                localStorageFunSave(null, true, {
                  name: "project-detail-closed-child",
                  data: "project-table",
                });
                break;
            }
          });
          intro2.onafterchange(() => {
            if (nextStepTutorial.value === 1) {
              funDocument(".introjs-3#step-tech-closed-2", "2").then(
                (status) => {
                  switch (status) {
                    case "step-1":
                      nextStepTutorial.value = 2;
                      nextData.value = 0;
                      intro2.nextStep();
                      localStorageFunSave(null, true, {
                        name: "project-detail-closed-child",
                        data: "project-table",
                      });
                      break;
                    case "step-2":
                      nextData.value = 0;
                      intro2.exit();
                      localStorageFunSave(null, true, {
                        name: "project-detail-closed-child",
                        data: "project-table",
                      });
                      break;
                  }
                }
              );
            }
          });
        });
      }
    };
    // detail project tech closed
    const listStep2 = () => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-project-tech-closed-3"
              )[0] as HTMLElement,
              intro: `<div class="introjs-3" id="step-tech-closed-3">
 <div>Klik disini untuk mengembalikan proyek yang tertutup ke proyek aktif</div>

  <div class="grid-tutorial">
    <div  class="lewati">Lewati</div>
    <button class="next">Mengerti</button>
  </div>
</div>`,
              position: "left",
            },
          ],
        });
        intro2.start();

        funDocument(".introjs-3#step-tech-closed-3").then((status) => {
          switch (status) {
            case "step-1":
              nextStepTutorial.value = 1;
              nextData.value = 0;

              intro2.nextStep();
              localStorageFunSave(null, true, {
                name: "project-detail-closed-child",
                data: "project-detail",
              });
              break;
            case "step-2":
              intro2.exit();
              localStorageFunSave(null, true, {
                name: "project-detail-closed-child",
                data: "project-detail",
              });
              break;
          }
        });
      });
    };
    // detail project tech modal
    const listStep3 = () => {
      $instance?.proxy?.$nextTick(() => {
        const intro2 = IntroJs().setOptions({
          showButtons: false,
          showBullets: false,
          showProgress: false,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          skipLabel: "",

          steps: [
            {
              element: document.querySelectorAll(
                ".introjs-project-tech-closed-4"
              )[0] as HTMLElement,
              intro: `<div class="introjs-3" id="step-tech-closed-4">
 <div>Isi data form untuk validasi penutupan proyek</div>

  <div class="grid-tutorial">
    <div  class="lewati">Lewati</div>
    <button class="next">Mengerti</button>
  </div>
</div>`,
              position: "left",
            },
            {
              element: document.querySelectorAll(
                ".introjs-project-tech-closed-5"
              )[0] as HTMLElement,
              intro: `<div class="introjs-3" id="step-tech-closed-5">
 <div>Isi pengalaman anda dari proyek yang telah selesai untuk evaluasi</div>

  <div class="grid-tutorial">
    <div  class="lewati">Lewati</div>
    <button class="next">Mengerti</button>
  </div>
</div>`,
              position: "left",
            },
            {
              element: document.querySelectorAll(
                ".introjs-project-tech-closed-6"
              )[0] as HTMLElement,
              intro: `<div class="introjs-3" id="step-tech-closed-6">
 <div>Tambah dokumen untuk validasi penutupan proyek</div>

  <div class="grid-tutorial">
    <div  class="lewati">Lewati</div>
    <button class="next">Mengerti</button>
  </div>
</div>`,
              position: "left",
            },
          ],
        });
        intro2.start();

        funDocument(".introjs-3#step-tech-closed-4").then((status) => {
          switch (status) {
            case "step-1":
              nextStepTutorial.value = 1;
              intro2.nextStep();
              break;
            case "step-2":
              intro2.exit();
              localStorageFunSave(null, true, {
                name: "project-detail-closed-child",
                data: "project-modal",
              });
              break;
          }
        });
        intro2.onafterchange(() => {
          if (nextStepTutorial.value === 1) {
            funDocument(".introjs-3#step-tech-closed-5", "2").then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 2;
                  intro2.nextStep();
                  break;
                case "step-2":
                  intro2.exit();
                  localStorageFunSave(null, true, {
                    name: "project-detail-closed-child",
                    data: "project-modal",
                  });
                  break;
              }
            });
          } else if (nextStepTutorial.value === 2) {
            funDocument(".introjs-3#step-tech-closed-6", "2").then((status) => {
              switch (status) {
                case "step-1":
                  nextStepTutorial.value = 3;
                  nextData.value = 0;
                  intro2.nextStep();
                  localStorageFunSave(null, true, {
                    name: "project-detail-closed-child",
                    data: "project-modal",
                  });
                  break;
                case "step-2":
                  intro2.exit();
                  localStorageFunSave(null, true, {
                    name: "project-detail-closed-child",
                    data: "project-modal",
                  });
                  break;
              }
            });
          }
        });
      });
    };

    watch(comAsideBar, (val) => {
      nextListStep.number = val;
    });
    watch(loadingMenu, (varnew) => {
      nextListStep.bool = varnew;
    });
    watch(comTabActive, (varnew) => {
      nextListStep.tab_active = varnew;
    });
    const funStepIntro = (varnew) => {
      const funDocumentDetail = (name?: string) => {
        switch (name) {
          case "project-tech-list":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-detail-closed-child"
                );
                if (!save?.includes("project-tech-closed")) {
                  if (!save2?.includes("project-table")) {
                    setTimeout(() => {
                      listStep();
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "project-tech-detail":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-detail-closed-child"
                );
                if (!save?.includes("project-tech-closed")) {
                  if (!save2?.includes("project-detail")) {
                    setTimeout(() => {
                      listStep2();
                    }, 1000);
                  }
                }
              });
            }
            break;
          case "project-tech-modal":
            {
              $instance?.proxy?.$nextTick(() => {
                const save: string[] = localStorageFun("introjs-tutorial");
                const save2: string[] = localStorageFun(
                  "project-detail-closed-child"
                );
                if (!save?.includes("project-tech-closed")) {
                  if (!save2?.includes("project-modal")) {
                    setTimeout(() => {
                      listStep3();
                    }, 1000);
                  }
                }
              });
            }
            break;
        }
      };
      const boolLinkChecker = (name = "", status?: string) => {
        switch (status) {
          case "regex": {
            const regext = name || "";
            let detect = regext.match(/\/project\/show\/?([^.]*)/g);
            detect = detect?.length ? detect[0].split("#") : [];
            return detect;
          }
          case "all":
            return varnew?.data?.link === name
              ? true
              : fullPath.value === `/project/show/${varnew?.data}#${name}`;
          default:
            return nextData.value === 0 && varnew?.data?.link === name
              ? true
              : fullPath.value === `/project/show/${varnew?.data}#${name}`;
        }
      };
      if (
        varnew?.name === "project-tech-closed/load-list-table" &&
        varnew?.bool === true
      ) {
        if (nextData.value === 0 && fullPath.value === "/project/U5JIT") {
          const save: string[] = localStorageFun("project-detail-closed-child");
          if (!save?.includes("project-table")) nextData.value = 1;
          funDocumentDetail("project-tech-list");
        }
      } else if (
        varnew?.name === "project-tech-closed/load-list-detail" &&
        varnew?.bool === true
      ) {
        if (
          boolLinkChecker(fullPath?.value, "regex")[1] === "informasi" &&
          nextData.value === 0
        ) {
          nextData.value = 1;
          funDocumentDetail("project-tech-detail");
        }
      } else if (
        varnew?.name === "project-tech-closed/load-list-modal" &&
        varnew?.bool === true
      ) {
        if (nextData.value === 0) {
          nextData.value = 1;
          funDocumentDetail("project-tech-modal");
        }
      }
    };

    watch(
      comFilterStep,
      (varnew) => {
        if (varnew?.name === "project-all/aside") {
          nextData2.url = varnew?.data;
          fullPath.value = varnew?.data;
        } else if (
          varnew?.name === "project-tech-closed/load-list-table" &&
          nextData2.url !== null
        ) {
          fullPath.value = route?.fullPath;
          if (fullPath.value !== "") {
            funStepIntro({ ...varnew });
          }
        } else if (varnew?.name === "project-tech-closed/load-list-table") {
          fullPath.value = route?.fullPath;
          if (fullPath.value !== "") {
            funStepIntro({ ...varnew });
          }
        } else if (varnew?.name === "project-tech-closed/load-list-modal") {
          fullPath.value = route?.fullPath;
          console.log("check-detail-modal");
          if (fullPath.value !== "") {
            funStepIntro({ ...varnew });
          }
        } else if (localStorageFun("save-id-project")?.includes("U5JIT")) {
          fullPath.value = route?.fullPath;
          if (fullPath.value !== "") {
            funStepIntro({
              ...varnew,
              name: "project-tech-closed/load-list-detail",
            });
          }
        }
      },
      { deep: true }
    );

    onMounted(() => {
      const local = localStorage.getItem("step") as string;
      const local2 = localStorage.getItem("saveSettingTutorial") as string;
      if (local !== null) {
        nextListStep.number = parseInt(local);
      }
      if (local2 !== null) {
        nextListStep.validate = local2;
      }
    });

    return {
      show,
    };
  },
};
