enum ActionsAdminValidasi {
    OPEN_VALIDASI_LIST = "openValidasiList",
    OPEN_APPROVAL_REQUEST_UPDATE = "openApprovalRequestUpdate",
    OPEN_MODAL_HISTORY = "openModalHistory",
    OPEN_MODAL_APPROVAL_REQUEST_UPDATE = "openMModalApprovalRequestUpdate",
    OPEN_MODAL_VALIDASI = "opeModalValidasi",
    GET_APPROVAL_STATUS = "get/approvalStatus",
    POST_STATUS_ASSET = "post/statusAssets",
    GET_APPROVAL_HISTORY = "get/historyApproval",
    GET_ACTITVITY_HISTORY = "get/activityHistory",
    POST_BULK_STATUS_ASSET = "postBulk/StatusAssets",
    GET_REQUEST_EDIT_HISTORY = "GET_REQUEST_EDIT_HISTORY",
    GET_DETAIL_USER_ACTIVITY = "getDetailUserActivity",
    GET_LAST_ACTIVITY = "getLastActivity"
}

enum MutationsAdminValidasi {
    SET_VALIDASI_LIST = "setValidasiList",
    SET_APPROVAL_REQUEST_UPDATE = "setApprovalRequestUpdate",
    SET_MODAL_HISTORY = "setModalHistory",
    SET_MODAL_APPROVAL_REQUEST_UPDATE = "setModalApprovalRequestUpdate",
    SET_MODAL_VALIDASI = "setModalValidasi",
    SET_APPROVAL_STATUS = "set/approvalStatus",
    SET_ACTION_STATUS_ASSET = "set/actionStatusAsset",
    SET_STATUS_MODAL_FEEDBACK = "set/statusModalFeedback",
    SET_LOADING_ADMIN_VALIDASI = "set/loadingAdminValidasi",
    SET_APPROVAL_HISTORY = "set/historyApproval",
    SET_ACTITVITY_HISTORY = "set/activityHistory",
    SET_SWITCH = "setSwitch",
    SET_DATA_REQUEST_EDIT = "setDataRequestEdit",
    SET_LAST_ACTIVITY = "setLastActivity",
    SET_BULK_REQUESTREVIEW_MODAL = "setBulkRequestReviewModal",
}

enum Getters { }

export { ActionsAdminValidasi, MutationsAdminValidasi, Getters };
