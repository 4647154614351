// import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Mutations, Getters } from "@/store/enums/StoreTutorialEnums";

export enum DrawerAdminEnum {
  STEP = 0,
  STEP_1 = 1,
}
@Module
export default class TutorialModules extends VuexModule {
  step: DrawerAdminEnum = 0;
  tabActive = "";
  filterStep = "";
  loading = false;
  loadingDocument = false;

  @Mutation
  [Mutations.STEP](data) {
    this.step = data;
  }

  @Mutation
  [Mutations.LOADING](data) {
    this.loading = data;
  }
  @Mutation
  [Mutations.LOADING_DOCUMENT](data) {
    this.loadingDocument = data;
  }
  @Mutation
  [Mutations.STEP_ASIDE](data) {
    this.loading = data;
  }
  @Mutation
  [Mutations.TAB_ACTIVE](data) {
    this.tabActive = data;
  }
  @Mutation
  [Mutations.FILTER_STEP](data) {
    this.filterStep = data;
  }
  get [Getters.STEP]() {
    return this.step;
  }

  get [Getters.TAB_ACTIVE]() {
    return this.tabActive;
  }

  get [Getters.STEP_ASIDE]() {
    return this.step;
  }
  get [Getters.LOADING]() {
    return this.loading;
  }
  get [Getters.LOADING_DOCUMENT]() {
    return this.loadingDocument;
  }
  get [Getters.FILTER_STEP]() {
    return this.filterStep;
  }
}
