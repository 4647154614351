import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

const service =
  process.env.VUE_APP_NODE_ENV == "local" ? "charter/api/v1" : "charter/api/v1";

@Module
export default class ProjectTemplateModules extends VuexModule {
  requestToken: any = {
    clientId: process.env.VUE_APP_MYTENS_CLIENT_ID,
    clientSecret: process.env.VUE_APP_MYTENS_CLIENT_SECRET,
  };

  reponseLogin: any = {
    page: 1,
    size: 5,
    nameApp: "mytds",
  };

  filter = {
    page: 1,
    itemsPerPage: 10,
    trash: 0,
    sortBy: ["created_at"],
    sortDesc: [1],
    filterName: "",
    filterNamaPartner: "",
  };

  informationTemplate = {};

  listDataTemplate = [];

  @Mutation
  [Mutations.SET_LIST_PROJECT_TEMPLATE](data) {
    this.listDataTemplate = data;
  }
  @Mutation
  [Mutations.SET_INFORMATION_TEMPLATE](data) {
    this.informationTemplate = data;
  }

  get getprojectTemplate() {
    return this.listDataTemplate;
  }

  get getProjectInformationDetail() {
    return this.informationTemplate;
  }

  get getFilterTemplate() {
    return this.filter;
  }

  @Action
  [Actions.GET_LIST_PROJECT_TEMPLATE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`${service}/template`, payload)
        .then(({ data }) => {
          console.log(data, "ini dara dari template");
          this.context.commit(
            Mutations.SET_LIST_PROJECT_TEMPLATE,
            data.data.data
          );
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }
  @Action
  [Actions.SAVE_AS_TEMPLATE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`${service}/template`, payload)
        .then(({ data }) => {
          console.log(data, "ini dara dari template");
          // this.context.commit(
          //   Mutations.SET_LIST_PROJECT_TEMPLATE,
          //   data.data.data
          // );
          resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }
  @Action
  [Actions.GET_DETAIl_TEMPLAE](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`${service}/template/${id}`)
        .then(({ data }) => {
          console.log(data, "ini dara dari template");
          // this.context.commit(
          //   Mutations.SET_LIST_PROJECT_TEMPLATE,
          //   data.data.data
          // );
          resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Action
  [Actions.PROJECT_INFORMATION_TEMPLATE](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`${service}/charter`, id)
        .then(({ data }) => {
          console.log("ini darai show", data.data);
          this.context.commit(Mutations.SET_INFORMATION_TEMPLATE, data.data);
          return resolve(data);
        })
        .catch((err) => {
          this.context.commit(Mutations.SET_ERROR, err);
          reject();
        });
    });
  }

  @Mutation
  [Mutations.SET_FILTER_TEMPLATE](payload) {
    this.filter = payload;
  }
}
